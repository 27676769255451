import { styled, Typography } from '@mui/material';

const SectionTitle = styled(Typography)(
  ({ fontSize, fontWeight, lineHeight, textAlign, theme }) => ({
    fontSize: fontSize ?? 42,
    fontWeight: fontWeight ?? 500,
    lineHeight: lineHeight ?? '50px',
    textAlign: textAlign ?? 'center',
    color: theme.palette.text.main,

    '@media (max-width: 905px)': {
      fontSize: fontSize ?? 32,
      fontWeight: fontWeight ?? 600,
      lineHeight: lineHeight ?? '40px',
    },

    '@media (max-width: 600px)': {
      fontSize: fontSize ?? 20,
      fontWeight: fontWeight ?? 600,
      lineHeight: lineHeight ?? '28px',
      textAlign: 'left',
    },
  }),
);

export default SectionTitle;
