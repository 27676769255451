import { ApiClient } from '../ApiClient';

export class SearchApi {
  /**
   * @alias module:api/SearchApi
   * @class
   * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
   * default to {@link module:ApiClient#instance} if unspecified.
   */
  constructor(apiClient) {
    this.apiClient = apiClient || ApiClient.generalApiInstance;
  }

  /**
   * Search contract or NFT by search-phrase. Only search more or equal 3 characters search phrase.
   *
   * @param {string} searchPhrase The keywords user want to search for
   */
  search(searchPhrase) {
    return this.apiClient.callApi('/search', 'get', {}, { searchPhrase });
  }
}
