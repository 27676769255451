import { styled } from '@mui/material/styles';
import {
  Reddit as RedditIcon,
  YouTube as YouTubeIcon,
  Instagram as InstagramReIcon,
  LocalOffer,
  ShoppingCart,
} from '@mui/icons-material';
import { ReactComponent as ActiveFilter } from 'assets/images/activeFilter.svg';
import { ReactComponent as Approved } from 'assets/images/approved.svg';
import { ReactComponent as ApprovedDark } from 'assets/images/approvedDark.svg';
import { ReactComponent as AVG } from 'assets/images/avg.svg';
import { ReactComponent as AVGLight } from 'assets/images/avgLight.svg';
import { ReactComponent as CheckDark } from 'assets/images/check-dark.svg';
import { ReactComponent as CheckLight } from 'assets/images/check-light.svg';
import { ReactComponent as Close } from 'assets/images/close.svg';
import { ReactComponent as CloseLight } from 'assets/images/closeLight.svg';
import { ReactComponent as ConfirmedIcon } from 'assets/images/collectionConfirmed.svg';
import { ReactComponent as Dark } from 'assets/images/dark.svg';
import { ReactComponent as DiscordIconOriginal } from 'assets/images/discord.svg';
import { ReactComponent as DescriptionDark } from 'assets/images/descriptionDark.svg';
import { ReactComponent as DescriptionLight } from 'assets/images/descriptionLight.svg';
import { ReactComponent as EmptyBids } from 'assets/images/NoChartData.svg';
import { ReactComponent as EnergiLogoSvg } from 'assets/images/logo.svg';
import { ReactComponent as EnergiNFTLogo } from 'assets/images/EnergiNFTLogoOnly.svg';
import { ReactComponent as Eth } from 'assets/images/ETH.svg';
import { ReactComponent as EthBlue } from 'assets/images/ETHBlue.svg';
import { ReactComponent as EthRed } from 'assets/images/ETHRed.svg';
import { ReactComponent as EthDark } from 'assets/images/ETHdark.svg';
import { ReactComponent as EthereumGrey } from 'assets/images/ETHgrey.svg';
import { ReactComponent as EthereumDarkGrey } from 'assets/images/ETHdarkgrey.svg';
import { ReactComponent as USD } from 'assets/images/USDLogo.svg';
import { ReactComponent as USDdark } from 'assets/images/USDdark.svg';
import { ReactComponent as NRGChrome } from 'assets/images/nrg-chrome.svg';
import { ReactComponent as NRGLogo } from 'assets/images/NRGLogo.svg';
import { ReactComponent as NRGLogoLight } from 'assets/images/NRGLogoLight.svg';
import { ReactComponent as NRGLogoDark } from 'assets/images/NRGLogoDark.svg';
import { ReactComponent as NRGLogoInverted } from 'assets/images/NRG.svg';
import { ReactComponent as NRGLogoInvertedLight } from 'assets/images/NRGInvertedLight.svg';
import { ReactComponent as NRGLogoInvertedDark } from 'assets/images/NRGInvertedDark.svg';
import { ReactComponent as NFTLogo } from 'assets/images/NFTLogo.svg';
import { ReactComponent as Filter } from 'assets/images/filter.svg';
import { ReactComponent as Items } from 'assets/images/items.svg';
import { ReactComponent as LargeGrid } from 'assets/images/large.svg';
import { ReactComponent as Light } from 'assets/images/light.svg';
import { ReactComponent as LockLight } from 'assets/images/lock-account.svg';
import { ReactComponent as LockDark } from 'assets/images/lock-account-dark.svg';
import { ReactComponent as MailSVG } from 'assets/images/newsletterMail.svg';
import { ReactComponent as MailSuccessSVG } from 'assets/images/newsletterSuccess.svg';
import { ReactComponent as MenuDiscordIconOriginal } from 'assets/images/menuDiscord.svg';
import { ReactComponent as Metamask } from 'assets/images/metamask.svg';
import { ReactComponent as NoHistoryData } from 'assets/images/noHistoryData.svg';
import { ReactComponent as OfferSettingsSvg } from 'assets/images/offerSettingsIllustration.svg';
import { ReactComponent as OptionSelected } from 'assets/images/optionSelected.svg';
import { ReactComponent as OptionSelectedDark } from 'assets/images/optionSelectedDark.svg';
import { ReactComponent as UncheckedLight } from 'assets/images/unchecked-light.svg';
import { ReactComponent as UncheckedDark } from 'assets/images/unchecked-dark.svg';
import { ReactComponent as SmallGrid } from 'assets/images/small.svg';
import { ReactComponent as Sort } from 'assets/images/sort.svg';
import { ReactComponent as Upload } from 'assets/images/upload.svg';
import { ReactComponent as Graph } from 'assets/images/Graph.svg';
import { ReactComponent as CollectionNew } from 'assets/images/collectionNew.svg';
import { ReactComponent as CollectionNewDark } from 'assets/images/collectionNewDark.svg';
import { ReactComponent as Public } from 'assets/images/public.svg';
import { ReactComponent as TwitterX } from 'assets/images/twitter-x.svg';
import { ReactComponent as Instagram } from 'assets/images/instagram.svg';
import { ReactComponent as DiscordNew } from 'assets/images/discordNew.svg';
import { ReactComponent as TelegramSVG } from 'assets/images/telegram.svg';
import { ReactComponent as Etherscan } from 'assets/images/etherscan.svg';
import { ReactComponent as More } from 'assets/images/more.svg';
import { ReactComponent as NRGTable } from 'assets/images/NRGTable.svg';
import { ReactComponent as CartDark } from 'assets/images/cartDark.svg';
import { ReactComponent as CartLight } from 'assets/images/cartLight.svg';
import { ReactComponent as SwapDark } from 'assets/images/swapDark.svg';
import { ReactComponent as SwapLight } from 'assets/images/swapLight.svg';
import { ReactComponent as OpenDark } from 'assets/images/openDark.svg';
import { ReactComponent as OpenLight } from 'assets/images/openLight.svg';
import { ReactComponent as Collection } from 'assets/images/collection.svg';
import { ReactComponent as GMILogoSVG } from 'assets/images/GMI-logo.svg';
import { ReactComponent as GMILogoOnlySVG } from 'assets/images/GMI_logo_only.svg';
import { ReactComponent as GMITokenLogoSVG } from 'assets/images/gmi-token-logo.svg';
import { ReactComponent as Offer } from 'assets/images/Offer.svg';
import { ReactComponent as Transfer } from 'assets/images/Transfer.svg';
import { ReactComponent as Minted } from 'assets/images/Minted.svg';
import { ReactComponent as Sale } from 'assets/images/Sale.svg';
import { ReactComponent as List } from 'assets/images/List.svg';
import { ReactComponent as Bid } from 'assets/images/Bid.svg';
import { ReactComponent as Help_Circle } from 'assets/images/Help_Circle.svg';
import { ReactComponent as Linkedin } from 'assets/images/linkedin.svg';
import { ReactComponent as GmiEthImageIcon } from 'assets/images/farmingAssets/gmi-eth.svg';
import { ReactComponent as GmiEthLpImage } from 'assets/images/farmingAssets/gmi-lp-token.svg';
import { ReactComponent as SushiSwapSVG } from 'assets/images/farmingAssets/sushi-swap-logo.svg';
import { ReactComponent as ETHNew } from 'assets/images/ETHNew.svg';
import { ReactComponent as ConfirmationNumberClosedSVG } from 'assets/images/confirmation-number-closed.svg';
import { ReactComponent as ConfirmationNumberCompleteSVG } from 'assets/images/confirmation-number-complete.svg';
import { ReactComponent as ConfirmationNumberOpenSVG } from 'assets/images/confirmation-number-open.svg';
import { ReactComponent as CopyLinkSVG } from 'assets/images/copy-link.svg';
import { ReactComponent as ChevronIconSVG } from 'assets/images/chevron.svg';
import { ReactComponent as NotFound404SVG } from 'assets/images/404.svg';
import { ReactComponent as LockerSVG } from 'assets/images/lockComponent.svg';
import { ReactComponent as GreenStarSVG } from 'assets/images/GreenStar.svg';
import { ReactComponent as Circle_1_SVG } from 'assets/images/Circle-1.svg';
import { ReactComponent as Circle_2_SVG } from 'assets/images/Circle-2.svg';
import { ReactComponent as Circle_3_SVG } from 'assets/images/Circle-3.svg';
import { ReactComponent as Circle_4_SVG } from 'assets/images/Circle-4.svg';
import { ReactComponent as ReferralBadgeSVG } from 'assets/images/ReferralBadge.svg';
import { ReactComponent as MetamaskVerticalSVG } from 'assets/images/metamask-vertical-text.svg';
import { ReactComponent as GMIPointLogoSVG } from 'assets/images/GMI-point-logo.svg';
import { ReactComponent as LockIcon } from 'assets/images/airdrop/lock.svg';
import { ReactComponent as ImportSign } from 'assets/images/import-sign.svg';
import { ReactComponent as PlusSign } from 'assets/images/plus-sign.svg';
import { ReactComponent as CloseButonSVG } from 'assets/images/close-button-with-bg.svg';
import { ReactComponent as MetamaskSVG } from 'assets/images/metamask-face.svg';
import { ReactComponent as VerifiedCheckmark } from 'assets/images/verified-checkmark.svg';
import { ReactComponent as WarningSVG } from 'assets/images/warning.svg';
import { ReactComponent as VerificationWarningSVG } from 'assets/images/warning-icon.svg';
import { ReactComponent as EthDarkCircleIcon } from 'assets/images/eth-dark-circle.svg';
import { ReactComponent as EthLightCircleIcon } from 'assets/images/eth-light-circle.svg';
import { ReactComponent as CancelCircleIcon } from 'assets/images/cancel-circle.svg';
import { ReactComponent as Vector } from 'assets/images/Vector.svg';
import { ReactComponent as SendSVG } from 'assets/images/send.svg';
import LaunchpadComingSoon from 'assets/images/launchpad/coming-soon.png';
import LaunchpadComingSoon904 from 'assets/images/launchpad/coming-soon-904.png';
import LaunchpadComingSoon599 from 'assets/images/launchpad/coming-soon-599.png';
import { ReactComponent as StatusSVG } from 'assets/images/launchpad/status.svg';
import { ReactComponent as CheckSVG } from 'assets/images/check.svg';
import TrashPNG from 'assets/images/trash.png';

export const UploadIcon = styled((props) => <Upload {...props} />)(() => ({
  width: 64,
  height: 64,
}));

export const VerifiedCheckmarkIcon = styled((props) => <VerifiedCheckmark {...props} />)(() => ({
  width: 24,
  height: 24,
}));

export const EthIcon = styled((props) => <Eth {...props} />)(() => ({
  width: '24px',
  height: '24px',
}));

export const EthDarkIcon = styled((props) => <EthDark {...props} />)(() => ({
  width: '24px',
  height: '24px',
}));

export const EthBlueIcon = styled((props) => <EthBlue {...props} />)(({ width, height }) => ({
  width: width ?? '20px',
  height: height ?? '20px',
}));

export const EthRedIcon = styled((props) => <EthRed {...props} />)(({ width, height }) => ({
  width: width ?? '20px',
  height: height ?? '20px',
}));

export const ApprovedIcon = styled((props) => <Approved {...props} />)(({ width, height }) => ({
  width: width ?? '35px',
  height: height ?? '35px',
  marginLeft: 6,
}));

export const ApprovedDarkIcon = styled((props) => <ApprovedDark {...props} />)(
  ({ width, height }) => ({
    width: width ?? '35px',
    height: height ?? '35px',
    marginLeft: 6,
  }),
);

export const OptionSelectedIcon = styled((props) => <OptionSelected {...props} />)(({ width }) => ({
  width: width ?? 20,
  aspectRatio: '1/1',
}));

export const OptionSelectedDarkIcon = styled((props) => <OptionSelectedDark {...props} />)(
  ({ width }) => ({
    width: width ?? 20,
    aspectRatio: '1/1',
  }),
);

export const UncheckedDarkIcon = styled((props) => <UncheckedDark {...props} />)(() => ({
  width: 20,
  height: 20,
}));

export const UncheckedLightIcon = styled((props) => <UncheckedLight {...props} />)(() => ({
  width: 20,
  height: 20,
}));

export const CloseIcon = styled((props) => <Close {...props} />)(() => ({
  width: '25px',
  height: '25px',
}));

export const CloseLightIcon = styled((props) => <CloseLight {...props} />)(() => ({
  width: '25px',
  height: '25px',
}));

export const EthereumGreyIcon = styled((props) => <EthereumGrey {...props} />)(({ fill }) => ({
  width: '25px',
  height: '25px',
  fill: fill ?? '#A2ADB8',
}));

export const EthereumDarkGreyIcon = styled((props) => <EthereumDarkGrey {...props} />)(() => ({
  width: '25px',
  height: '25px',
}));

export const USDLogo = styled((props) => <USD {...props} />)(({ width, height }) => ({
  width: width ?? '23px',
  height: height ?? '23px',
}));

export const USDLogoDark = styled((props) => <USDdark {...props} />)(({ width, height }) => ({
  width: width ?? '23px',
  height: height ?? '23px',
}));

export const NRGChromeIcon = styled((props) => <NRGChrome {...props} />)(
  ({ theme, width, height, color }) => ({
    width: width ?? '24px',
    height: height ?? '24px',

    '& path': {
      fill: color || theme.palette.icon.secondary,
    },
  }),
);

export const NRGLogoDarkIcon = styled((props) => <NRGLogoDark {...props} />)(
  ({ width, height }) => ({
    width: width ?? '26px',
    height: height ?? '26px',
  }),
);

export const NRGLogoLightIcon = styled((props) => <NRGLogoLight {...props} />)(
  ({ width, height }) => ({
    width: width ?? '26px',
    height: height ?? '26px',
  }),
);

export const NRGLogoIcon = styled((props) => <NRGLogo {...props} />)(({ width, height }) => ({
  width: width ?? '26px',
  height: height ?? '26px',
}));

export const NRGLogoInvertedIcon = styled((props) => <NRGLogoInverted {...props} />)(
  ({ width, height }) => ({
    width: width ?? '26px',
    height: height ?? '26px',
  }),
);

export const NRGLogoInvertedDarkIcon = styled((props) => <NRGLogoInvertedDark {...props} />)(
  ({ width, height }) => ({
    width: width ?? '25px',
    height: height ?? '25px',
  }),
);

export const NRGLogoInvertedLightIcon = styled((props) => <NRGLogoInvertedLight {...props} />)(
  ({ width, height }) => ({
    width: width ?? '25px',
    height: height ?? '25px',
  }),
);

export const EnergiIconCustom = styled((props) => <NRGChrome {...props} />)(
  ({ width, height, fill }) => ({
    width: width ?? '25px',
    height: height ?? '25px',
    fill: fill ?? '#00e676',
  }),
);

export const DiscordIcon = styled((props) => <DiscordIconOriginal {...props} />)(
  ({ theme, width }) => ({
    fill: theme.palette.text.white,
    width: width ?? 20,

    '&:hover': {
      fill: theme.palette.primary.main,
    },
  }),
);

export const NFTLogoIcon = styled((props) => <NFTLogo {...props} />)(({ width, height }) => ({
  width: width ?? 195,
  height: height ?? 42.5,
}));

export const LightIcon = styled((props) => <Light {...props} />)(() => ({
  width: 30,
  height: 30,

  '&:hover': {
    cursor: 'pointer',
  },
}));

export const DarkIcon = styled((props) => <Dark {...props} />)(() => ({
  width: 30,
  height: 30,

  '&:hover': {
    cursor: 'pointer',
  },
}));

export const MenuDiscordIcon = styled((props) => <MenuDiscordIconOriginal {...props} />)(
  ({ theme }) => ({
    fill: theme.palette.text.secondary,
    width: 18,

    '&:hover': {
      fill: theme.palette.text.main,
    },
  }),
);

export const MetamaskIcon = styled((props) => <Metamask {...props} />)(
  ({ width, height, margin }) => ({
    width: width ?? '25px',
    height: height ?? '25px',
    marginLeft: margin ?? '5px',
  }),
);

export const GMI = styled((props) => <GMILogoSVG {...props} />)(({ color }) => ({
  height: 32,
  '& .logo-theme': {
    fill: color,
  },
}));

export const GMIIcon = styled((props) => <GMILogoOnlySVG {...props} />)(({ color }) => ({
  height: 48,
  '& .logo-theme': {
    fill: color,
  },
}));

export const GMITokenLogo = styled((props) => <GMITokenLogoSVG {...props} />)(
  ({ color, width, height }) => ({
    height: height ?? 48,
    width: width ?? 48,

    '& .logo-theme': {
      fill: color,
    },
  }),
);

export const Logo = styled((props) => <EnergiLogoSvg {...props} />)(({ color }) => ({
  width: 222,
  height: 48,
  '& .logo-theme': {
    fill: color,
  },
}));

export const EnergiNFTLogoIcon = styled((props) => <EnergiNFTLogo {...props} />)(
  ({ width, height }) => ({
    width: width ?? 25,
    height: height ?? 25,
  }),
);

export const NoBidsSVG = styled((props) => <EmptyBids {...props} />)(() => ({
  width: '138px',
  height: '101px',
  margin: 'auto auto',
}));

export const NoHistoryDataIcon = styled((props) => <NoHistoryData {...props} />)(() => ({
  width: '152x',
  height: '102px',
  margin: 'auto auto',
}));

export const LargeGridIcons = styled((props) => <LargeGrid {...props} />)(() => ({
  width: '60px',
  marginLeft: '5px',
}));

export const SmallGridIcons = styled((props) => <SmallGrid {...props} />)(() => ({
  width: '60px',
  marginLeft: '5px',
}));

export const ActiveFilterIcon = styled((props) => <ActiveFilter {...props} />)(() => ({
  width: '23px',
  height: '23px',
}));

export const FilterIcon = styled((props) => <Filter {...props} />)(() => ({
  width: '23px',
  height: '23px',
}));

export const ItemsIcon = styled((props) => <Items {...props} />)(() => ({
  width: '25px',
  height: '25px',
  marginLeft: '5px',
}));

export const SortIcon = styled((props) => <Sort {...props} />)(() => ({
  width: '25px',
  height: '25px',
  marginLeft: '5px',
}));

export const LockLightIcon = styled((props) => <LockLight {...props} />)(() => ({
  width: 149,
  height: 123,
}));

export const LockDarkIcon = styled((props) => <LockDark {...props} />)(() => ({
  width: 149,
  height: 123,
}));

export const OfferSettingsSvgIcon = styled((props) => <OfferSettingsSvg {...props} />)(() => ({
  width: 110,
  height: 127,
}));

export const CollectionConfirmedIcon = styled((props) => <ConfirmedIcon {...props} />)(() => ({
  width: 18,
  height: 18,
  position: 'absolute',
  bottom: -1,
  right: -1,
}));

export const NewCollectionIcon = styled(({ mode, ...props }) =>
  mode === 'light' ? <CollectionNew {...props} /> : <CollectionNewDark {...props} />,
)(() => ({
  width: '10px',
  height: '10px',
  position: 'absolute',
  top: 1,
  right: 1,
}));

export const MailSVGIcon = styled((props) => <MailSVG {...props} />)(() => ({
  width: '25px',
  height: '25px',
}));

export const MailSuccessSVGIcon = styled((props) => <MailSuccessSVG {...props} />)(() => ({
  width: '25px',
  height: '25px',
}));

export const GraphIcon = styled((props) => <Graph {...props} />)(() => ({
  width: '140px',
  height: '32px',
}));
export const AVGIcon = styled((props) => <AVG {...props} />)(() => ({
  width: '9px',
  height: '12px',
}));

export const AVGLightIcon = styled((props) => <AVGLight {...props} />)(() => ({
  width: '9px',
  height: '12px',
}));

export const PublicIcon = styled((props) => <Public {...props} />)(() => ({
  width: '20px',
  height: '20px',
}));

export const DiscordNewIcon = styled((props) => <DiscordNew {...props} />)(({ color }) => ({
  width: '20px',
  height: '20px',

  '& path': {
    fill: color && color,
  },
}));

export const TelegramIcon = styled((props) => <TelegramSVG {...props} />)(() => ({
  width: '20px',
  height: '20px',
}));

export const TwitterXIcon = styled((props) => <TwitterX {...props} />)(() => ({
  width: '20px',
  height: '20px',
}));

export const InstagramIcon = styled((props) => <Instagram {...props} />)(() => ({
  width: '20px',
  height: '20px',
}));

export const EtherscanIcon = styled((props) => <Etherscan {...props} />)(({ theme }) => ({
  width: '20px',
  height: '20px',
  cursor: 'pointer',

  '&:hover': {
    '& path': {
      fill: theme.palette.text.main,
    },
  },
}));

export const MoreIcon = styled((props) => <More {...props} />)(() => ({
  width: '20px',
  height: '20px',
}));

export const NRGLogoTableIcon = styled((props) => <NRGTable {...props} />)(({ width, height }) => ({
  width: width ?? '26px',
  height: height ?? '26px',
}));

export const CartDarkIcon = styled((props) => <CartDark {...props} />)(({ width, height }) => ({
  width: width ?? '26px',
  height: height ?? '26px',
  marginRight: 4,
}));

export const CartLightIcon = styled((props) => <CartLight {...props} />)(({ width, height }) => ({
  width: width ?? '26px',
  height: height ?? '26px',
  marginRight: 4,
}));

export const SwapDarkIcon = styled((props) => <SwapDark {...props} />)(({ width, height }) => ({
  width: width ?? '26px',
  height: height ?? '26px',
  marginRight: 4,
}));

export const SwapLightIcon = styled((props) => <SwapLight {...props} />)(({ width, height }) => ({
  width: width ?? '26px',
  height: height ?? '26px',
  marginRight: 4,
}));

export const OpenDarkIcon = styled((props) => <OpenDark {...props} />)(({ width, height }) => ({
  width: width ?? '26px',
  height: height ?? '26px',
  marginTop: 2,
}));

export const OpenLightIcon = styled((props) => <OpenLight {...props} />)(({ width, height }) => ({
  width: width ?? '26px',
  height: height ?? '26px',
  marginTop: 2,
}));

export const CollectionIcon = styled((props) => <Collection {...props} />)(
  ({ width, height, theme }) => ({
    width: width ?? '18px',
    height: height ?? '24px',
    color: theme.palette.text.light,
  }),
);

export const MenuTwitterIcon = styled((props) => <TwitterX {...props} />)(({ theme }) => ({
  color: theme.palette.text.secondary,
  width: 15,

  '&:hover': {
    fill: theme.palette.text.main,
  },
}));

export const MenuInstagramIcon = styled((props) => <InstagramReIcon {...props} />)(({ theme }) => ({
  color: theme.palette.text.secondary,
  width: 19,

  '&:hover': {
    fill: theme.palette.text.main,
  },
}));

export const MenuRedditIcon = styled((props) => <RedditIcon {...props} />)(({ theme }) => ({
  color: theme.palette.text.secondary,
  width: 20,

  '&:hover': {
    fill: theme.palette.text.main,
  },
}));

export const MenuYouTubeIcon = styled((props) => <YouTubeIcon {...props} />)(({ theme }) => ({
  color: theme.palette.text.secondary,
  width: 20,

  '&:hover': {
    fill: theme.palette.text.main,
  },
}));

export const OfferIcon = styled((props) => <Offer {...props} />)(({ width, height }) => ({
  width: width ?? '20px',
  height: height ?? '20px',
}));

export const TransferIcon = styled((props) => <Transfer {...props} />)(({ width, height }) => ({
  width: width ?? '20px',
  height: height ?? '20px',
}));

export const MintedIcon = styled((props) => <Minted {...props} />)(({ width, height }) => ({
  width: width ?? '20px',
  height: height ?? '20px',
}));

export const SaleIcon = styled((props) => <Sale {...props} />)(({ width, height }) => ({
  width: width ?? '20px',
  height: height ?? '20px',
}));

export const ListIcon = styled((props) => <List {...props} />)(({ width, height }) => ({
  width: width ?? '20px',
  height: height ?? '20px',
}));

export const BidIcon = styled((props) => <Bid {...props} />)(({ width, height }) => ({
  width: width ?? '20px',
  height: height ?? '20px',
}));

export const Help_CircleIcon = styled((props) => <Help_Circle {...props} />)(
  ({ theme, width, height }) => ({
    width: width ?? '18px',
    height: height ?? '18px',

    '& path': {
      stroke: theme.palette.icon.secondary,
    },
  }),
);

export const DescriptionDarkIcon = styled((props) => <DescriptionDark {...props} />)(
  ({ width, height }) => ({
    width: width ?? '24px',
    height: height ?? '24px',
  }),
);

export const DescriptionLightIcon = styled((props) => <DescriptionLight {...props} />)(
  ({ width, height }) => ({
    width: width ?? '24px',
    height: height ?? '24px',
  }),
);

export const LinkedinIcon = styled((props) => <Linkedin {...props} />)(({ width, height }) => ({
  width: width ?? '20px',
  height: height ?? '20px',
}));

export const GmiEthImage = styled((props) => <GmiEthImageIcon {...props} />)(
  ({ width, height }) => ({
    width: width ?? '178px',
    height: height ?? '100px',
  }),
);

export const LpTokenImage = styled((props) => <GmiEthLpImage {...props} />)(
  ({ width, height }) => ({
    width: width ?? '178px',
    height: height ?? '100px',
  }),
);

export const SushiSwapIcon = styled((props) => <SushiSwapSVG {...props} />)(
  ({ width, height }) => ({
    width: width ?? '20px',
    height: height ?? '20px',
  }),
);

export const EthNewIcon = styled((props) => <ETHNew {...props} />)(({ theme, width, height }) => ({
  width: width ?? '24px',
  height: height ?? '24px',

  '& path': {
    fill: theme.palette.icon.secondary,
  },
}));

export const ConfirmationNumberOpen = styled((props) => <ConfirmationNumberOpenSVG {...props} />)(
  ({ width, height }) => ({
    width: width ?? '30px',
    height: height ?? '30px',
  }),
);

export const ConfirmationNumberClosed = styled((props) => (
  <ConfirmationNumberClosedSVG {...props} />
))(({ width, height }) => ({
  width: width ?? '30px',
  height: height ?? '30px',
}));

export const ConfirmationNumberComplete = styled((props) => (
  <ConfirmationNumberCompleteSVG {...props} />
))(({ width, height }) => ({
  width: width ?? '30px',
  height: height ?? '30px',
}));

export const CopyLinkIcon = styled((props) => <CopyLinkSVG {...props} />)(({ width, height }) => ({
  width: width ?? '24px',
  height: height ?? '24px',
}));

export const CheckDarkIcon = styled((props) => <CheckDark {...props} />)(
  ({ width, height, margin }) => ({
    width: width ?? '25px',
    height: height ?? '25px',
    marginLeft: margin ?? '5px',
  }),
);

export const CheckLightIcon = styled((props) => <CheckLight {...props} />)(
  ({ width, height, margin }) => ({
    width: width ?? '25px',
    height: height ?? '25px',
    marginLeft: margin ?? '5px',
  }),
);

export const ChevronIcon = styled((props) => <ChevronIconSVG {...props} />)(
  ({ width, height }) => ({
    width: width ?? '12px',
    height: height ?? '8px',
  }),
);

export const NotFound404Icon = styled((props) => <NotFound404SVG {...props} />)(
  ({ theme, width, height }) => ({
    width: width ?? '290px',
    height: height ?? '138px',

    '& path:nth-of-type(2)': {
      fill: theme.palette.text.main,
    },

    '@media(max-width: 420px)': {
      width: 218.55,
      height: 104,
    },
  }),
);
export const ReferralBadgeIcon = styled((props) => <ReferralBadgeSVG {...props} />)(
  ({ width, height }) => ({
    width: width ?? '44px',
    height: height ?? '57px',
  }),
);

export const GreenStarIcon = styled((props) => <GreenStarSVG {...props} />)(
  ({ width, height }) => ({
    width: width ?? '20px',
    height: height ?? '19px',
  }),
);

export const Circle_1_Icon = styled((props) => <Circle_1_SVG {...props} />)(
  ({ width, height, light }) => ({
    width: width ?? '40px',
    height: height ?? '40px',

    '& rect:first-of-type': {
      fill: light ? '#07BD66' : '#00AB58',
    },
  }),
);

export const Circle_2_Icon = styled((props) => <Circle_2_SVG {...props} />)(
  ({ width, height, light }) => ({
    width: width ?? '40px',
    height: height ?? '40px',

    '& rect:first-of-type': {
      fill: light ? '#07BD66' : '#00AB58',
    },
  }),
);

export const Circle_3_Icon = styled((props) => <Circle_3_SVG {...props} />)(
  ({ width, height, light }) => ({
    width: width ?? '40px',
    height: height ?? '40px',

    '& rect:first-of-type': {
      fill: light ? '#07BD66' : '#00AB58',
    },
  }),
);

export const Circle_4_Icon = styled((props) => <Circle_4_SVG {...props} />)(
  ({ width, height, light }) => ({
    width: width ?? '40px',
    height: height ?? '40px',

    '& rect:first-of-type': {
      fill: light ? '#07BD66' : '#00AB58',
    },
  }),
);

export const LockerIcon = styled((props) => <LockerSVG {...props} />)(
  ({ theme, width, height }) => ({
    width: width ?? '70px',
    height: height ?? '93px',

    '& path': {
      fill: theme.palette.text.main,
    },
  }),
);

export const MetamaskVerticalIcon = styled((props) => <MetamaskVerticalSVG {...props} />)(
  ({ width, height }) => ({
    width: width ?? 255,
    height: height ?? 153,
  }),
);

export const GMIPointLogo = styled((props) => <GMIPointLogoSVG {...props} />)(
  ({ width, height }) => ({
    height: height ?? 49,
    width: width ?? 52,
  }),
);

export const SmallLockIcon = styled((props) => <LockIcon {...props} />)(
  ({ theme, width, height }) => ({
    width: width ?? 24,
    height: height ?? 24,

    '& path': {
      fill: theme.palette.text.main,
    },
  }),
);

export const ImportIcon = styled((props) => <ImportSign {...props} />)(
  ({ theme, width, height }) => ({
    width: width ?? 24,
    height: height ?? 24,

    '& path': {
      fill: theme.palette.text.main,
    },
  }),
);

export const PlusIcon = styled((props) => <PlusSign {...props} />)(({ theme, width, height }) => ({
  width: width ?? 24,
  height: height ?? 24,

  '& path': {
    fill: theme.palette.text.main,
  },
}));

export const CloseButton = styled((props) => <CloseButonSVG {...props} />)(
  ({ theme, width, height }) => ({
    width: width ?? 40,
    height: height ?? 40,

    '& rect': {
      fill: theme.palette.background.default,
    },

    '@media(max-width: 480px)': {
      width: width ?? 24,
      height: height ?? 24,
    },
  }),
);

export const MetamaskFaceIcon = styled((props) => <MetamaskSVG {...props} />)(
  ({ width, height }) => ({
    width: width ?? 105,
    height: height ?? 105,
  }),
);

export const WarningIcon = styled((props) => <WarningSVG {...props} />)(
  ({ width, black, height }) => ({
    width: width ?? 23,
    height: height ?? 23,

    '& path': {
      fill: black ? 'black' : 'white',
    },
  }),
);

export const VerificationWarningIcon = styled((props) => <VerificationWarningSVG {...props} />)(
  ({ width, height }) => ({
    width: width ?? 23,
    height: height ?? 23,
  }),
);

export const EthDarkCircle = styled((props) => <EthDarkCircleIcon {...props} />)(
  ({ width, height }) => ({
    width: width ?? 32,
    height: height ?? 32,
  }),
);

export const EthLightCircle = styled((props) => <EthLightCircleIcon {...props} />)(
  ({ width, height }) => ({
    width: width ?? 32,
    height: height ?? 32,
  }),
);

export const CancelCircle = styled((props) => <CancelCircleIcon {...props} />)(
  ({ theme, width, height }) => ({
    width: width ?? 24,
    height: height ?? 24,

    '& path': {
      fill: theme.palette.mode === 'dark' ? theme.palette.icon.clock : theme.palette.icon.secondary,
    },
  }),
);

export const SwapHorizIcon = styled((props) => <SwapDark {...props} />)(
  ({ theme, width, height }) => ({
    width: width ?? 24,
    height: height ?? 24,

    '& path': {
      fill: theme.palette.icon.tertiary,
    },
  }),
);

export const LocalOfferIcon = styled((props) => <LocalOffer {...props} />)(
  ({ theme, width, height }) => ({
    width: width ?? 24,
    height: height ?? 24,
    marginRight: 4,

    '& path': {
      fill: theme.palette.icon.tertiary,
    },
  }),
);

export const CartIcon = styled((props) => <ShoppingCart {...props} />)(
  ({ theme, width, height }) => ({
    width: width ?? 24,
    height: height ?? 24,

    '& path': {
      fill: theme.palette.icon.tertiary,
    },
  }),
);

export const VectorIcon = styled((props) => <Vector {...props} />)(({ theme, width, height }) => ({
  width: width ?? 24,
  height: height ?? 24,

  '& path': {
    fill: theme.palette.mode === 'dark' ? theme.palette.icon.clock : theme.palette.icon.secondary,
  },
}));

export const SendIcon = styled((props) => <SendSVG {...props} />)(({ theme, width, height }) => ({
  width: width ?? 24,
  height: height ?? 24,

  '& path': {
    fill: theme.palette.text.main,
  },
}));

export const LaunchpadComingSoonImage = ({ width, height }) => {
  return <img src={LaunchpadComingSoon} width={width} height={height} />;
};

export const LaunchpadComingSoon904Image = ({ width, height }) => {
  return <img src={LaunchpadComingSoon904} width={width} height={height} />;
};

export const LaunchpadComingSoon599Image = ({ width, height }) => {
  return <img src={LaunchpadComingSoon599} width={width} height={height} />;
};

export const StatusIcon = styled((props) => <StatusSVG {...props} />)(
  ({ theme, width, height, color }) => ({
    width: width ?? 16,
    height: height ?? 16,

    '& path': {
      fill: color || theme.palette.icon.tertiary,
    },
  }),
);

export const CheckIcon = styled((props) => <CheckSVG {...props} />)(
  ({ theme, width, height, color }) => ({
    width: width ?? 19,
    height: height ?? 19,

    '& path': {
      fill: color || theme.palette.icon.tertiary,
    },
  }),
);

export const TrashIcon = ({ width = 22, height = 'auto' }) => {
  return <img src={TrashPNG} width={width} height={height} />;
};
