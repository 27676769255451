import React from 'react';
import { useHistory } from 'react-router-dom';
import { styled, useMediaQuery } from '@mui/material';
import Modal, { Divider } from 'components/Modal';
import { Text } from 'components/Text';
import { GeneralFlexBox } from 'components/StyledComponents';
import { GrayButtonOutlined, StandardButton } from 'components/Button';

/********************  Styled Components  ********************/
const ContentWrapper = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  maxWidth: 460,
  padding: 24,
}));

/********************  Main Component  ********************/
const BeforeClaimModal = ({ isOpen, onDismiss, continueToClaim, width }) => {
  const below480 = useMediaQuery('(max-width: 480px)');
  const history = useHistory();

  return (
    <Modal
      title="Before you claim"
      isOpen={isOpen}
      onDismiss={onDismiss}
      width={width}
      borderradius={10}
    >
      <ContentWrapper>
        <Text main="true" fs={32} fw={600} lh={40} mb={24} xs={{ fs: 28, fw: 500, lh: 36 }}>
          Don’t lose your 10% GMI Airdrop Bonus
        </Text>
        <Text tertiary="true" fs={16} fw={600} lh={24}>
          You haven’t entered your referral link yet. Enter your referral Link Now so you don’t lose
          your elegibility for 10% GMI Airdrop Bonus.
        </Text>
      </ContentWrapper>

      <Divider />

      <GeneralFlexBox width="100%" gap={16} justify="center" sx={{ py: 3 }}>
        <GrayButtonOutlined width={below480 ? 156 : 171} height={48} onClick={continueToClaim}>
          Continue to claim
        </GrayButtonOutlined>
        <StandardButton
          width={below480 ? 156 : 169}
          height={48}
          sx={{ p: 0 }}
          onClick={() => history.push('/rewards/referral_program/apply_promo')}
        >
          Enter referral link
        </StandardButton>
      </GeneralFlexBox>
    </Modal>
  );
};

export default BeforeClaimModal;
