import React from 'react';
import { styled, Button, Skeleton, useMediaQuery, Typography } from '@mui/material';

import { useUserContext } from 'context';
import useWalletContext from 'hooks/useWalletContext';
import { shorten } from 'utils/helper';

import { ConnectButton } from 'components/Button';
import Blockie from 'components/Blockie';

/********************  Styled Components  ********************/
const UserButton = styled((props) => <Button variant="contained" disableRipple {...props} />)(
  ({ theme, open }) => ({
    backgroundColor: 'transparent',
    border: `1px solid ${!open ? theme.palette.border.decorative : theme.palette.button.active}`,
    borderRadius: 8,
    lineHeight: '24px',
    letterSpacing: '0.15px',
    fontSize: 16,
    fontWeight: 700,
    color: theme.palette.text.secondary,
    boxShadow: 'none',
    height: 37,

    '@media(max-width: 1023px)': {
      fontSize: 13,
    },
    '@media(max-width: 599px)': {
      minWidth: 'auto',
    },

    '&:hover': {
      border: `1px solid ${theme.palette.border.medium}`,
      backgroundColor: theme.palette.background.default,
      boxShadow: 'none',
    },

    '&:focus': {
      border: `1px solid ${theme.palette.button.active}`,
      backgroundColor: theme.palette.border.primary,
    },

    '&:active': {
      border: `1px solid ${theme.palette.button.active}`,
      backgroundColor: theme.palette.border.primary,
    },

    '&:disabled': {
      opacity: 0.24,
      border: `1px solid ${theme.palette.border.medium}`,
      color: theme.palette.text.main,
      backgroundColor: theme.palette.background.default,
    },
  }),
);

const CustomText = styled(Typography)(() => ({
  fontSize: 16,
  fontWeight: 700,
  lineHeight: '24px',

  '@media(max-width: 1023px)': {
    fontSize: 13,
  },
}));

const ConnectButtonSkeleton = styled((props) => <Skeleton variant="rectangular" {...props} />)(
  () => ({
    borderRadius: 8,
    width: 220,
    height: 37,
  }),
);

/********************  Main Component  ********************/
const AddressButton = ({ openWalletSideBar }) => {
  const { address, isConnected, isInstalled } = useWalletContext();
  const { displayName } = useUserContext();

  const below1024 = useMediaQuery('(max-width: 1023px)');

  if (isInstalled === undefined) {
    return <ConnectButtonSkeleton />;
  }

  if (isConnected) {
    return (
      <UserButton onClick={openWalletSideBar}>
        <Blockie size={25} />
        {displayName || shorten(address, 5)}
      </UserButton>
    );
  }

  return (
    <ConnectButton width={below1024 ? 175 : 210} height={37}>
      <CustomText>Connect to Metamask</CustomText>
    </ConnectButton>
  );
};

export default AddressButton;
