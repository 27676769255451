import React from 'react';
import { useStateIfMounted } from 'use-state-if-mounted';

import { styled } from '@mui/material/styles';

/************************* Styled Components  **************************/
const SwitchInput = styled('input')(({ theme }) => ({
  position: 'relative',
  appearance: 'none',
  outline: 'none',
  width: 40,
  height: 20,
  backgroundColor: theme.palette.background.white,
  border: `3px solid ${theme.palette.background.medium}`,
  borderRadius: 50,
  boxShadow: `inset -20px 0 0 0 ${theme.palette.background.medium}`,
  transitionDuration: '150ms',
  cursor: 'pointer',
  '&:after': {
    content: '""',
    position: 'absolute',
    top: 1,
    left: 1,
    width: 20,
    height: 20,
    backgroundColor: 'green',
    borderRadius: '50%',
    boxShadow: '2px 4px 6px rgba(0,0,0,0.2)',
  },
  '&:checked': {
    borderColor: theme.palette.primary.main,
    boxShadow: `inset 20px 0 0 0 ${theme.palette.primary.main}`,
    backgroundColor: 'white',
  },
  '&:checked:after': {
    borderColor: theme.palette.primary.main,
  },
}));

/************************* Main Component  **************************/
const SwitchCheckbox = ({ name, onChange, checked = false }) => {
  const [isChecked, setIsChecked] = useStateIfMounted(checked);
  const handleChange = (e) => {
    let newState = e.target.checked;
    setIsChecked(newState);
    onChange && onChange(newState);
  };
  return (
    <SwitchInput
      type="checkbox"
      name={name}
      checked={isChecked}
      onChange={handleChange}
      value={isChecked}
      id={`field-${name}`}
    />
  );
};

export default SwitchCheckbox;
