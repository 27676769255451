import { useCallback } from 'react';
import { waitForTransaction } from '@wagmi/core';
import dotenv from 'dotenv';

import {
  ERC20ABI,
  ERC721ABI,
  ExchangeContractABI,
  ExchangeHelperContractABI,
} from 'constants/abis';
import {
  EXCHANGE_PROXY_ADDRESS,
  EXCHANGE_HELPER_PROXY_ADDRESS,
  EIP712_SIGNER_MESSAGE,
  WETH_PROXY_ADDRESS,
  MAX_APPROVAL_AMOUNT,
  MAX_GAS_ALLOWANCE,
  DEFAULT_NETWORK,
  SUPPORTED_NETWORKS,
  WNRG_PROXY_ADDRESS,
  SOMETHING_WENT_WRONG,
} from 'constants/index';
import useWalletContext from 'hooks/useWalletContext';
import { useSwitchNetwork } from './common';
import { calculateGasMargin, useContract } from 'utils/index';

dotenv.config();

const chainId = parseInt(DEFAULT_NETWORK);
const exchangeProxy = EXCHANGE_PROXY_ADDRESS[chainId];
const exchangeHelperProxy = EXCHANGE_HELPER_PROXY_ADDRESS[chainId];

export const useSwitchNetworkAndReload = () => {
  const { chainId: currentChainId } = useWalletContext();
  const swtichNetwork = useSwitchNetwork(chainId);

  const switchNetworkAndReload = useCallback(async () => {
    if (currentChainId === chainId) {
      return;
    }

    const networkCheck = await swtichNetwork();
    if (!networkCheck) {
      throw Error('Network change request rejected');
    } else {
      // We need a huge refactor to get things working without refactor
      window.location.reload();
      return;
    }
  }, [currentChainId, swtichNetwork]);
  return switchNetworkAndReload;
};

// check if exchange proxy is approved for given tokenId, if not get approval
export const useApproveExchange = ({ contractAddress, tokenId }) => {
  const nftContract = contractAddress && useContract(contractAddress, ERC721ABI.abi);

  const handleApproveExchange = useCallback(async () => {
    const txHash = (await nftContract.write.approve([exchangeProxy, tokenId])) || {};
    const receipt = await waitForTransaction({ hash: txHash });
    return receipt;
  }, [nftContract, tokenId]);
  return handleApproveExchange;
};

export const useCheckExchangeApproved = ({ contractAddress, tokenId }) => {
  const nftContract = contractAddress && useContract(contractAddress, ERC721ABI.abi);
  const switchNetworkAndReload = useSwitchNetworkAndReload();

  const checkExchangeApproved = useCallback(async () => {
    if (!contractAddress) {
      throw Error(SOMETHING_WENT_WRONG);
    }

    await switchNetworkAndReload();

    // check if exchange contract proxy is already approved
    const approvedAdr = await nftContract.read.getApproved([tokenId]);
    if (!approvedAdr) {
      return;
    }
    return approvedAdr.toLowerCase() === exchangeProxy.toLowerCase();
  }, [contractAddress, nftContract, switchNetworkAndReload, tokenId]);
  return checkExchangeApproved;
};

export const useApproveAllExchange = ({ contractAddress }) => {
  const nftContract = contractAddress && useContract(contractAddress, ERC721ABI.abi);

  const handleApproveAllExchange = useCallback(async () => {
    const txHash = (await nftContract.write.setApprovalForAll([exchangeProxy, true])) || {};
    const receipt = await waitForTransaction({ hash: txHash });
    return receipt;
  }, [nftContract]);

  return handleApproveAllExchange;
};

export const useCheckExchangeApprovedAll = ({ contractAddress, ownerAddress }) => {
  const nftContract = contractAddress && useContract(contractAddress, ERC721ABI.abi);
  const switchNetworkAndReload = useSwitchNetworkAndReload();

  const checkExchangeApprovedAll = useCallback(async () => {
    if (!contractAddress || !ownerAddress) {
      throw Error(SOMETHING_WENT_WRONG);
    }

    await switchNetworkAndReload();

    // Check if exchange contract proxy is already approved for all NFTs of the owner
    const isApprovedForAll = await nftContract.read.isApprovedForAll([ownerAddress, exchangeProxy]);
    return isApprovedForAll;
  }, [contractAddress, nftContract, switchNetworkAndReload, ownerAddress]);

  return checkExchangeApprovedAll;
};

// check if exchange proxy is approved for given tokenId, if not get approval
export const useApproveExchangeWETH = () => {
  const wethContract = useContract(
    WETH_PROXY_ADDRESS[SUPPORTED_NETWORKS.Ethereum],
    ERC20ABI.abi,
    SUPPORTED_NETWORKS.Ethereum,
  );

  const handleApproveExchangeWETH = useCallback(async () => {
    const tx =
      (await wethContract.write.approve([exchangeProxy, BigInt(MAX_APPROVAL_AMOUNT)])) || {};
    return tx;
  }, [wethContract]);
  return handleApproveExchangeWETH;
};

// check if exchange proxy is approved for given tokenId, if not get approval
export const useApproveExchangeWNRG = () => {
  const wnrgContract = useContract(
    WNRG_PROXY_ADDRESS[SUPPORTED_NETWORKS.Energi],
    ERC20ABI.abi,
    SUPPORTED_NETWORKS.Energi,
  );

  const handleApproveExchangeWNRG = useCallback(async () => {
    const tx =
      (await wnrgContract.write.approve([exchangeProxy, BigInt(MAX_APPROVAL_AMOUNT)])) || {};
    return tx;
  }, [wnrgContract]);
  return handleApproveExchangeWNRG;
};

export const useGetExchangeApprovedWETH = () => {
  const wethContract = useContract(
    WETH_PROXY_ADDRESS[SUPPORTED_NETWORKS.Ethereum],
    ERC20ABI.abi,
    SUPPORTED_NETWORKS.Ethereum,
  );
  const switchNetworkAndReload = useSwitchNetworkAndReload();
  const { address } = useWalletContext();

  const getExchangeApprovedWETH = useCallback(async () => {
    await switchNetworkAndReload();

    // check if exchange contract proxy is already approved
    const approveAmount = await wethContract.read.allowance([address, exchangeProxy]);
    return approveAmount;
  }, [address, switchNetworkAndReload, wethContract]);
  return getExchangeApprovedWETH;
};

export const useGetExchangeApprovedWNRG = () => {
  const wnrgContract = useContract(
    WNRG_PROXY_ADDRESS[SUPPORTED_NETWORKS.Energi],
    ERC20ABI.abi,
    SUPPORTED_NETWORKS.Energi,
  );
  const switchNetworkAndReload = useSwitchNetworkAndReload();
  const { address } = useWalletContext();

  const getExchangeApprovedWNRG = useCallback(async () => {
    await switchNetworkAndReload();

    // check if exchange contract proxy is already approved
    const approveAmount = await wnrgContract.read.allowance([address, exchangeProxy]);
    return approveAmount;
  }, [address, switchNetworkAndReload, wnrgContract]);
  return getExchangeApprovedWNRG;
};

// check if order maker still hold the NFT
export const useCheckIfMakerIsOwner = ({ contractAddress }) => {
  const nftContract = contractAddress && useContract(contractAddress, ERC721ABI.abi);
  const checkIfMakerIsOwner = useCallback(
    async ({ contractAddress, maker, tokenId }) => {
      try {
        if (!contractAddress || !maker || !tokenId) {
          return false;
        }
        const owner = await nftContract.read.ownerOf([tokenId]);
        if (owner && owner.toLowerCase() === maker.toLowerCase()) {
          return true;
        }
      } catch (e) {
        console.error(e);
      }
      return false;
    },
    [nftContract],
  );

  return checkIfMakerIsOwner;
};

// Recursive function to check orderKeyHash of order and to check if any such order is already filled.
const checkAndUpdateOrderIfFilled = async (
  exchangeContractProxy,
  exchangeHelperContractProxy,
  order,
) => {
  try {
    if (!order) {
      return false;
    }

    const orderKeyHash = await exchangeHelperContractProxy.read.hashKey([order]);
    const filled = await exchangeContractProxy.read.getOrderFill([orderKeyHash]);
    if (filled == 0) {
      return order;
    } else {
      return checkAndUpdateOrderIfFilled(exchangeContractProxy, {
        ...order,
        salt: order.salt + 1,
      });
    }
  } catch (e) {
    console.error(e);
    return false;
  }
};

export const useCheckAndUpdateOrderIfFilled = () => {
  const exchangeContractProxy = useContract(exchangeProxy, ExchangeContractABI);
  const exchangeHelperContractProxy = useContract(exchangeHelperProxy, ExchangeHelperContractABI);
  const check = useCallback(
    async (order) => {
      return await checkAndUpdateOrderIfFilled(
        exchangeContractProxy,
        exchangeHelperContractProxy,
        order,
      );
    },
    [exchangeContractProxy],
  );
  return check;
};

export const useSignOrder = () => {
  const { address } = useWalletContext();
  const sign = useCallback(async (message) => {
    const msgParams = {
      ...EIP712_SIGNER_MESSAGE,
      domain: {
        name: 'Energi',
        version: '1',
        chainId,
        verifyingContract: EXCHANGE_PROXY_ADDRESS[chainId].toLowerCase(),
      },
      message,
    };
    let params = [address, JSON.stringify(msgParams)];

    try {
      const response = await window.ethereum.request({
        method: 'eth_signTypedData_v4',
        params,
      });
      return response;
    } catch (err) {
      return console.error(err);
    }
  }, []);
  return sign;
};

export const useMultiSignOrder = () => {
  const { address, chainId } = useWalletContext();

  const multiSign = useCallback(
    async (messages) => {
      const signPromises = messages.map((message) => {
        const msgParams = {
          ...EIP712_SIGNER_MESSAGE,
          domain: {
            name: 'Energi',
            version: '1',
            chainId,
            verifyingContract: EXCHANGE_PROXY_ADDRESS[chainId].toLowerCase(),
          },
          message,
        };
        const params = [address, JSON.stringify(msgParams)];

        return window.ethereum
          .request({
            method: 'eth_signTypedData_v4',
            params,
          })
          .catch((err) => {
            console.error(err);
            throw new Error('Signature rejected');
          });
      });

      const signedOrders = await Promise.all(signPromises);
      return signedOrders;
    },
    [address, chainId],
  );

  return multiSign;
};

// BUY NFT
export const useMatchBuyOrderTx = () => {
  const { address } = useWalletContext();
  const exchangeContractProxy = exchangeProxy && useContract(exchangeProxy, ExchangeContractABI);

  const handleMatchBuyOrderTx = useCallback(
    async ({
      amount,
      makerOrder,
      takerOrder,
      makerSign,
      matchBeforeTimestamp,
      matchAllowanceSignature,
    }) => {
      if (!address) {
        return null;
      }
      try {
        // await exchangeContractProxy.read.getOrderKeyHash([makerOrder]);
        const hash = await exchangeContractProxy.write.matchOrders(
          [
            takerOrder, // orderLeft - buyer
            '0x', // signatureLeft
            0, // matchLeftBeforeTimestamp
            '0x', // orderBookSignatureLeft
            makerOrder, // orderRight - seller
            makerSign, //signatureRight
            matchBeforeTimestamp, // matchRightBeforeTimestamp
            matchAllowanceSignature, // orderBookSignatureRight
          ],
          {
            from: address,
            gasLimit: calculateGasMargin(MAX_GAS_ALLOWANCE),
            value: amount,
          },
        );

        return hash;
      } catch (e) {
        console.error(e);
        return null;
      }
    },
    [address, exchangeContractProxy],
  );

  return handleMatchBuyOrderTx;
};

export const useMatchAcceptOrderTx = () => {
  const { address } = useWalletContext();
  const exchangeContractProxy = exchangeProxy && useContract(exchangeProxy, ExchangeContractABI);

  const handleMatchAcceptOrder = useCallback(
    async ({
      makerOrder,
      takerOrder,
      makerSign,
      matchBeforeTimestamp,
      matchAllowanceSignature,
    }) => {
      if (!address) {
        return null;
      }
      try {
        const hash = await exchangeContractProxy.write.matchOrders(
          [
            takerOrder, // orderRight
            '0x', //signatureRight
            0, // matchRightBeforeTimestamp
            '0x', // orderBookSignatureRight
            makerOrder, // orderLeft
            makerSign, // signatureLeft
            matchBeforeTimestamp, // matchLeftBeforeTimestamp
            matchAllowanceSignature, // orderBookSignatureLeft
          ],
          {
            from: address,
            gasLimit: calculateGasMargin(MAX_GAS_ALLOWANCE),
          },
        );

        return hash;
      } catch (e) {
        console.error(e);
        return null;
      }
    },
    [address, exchangeContractProxy],
  );

  return handleMatchAcceptOrder;
};

// SWEEP NFTS
export const useBatchMatchBuyOrderTx = () => {
  const { address } = useWalletContext();
  const exchangeContractProxy = exchangeProxy && useContract(exchangeProxy, ExchangeContractABI);

  const handleBatchMatchBuyOrderTx = useCallback(
    async ({ amount, orders, makerSigns, matchBeforeTimestamps, matchAllowanceSignatures }) => {
      if (!address) {
        return null;
      }
      try {
        // await exchangeContractProxy.read.getOrderKeyHash([makerOrder]);
        const hash = await exchangeContractProxy.write.batchMatchOrders(
          [
            orders, // pairs of left and right orders
            makerSigns, // pairs of left and right signatures
            matchBeforeTimestamps, // pairs of left and right matchBeforeTimestamps
            matchAllowanceSignatures, // pairs of left and right orderBookSignatures
          ],
          {
            from: address,
            gasLimit: calculateGasMargin(MAX_GAS_ALLOWANCE),
            value: amount,
          },
        );

        return hash;
      } catch (e) {
        console.error(e);
        return null;
      }
    },
    [address, exchangeContractProxy],
  );

  return handleBatchMatchBuyOrderTx;
};
