import React from 'react';
import { useHistory } from 'react-router-dom';
import { styled, useTheme, useMediaQuery } from '@mui/material';
import { StandardButton } from 'components/Button';
import { GeneralFlexBox } from 'components/StyledComponents';
import { GreenLinkText } from 'components/Text';
import SectionTitle from 'components/General/Section/SectionTitle';

/********************  Styled Components  ********************/
const ImageWrapper = styled('div')(({ theme }) => ({
  width: '50%',
  borderRadius: 6,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'start',
  backgroundColor: theme.palette.background.filter,
  border: `1px solid ${theme.palette.border.decorative}`,

  '@media (max-width: 800px)': {
    width: '100%',
  },

  img: {
    width: '100%',
    height: 369,
    objectFit: 'fit',

    '@media (max-width: 1150px)': {
      objectFit: 'cover',
    },
    '@media (max-width: 800px)': {
      objectFit: 'contain',
    },
    '@media (max-width: 420px)': {
      height: 350,
    },
  },
}));

/********************  Main Components  ********************/
const ImageCallToAction = ({
  image,
  title,
  text,
  buttonText,
  route,
  docLink,
  buttonLg,
  shortText,
}) => {
  const theme = useTheme();
  const history = useHistory();
  const below480 = useMediaQuery('(max-width: 480px)');

  return (
    <ImageWrapper>
      <GeneralFlexBox
        width="100%"
        sx={{
          borderBottom: `1px solid ${theme.palette.border.decorative}`,
        }}
      >
        <img src={image} alt="3d Coin With GMI Logo" style={{ objectFit: 'contain' }} />
      </GeneralFlexBox>
      <GeneralFlexBox direction="column" padding="24px 30px 24px 24px" align="start" gap={16}>
        <SectionTitle fontSize={24} fontWeight={500} lineHeight="32px">
          {title}
        </SectionTitle>
        <SectionTitle
          fontSize={14}
          fontWeight={400}
          lineHeight="20px"
          sx={{
            textAlign: 'left',
            maxWidth: '530px',
            width: `${below480 ? '95%' : '100%'}`,
          }}
        >
          {shortText ? (
            <>
              {text.split('.')[0]}.<br />
              {text.split('.')[1]}
            </>
          ) : (
            text
          )}
        </SectionTitle>
        <GeneralFlexBox gap={24}>
          <StandardButton
            width={buttonLg ? 141 : 104}
            height={48}
            onClick={() => history.push(route)}
          >
            {buttonText}
          </StandardButton>
          <GreenLinkText href={docLink} sx={{ width: '90px' }}>
            Learn more
          </GreenLinkText>
        </GeneralFlexBox>
      </GeneralFlexBox>
    </ImageWrapper>
  );
};

export default ImageCallToAction;
