import { ApiClient } from '../ApiClient';

export class FarmingStatsApi {
  /**
   * Constructs a new FarmingStatsApi.
   * @alias module:api/FarmingStatsApi
   * @class
   * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
   * default to {@link module:ApiClient#instance} if unspecified.
   */
  constructor(apiClient) {
    this.apiClient = apiClient || ApiClient.generalApiInstance;
  }

  /**
   * Get farming stats Values
   */
  async getFarmingStats() {
    return await this.apiClient.callApi('/farming/stats', 'get', {}, {}, null);
  }
}
