import React from 'react';
import { styled, Typography } from '@mui/material';

/********************  Styled Components  ********************/
const Container = styled('div')(({ theme }) => ({
  margin: theme.spacing(3, 0),
}));

const Wrapper = styled('div')(({ max }) => ({
  maxWidth: max ?? 'auto',
}));

const Table = styled('table')(({ theme }) => ({
  width: '100%',
  padding: 0,
  margin: 0,
  border: `1px solid ${theme.palette.border.separator}`,
  borderRadius: 6,
  '& .MuiFormControl-root': {
    width: '100%',
  },
  '& fieldset': {
    border: 'none',
  },
}));

const TableRow = styled('tr')(({ theme, index }) =>
  index > 1
    ? {
        border: `1px solid ${theme.palette.border.separator}`,
        borderBottomWidth: 0,
        borderLeftWidth: 0,
        borderRightWidth: 0,
      }
    : {
        border: 'none',
      },
);

const FirstColumn = styled('td')(({ theme }) => ({
  backgroundColor: theme.palette.ui2,
  borderRight: `1px solid ${theme.palette.border.separator}`,
  textAlign: 'center',
  color: theme.palette.text.subtitle,
  padding: theme.spacing(0.3),
  width: 150,
}));

const SecondColumn = styled('td')(({ theme }) => ({
  backgroundColor: theme.palette.background.secondary,
}));

/********************  Main Component  ********************/
const FormGroup = ({ maxWidth, children, title = '', inputs = [] }) => {
  return (
    <Container>
      <Typography fontSize={16} fontWeight={600} marginBottom={1}>
        {title}
      </Typography>
      {children}
      <Wrapper max={maxWidth}>
        <Table cellPadding={0} cellSpacing={0}>
          <tbody>
            {inputs.map(([first, second], index) => (
              <TableRow key={index} index={index + 1}>
                <FirstColumn>{first}</FirstColumn>
                <SecondColumn>{second}</SecondColumn>
              </TableRow>
            ))}
          </tbody>
        </Table>
      </Wrapper>
    </Container>
  );
};

export default FormGroup;
