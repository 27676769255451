import { useHistory } from 'react-router-dom';
import { useStateIfMounted } from 'use-state-if-mounted';
import {
  Typography,
  CircularProgress,
  Box,
  ClickAwayListener,
  styled,
  useTheme,
} from '@mui/material';
import {
  KeyboardArrowDown as KeyboardArrowDownIcon,
  KeyboardArrowUp as KeyboardArrowUpIcon,
} from '@mui/icons-material';

import { NETWORK, NETWORK_DEFAULT } from 'constants';
import useWalletContext from 'hooks/useWalletContext';
import { getFullMediaUrl } from 'utils/image';
import { shorten } from 'utils/helper';

import { CurrencyIcon } from 'components/Currency';
//import { ApprovedIcon} from 'components/ImageComponent';
import { FlexBox } from 'components/StyledComponents';
import Blockie from 'components/Blockie';

/********************  Styled Components  ********************/
const ResultContainer = styled('div')(({ theme, fullHeight }) => ({
  backgroundColor: theme.palette.background.default,
  width: '100%',
  minWidth: '482px',
  maxHeight: 'calc(100vh - 79px)',
  overflowY: 'auto',
  alignItems: 'center',

  '@media (max-width: 1440px)': {
    left: '2px',
  },

  '@media (max-width: 1280px)': {
    width: '100%',
    minWidth: '508px',
  },

  '@media (max-width: 940px)': {
    minWidth: '350px',
  },

  '@media(min-width: 700px)': {
    position: 'absolute',
    right: 0,
    backgroundColor: theme.palette.background.default,
    borderRadius: 8,
    maxHeight: 'calc(100vh - 144px)',
    overflowY: 'auto',
    alignItems: 'center',
    boxShadow: '0px 1px 8px 3px #00000033',
    '&:hover': {
      display: 'block',
    },
  },

  '@media(max-width: 700px)': {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    justifyContent: fullHeight ? 'center' : 'flex-start',
  },

  '& > div': {
    width: '100%',
  },
}));

const ResultRow = styled('div')(({ theme }) => ({
  display: 'flex',
  cursor: 'pointer',
  justifyContent: 'space-between',
  alignItems: 'center',
  backgroundColor: theme.palette.background.default,
  width: '100%',
  padding: '12px 16px',
  borderBottom: `1px solid ${theme.palette.border.separator}`,
  height: 60,
}));
const SearchResultContent = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap: '8px',
}));
const SearchResultTextContainer = styled('div')(() => ({
  display: 'flex',
  padding: '0 8px',
  flexDirection: 'column',
  justifyContent: 'space-between',
}));

const TitleContainer = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  padding: 0,
  height: '24px',
}));

const SearchResultImageContainer = styled('div')(() => ({
  height: 32,
  width: 32,
  overflow: 'hidden',
  borderRadius: '8px',
}));

const SearchImg = styled('img')(({ profile }) => ({
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  borderRadius: profile ? '50%' : '8px',
}));

const SearchResultTitle = styled(Typography)(({ theme }) => ({
  fontSize: 14,
  fontWeight: 500,
  lineHeight: '20px',
  letterSpacing: '0.25px',
  fontFeatureSettings: "'cv11' on, 'cv09' on, 'cv03' on, 'cv04' on, 'cv02' on, 'ss01' on",
  color: theme.palette.text.main,
}));

const SearchResultSubtitle = styled(Typography)(({ theme }) => ({
  fontSize: 10,
  fontWeight: 500,
  lineHeight: '15px',
  letterSpacing: '0.4px',
  color: theme.palette.text.main,
}));

const NoSearchResultText = styled(Typography)(({ theme, isError }) => ({
  display: 'flex',
  fontSize: 16,
  fontWeight: 600,
  lineHeight: '24x',
  letterSpacing: '0.15px',
  color: isError ? theme.palette.error.main : theme.palette.text.secondary,
  padding: 16,
}));

const LoadingBox = styled('div')(() => ({
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  placeItems: 'center',
  padding: 18,
}));

const SubHeaderContainer = styled('div')(({ theme }) => ({
  boxSizing: 'border-box',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'flex-start',
  padding: '10px 16px',
  gap: '8px',
  width: '100%',
  height: '40px',
  backgroundColor: theme.palette.background.default,
  borderBottom: `1px solid ${theme.palette.border.separator}`,
}));

const SubHeaderItem = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'flex-start',
  padding: 0,
  gap: '16px',
  width: '100%',
  height: '20px',
}));

const SubHeaderItemText = styled(Typography)(({ theme }) => ({
  height: '20px',
  fontFamily: 'Inter',
  fontStyle: 'normal',
  fontWeight: 500,
  fontSize: 14,
  lineHeight: '20px',
  letterSpacing: '0.25px',
  fontFeatureSettings: "'cv11' on, 'cv09' on, 'cv03' on, 'cv04' on, 'cv02' on, 'ss01' on",
  color: theme.palette.text.secondary,
}));

const ButtonContainer = styled('div')(({ theme }) => ({
  boxSizing: 'border-box',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '10px 16px',
  gap: '8px',
  width: '100%',
  height: '40px',
  backgroundColor: theme.palette.background.default,
  borderBottom: `1px solid ${theme.palette.border.separator}`,
}));

const CustomMoreButton = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  cursor: 'pointer',
  padding: '4px 8px 4px 16px',
  gap: '5px',
  boxSizing: 'border-box',
}));

const SearchItem = ({ item, setSearchText, type }) => {
  const theme = useTheme();
  const history = useHistory();
  const { chainId } = useWalletContext();

  const currency = NETWORK[chainId] ?? NETWORK_DEFAULT;
  const itemProps = {};

  switch (type) {
    case 'account':
      Object.assign(itemProps, {
        url: `/portfolio/${item.address}`,
        name: item.username,
        subtitle: item.bio,
        image: item.avatar,
        address: item.address,
      });
      break;
    case 'collection':
      Object.assign(itemProps, {
        url: `/collections/${item?.address}`,
        name: item.name,
        subtitle: item.symbol,
        image: getFullMediaUrl(item.contractThumbnailPathName),
      });
      break;
    case 'item':
      Object.assign(itemProps, {
        url: `/asset/${item?.contractAddress}/${item?.tokenId}`,
        name: item.name,
        subtitle: item.symbol,
        image: getFullMediaUrl(item.thumbnailPathName, item.rawFileUrl),
      });
      break;
  }

  const { url, name, subtitle, image, address } = itemProps;

  return (
    <>
      <ResultRow
        onClick={() => {
          if (url) {
            history.push(url);
            setSearchText('');
          }
        }}
      >
        <SearchResultContent>
          <SearchResultImageContainer>
            {Boolean(image) ? <SearchImg src={image} /> : <Blockie avatar={address} />}
          </SearchResultImageContainer>
          <SearchResultTextContainer>
            <TitleContainer>
              <SearchResultTitle>{Boolean(name) ? name : shorten(address, 5)}</SearchResultTitle>
              {/*<ApprovedIcon width={'16px'} height={'16px'} />*/}
            </TitleContainer>
            <SearchResultSubtitle>{subtitle}</SearchResultSubtitle>
          </SearchResultTextContainer>
        </SearchResultContent>
        <CurrencyIcon
          props={{
            fill: theme.palette.icon.secondary,
          }}
          currency={currency.symbol}
          type={'symbol'}
        />
      </ResultRow>
    </>
  );
};

/************************* Main Component  **************************/
const SearchResultContainer = ({ searchResult, isLoading, setSearchText, isError, onClose }) => {
  const [showCollection, setShowCollection] = useStateIfMounted(false);
  const [showAccount, setShowAccount] = useStateIfMounted(false);
  const [showItems, setShowItems] = useStateIfMounted(false);
  const [hideContent, setHideContent] = useStateIfMounted(false);

  const { collections, accounts, items } = searchResult;

  const renderedResult = (
    <>
      {collections?.length > 0 && (
        <Box>
          <SubHeaderContainer>
            <SubHeaderItem>
              <SubHeaderItemText>Collections</SubHeaderItemText>
            </SubHeaderItem>
          </SubHeaderContainer>

          {!showCollection
            ? collections
                .slice(0, 3)
                .map((item) => (
                  <SearchItem
                    key={`${item?.id}`}
                    item={item}
                    setSearchText={setSearchText}
                    type="collection"
                  />
                ))
            : collections.map((item) => (
                <SearchItem
                  key={`${item?.id}`}
                  item={item}
                  setSearchText={setSearchText}
                  type="collection"
                />
              ))}
          {collections.length > 3 && (
            <ButtonContainer>
              <CustomMoreButton
                onClick={() => {
                  setShowCollection(!showCollection);
                }}
              >
                {showCollection ? (
                  <FlexBox>
                    Less
                    <KeyboardArrowUpIcon width={'16px'} height={'16px'} />
                  </FlexBox>
                ) : (
                  <FlexBox>
                    More
                    <KeyboardArrowDownIcon width={'16px'} height={'16px'} />
                  </FlexBox>
                )}
              </CustomMoreButton>
            </ButtonContainer>
          )}
        </Box>
      )}
      {accounts?.length > 0 && (
        <Box>
          <SubHeaderContainer>
            <SubHeaderItem>
              <SubHeaderItemText>Accounts</SubHeaderItemText>
            </SubHeaderItem>
          </SubHeaderContainer>

          {!showAccount
            ? accounts
                .slice(0, 3)
                .map((item) => (
                  <SearchItem
                    key={`${item?.id}`}
                    item={item}
                    setSearchText={setSearchText}
                    type="account"
                  />
                ))
            : accounts.map((item) => (
                <SearchItem
                  key={`${item?.id}`}
                  item={item}
                  setSearchText={setSearchText}
                  type="account"
                />
              ))}

          {accounts.length > 3 && (
            <ButtonContainer>
              <CustomMoreButton
                onClick={() => {
                  setShowAccount(!showAccount);
                }}
              >
                {showAccount ? (
                  <FlexBox>
                    Less
                    <KeyboardArrowUpIcon width={'16px'} height={'16px'} />
                  </FlexBox>
                ) : (
                  <FlexBox>
                    More
                    <KeyboardArrowDownIcon width={'16px'} height={'16px'} />
                  </FlexBox>
                )}
              </CustomMoreButton>
            </ButtonContainer>
          )}
        </Box>
      )}
      {items?.length > 0 && (
        <Box>
          <SubHeaderContainer>
            <SubHeaderItem>
              <SubHeaderItemText>Items</SubHeaderItemText>
            </SubHeaderItem>
          </SubHeaderContainer>
          {!showItems
            ? items
                .slice(0, 3)
                .map((item) => (
                  <SearchItem
                    key={`${item?.id}`}
                    item={item}
                    setSearchText={setSearchText}
                    onClose={onClose}
                    type="item"
                  />
                ))
            : items.map((item) => (
                <SearchItem
                  key={`${item?.id}`}
                  item={item}
                  setSearchText={setSearchText}
                  onClose={onClose}
                  type="item"
                />
              ))}
          {items.length > 3 && (
            <ButtonContainer>
              <CustomMoreButton
                onClick={() => {
                  setShowItems(!showItems);
                }}
              >
                {showItems ? (
                  <FlexBox>
                    Less
                    <KeyboardArrowUpIcon width={'16px'} height={'16px'} />
                  </FlexBox>
                ) : (
                  <FlexBox>
                    More
                    <KeyboardArrowDownIcon width={'16px'} height={'16px'} />
                  </FlexBox>
                )}
              </CustomMoreButton>
            </ButtonContainer>
          )}
        </Box>
      )}
    </>
  );

  return (
    <>
      <ClickAwayListener
        onClickAway={() => {
          setHideContent(true);
          onClose();
        }}
      >
        {!hideContent ? (
          <ResultContainer fullHeight={isLoading || searchResult?.collections?.length === 0}>
            {isLoading ? (
              <LoadingBox>
                <CircularProgress />
              </LoadingBox>
            ) : isError ? (
              <NoSearchResultText isError={isError}>
                Error while searching, unable to connect
              </NoSearchResultText>
            ) : searchResult?.collections?.length === 0 &&
              searchResult?.accounts?.length === 0 &&
              searchResult?.items?.length === 0 &&
              !isError ? (
              <NoSearchResultText>No results found</NoSearchResultText>
            ) : (
              renderedResult
            )}
          </ResultContainer>
        ) : (
          <></>
        )}
      </ClickAwayListener>
    </>
  );
};

export default SearchResultContainer;
