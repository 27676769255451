import React from 'react';

import {
  PanelExpand,
  PanelExpandHead,
  PanelExpandContent,
  PanelTitle,
} from 'components/StyledPanelExpand';
import ProgressBar from 'components/General/ProgressBar';
import { DescriptionContent, DescriptionText, LinkText } from './common';

/********************  Main Component  ********************/
const EnableWNRG = ({ failed, processing, onTryAgain }) => {
  return (
    <>
      <PanelExpand radius="8px 8px 0px 0px">
        <PanelExpandHead show={1} style={{ borderRadius: '8px 8px 0px 0px' }}>
          <ProgressBar value={1} loading={processing?.toString()} />
          <PanelTitle>Enable WNRG spending</PanelTitle>
        </PanelExpandHead>
        <PanelExpandContent in={true} timeout="auto" unmountOnExit style={{ borderRadius: '0px' }}>
          <DescriptionContent>
            <DescriptionText>
              To get set up for selling on Energi NFT for the first time, you must initialize your
              wallet, which requires a one-time gas fee.
            </DescriptionText>
            {failed ? (
              <LinkText onClick={onTryAgain}>Initialize wallet again</LinkText>
            ) : (
              <LinkText>Waiting for confirmation</LinkText>
            )}
          </DescriptionContent>
        </PanelExpandContent>
      </PanelExpand>
      <PanelExpand radius="0px 0px 8px 8px">
        <PanelExpandHead show={1} style={{ borderBottom: 0, borderRadius: '0px 0px 8px 8px' }}>
          <ProgressBar value={2} loading="false" />
          <PanelTitle>Confirm offer</PanelTitle>
        </PanelExpandHead>
      </PanelExpand>
    </>
  );
};

export default EnableWNRG;
