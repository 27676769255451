import React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import { Typography } from '@mui/material';

/************************* Styled Components  **************************/
const Container = styled('div')(({ theme }) => ({
  paddingBottom: theme.spacing(1.5),
  margin: theme.spacing(3, 0),
  borderBottom: `1px solid ${theme.palette.border.separator}`,
}));

const Main = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'space-between',
}));

const LeftSection = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'flex-start',
}));

const RightSection = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
}));

const IconContainer = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2.5, 0, 0),
}));

/************************* Main Component  **************************/
const InlineRow = ({ icon, title, text, children, extra = '' }) => {
  const theme = useTheme();
  return (
    <Container>
      <Main>
        <LeftSection>
          <IconContainer>{icon}</IconContainer>
          <div>
            <Typography fontWeight={600} fontSize={16} color={theme.palette.text.main}>
              {title}
            </Typography>
            <Typography fontSize={15} color={theme.palette.text.medium}>
              {text}
            </Typography>
          </div>
        </LeftSection>
        <RightSection>{children}</RightSection>
      </Main>
      {extra}
    </Container>
  );
};

export default InlineRow;
