import { useEffect } from 'react';
import { useStateIfMounted } from 'use-state-if-mounted';
import referralService from 'services/referral';

export const useAirdropBonus = () => {
  const [totalAirdropBalance, setTotalAirdropBalance] = useStateIfMounted(0);
  // leaving it as true just for now -> TODO: leave false as default
  const [qualifiedForAirdrop, setQualifiedForAirdrop] = useStateIfMounted(false);

  const getAidropBonuses = async () => {
    const data = await referralService.getAirdropBonuses();
    setTotalAirdropBalance(data?.totalAirdropBalance);
    if (data?.baseRewards) {
      const { eligibleGmi, nrgRewardGmi, ethStackerGmi } = data.baseRewards;
      setQualifiedForAirdrop(!eligibleGmi || !nrgRewardGmi || !ethStackerGmi);
    }
  };

  useEffect(() => {
    // TODO: enpoint not working for now -> Worker JWT not recognizing User JWT
    getAidropBonuses();
  }, []);

  return { totalAirdropBalance, qualifiedForAirdrop };
};
