import { Percent } from '@sushiswap/core-sdk';
import {
  calculateGasMargin,
  useFarmingContract,
  useFarmingProxyContract,
  useSushiswapRouterContract,
  useTokenContract,
} from 'utils/index';
import {
  CHAIN_ID,
  FARMING_POOL_TIME,
  FARMING_PROXY_ADDRESS,
  GMI_TOKEN_DETAILS,
  MAX_GAS_ALLOWANCE,
  SUPPORTED_NETWORKS,
  SUSHISWAP_ROUTER_ADDRESS,
} from 'constants/index';
import { useCallback, useEffect, useState } from 'react';
import useWalletContext from './useWalletContext';

export const useLiquidityApproval = (amount) => {
  const { chainId, address } = useWalletContext();
  const tokenContract = useTokenContract();
  const liquidityApproval = useCallback(async () => {
    const tx = await tokenContract.approve(
      SUSHISWAP_ROUTER_ADDRESS[chainId === 1 ? 'Mainnet' : 'Testnet'],
      amount,
      {
        from: address,
        gasLimit: calculateGasMargin(MAX_GAS_ALLOWANCE),
      },
    );
    return tx;
  }, [address, amount, chainId, tokenContract]);

  return liquidityApproval;
};

export const useDepositApproval = (amount, type) => {
  const { chainId, address } = useWalletContext();
  const farmingContract = useFarmingContract();
  const depositApproval = useCallback(async () => {
    const tx = await farmingContract.approve(
      FARMING_PROXY_ADDRESS[chainId === 1 ? 'Mainnet' : 'Testnet'][type],
      amount,
      {
        from: address,
        gasLimit: calculateGasMargin(MAX_GAS_ALLOWANCE),
      },
    );
    return tx;
  }, [address, amount, chainId, farmingContract, type]);
  return depositApproval;
};

export const useAddLiquidity = (gmiAmountInBignumber, ethAmountInBignumber) => {
  const { chainId, address } = useWalletContext();
  const sushiswapRouterContract = useSushiswapRouterContract();
  const addLiquidity = useCallback(async () => {
    try {
      const response = await sushiswapRouterContract.addLiquidityETH(
        GMI_TOKEN_DETAILS[chainId === 1 ? 'Mainnet' : 'Testnet'].address,
        gmiAmountInBignumber.toString(),
        ((BigInt(gmiAmountInBignumber) * 5n) / 100n).toString(),
        ((BigInt(ethAmountInBignumber) * 5n) / 100n).toString(),
        address,
        Date.now() * 1000,
        {
          value: ethAmountInBignumber.toString(),
          gasLimit: calculateGasMargin(MAX_GAS_ALLOWANCE),
        },
      );

      return response;
    } catch (error) {
      // print any other error except of user rejected in metamask (error code 4001)
      if (error?.code !== 4001) {
        console.error(error);
      }
      throw error;
    }
  }, [address, chainId, ethAmountInBignumber, gmiAmountInBignumber, sushiswapRouterContract]);
  return addLiquidity;
};

export const useDeposit = (amount, type) => {
  const { address } = useWalletContext();
  const farmingProxyContract = useFarmingProxyContract(type);
  const deposit = useCallback(async () => {
    try {
      const response = await farmingProxyContract.stake(amount, {
        from: address,
        gasLimit: calculateGasMargin(MAX_GAS_ALLOWANCE),
      });

      return response;
    } catch (error) {
      // print any other error except of user rejected in metamask (error code 4001)
      if (error?.code !== 4001) {
        console.error(error);
      }
      throw error;
    }
  }, [address, amount, farmingProxyContract]);
  return deposit;
};

export const useReserveValues = () => {
  const { chainId } = useWalletContext();
  const farmingContract = useFarmingContract();
  const [ethPerGmi, setEthPerGmi] = useState(0);
  const [gmiPerEth, setGmiPerEth] = useState(0);

  useEffect(() => {
    if (!chainId || chainId !== CHAIN_ID || chainId != SUPPORTED_NETWORKS.Ethereum) {
      return;
    }

    (async () => {
      try {
        const reserveValues = await farmingContract.getReserves();
        setEthPerGmi(parseFloat(reserveValues[0] / reserveValues[1]));
        setGmiPerEth(parseFloat(reserveValues[1] / reserveValues[0]));
      } catch (err) {
        console.error('Failed to fetch reserve values!');
      }
    })();
  }, [farmingContract]);
  return [ethPerGmi, gmiPerEth];
};

export const useMyPoolBalance = () => {
  const { address, chainId } = useWalletContext();
  const farmingContract = useFarmingContract();
  const [liquidityMinted, setLiquidityMinted] = useState(0);

  useEffect(() => {
    if (!chainId || chainId !== CHAIN_ID || chainId != SUPPORTED_NETWORKS.Ethereum) {
      return;
    }

    (async () => {
      try {
        const balance = await farmingContract?.balanceOf(address);
        setLiquidityMinted(balance.toString() / 1e18);
      } catch (err) {
        console.error('Lock values ', err);
        throw err;
      }
    })();
  }, [address, farmingContract]);
  return liquidityMinted;
};

export const useShareOfPool = () => {
  const { chainId, address } = useWalletContext();
  const farmingContract = useFarmingContract();
  const [share, setShare] = useState(0);
  useEffect(() => {
    if (!chainId || chainId !== CHAIN_ID || chainId != SUPPORTED_NETWORKS.Ethereum) {
      return;
    }

    (async () => {
      try {
        const liquidityMinted = await farmingContract?.balanceOf(address);
        const totalSupply = await farmingContract?.totalSupply();

        setShare(new Percent(liquidityMinted, totalSupply));
      } catch (err) {
        console.error('Reserve values ', err);
        throw err;
      }
    })();
  }, [address, farmingContract]);
  return share;
};

export const useLockBalance = () => {
  const { address, chainId } = useWalletContext();
  const farmingProxyContracts = FARMING_POOL_TIME.map((data) => data.duration).map(
    useFarmingProxyContract,
  );
  const [lockBalance, setLockBalance] = useState(0);
  useEffect(() => {
    if (!chainId || chainId !== CHAIN_ID || chainId != SUPPORTED_NETWORKS.Ethereum) {
      return;
    }

    (async () => {
      try {
        const balances = await Promise.all(
          farmingProxyContracts.map(async (contract, idx) => {
            return await contract?.getBalance(
              FARMING_PROXY_ADDRESS[chainId === 1 ? 'Mainnet' : 'Testnet'][
                FARMING_POOL_TIME[idx].duration
              ],
              address,
            );
          }),
        );
        const poolBalance = balances.reduce((acc, balance) => BigInt(balance) + BigInt(acc), 0n);

        setLockBalance(poolBalance.toString() / 1e18);
      } catch (err) {
        console.error('Lock balance ', err);
        throw err;
      }
    })();
  }, [address, farmingProxyContracts]);
  return lockBalance;
};

export const useRemoveLiquidity = (amount, ethValue, gmiValue) => {
  const { chainId, address } = useWalletContext();
  const sushiswapRouterContract = useSushiswapRouterContract();
  const removeLiquidity = useCallback(async () => {
    try {
      return await sushiswapRouterContract.removeLiquidityETH(
        GMI_TOKEN_DETAILS[chainId === 1 ? 'Mainnet' : 'Testnet'].address,
        amount.toString(),
        ((BigInt(gmiValue) * 5n) / 100n).toString(),
        ((BigInt(ethValue) * 5n) / 100n).toString(),
        address,
        Date.now() * 1000,
        {
          gasLimit: calculateGasMargin(MAX_GAS_ALLOWANCE),
        },
      );
    } catch (error) {
      // print any other error except of user rejected in metamask (error code 4001)
      if (error?.code !== 4001) {
        console.error(error);
      }
      throw error;
    }
  }, [address, amount, chainId, ethValue, gmiValue, sushiswapRouterContract]);

  return removeLiquidity;
};

export const useWithdraw = (amount, limit = 99999999) => {
  const farmingProxyContract = useFarmingProxyContract('6 Months');
  const { address } = useWalletContext();
  const withdraw = useCallback(async () => {
    try {
      const response = await farmingProxyContract.withdraw(amount, limit, {
        from: address,
        gasLimit: calculateGasMargin(MAX_GAS_ALLOWANCE),
      });

      return response;
    } catch (error) {
      // print any other error except of user rejected in metamask (error code 4001)
      if (error?.code !== 4001) {
        console.error(error);
      }
      throw error;
    }
  }, [address, amount, farmingProxyContract, limit]);

  return withdraw;
};
