import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useStateIfMounted } from 'use-state-if-mounted';
import {
  Stack,
  Button,
  Typography,
  IconButton,
  Avatar as MuiAvatar,
  TextField,
  InputAdornment,
  useMediaQuery,
  Box,
  useTheme,
  CircularProgress,
  styled,
} from '@mui/material';
import { InfoOutlined as InfoIcon } from '@mui/icons-material';

import notificationService from 'services/notification';

import { SOMETHING_WENT_WRONG } from 'constants';
import { usePriceContext } from 'context/PriceContext';
import { useSettingsContext } from 'context/SettingsContext';
import useNotification from 'hooks/useNotification';
import useWalletContext from 'hooks/useWalletContext';

import { ToolTip, SubTitleText, FlexBox, HeaderText } from 'components/StyledComponents';
import { EthBlueIcon, OfferSettingsSvgIcon } from 'components/ImageComponent';
import { LoadingBar } from 'components/General';

/********************  Styled Components  ********************/
const Header = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  flexWrap: 'wrap',
  margin: 0,
  marginBottom: theme.spacing(3),
}));

const ViewOfferButton = styled(Button)(({ theme }) => ({
  color: theme.palette.text.tertiary,
  border: `1px solid ${
    theme.palette.mode === 'dark' ? theme.palette.border.decorative : theme.palette.border.separator
  }`,
  borderRadius: 8,
  fontSize: 16,
  fontWeight: 600,
  lineHeight: '24px',
  width: 150,
  height: 40,
  backgroundColor: theme.palette.background.secondary,

  '&:hover': {
    boxShadow: '0px 0px 5px 0px #888888',
    color: theme.palette.text.main,
  },

  '@media(max-width: 480px)': {
    marginTop: theme.spacing(2),
    width: '100%',
  },
}));

const EmptyBox = styled('div')(({ theme }) => ({
  height: 570,
  border: `1px solid ${theme.palette.border.separator}`,
  borderRadius: theme.spacing(1),
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  marginTop: theme.spacing(2),
  width: '100%',
  '@media (max-width: 590px)': {
    marginTop: 0,
    border: 'none',
  },
}));

const EmptyBoxTitle = styled(Typography)(({ theme }) => ({
  fontSize: 20,
  lineHeight: '28px',
  fontWeight: 600,
  color: theme.palette.background.main,
  textAlign: 'center',
  marginTop: theme.spacing(3),
}));

const EmptyBoxSubTitle = styled(Typography)(({ theme }) => ({
  width: 355,
  textAlign: 'center',
  color: theme.palette.text.subtitle,
  fontSize: 16,
  lineHeight: '24px',
  marginTop: theme.spacing(1.5),
  '@media(max-width: 590px)': {
    width: 'auto',
  },
}));

const TableContainer = styled(Box)(({ theme }) => ({
  width: '100%',
  marginTop: theme.spacing(8),
  '@media (max-width: 600px)': {
    marginTop: theme.spacing(6),
  },
}));

const TableRow = styled(FlexBox)(({ theme }) => ({
  width: '100%',
  borderTop: `1px solid ${theme.palette.border.decorative}`,
  borderLeft: 'none',
  borderRight: 'none',
  padding: theme.spacing(2),
  flexWrap: 'wrap',
  '&:last-of-type': {
    borderBottom: `1px solid ${theme.palette.border.decorative}`,
  },
  '@media (max-width: 590px)': {
    flexDirection: 'column',
  },
}));

const TableHeading = styled(Box)(({ theme }) => ({
  fontWeight: 600,
  fontSize: 16,
  lineHeight: '21px',
  padding: theme.spacing(2, 3),
}));

const SaveButton = styled(Button)(({ theme }) => ({
  width: 72,
  height: 40,
  backgroundColor: theme.palette.primary.main,
  borderRadius: 8,
  fontSize: 16,
  lineHeight: '24px',
  fontWeight: 600,
  color: theme.palette.text.white,
  marginTop: theme.spacing(4),
  marginBottom: theme.spacing(10),
  '&:disabled, &[disabled]': {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.text.white,
    opacity: 0.25,
  },
}));

const SaveButtonWrapper = styled(FlexBox)(() => ({
  marginTop: 10,
  justifyContent: 'end',
}));

const Avatar = styled(MuiAvatar)(() => ({
  width: 84,
  height: 84,
}));

const ItemSumText = styled(Typography)(({ theme }) => ({
  fontSize: 12,
  lineHeight: '18px',
  fontWeight: 500,
  color: theme.palette.text.secondary,
}));

const ItemNameText = styled(Typography)(({ theme }) => ({
  fontSize: 16,
  lineHeight: '24px',
  fontWeight: 600,
  color: theme.palette.text.main,
  marginBottom: 2,
}));

const ItemPriceText = styled(Typography)(({ theme }) => ({
  fontSize: 12,
  lineHeight: '16px',
  fontWeight: 500,
  color: theme.palette.text.main,
}));

const PriceInput = styled(TextField)(({ theme }) => ({
  width: 155,

  '.MuiOutlinedInput-notchedOutline': {
    borderRadius: theme.spacing(1),
    borderColor: theme.palette.border.decorative,
    color: theme.palette.text.secondary,
  },
  '& p': {
    marginLeft: 0,
  },

  '& .MuiInputBase-root': {
    backgroundColor: theme.palette.background.secondary,
    px: 1,
  },

  '& .MuiOutlinedInput-input': {
    width: 125,
    height: 48,
    boxSizing: 'border-box',
    fontSize: '14px',
    fontWeight: 500,
    color: theme.palette.text.secondary,
  },

  '@media(max-width: 600px)': {
    marginTop: theme.spacing(1.2),
  },

  '@media(max-width: 590px)': {
    width: '100%',
    marginTop: theme.spacing(2),
    '& .MuiOutlinedInput-input': {
      width: '100%',
      height: 48,
      boxSizing: 'border-box',
      padding: theme.spacing(1),
    },
  },
}));

const HeadingContainer = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  '@media (max-width: 480px)': {
    flexDirection: 'column',
  },
}));

const StackContainer = styled(Stack)(({ theme }) => ({
  background: theme.palette.background.default,
  padding: theme.spacing(0, 15),
  '@media (max-width: 1280px)': {
    padding: theme.spacing(0, 6),
  },
  '@media (max-width: 905px)': {
    padding: theme.spacing(0, 3),
  },
  '@media (max-width: 590px)': {
    padding: theme.spacing(0, 2),
  },
}));

/********************  Main Component  ********************/
const Offers = () => {
  const [disable, setDisable] = useStateIfMounted(true);
  const [offers, setOffers] = useStateIfMounted([]);
  const [isLoading, setIsLoading] = useStateIfMounted(false);

  const history = useHistory();
  const theme = useTheme();
  const below600 = useMediaQuery('(max-width: 600px)');
  const between480and600 = useMediaQuery('(min-width: 480px) and (max-width: 600px)');

  const { address } = useWalletContext();
  const { offersLoading, ownedCollections } = useSettingsContext();

  const { ethPrice } = usePriceContext();
  const showNotification = useNotification();

  useEffect(() => {
    ownedCollections && setOffers([...ownedCollections]);
  }, [ownedCollections]);

  const viewOfMyOffers = () => {
    history.push(`/account/${address}?tab=offers`);
  };

  const handleChange = (e, index) => {
    let values = [...offers];
    offers[index].offerPrice = e.target.value;
    setOffers(values);
    setDisable(false);
  };

  const handleSaveButton = async () => {
    const settings = {
      collections_min_offer: {
        ...offers.reduce((acc, obj) => {
          acc[obj.address] = obj.offerPrice;
          return acc;
        }, {}),
      },
    };
    setIsLoading(true);
    try {
      await notificationService.updateOfferSettings(settings);
      showNotification({
        type: 'success',
        message: 'Offer settings updated successfully.',
      });
    } catch (error) {
      showNotification({ type: 'error', message: SOMETHING_WENT_WRONG });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <StackContainer>
      <Header>
        {!below600 && (
          <>
            <HeaderText textAlign="left">Offer Settings</HeaderText>
            <ViewOfferButton variant="text" onClick={viewOfMyOffers} disableRipple>
              View my offers
            </ViewOfferButton>
          </>
        )}
      </Header>
      <HeadingContainer>
        <SubTitleText textAlign="left" sx={{ fontWeight: 600 }}>
          Set a minimum offer for collections {between480and600 && <br />} to ignore low offers.
          <ToolTip
            title="You will not be notified on offers below your minimum amounts."
            placement="top"
          >
            <IconButton>
              <InfoIcon sx={{ color: 'text.secondary', fontSize: '20px' }} />
            </IconButton>
          </ToolTip>
        </SubTitleText>

        {below600 && (
          <ViewOfferButton variant="text" onClick={viewOfMyOffers} disableRipple>
            View my offers
          </ViewOfferButton>
        )}
      </HeadingContainer>

      {offersLoading ? (
        <LoadingBar />
      ) : offers.length > 0 ? (
        <TableContainer>
          <Box display="flex" justifyContent="space-between">
            {!below600 ? <TableHeading>Collection</TableHeading> : null}
            <TableHeading>Minimum offer</TableHeading>
          </Box>

          <Box>
            {offers.map((row, index) => (
              <TableRow key={index}>
                <Box
                  flex={1}
                  display="flex"
                  alignItems="center"
                  justifyContent="flex-start"
                  width="100%"
                >
                  <Avatar alt="collection" src={row.collectionImage} />
                  <Box marginLeft={3}>
                    <ItemSumText>
                      {row.numberOfItems} {row.numberOfItems === 1 ? 'item' : 'items'}
                    </ItemSumText>
                    <ItemNameText>{row.name}</ItemNameText>
                    <ItemPriceText>Floor price: {row.floorPrice} ETH</ItemPriceText>
                  </Box>
                </Box>
                <Box width={below600 ? '100%' : 'auto'}>
                  <PriceInput
                    type="number"
                    variant="outlined"
                    value={row.offerPrice}
                    placeholder="0"
                    onChange={(e) => handleChange(e, index)}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <EthBlueIcon width={24} height={24} />
                        </InputAdornment>
                      ),
                    }}
                    helperText={(Number(row?.offerPrice) * ethPrice).toLocaleString('en-US', {
                      style: 'currency',
                      currency: 'USD',
                    })}
                    FormHelperTextProps={{
                      sx: {
                        fontWeight: 500,
                        fontSize: '12px',
                        letterSpacing: '.4px',
                        lineHeight: '16px',
                        paddingTop: '4px',
                        color: theme.palette.text.secondary,
                      },
                    }}
                  />
                </Box>
              </TableRow>
            ))}
          </Box>
          <SaveButtonWrapper>
            <SaveButton
              disabled={disable}
              variant="contained"
              onClick={handleSaveButton}
              disableRipple
            >
              {isLoading ? (
                <Box sx={{ lineHeight: 0 }}>
                  <CircularProgress sx={{ p: 1, color: 'white' }} />
                </Box>
              ) : (
                'Save'
              )}
            </SaveButton>
          </SaveButtonWrapper>
        </TableContainer>
      ) : (
        <EmptyBox>
          <OfferSettingsSvgIcon />
          <EmptyBoxTitle>No collections to manage offers</EmptyBoxTitle>
          <EmptyBoxSubTitle>
            You currently don’t have any collections and items to manage offers.
          </EmptyBoxSubTitle>
        </EmptyBox>
      )}
    </StackContainer>
  );
};

export default Offers;
