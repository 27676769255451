import React from 'react';
import { useStateIfMounted } from 'use-state-if-mounted';
import { styled, useTheme } from '@mui/material/styles';
import { ExpandMore as ExpandMoreIcon, ExpandLess as ExpandLessIcon } from '@mui/icons-material';
import { Collapse, ListItemIcon, ListItemText, ListItemButton, Badge } from '@mui/material';
import RewardsSection from './RewardSection';

/********************  Styled Components  ********************/
const FilterMenuItem = styled(ListItemButton)(({ theme }) => ({
  padding: theme.spacing(1.4, 2, 1.4, 0),
  border: `1px solid ${theme.palette.border.separator}`,
  backgroundColor: theme.palette.background.filter,
  color: theme.palette.text.main,
  lineHeight: '24px',
  letterSpacing: '0.15px',
  fontWeight: 600,
  '&:hover': {
    backgroundColor: theme.palette.background.filter,
  },
}));

const CollapseCustom = styled(Collapse)(({ theme }) => ({
  backgroundColor: theme.palette.background.secondary,
}));

const Content = styled('div')(({ theme }) => ({
  padding: theme.spacing(1, 3),
}));

const StyledBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    right: -16,
    top: 12,
    color: theme.palette.text.tertiary,
    fontWeight: 500,
    fontSize: 12,
    lineHeight: '16px',
    letterSpacing: '0.4px',
    padding: 0,
    background: theme.palette.border.separator,
    borderRadius: 4,
    minWidth: 16,
    minHeight: 24,
  },
}));

/************************* Main Component  **************************/
export default function RewardsContainer({ title, list, logoComponent, closeSideBar }) {
  const theme = useTheme();
  const [open, setOpen] = useStateIfMounted(false);

  const handleStatusClick = () => {
    setOpen(!open);
  };

  return (
    <>
      <FilterMenuItem onClick={handleStatusClick}>
        <ListItemIcon style={{ minWidth: 16 }} />
        <ListItemText primaryTypographyProps={{ fontWeight: 600 }}>
          <StyledBadge badgeContent={0} showZero>
            {title}
          </StyledBadge>
        </ListItemText>
        {open ? (
          <ExpandLessIcon style={{ color: theme.palette.icon.tertiary }} />
        ) : (
          <ExpandMoreIcon style={{ color: theme.palette.icon.tertiary }} />
        )}
      </FilterMenuItem>
      <CollapseCustom in={open} timeout="auto" unmountOnExit>
        <Content>
          {list &&
            list.map((data) => (
              <RewardsSection
                key={data.name}
                {...data}
                logoComponent={logoComponent}
                closeSideBar={closeSideBar}
              />
            ))}
        </Content>
      </CollapseCustom>
    </>
  );
}
