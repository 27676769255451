import React from 'react';
import { Collapse } from '@mui/material';
import { styled } from '@mui/material/styles';
import { KeyboardArrowDown as ArrowDown, KeyboardArrowUp as ArrowUp } from '@mui/icons-material';

const ArrowDownIcon = styled(ArrowDown)(() => ({
  marginLeft: 'auto',
  opacity: 0.5,
}));

const ArrowUpIcon = styled(ArrowUp)(() => ({
  marginLeft: 'auto',
  opacity: 0.5,
}));

export const PanelExpand = styled('div')(({ radius = '5px', theme }) => ({
  display: 'flex',
  justifyContent: 'start',
  flexDirection: 'column',
  width: '100%',
  border: `1px solid ${theme.palette.border.decorative}`,
  borderRadius: radius,
}));

export const PanelExpandHead = styled('div')(({ theme, show }) => ({
  display: 'flex',
  justifyContent: 'start',
  alignItems: 'center',
  flexDirection: 'row',
  gap: 20,
  padding: '0 24px',
  height: '62px',
  borderBottom: show ? `1px solid ${theme.palette.border.decorative}` : 0,
  backgroundColor: theme.palette.background.secondary,

  '&:hover': {
    cursor: 'pointer',

    '& svg': {
      opacity: 1,
    },
  },
}));

export const PanelTitle = styled('p')(({ theme }) => ({
  fontSize: 16,
  fontWeight: 600,
  color: theme.palette.text.main,
  margin: '0px',
  letterSpacing: '0.15px',
  lineHeight: '24px',
}));

export const PanelExpandContent = styled(Collapse)(({ theme }) => ({
  backgroundColor: theme.palette.background.filter,
  padding: 0,
}));

export const PanelArrowIcon = ({ collapse = true }) => {
  return collapse ? <ArrowUpIcon /> : <ArrowDownIcon />;
};
