import { Typography, Box } from '@mui/material';
import { Close as CloseIcon, ArrowBack as ArrowBackIcon } from '@mui/icons-material';
import { useTheme } from '@mui/material/styles';
import { ModalHeaderContainer, ModalIconButton } from '../../components/styledComponents';

/********************  Sub Component  ********************/
const ModalHeader = ({ prevModal, onBack, closeModal }) => {
  const theme = useTheme();

  return (
    <ModalHeaderContainer padding={theme.spacing(3, 3, 1.9, 3)}>
      {prevModal ? (
        <ModalIconButton onClick={onBack}>
          <ArrowBackIcon style={{ fill: theme.palette.icon.tertiary }} />
        </ModalIconButton>
      ) : (
        <Box width={30}></Box>
      )}
      <Typography fontSize={24} fontWeight={500} lineHeight="32px">
        Convert
      </Typography>
      <ModalIconButton onClick={closeModal} disableRipple>
        <CloseIcon style={{ fill: theme.palette.icon.tertiary }} />
      </ModalIconButton>
    </ModalHeaderContainer>
  );
};

export default ModalHeader;
