import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { useStateIfMounted } from 'use-state-if-mounted';
import { styled } from '@mui/material/styles';
import { Tooltip, tooltipClasses } from '@mui/material';
import {
  Facebook as FacebookIcon,
  Telegram as TelegramIcon,
  Link as LinkIcon,
} from '@mui/icons-material';

import { copyToClipboard } from 'utils/helper';

import { TwitterXIcon } from 'components/ImageComponent';

const iconStyles = (theme) => {
  return {
    border: `1px solid ${theme.palette.border.decorative}`,
    borderRadius: 8,
    width: 40,
    height: 40,
    margin: theme.spacing(0, 0.5),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    transitionDuration: '0.1s',
    background: theme.palette.background.default,
    '& svg': {
      color: theme.palette.icon.secondary,
      fontSize: 24,
    },
    '&:hover': {
      background: theme.palette.background.hover,
      cursor: 'pointer',
    },
    '&:active': {
      background: theme.palette.background.linear,
      cursor: 'pointer',
    },
  };
};

const CopyIconLink = styled('div')(({ theme }) => ({
  ...iconStyles(theme),
}));

const ShareIconLink = styled(Link)(({ theme }) => ({
  ...iconStyles(theme),
}));

// Add more icons here if needed and pass an array of required buttons
const SocialMediaShare = ({ url }) => {
  const [copied, setCopied] = useStateIfMounted(null);
  const [copyTimeout, setCopyTimeout] = useStateIfMounted(null);

  const handleCopy = async () => {
    await copyToClipboard(url);
    setCopied('URL');
    if (copyTimeout) {
      clearTimeout(copyTimeout);
      setCopyTimeout(null);
    }
    const timeout = setTimeout(() => {
      setCopied(null);
      setCopyTimeout(null);
    }, 1000);
    setCopyTimeout(timeout);
  };

  const shareIcons = useMemo(() => {
    return [
      {
        icon: <TwitterXIcon />,
        href: `https://twitter.com/intent/tweet?url=${url}`,
      },
      {
        icon: <FacebookIcon />,
        href: `https://www.facebook.com/sharer.php?u=${url}`,
      },
      {
        icon: <TelegramIcon />,
        href: `https://t.me/share/url?url=${url}`,
      },
      { icon: <LinkIcon />, copy: true }, // copy URL
    ];
  }, [url]);

  const CopyTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: theme.palette.border.high,
    },
    [`& .${tooltipClasses.tooltip}`]: {
      borderRadius: 4,
      backgroundColor: theme.palette.border.high,
      fontSize: '14px',
      fontWeight: 500,
      padding: '6px 8px',
      letterSpacing: '0.25px',
      marginTop: '0px !important',
      marginBottom: '10px !important',
    },
  }));

  return shareIcons.map((item) => {
    return item.copy ? (
      <CopyTooltip
        key={'copyLinkKey'}
        onClick={handleCopy}
        title={copied === 'URL' ? 'Link copied to clipboard' : 'Copy Link'}
        placement="top"
        arrow
      >
        <CopyIconLink>{item.icon}</CopyIconLink>
      </CopyTooltip>
    ) : (
      <ShareIconLink
        key={item.href}
        to={{ pathname: item.href }}
        target="_blank"
        rel="noopener noreferrer"
      >
        {item.icon}
      </ShareIconLink>
    );
  });
};

export default SocialMediaShare;
