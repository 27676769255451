import React from 'react';
import { useHistory } from 'react-router-dom';
import { useStateIfMounted } from 'use-state-if-mounted';
import { styled } from '@mui/material';
import { GMITokenLogo, SmallLockIcon } from 'components/ImageComponent';
import { Text } from 'components/Text';
import { StandardButton, GreenTextButton } from 'components/Button';
import { useListedNfts } from 'hooks/useListedNfts';
import AirdropClaimModal from './AirdropClaimModal';
import { useAirdropBonus } from 'hooks/useAirdropBonus';

/********************  Styled Components  ********************/
const LockContainer = styled('div')(({ theme, gap }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  height: 40,
  backgroundColor: theme.palette.background.tertiary,
  gap: gap ?? '12px',
}));

const ContentWrapper = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '16px 24px 32px 24px',
}));

/********************  Main Component  ********************/
const AirdropReward = () => {
  const history = useHistory();
  const { listedNFTs } = useListedNfts();
  const { totalAirdropBalance } = useAirdropBonus();

  const [claimModalOpen, setClaimModalOpen] = useStateIfMounted(false);

  return (
    <>
      <Text main="true" fs={20} fw={600} lh={28} align="left" sx={{ p: 3 }}>
        Reward
      </Text>
      <LockContainer>
        <SmallLockIcon />
        <Text
          main="true"
          fs={16}
          fw={600}
          lh={24}
          sm={{ fs: 12, fw: 500, lh: 16 }}
          xs={{ fs: 16, fw: 600, lh: 24 }}
        >
          Unlocks after you list 2 NFTs
        </Text>
      </LockContainer>

      <ContentWrapper>
        <GMITokenLogo width={'56px'} height={'56px'} />
        <Text main="true" mt={16} mb={16} fs={42} fw={500} lh={50} xs={{ lh: 40 }}>
          {totalAirdropBalance?.toLocaleString('en-US')} GMI
        </Text>
        <StandardButton
          height={48}
          width="100%"
          sx={{ mb: 3 }}
          disabled={listedNFTs < 2}
          onClick={() => setClaimModalOpen(true)}
        >
          Claim
        </StandardButton>
        <GreenTextButton onClick={() => history.push('/rewards/referral_program/apply_promo')}>
          Enter referral link
        </GreenTextButton>
      </ContentWrapper>

      <AirdropClaimModal isOpen={claimModalOpen} onDismiss={() => setClaimModalOpen(false)} />
    </>
  );
};

export default AirdropReward;
