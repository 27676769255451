import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  PointElement,
  LineElement,
  ArcElement,
} from 'chart.js';
import { Bar, Line, Doughnut } from 'react-chartjs-2';
import { styled, useTheme } from '@mui/material';

const BarContainer = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  width: '90px',
  height: '45px',

  '@media (max-width: 860px)': {
    width: '53px',
    height: '22px',
  },
}));

const LineContainer = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  width: '129px',
  height: '40px',

  '@media (max-width: 860px)': {
    width: '71px',
    height: '22px',
  },
}));

const DoughnutContainer = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  width: 238,
  height: 238,

  '@media (max-width: 905px)': {
    width: 122,
    height: 122,
  },
  '@media (max-width: 600px)': {
    width: 118,
    height: 118,
  },
  '@media (max-width: 480px)': {
    width: 100,
    height: 100,
  },
}));

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
  LineElement,
  ArcElement,
  Title,
  Tooltip,
  Legend,
);

export default function Chart({ resource, type, label, align }) {
  const theme = useTheme();
  const isLine = type === 'line';
  const isBar = type === 'bar';
  const isDoughnut = type === 'doughnut';

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    categoryPercentage: 1,
    barPercentage: 0.95,
    interaction: {
      mode: 'nearest',
      intersect: false,
    },
    scales: {
      x: {
        display: false,
      },
      y: {
        display: false,
      },
    },
    elements: {
      point: {
        radius: 0,
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: false,
      },
      tooltip: {
        enabled: !isDoughnut,
        backgroundColor: theme.palette.border.high,
        bodyFontSize: 14,
        xAlign: 'center',
        yAlign: 'bottom',
        titleAlign: 'center',
        bodyAlign: 'center',
        displayColors: false,
        position: 'nearest',
        bodyFont: {
          weight: 500,
        },
        callbacks: {
          labelTextColor: function () {
            return theme.palette.mode === 'dark'
              ? theme.palette.text.tertiary
              : theme.palette.text.placeholder;
          },
          label: function () {
            return '';
          },
        },
      },
    },
  };

  const labels = resource;
  const colors = Array.from(
    [0.5, 0.5, 0.5, 0.8, 1, 1, 1],
    (opacity) => `rgba(0, 171, 88, ${opacity})`, // main color on rgb format
  );

  const data = {
    labels,
    datasets: [
      {
        label: label,
        data: resource,
        fill: false,
        borderColor: isDoughnut
          ? [theme.palette.primary.darker, theme.palette.border.decorative]
          : theme.palette.primary.main,
        backgroundColor: isDoughnut
          ? [theme.palette.primary.darker, theme.palette.border.decorative]
          : colors,
        borderWidth: isLine ? 3 : 0,
        cutout: '90%',
      },
    ],
  };

  if (isBar) {
    return (
      <BarContainer sx={align && { float: align }}>
        <Bar options={options} data={data} />
      </BarContainer>
    );
  }

  if (isLine) {
    return (
      <LineContainer sx={align && { float: align }}>
        <Line options={options} data={data} />
      </LineContainer>
    );
  }

  if (isDoughnut) {
    return (
      <DoughnutContainer sx={align && { float: align }}>
        <Doughnut options={options} data={data} />
      </DoughnutContainer>
    );
  }
}
