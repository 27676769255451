import React, { useEffect } from 'react';
import { useStateIfMounted } from 'use-state-if-mounted';
import { Box, CircularProgress, styled, Typography } from '@mui/material';
import { GeneralFlexBox } from 'components/StyledComponents';
import { TextInput } from 'components/FormElements';
import { StandardButton } from 'components/Button';
import { EMAIL_ADDRESS_VALIDATOR } from 'utils/validators';
import EmailCountdown from 'components/General/EmailCountdown';
import { RESEND_EMAIL_VERIFICATION_DELAY, LOCAL_STORAGE_TIMER_KEY } from 'constants/index';
import Modal from 'components/Modal';
import emailService from 'services/email';
import useWalletContext from 'hooks/useWalletContext';

/********************  Styled Components  ********************/
const Wrapper = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',

  '@media (max-width: 480px)': {
    marginBottom: 32,
  },
}));

const Text = styled(Typography)(({ fontSize, fontWeight, lineHeight, textAlign, theme }) => ({
  fontSize: fontSize ?? 16,
  fontWeight: fontWeight ?? 400,
  lineHeight: lineHeight ?? '24px',
  textAlign: textAlign ?? 'left',
  color: theme.palette.text.main,
  width: '100%',
}));

const InputButtontWrapper = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  width: 398,

  '@media (max-width: 480px)': {
    width: '100%',
  },
}));

const AlertBox = styled(GeneralFlexBox)(({ theme }) => ({
  alignItems: 'center',
  color: theme.palette.text.white,
  fontWeight: 400,
  fontSize: 16,
  lineHeight: '24px',
  backgroundColor: theme.palette.danger.variant,
  padding: '8px 26px',
  marginBottom: 32,
  marginTop: -8,
  borderRadius: 8,
  textAlign: 'center',
}));

const CountdownWrapper = styled('div')(() => ({
  marginBottom: '32px',
  marginTop: '-16px',
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
}));

const VERIFY_STATUS = {
  FAILED: 'failed',
  SENT: 'sent',
  RESENT: 'resent',
};

const saveDataOnLocalStorage = (email) => {
  const data = { endTime: Date.now() + RESEND_EMAIL_VERIFICATION_DELAY, email };

  if (!localStorage.getItem(LOCAL_STORAGE_TIMER_KEY)) {
    localStorage.setItem(LOCAL_STORAGE_TIMER_KEY, JSON.stringify(data));
  }
};

/********************  Sub Component  ********************/
const SendButton = ({ title, isLoading, resendEmail, verifyStatus }) => {
  return (
    <StandardButton
      height={48}
      onClick={resendEmail}
      sx={{ mt: 1 }}
      disabled={verifyStatus === VERIFY_STATUS.RESENT}
    >
      {!isLoading ? (
        `${title} verification email`
      ) : (
        <Box sx={{ lineHeight: 0 }}>
          <CircularProgress sx={{ p: 0.7, color: 'white' }} />
        </Box>
      )}
    </StandardButton>
  );
};

/********************  Main Component  ********************/
const VerifyEmailModal = ({ isOpen, onDismiss, email }) => {
  const [inputError, setInputError] = useStateIfMounted(false);
  const [inputText, setInputText] = useStateIfMounted('');
  const [verifyStatus, setVerifyStatus] = useStateIfMounted(VERIFY_STATUS.SENT);
  const [isLoading, setIsLoading] = useStateIfMounted(false);

  const { address } = useWalletContext();

  useEffect(() => {
    if (isOpen && email) {
      sendVerificationEmail(email);
    }
  }, [isOpen, email]);

  useEffect(() => {
    email && setInputText(email);
  }, [email]);

  const handleChange = (e) => {
    setInputText(e.target.value);
    if (e.target.value && !EMAIL_ADDRESS_VALIDATOR.test(e.target.value)) {
      setInputError(true);
    } else {
      setInputError(false);
    }
  };

  const sendVerificationEmail = async (email) => {
    if (email && EMAIL_ADDRESS_VALIDATOR.test(email)) {
      setIsLoading(true);
      try {
        const response = await emailService.sendEmailVerification({ address, email });
        saveDataOnLocalStorage(inputText);
        return response;
      } catch (error) {
        setVerifyStatus(VERIFY_STATUS.FAILED);
      } finally {
        setIsLoading(false);
      }
    }
  };

  const resendEmail = async () => {
    const sent = await sendVerificationEmail(inputText);
    if (sent) {
      return setVerifyStatus(VERIFY_STATUS.RESENT);
    }
    return setVerifyStatus(VERIFY_STATUS.FAILED);
  };

  const onCountDownComplete = () => {
    setVerifyStatus(VERIFY_STATUS.SENT);
    localStorage.removeItem(LOCAL_STORAGE_TIMER_KEY);
  };

  return (
    <Modal
      title="Email verification"
      isOpen={isOpen}
      onDismiss={onDismiss}
      width={430}
      padding="32px 16px 16px 16px"
    >
      <Wrapper>
        {verifyStatus === VERIFY_STATUS.FAILED && (
          <InputButtontWrapper>
            <Text fontWeight={600} sx={{ mb: 1 }}>
              Your email address
            </Text>
            <TextInput
              height={48}
              value={inputText}
              onChange={handleChange}
              placeholder="Enter your email address"
              error={inputError}
              helperText={inputError ? 'Invalid email address' : ''}
            />

            <AlertBox>Our attempt to send you an email failed. Please try again.</AlertBox>
            <SendButton
              title="Send"
              isLoading={isLoading}
              verifyStatus={verifyStatus}
              resendEmail={resendEmail}
            />
          </InputButtontWrapper>
        )}

        {[VERIFY_STATUS.SENT, VERIFY_STATUS.RESENT].includes(verifyStatus) && (
          <>
            <Text sx={{ mb: 3 }}>
              We have sent an email to <strong>{inputText}</strong>.
            </Text>
            <Text>You need to verify your email to continue.</Text>

            <Text sx={{ mb: 4, width: '98%' }}>
              If you have not received the verification email, please check your “Spam” or “Bulk
              Email” folder. You can also click resend button below{' '}
              {verifyStatus === VERIFY_STATUS.SENT ? (
                <span>to have another email sent to you.</span>
              ) : (
                <span>after the time elapses.</span>
              )}
            </Text>

            {verifyStatus === VERIFY_STATUS.RESENT && (
              <CountdownWrapper>
                <EmailCountdown onComplete={onCountDownComplete} />
              </CountdownWrapper>
            )}
            <SendButton
              title="Resend"
              isLoading={isLoading}
              verifyStatus={verifyStatus}
              resendEmail={resendEmail}
            />
          </>
        )}
      </Wrapper>
    </Modal>
  );
};

export default VerifyEmailModal;
