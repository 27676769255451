import { IconButton, OutlinedInput } from '@mui/material';
import { styled } from '@mui/material/styles';

export const CheckoutModalRow = styled('div')(({ theme, padding }) => ({
  width: '100%',
  padding: padding ?? theme.spacing(2.5, 3),
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',

  '@media(max-width: 500px)': {
    padding: theme.spacing(2),
  },
}));

export const CheckoutHeadingRow = styled(CheckoutModalRow)(({ theme }) => ({
  justifyContent: 'space-between',
  borderBottom: `1px solid ${theme.palette.border.separator}`,
  alignItems: 'center',
  height: 72,

  '@media(max-width: 500px)': {
    height: 64,
  },
}));

export const PriceInput = styled(OutlinedInput)(({ theme }) => ({
  fontSize: '14px',
  borderRadius: 4,
  background: theme.palette.background.default,
  height: 48,
  fontWeight: 500,
  '& .MuiOutlinedInput-root': {
    borderRadius: 8,
    padding: 2,
    height: 48,
  },
  '& .MuiOutlinedInput-input': {
    color: theme.palette.text.main,
  },
  '& input::placeholder': {
    fontWeight: 500,
    color: theme.palette.text.secondary,
    opacity: 1,
  },
  '& fieldset': {
    borderColor: theme.palette.border.decorative,
  },
  '&:hover': {
    background: theme.palette.background.apply.hovered,
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.palette.border.decorative,
    },
  },
  '&:active': {
    background: theme.palette.background.apply.pressed,
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.palette.border.decorative,
    },
  },
  '& .MuiInputAdornment-root.MuiInputAdornment-positionEnd': {
    '& p': {
      fontSize: 12,
    },
  },
}));

export const ModalIconButton = styled(IconButton)(({ theme }) => ({
  color: theme.palette.text.tertiary,
  borderRadius: 4,

  '&:hover': {
    background: theme.palette.background.apply.hovered,
  },

  '&:focus': {
    background: theme.palette.background.apply.pressed,
  },
}));

export const ModalHeaderContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  width: '100%',
  padding: theme.spacing(3, 3, 2, 3),
  justifyContent: 'space-between',
  alignItems: 'center',
  borderBottom: `1px solid ${theme.palette.background.tertiary}`,
  '@media(max-width: 500px)': {
    padding: theme.spacing(3, 2, 2, 2),
  },
}));
