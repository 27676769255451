import axios from 'axios';
import dotenv from 'dotenv';
import { getHook } from 'react-hooks-outside';

dotenv.config();

const DEFAULT_CONFIG = {
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },

  timeout: 5000,
};

export class ApiClient {
  constructor(axiosConfig) {
    // This way allows override baseURL if we want to have multiple URL servers in future
    this.config = { ...DEFAULT_CONFIG, ...axiosConfig }; // axiosConfig take higher precedence
    this.axiosInstance = axios.create(this.config);

    this.axiosInstance.interceptors.request.use(
      async (config) => {
        return {
          ...config,
          headers: {
            ...config.headers,
            Authorization: `Bearer ${localStorage.token}`,
          },
        };
      },
      (error) => {
        Promise.reject(error);
      },
    );

    this.axiosInstance.interceptors.response.use(
      (response) => {
        return response;
      },
      (error) => {
        if (error?.response?.status === 401) {
          const { signOut } = getHook('tokenContext');
          signOut(true);

          return Promise.reject({
            ...error,
            message: error.response.data.message || error.message,
            reason: error.response.data.message || error.reason,
          });
        }
        return Promise.reject(error);
      },
    );
  }

  async callApi(
    path,
    method = 'get',
    pathParams = {},
    params = {},
    returnType = null,
    headers = {},
    data = {},
  ) {
    // Construct final URL from pathParams
    const url = this.buildUrl(path, pathParams);

    try {
      const response = await this.axiosInstance.request({
        url,
        method,
        params,
        headers,
        data,
      });
      return this.deserialize(response, returnType);
    } catch (err) {
      console.error('error fetching: ', url);
    }
  }

  /**
   * Builds full URL by appending the given path to the base URL and replacing path parameter place-holders with parameter values.
   * NOTE: query parameters are not handled here.
   * @param {String} path The path to append to the base URL.
   * @param {Object} pathParams The parameter values to append.
   * @returns {String} The encoded path with parameter values substituted.
   */
  buildUrl(path, pathParams) {
    if (!path.match(/^\//)) {
      path = '/' + path;
    }

    const url = path.replace(/\{([\w-]+)\}/g, (fullMatch, key) => {
      let value;
      if (pathParams.hasOwnProperty(key)) {
        value = this.paramToString(pathParams[key]);
      } else {
        value = fullMatch;
      }

      return encodeURIComponent(value);
    });
    return url;
  }

  /**
   * Returns a string representation for an actual parameter.
   * @param param The actual parameter.
   * @returns {String} The string representation of <code>param</code>.
   */
  paramToString(param) {
    if (param == undefined || param == null) {
      return '';
    }
    if (param instanceof Date) {
      return param.toJSON();
    }

    return param.toString();
  }

  /**
   * Deserializes an HTTP response body into a value of the specified type.
   * @param {Object} responseData Response from Axios.
   * @param {(String|Array.<String>|Object.<String, Object>|Function)} returnType The type to return. Pass a string for simple types
   * or the constructor function for a complex type. Pass an array containing the type name to return an array of that type. To
   * return an object, pass an object with one property whose name is the key type and whose value is the corresponding value type:
   * all properties on <code>data<code> will be converted to this type.
   * @returns A value of the specified type.
   */
  deserialize(response, returnType) {
    // Don't deserialize if not know returnType
    if (returnType == null) {
      return response.data;
    }

    if (response == null || response.status == 204) {
      return null;
    }

    // Axios response data body.
    const data = response.data;

    return ApiClient.convertToType(data, returnType);
  }

  /**
   * Parses an ISO-8601 string representation of a date value.
   * @param {String} str The date value as a string.
   * @returns {Date} The parsed date object.
   */
  static parseDate(str) {
    return new Date(str);
  }

  /**
   * Converts a value to the specified type.
   * @param {(String|Object)} data The data to convert, as a string or object.
   * @param {(String|Array.<String>|Object.<String, Object>|Function)} type The type to return. Pass a string for simple types
   * or the constructor function for a complex type. Pass an array containing the type name to return an array of that type. To
   * return an object, pass an object with one property whose name is the key type and whose value is the corresponding value type:
   * all properties on <code>data<code> will be converted to this type.
   * @returns An instance of the specified type or null or undefined if data is null or undefined.
   */
  static convertToType(data, type) {
    if (data === null || data === undefined) {
      return data;
    }

    switch (type) {
      case 'Boolean':
        return Boolean(data);
      case 'Integer':
        return parseInt(data, 10);
      case 'Number':
        return parseFloat(data);
      case 'String':
        return String(data);
      case 'Date':
        return ApiClient.parseDate(String(data));
      case 'Blob':
        return data;
      default:
        if (type === Object) {
          // generic object, return directly
          return data;
        } else if (typeof type === 'function') {
          // for model type like: User
          return type.constructFromObject(data);
        } else if (Array.isArray(type)) {
          // for array type like: ['String']
          let itemType = type[0];

          return data.map((item) => {
            return ApiClient.convertToType(item, itemType);
          });
        } else if (typeof type === 'object') {
          // for plain object type like: {'String': 'Integer'}
          let keyType, valueType;
          for (let k in type) {
            if (type.hasOwnProperty(k)) {
              keyType = k;
              valueType = type[k];
              break;
            }
          }

          let result = {};
          for (let k in data) {
            if (data.hasOwnProperty(k)) {
              let key = ApiClient.convertToType(k, keyType);
              let value = ApiClient.convertToType(data[k], valueType);
              result[key] = value;
            }
          }

          return result;
        } else {
          // for unknown type, return the data directly
          return data;
        }
    }
  }

  /**
   * Constructs a new map or array model from REST data.
   * @param data {Object|Array} The REST data.
   * @param obj {Object|Array} The target object or array.
   */
  static constructFromObject(data, obj, itemType) {
    if (Array.isArray(data)) {
      for (let i = 0; i < data.length; i++) {
        if (data.hasOwnProperty(i)) {
          obj[i] = ApiClient.convertToType(data[i], itemType);
        }
      }
    } else {
      for (let k in data) {
        if (data.hasOwnProperty(k)) {
          obj[k] = ApiClient.convertToType(data[k], itemType);
        }
      }
    }
  }
}

/**
 * The General API client implementation.
 * @type {module:ApiClient}
 */
ApiClient.generalApiInstance = new ApiClient({
  baseURL: process.env.REACT_APP_SERVER_URL,
});

/**
 * The User API client implementation.
 * @type {module:ApiClient}
 */
ApiClient.userApiInstance = new ApiClient({
  baseURL: process.env.REACT_APP_USER_API_URL,
});

/**
 * The Referral PRogram API client implementation.
 * @type {module:ApiClient}
 */
ApiClient.referralApiInstance = new ApiClient({
  baseURL: process.env.REACT_APP_REFERRAL_API_URL,
});

/**
 * The External API client implementation.
 * @type {module:ApiClient}
 */
ApiClient.assetsApiInstance = new ApiClient({
  baseURL: process.env.REACT_APP_ASSETS_INFO_API_URL,
});
