import { ApiClient } from '../ApiClient';

export class GeneralApi {
  /**
   * @alias module:api/GeneralApi
   * @class
   * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
   * default to {@link module:ApiClient#instance} if unspecified.
   */
  constructor(apiClient) {
    this.apiClient = apiClient || ApiClient.generalApiInstance;
  }

  /**
   * Get current NRG price in USD
   */
  async getPrices() {
    return await this.apiClient.callApi('/prices', 'get', {}, {});
  }
}
