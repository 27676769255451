import React from 'react';
import { Badge as MuiBadge, Avatar, Typography, useTheme } from '@mui/material';
import { ToolTip } from 'components/StyledComponents';
import { CollectionConfirmedIcon, NewCollectionIcon } from 'components/ImageComponent';

const Badge = ({ mode, isNew, confirmed, imageUrl }) => {
  const theme = useTheme();

  return (
    <MuiBadge color="primary" overlap="circular">
      <Avatar
        alt="collection"
        src={imageUrl}
        sx={{ border: `2px solid ${theme.palette.border.decorative}` }}
      />
      {isNew && (
        <ToolTip title="New" arrow placement="top">
          <Typography>
            <NewCollectionIcon mode={mode} />
          </Typography>
        </ToolTip>
      )}
      {confirmed ? <CollectionConfirmedIcon /> : null}
    </MuiBadge>
  );
};

export default Badge;
