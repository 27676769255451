import { EmailApi } from 'clientSDK';
import dotenv from 'dotenv';
dotenv.config();

const userApi = new EmailApi();

export default {
  subscribeToMailingList: async (email) => await userApi.subscribeToMailingList({ email }),

  sendEmailVerification: async ({ email, address }) =>
    await userApi.verifyEmail({ email, address }),
};
