// Validators

// Email address validator (i at end for case insensitive)
export const EMAIL_ADDRESS_VALIDATOR =
  /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/i;

// Username validator
// Can only be max 50 characters and contain letters, numbers, hyphens(-), and underscores(_)
export const USERNAME_VALIDATOR = /^[\w-_]{0,50}$/;

// Profile Bio string validator
// Can only be max 160 characters and contain letters, numbers, (-), (_), (@), and(.).
export const BIO_STRING_VALIDATOR = /^[\s\w-_@.,]{0,160}$/;

// Website link validator
export const URL_STRING_VALIDATOR =
  /^((?:https?:\/\/)?(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b[-a-zA-Z0-9@:%_\+.~#?&\/=]*|)$/;

// validate eth address
export const ETH_ADDRESS_VALIDATOR = /^0x[a-fA-F0-9]{40}$/;

// regex to match only positive decimal numbers allowed decimals (.) and (,)
export const POSITIVE_DECIMALS = /^[0-9]+[.,]?[0-9]*$/;

export const POSITIVE_DECIMALS_WITHOUT_COMMA = /^[0-9]+[.]?[0-9]{0,18}$/;

// Input controller
export const isDigitsOnly = (e) => {
  const regex = /[^0-9.]/g;

  if (regex.test(e.key) && e.key !== 'Backspace' && e.key !== 'Tab' && !e.key.startsWith('Arrow')) {
    e.preventDefault();
  }
};
