import useWalletContext from './useWalletContext';
import useTokenContext from 'hooks/useTokenContext';
import { useContext } from 'react';
import { CheckoutModalContext } from 'pages/context';

export const useMetamaskConnect = () => {
  const { connectAsync, setWalletModalOpen, isConnected, isInstalled } = useWalletContext();
  const { openModal } = useContext(CheckoutModalContext);
  const { isAuthenticated } = useTokenContext();

  const verifyWalletOwnership = (callback = () => {}) => {
    if (!isAuthenticated) {
      openModal(
        {
          callback,
        },
        'verify_wallet_ownership',
        'sign',
      );
    } else {
      callback && callback();
    }
  };

  const connectMetamask = async (callback) => {
    try {
      if (isInstalled) {
        !isConnected && (await connectAsync());
        verifyWalletOwnership(callback);
      } else {
        setWalletModalOpen(true);
      }
    } catch (err) {
      console.error(err);
    }
  };

  return { connectMetamask, setWalletModalOpen, verifyWalletOwnership };
};
