import { useEffect } from 'react';
import { useStateIfMounted } from 'use-state-if-mounted';

export const useDarkMode = () => {
  const [theme, setTheme] = useStateIfMounted('dark');
  const [componentMounted, setComponentMounted] = useStateIfMounted(false);

  const setMode = (mode) => {
    window.localStorage.setItem('theme', mode);
    setTheme(mode);
  };

  const toggleTheme = () => {
    if (theme === 'light') {
      setMode('dark');
    } else {
      setMode('light');
    }
  };

  useEffect(() => {
    const localTheme = window.localStorage.getItem('theme');
    localTheme ? setTheme(localTheme) : setMode('dark');
    setComponentMounted(true);
  }, []);

  return [theme, toggleTheme, componentMounted];
};
