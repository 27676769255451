import React from 'react';
import { styled, Typography, useMediaQuery } from '@mui/material';
import SectionTitle from './SectionTitle';

/********************  Styled Components  ********************/
const ContentWrapper = styled('div')(({ position }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: position,
  justifyContent: position,
  gap: 10,
  width: '50%',

  '@media (max-width: 480px)': {
    width: '100%',

    svg: {
      width: 32,
      height: 32,
    },
  },
}));

const SmallText = styled(Typography)(({ fontSize, fontWeight, lineHeight, textAlign, theme }) => ({
  fontSize: fontSize ?? 14,
  fontWeight: fontWeight ?? 500,
  lineHeight: lineHeight ?? '20px',
  textAlign: textAlign ?? 'center',
  color: theme.palette.text.tertiary,
  whiteSpace: 'pre-line',
}));

/********************  Main Component  ********************/
const NumSection = ({ icon, title, text, position = 'flex-start' }) => {
  const below905 = useMediaQuery('(max-width: 905px)');

  return (
    <ContentWrapper position={position}>
      {icon}
      <SectionTitle fontSize={below905 ? 20 : 24} lineHeight={below905 ? '28px' : '32px'}>
        {title}
      </SectionTitle>
      <SmallText textAlign={position === 'flex-start' ? 'left' : 'center'}>{text}</SmallText>
    </ContentWrapper>
  );
};

export default NumSection;
