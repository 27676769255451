import { useDispatch } from 'react-redux';

const useNotification = () => {
  const dispatch = useDispatch();

  const showNotification = ({
    message,
    type,
    status = true,
    link = null,
    description,
    position = null,
  }) => {
    dispatch({
      type: 'NOTIFICATION',
      payload: { message, type, status, link, description, position },
    });
  };

  return showNotification;
};

export default useNotification;
