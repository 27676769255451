import { useContext } from 'react';
import { WalletContext } from 'context';

const useWalletContext = () => {
  const context = useContext(WalletContext);

  if (!context) {
    throw new Error('Wallet context must be used inside provider');
  }

  return context;
};

export default useWalletContext;
