import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

const Root = styled('div')(() => ({
  position: 'relative',
  top: 4,
}));

const Bottom = styled(CircularProgress)(({ theme, completed, show }) => ({
  color: !show ? 'transparent' : theme.palette.border.decorative,

  '& circle': {
    fill: completed ? theme.palette.support.success : 'transparent',
  },
}));

const Top = styled(CircularProgress)(({ theme }) => ({
  color: theme.palette.support.success,
  animationDuration: '2000ms',
  position: 'absolute',
  left: 0,

  '& circle': {
    strokeDasharray: '20px,200px',
  },
}));

export default function ProgressBar(props) {
  return (
    <Root>
      <Bottom
        show={props.show}
        completed={props.completed === 'true' ? 1 : 0}
        variant="determinate"
        size={props.size ?? 40}
        thickness={2}
        {...props}
        value={100}
      />
      <Top
        variant={props.loading === 'true' ? 'indeterminate' : 'determinate'}
        size={40}
        thickness={4}
        {...props}
      />
      <Typography style={{ position: 'absolute', top: 8, left: 16 }}>{props.value}</Typography>
    </Root>
  );
}
