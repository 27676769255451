import { StandardButton } from 'components/Button';

const RenderButton = ({ converting, onSwapTokens, error }) => {
  if (converting) {
    return (
      <StandardButton width={199} height={48} disabled disableRipple>
        <div className="loading">Converting...</div>
      </StandardButton>
    );
  }
  return (
    <StandardButton width={199} height={48} onClick={onSwapTokens} disabled={error}>
      Convert
    </StandardButton>
  );
};

export default RenderButton;
