import { CONTRACT_ASSET_CLASSES, DEFAULT_NETWORK, WNRG_PROXY_ADDRESS } from 'constants/index';
import { buildDataField, getStringAssetClassContract, randomSalt, tokenIdtoHex } from './common';

const chainId = parseInt(DEFAULT_NETWORK);

// TODO: change asset classes according to ERC1155 and ERC721, once we have the classification in NFT object from BE
export const constructCancelOfferMessage = ({
  owner,
  taker,
  makerContract,
  takerContract,
  tokenId,
  valueWeiBN,
  salt,
  start,
  end,
}) => {
  const message = {
    maker: owner,
    makeAsset: {
      assetType: {
        assetClass: CONTRACT_ASSET_CLASSES['C_ERC20_STR'], // ERC721 class string
        data: `0x${makerContract.slice(2).padStart(64, '0')}`,
      },
      value: valueWeiBN.toString(),
    },
    taker,
    takeAsset: {
      assetType: {
        assetClass: CONTRACT_ASSET_CLASSES['C_ERC721_STR'], // ETH class string
        data: buildDataField(takerContract, tokenIdtoHex(tokenId)),
      },
      value: '1',
    },
    salt: salt || randomSalt(),
    start: start,
    end: end,
    dataType: CONTRACT_ASSET_CLASSES['C_ff_STR'],
    data: '0x',
    collectionBid: false,
  };

  return { message };
};

// construct takerOrder and makerOrder for Accept Offer
export const constructAcceptOfferOrders = (order, account) => {
  const nftAsset = {
    assetType: {
      assetClass: getStringAssetClassContract(order.TakerAsset.Class),
      data: buildDataField(order.TakerAsset.Contract, tokenIdtoHex(order.NftToken)),
    },
    value: '1',
  };

  const valueAsset = {
    assetType: {
      assetClass: getStringAssetClassContract(order.MakerAsset.Class),
      data: `0x${WNRG_PROXY_ADDRESS[chainId].slice(2).padStart(64, '0')}`,
    },
    value: order.MakerAsset.Value,
  };

  const makerOrder = {
    maker: order.MakerAsset.Owner, // buyer
    makeAsset: valueAsset,
    taker: account, // seller
    takeAsset: nftAsset,
    salt: parseInt(order.Salt),
    start: order.MatchAfterTimestamp,
    end: order.MatchBeforeTimestamp,
    dataType: getStringAssetClassContract(order.DataType),
    data: '0x',
    collectionBid: false,
  };

  const takerOrder = {
    maker: account, // seller
    makeAsset: nftAsset,
    taker: order.MakerAsset.Owner, // buyer
    takeAsset: valueAsset,
    salt: 0,
    start: order.MatchAfterTimestamp,
    end: order.MatchBeforeTimestamp,
    dataType: getStringAssetClassContract(order.DataType),
    data: '0x',
    collectionBid: false,
  };

  return { takerOrder, makerOrder };
};

// TODO: change asset classes according to ERC1155 and ERC721, once we have the classification in NFT object from BE
// construct maker MESSAGE for MAKE OFFER
export const constructMakeOfferMessage = ({
  owner,
  address,
  contractAddress,
  tokenId,
  valueWeiBN,
  start,
  end,
}) => {
  const salt = randomSalt();
  const message = {
    maker: address,
    makeAsset: {
      assetType: {
        assetClass: CONTRACT_ASSET_CLASSES['C_ERC20_STR'],
        data: `0x${WNRG_PROXY_ADDRESS[chainId].slice(2).padStart(64, '0')}`,
      },
      value: valueWeiBN.toString(),
    },
    taker: owner,
    takeAsset: {
      assetType: {
        assetClass: CONTRACT_ASSET_CLASSES['C_ERC721_STR'],
        data: buildDataField(contractAddress, tokenIdtoHex(tokenId)),
      },
      value: '1',
    },
    salt: salt,
    start: start,
    end: end,
    dataType: CONTRACT_ASSET_CLASSES['C_ff_STR'],
    data: '0x',
    collectionBid: false,
  };

  return { message };
};

// TODO - Once we have details whether an NFT is ERC1155 or ERC721 we will change th Asset classes below accordingly
// construct maker ORDER for MAKE OFFER
export const constructOfferOrder = ({
  address,
  owner,
  contractAddress,
  tokenId,
  valueWeiBN,
  signedOrder,
  salt,
  start,
  end,
}) => {
  return {
    Key: '',
    MakerAsset: {
      Owner: address,
      TokenID: '',
      Contract: WNRG_PROXY_ADDRESS[chainId],
      Value: valueWeiBN.toString(),
      Class: CONTRACT_ASSET_CLASSES['C_ERC20'], // ETH class number
    },
    TakerAsset: {
      Owner: owner,
      TokenID: tokenIdtoHex(tokenId),
      Contract: contractAddress,
      Value: '1', // hardcoded to one - One NFT for sale
      Class: CONTRACT_ASSET_CLASSES['C_ERC721'], // ERC721 class number
    },
    Salt: salt.toString(),
    MatchAfterTimestamp: start,
    MatchBeforeTimestamp: end,
    MakerSign: signedOrder,
    Data: '0x',
    DataType: CONTRACT_ASSET_CLASSES['C_ff'],
    MatchAllowanceSignature: '',
    CollectionBid: false,
  };
};
