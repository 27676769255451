import { useCallback } from 'react';
import useWalletContext from 'hooks/useWalletContext';

// checks and request user to switch network
export const useSwitchNetwork = (chainId) => {
  const { chainId: currentChainId, switchToNetwork } = useWalletContext();
  const swtichNetwork = useCallback(async () => {
    try {
      if (currentChainId !== chainId) {
        await switchToNetwork(chainId); // returns null in both cases (accept or reject)
      }
      return true;
    } catch (err) {
      console.error(err);
      return false;
    }
  }, [chainId, currentChainId, switchToNetwork]);
  return swtichNetwork;
};
