import React, { useEffect } from 'react';
import { useStateIfMounted } from 'use-state-if-mounted';
import { useTheme } from '@mui/material/styles';
import { useHistory, useLocation } from 'react-router-dom';
import { Drawer, Box, List, ListItemText } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { ChevronLeft as ChevronLeftIcon, Close as CloseIcon } from '@mui/icons-material';
import { ACCOUNT_SETTINGS } from 'constants/index';
import { openLink } from 'utils/openLink';
import useTopValue from 'hooks/useTopValue';
import SettingsDrawer from './SettingsDrawer';
import { FilterMenuItem, MenuItemArrowIcon, NavigationBody } from 'components/StyledComponents';
import { isAddress } from 'utils';

const AccountSettingsSidebar = ({ open, setWalletSidebarOpen, setSettingMenuOpen }) => {
  const theme = useTheme();
  const history = useHistory();
  const { pathname } = useLocation();
  const overlay = useMediaQuery('(max-width: 1200px)');
  const below600 = useMediaQuery('(max-width: 600px)');
  const [settingsDrawerOpen, setSettingsDrawerOpen] = useStateIfMounted(false);
  const [menu, setMenu] = useStateIfMounted(null);
  const [params, setParams] = useStateIfMounted({});
  const { marginTop } = useTopValue();

  const handleOpenWalletSidebar = () => {
    setWalletSidebarOpen(true);
    setSettingMenuOpen(false);
  };

  const handleOnBack = () => {
    setSettingsDrawerOpen(false);
    setSettingMenuOpen(true);
  };

  useEffect(() => {
    setSettingMenuOpen(false);

    // Should be in settings path
    if (pathname.includes('account/settings')) {
      const urlArray = history.location.pathname.split('/');
      let current = urlArray[urlArray.length - 1];
      let selectedMenu = ACCOUNT_SETTINGS.find((item) => item.slug === current);

      if (isAddress(current) && urlArray.reverse()[1] === 'collections') {
        selectedMenu = ACCOUNT_SETTINGS.find((item) => item.slug === 'collections');
        setParams({ collectionAddress: current });
      } else {
        setParams({});
      }

      if (selectedMenu) {
        setMenu(selectedMenu.text);
        setSettingsDrawerOpen(true);
      }
    } else {
      setSettingsDrawerOpen(false);
    }
  }, [pathname]);

  return (
    <>
      <Drawer
        anchor="right"
        open={open && !settingsDrawerOpen}
        variant={overlay ? 'temporary' : 'persistent'}
        sx={{
          zIndex: below600 ? 2000 : 1280, // zIndex for going over the header
          '.MuiPaper-root': {
            backgroundColor: theme.palette.background.secondary,
            backgroundImage: 'none',
          },
        }}
      >
        <NavigationBody top={below600 ? 0 : marginTop}>
          <Box>
            <List sx={{ py: 0 }}>
              <FilterMenuItem onClick={handleOpenWalletSidebar} heading={below600 ? 1 : 0}>
                <MenuItemArrowIcon style={{ flex: 'initial' }}>
                  {below600 ? (
                    <CloseIcon style={{ color: theme.palette.text.main }} />
                  ) : (
                    <ChevronLeftIcon style={{ color: theme.palette.text.main }} />
                  )}
                </MenuItemArrowIcon>
                <ListItemText
                  sx={{
                    marginLeft: 1,
                  }}
                  primaryTypographyProps={{
                    fontWeight: below600 ? 600 : 500,
                    fontSize: below600 ? 24 : 18,
                  }}
                  primary={below600 ? 'Settings' : 'Account Settings'}
                />
              </FilterMenuItem>
              {ACCOUNT_SETTINGS.map((menu) => (
                <FilterMenuItem key={menu.text}>
                  {menu?.icon}
                  <ListItemText
                    sx={{
                      marginLeft: 1,
                    }}
                    primaryTypographyProps={{ fontWeight: '600' }}
                    primary={menu.text}
                    onClick={() => {
                      if (below600) {
                        setSettingsDrawerOpen(true);
                        setMenu(menu.text);
                        history.push(menu.path);
                      } else {
                        menu.path.startsWith('https')
                          ? openLink(menu.path)
                          : history.push(menu.path);
                        setSettingMenuOpen(false);
                      }
                    }}
                  />
                </FilterMenuItem>
              ))}
            </List>
          </Box>
        </NavigationBody>
      </Drawer>
      {below600 && (
        <SettingsDrawer
          open={settingsDrawerOpen}
          page={menu}
          overlay={overlay}
          onBack={handleOnBack}
          params={params}
        />
      )}
    </>
  );
};

export default AccountSettingsSidebar;
