import React from 'react';
import { CircularProgress } from '@mui/material';
import { useStateIfMounted } from 'use-state-if-mounted';
import { StandardButton } from './Button';
import { useMetamaskConnect } from 'hooks/useMetamaskConnect';
import useWalletContext from 'hooks/useWalletContext';

export const ConnectButton = ({
  width,
  height,
  disabled,
  onClick,
  children,
  withLoading = false,
  onWalletConnect = () => {},
}) => {
  const { connectMetamask } = useMetamaskConnect();
  const { isConnected } = useWalletContext();
  const [loading, setLoading] = useStateIfMounted(false);

  const handleClick = async () => {
    !isConnected && withLoading && setLoading(true);
    await connectMetamask(onWalletConnect);
    setLoading(false);
  };

  return (
    <>
      <StandardButton
        width={width ?? '100%'}
        height={height ?? 48}
        disabled={disabled}
        onClick={onClick ?? handleClick}
      >
        {loading ? <CircularProgress color="white" size={24} /> : children ?? 'Connect to Metamask'}
      </StandardButton>
    </>
  );
};
