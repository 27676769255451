import React from 'react';
import { useStateIfMounted } from 'use-state-if-mounted';
import { styled } from '@mui/material/styles';
import {
  Menu as MuiMenu,
  Button,
  Stack,
  Typography,
  useMediaQuery,
  Box,
  RadioGroup,
  Radio,
} from '@mui/material';
import {
  KeyboardArrowDown as ArrowDropDownIcon,
  KeyboardArrowUp as ArrowDropUpIcon,
  ArrowUpward as ArrowUpwardIcon,
  Close as CloseIcon,
} from '@mui/icons-material';
import { GrayIconButton } from 'components/Button';
import { PAGE_SIZES } from 'constants/index';
import {
  MenuItem,
  CustomExpandMore,
  StyledBackdrop,
  StyledForm,
  StyledLabel,
  StyledCloseButton,
  StyledControlLabel,
} from 'components/StyledComponents';

/************************* Styled Components  **************************/
const Container = styled(Stack)(({ open = 0, position, width, left, theme }) => ({
  position: position ?? 'fixed',
  bottom: 0,
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  width:
    width ??
    (open === 2 ? 'calc(100% - 390px)' : open === 1 ? 'calc(100% - 122px)' : 'calc(100% - 50px)'),
  padding: '12px',
  left: left ?? (open === 2 ? '365px' : open === 1 ? '97px' : '25px'),
  right: '25px',
  zIndex: 1200,
  backgroundColor: theme.palette.background.secondary,
  border: `1px solid ${theme.palette.border.separator}`,
  borderRadius: '0 0 10px 10px',

  '@media (max-width: 1280px) and (min-width: 906px)': {
    left: left ?? (open === 2 ? '372px' : open === 1 ? '104px' : '32px'),
    right: '32px',
    width:
      width ??
      (open === 2 ? 'calc(100% - 404px)' : open === 1 ? 'calc(100% - 136px)' : 'calc(100% - 64px)'),
  },
  '@media (max-width: 600px)': {
    left: '0px',
    right: '0px',
    width: '100%',
  },

  '@media(min-width: 601px)': {
    padding: '12px 24px',
    border: `1px solid ${theme.palette.border.separator}`,
  },

  fontFeatureSettings: `'cv11' on, 'cv09' on, 'cv03' on, 'cv04' on, 'cv02' on, 'ss01' on`,
}));

const Handler = styled(Button)(({ theme, width }) => ({
  color: theme.palette.text.tertiary,
  height: 48,
  width: width,
  borderRadius: theme.spacing(1),
  borderColor: theme.palette.border.separator,

  '.MuiTypography-root': {
    fontSize: 16,
    lineHeight: '24px',
    fontWeight: 600,
  },

  '&:disabled': {
    opacity: 0.24,
  },
}));

const CurrentShower = styled('div')(({ theme }) => ({
  color: theme.palette.text.main,
  fontSize: 12,
  fontWeight: 500,
  height: 54,
  margin: theme.spacing(1),
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}));

const SelectCounts = styled(Button)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: 12,
  position: 'static',
  width: 79,
  height: 48,
  left: 0,
  top: 0,
  background: theme.palette.ui2,
  border: `1px solid ${theme.palette.border.separator}`,
  borderRadius: 8,
}));

const Menu = styled(MuiMenu)(() => ({
  '& .MuiList-root': {
    width: 79,
    padding: 0,
  },
}));

/************************* Main Component  **************************/
const Pagination = ({
  counts,
  currentPage,
  perPage,
  handlePerPage,
  next,
  previous,
  open,
  position,
  width,
  left,
}) => {
  const below600 = useMediaQuery('(max-width: 600px)');
  const below500 = useMediaQuery('(max-width: 500px)');

  const [openMobileView, setOpenMobileView] = useStateIfMounted(false);

  const [anchorEl, setAnchorEl] = useStateIfMounted(null);
  const isShow = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const calculator = (page) => {
    const from = perPage * (page - 1);
    let to = from + perPage;
    if (to > counts) {
      to = counts;
    }
    return `${from + 1} - ${to}`;
  };

  const itemsMobileView = () => {
    return (
      <StyledBackdrop open={openMobileView}>
        <StyledForm>
          <Box>
            <StyledLabel id="demo-radio-buttons-group-label">Rows</StyledLabel>
            <StyledCloseButton onClick={() => setOpenMobileView(false)}>
              <CloseIcon />
            </StyledCloseButton>
          </Box>
          <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label"
            value={perPage}
            name="radio-buttons-group"
          >
            {PAGE_SIZES.map((option) => (
              <StyledControlLabel
                key={option}
                value={option}
                onClick={() => {
                  handlePerPage(option);
                  setOpenMobileView(false);
                }}
                control={<Radio />}
                label={option}
                sx={{ marginRight: -11 }}
              />
            ))}
          </RadioGroup>
        </StyledForm>
      </StyledBackdrop>
    );
  };

  return counts <= perPage ? null : (
    <Container
      open={open}
      position={position}
      width={width}
      left={left}
      className="MuiTablePagination-root"
    >
      <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={2}>
        <Handler
          variant="outlined"
          color="medium"
          width={101}
          onClick={previous}
          disabled={currentPage === 1}
        >
          <Typography>Previous</Typography>
        </Handler>

        <Handler
          variant="outlined"
          color="medium"
          width={69}
          onClick={next}
          disabled={perPage * currentPage + 1 > counts}
        >
          <Typography>Next</Typography>
        </Handler>
        {!below500 && (
          <CurrentShower>
            {calculator(currentPage)} of {counts}
          </CurrentShower>
        )}
      </Stack>

      <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={1}>
        <Box>
          <GrayIconButton
            onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
            sx={{ marginRight: below500 ? 0 : 8 }}
          >
            <ArrowUpwardIcon />
          </GrayIconButton>
        </Box>
        {!below500 && <Typography>Rows: </Typography>}
        {below600 ? (
          <SelectCounts variant="outlline" onClick={() => setOpenMobileView(true)}>
            {perPage}
            {openMobileView ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
          </SelectCounts>
        ) : (
          <Box display="flex">
            <SelectCounts
              variant="outlline"
              id="pagesize-button"
              aria-controls={isShow ? 'pagesize-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={isShow ? 'true' : undefined}
              onClick={handleClick}
              endIcon={<CustomExpandMore />}
              disableRipple
            >
              {perPage}
            </SelectCounts>
            <Menu
              id="pagesize-menu"
              anchorEl={anchorEl}
              open={isShow}
              onClose={handleClose}
              MenuListProps={{
                'aria-labelledby': 'pagesize-button',
              }}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
            >
              {PAGE_SIZES.map((size, index) => (
                <MenuItem
                  key={index}
                  value={size}
                  onClick={() => {
                    handlePerPage(size);
                    handleClose();
                  }}
                >
                  {size}
                </MenuItem>
              ))}
            </Menu>
          </Box>
        )}
      </Stack>

      {itemsMobileView()}
    </Container>
  );
};

export default Pagination;
