import { ApiClient } from 'clientSDK/ApiClient';

export class LaunchpadApi {
  /**
   * Constructs a new LaunchpadApi.
   * @alias module:api/LaunchpadApi
   * @class
   * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
   * default to {@link module:ApiClient#instance} if unspecified.
   */
  constructor(apiClient) {
    this.apiClient = apiClient || ApiClient.userApiInstance;
  }

  async updateCollection({ address, body }) {
    return await this.apiClient.callApi(
      '/launchpad/updateCollection/{address}',
      'post',
      { address },
      {},
      null,
      {},
      body,
    );
  }

  async updateMintDetails({ address, body }) {
    return await this.apiClient.callApi(
      '/launchpad/updateMintDetails/{address}',
      'post',
      { address },
      {},
      null,
      {},
      body,
    );
  }

  async checkUrl({ collectionUrl }) {
    return await this.apiClient.callApi(
      '/launchpad/checkUrl/{collectionUrl}',
      'get',
      { collectionUrl },
      {},
      null,
    );
  }
}
