import { ApiClient } from '../ApiClient';
export class OrderbookApi {
  /**
   * Constructs a new OrderbookApi.
   * @alias module:api/OrderbookApi
   * @class
   * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
   * default to {@link module:ApiClient#instance} if unspecified.
   */
  constructor(apiClient) {
    this.apiClient = apiClient || ApiClient.generalApiInstance;
  }

  /**
   * Create an order in orderbook for an NFT
   * @param {object} body order object for creating an order
   */
  async create(body) {
    return await this.apiClient.callApi('/obsubmit/create/', 'post', {}, {}, null, undefined, body);
  }

  /**
   * Cancel an order in orderbook for an NFT
   * @param {object} body order object for creating an order
   */
  async cancelOrder(body) {
    return await this.apiClient.callApi('/obsubmit/cancel/', 'post', {}, {}, null, undefined, body);
  }

  /**
   * Get Order for an NFT from orderbook
   * @param {string} hashkey tokenId to retrieve data
   */
  async getOrder(hashkey) {
    return await this.apiClient.callApi('/obquery/hashkey/{hashkey}', 'get', { hashkey }, {});
  }

  /**
   * Get Orders for every NFT selected in orderbook
   */
  async getOrders(hashkeys) {
    return await this.apiClient.callApi('/obquery/hashkeys/', 'get', {}, { hashkeys });
  }

  /**
   * Get all offers in orderbook made by a user
   * @param {string} address user address used to retrieve data
   */
  async getOffersMade({ makerAddress }) {
    return await this.apiClient.callApi(
      '/obquery/maker/{makerAddress}',
      'get',
      { makerAddress },
      {},
    );
  }
}
