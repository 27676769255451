/* eslint-disable no-console */
import React, { createContext, useCallback, useEffect, useState } from 'react';

export const WindowContext = createContext({
  active: true,
});

export const WindowProvider = ({ children }) => {
  const [active, setActive] = useState(true);

  const handleFocus = useCallback(() => {
    setActive(true);
  }, []);

  const handleBlur = useCallback(() => {
    setActive(false);
  }, []);

  useEffect(() => {
    window.addEventListener(
      'beforeunload',
      () => {
        localStorage.removeItem('active_rental');
      },
      false,
    );

    window.addEventListener('focus', handleFocus);
    window.addEventListener('blur', handleBlur);
    return () => {
      window.removeEventListener('focus', handleFocus);
      window.removeEventListener('blur', handleBlur);
    };
  }, []);

  return (
    <WindowContext.Provider
      value={{
        active,
      }}
    >
      {children}
    </WindowContext.Provider>
  );
};
