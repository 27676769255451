import React from 'react';
import { useStateIfMounted } from 'use-state-if-mounted';
import { useMediaQuery } from '@mui/material';
import BeforeClaimModal from './components/BeforeClaimModal';
import ClaimGmiModal from './components/ClaimGmiModal';
import ClaimSuccessModal from './components/ClaimSuccessModal';

/********************  Main Component  ********************/
const CLAIM_DATA = {
  gmiAmount: '3000',
  gmiValue: '~$1000.00',
};

const AirdropClaimModal = ({ isOpen, onDismiss }) => {
  const [hasEnteredReferralCode, setHasEnteredReferralCode] = useStateIfMounted(false);
  const [claimInProgress, setClaimInProgress] = useStateIfMounted(true);

  const below600 = useMediaQuery('(max-width: 600px)');
  const modalWidth = below600 ? 486 : 524;

  if (!hasEnteredReferralCode) {
    return (
      <BeforeClaimModal
        isOpen={isOpen}
        onDismiss={onDismiss}
        continueToClaim={() => setHasEnteredReferralCode(true)}
        width={modalWidth}
      />
    );
  }

  if (claimInProgress) {
    return (
      <ClaimGmiModal
        isOpen={isOpen}
        onDismiss={onDismiss}
        handleSuccess={() => setClaimInProgress(false)}
        width={modalWidth}
        claimData={CLAIM_DATA}
      />
    );
  }

  return (
    <ClaimSuccessModal
      isOpen={isOpen}
      onDismiss={onDismiss}
      width={modalWidth}
      claimData={CLAIM_DATA}
    />
  );
};

export default AirdropClaimModal;
