import React from 'react';
import { useHistory } from 'react-router-dom';
import { styled, useMediaQuery } from '@mui/material';
import { Footer } from 'components/General';
import { Text } from 'components/Text';
import InviteDescription from 'components/Invite/InviteDescription';
import { GrayButtonOutlined, StandardButton } from 'components/Button';
import ImageCallToAction from 'pages/ReferralProgram/InviteFriends/components/ImageCallToAction';
import GMI_3dDoubleCoin from 'assets/images/GMI-3d-Double-Coin.png';
import GMI_ETH_3dCoin from 'assets/images/GMI-ETH-3d-Coin.png';
import BlurGreenBackground from './components/BlurGreenBackground';
import { SUSHISWAP_GMI } from 'constants/index';
import { openLink } from 'utils/openLink';

/********************  Styled Components  ********************/
const PageWrapper = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
}));

const HeaderWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  height: 515,
  padding: '181px 100px 0 100px',
  width: '100%',
  position: 'relative',
  alignItems: 'center',
  overflow: 'hidden',
  borderBottom: `1px solid ${theme.palette.border.separator}`,

  p: {
    maxWidth: 1000,
  },

  '@media(max-width: 905px)': {
    padding: '181px 90px 0 90px',
  },

  '@media(max-width: 600px)': {
    height: 374,
    padding: '96px 85px 0 85px',
  },

  '@media(max-width: 480px)': {
    height: 340,
    padding: '81px 44px 0 44px',
  },
}));

const PageContent = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  alignItems: 'center',
  padding: '64px 162px 64px 162px',

  '@media(max-width: 1280px)': {
    padding: '64px 0 64px 0',
  },
}));

const Section = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  alignItems: 'center',

  '@media(max-width: 1280px)': {
    padding: '0 60px',
  },

  '@media(max-width: 600px)': {
    '> div': {
      '> div': {
        padding: '0 30px',
      },
    },
  },

  '@media(max-width: 480px)': {
    padding: '0 35px',
    '> div': {
      '> div': {
        padding: '0 0px',
      },
    },
  },
}));

const Divider = styled('div')(({ theme }) => ({
  height: '1px',
  width: '100%',
  background: theme.palette.border.separator,
  marginTop: 64,
  marginBottom: 64,

  '@media(max-width: 905px)': {
    marginTop: 14,
  },
  '@media(max-width: 600px)': {
    marginTop: 40,
    marginBottom: 40,
  },
}));

const TextWrapper = styled('div')(() => ({
  width: '100%',

  '@media(max-width: 905px)': {
    maxWidth: 650,
  },

  '@media(max-width: 600px)': {
    maxWidth: 540,
  },

  '@media(max-width: 480px)': {
    maxWidth: 285,
  },
}));

const PanelWrapper = styled('div')(() => ({
  display: 'flex',
  marginTop: 40,
  width: '100%',
  gap: 24,
  maxWidth: 1116,

  '@media(max-width: 1280px)': {
    maxWidth: 1058,
    paddingRight: 24,
    paddingLeft: 24,
  },

  '@media(max-width: 600px)': {
    flexDirection: 'column',
  },

  '@media(max-width: 480px)': {
    paddingRight: 16,
    paddingLeft: 16,
  },
}));

/********************  Sub Component  ********************/
const SectionTitle = ({ title }) => {
  return (
    <Text fs={42} fw={500} lh={50} mb={8} xs={{ fs: 24, lh: 32 }}>
      {title}
    </Text>
  );
};

/********************  Main Component  ********************/
const NotQualifiedPage = () => {
  const below480 = useMediaQuery('(max-width: 480px)');
  const history = useHistory();

  return (
    <>
      <PageWrapper>
        <HeaderWrapper>
          <BlurGreenBackground onlyTwoDots />
          <Text
            main="true"
            fs={60}
            fw={500}
            lh={68}
            mb={below480 ? 16 : 24}
            sm={{ fs: 32, fw: 600, lh: 40 }}
            xs={{ fs: 24, fw: 500, lh: 32 }}
          >
            Sorry, you’re not qualified for GMI Airdrop!
          </Text>
          <Text
            main="true"
            fs={32}
            fw={600}
            lh={40}
            mb={below480 ? 24 : 40}
            sm={{ fs: 20, lh: 28 }}
            xs={{ fs: 16, fw: 500, lh: 24 }}
          >
            Who is eligible for GMI Airdrop?
          </Text>
          <GrayButtonOutlined
            width={130}
            height={40}
            onClick={() => openLink('https://docs.gonnamakeit.com/docs/about/airdrop-referral')}
          >
            Learn more
          </GrayButtonOutlined>
        </HeaderWrapper>

        <PageContent>
          <Section>
            <InviteDescription position="center">
              <SectionTitle title="Don't worry, you can still earn GMI by inviting frens" />
            </InviteDescription>
            <StandardButton
              width={129}
              height={40}
              sx={{ mt: below480 ? 4 : 5 }}
              onClick={() => history.push('/rewards/referral_program/')}
            >
              Invite frens
            </StandardButton>
          </Section>

          <Divider />

          <SectionTitle title="Get GMI" />
          <TextWrapper>
            <Text fs={28} fw={500} lh={36} mt={24} mb={40} xs={{ fs: 16, lh: 24 }}>
              Buy GMI and benefit from our high APYs by staking in our Liquidity Pools
            </Text>
          </TextWrapper>
          <StandardButton width={185} height={40} onClick={() => openLink(SUSHISWAP_GMI)}>
            Buy on Sushiswap
          </StandardButton>

          <Divider />

          <SectionTitle title="More ways to earn" />
          <PanelWrapper>
            <ImageCallToAction
              image={GMI_3dDoubleCoin}
              title="Listing rewards"
              text="Earn points by listing NFTs from eligible collections. Points convert to GMI once daily!"
              buttonText="Earn GMI"
              route="rewards/listing_rewards"
              docLink="http://docs.gonnamakeit.com/"
              shortText
            />
            <ImageCallToAction
              image={GMI_ETH_3dCoin}
              title="Farming"
              text="Lock GMI-ETH LP tokens up to 5 years to earn GMI. The longer you lock the more GMI you get."
              buttonText="Start Farming"
              route="/rewards/farming"
              docLink="https://docs.gonnamakeit.com/docs/faq/faq-farming"
              buttonLg
            />
          </PanelWrapper>
        </PageContent>
      </PageWrapper>
      <Footer />
    </>
  );
};

export default NotQualifiedPage;
