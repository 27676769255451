import { Typography, InputAdornment, Button } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import { CheckoutModalRow, PriceInput } from '../../components/styledComponents';
import { isDigitsOnly } from 'utils/validators';
import { Text } from 'components/Text';

/********************  Styled Components  ********************/
const MiddleRow = styled(CheckoutModalRow)(() => ({
  flexGrow: 1,
  alignItems: 'flex-start',
  flexDirection: 'column',
}));

const Content = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  width: 'inherit',
  paddingBottom: 10,
}));

const ContentHeading = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
}));

const SubHeading = styled(Typography)(({ theme, color }) => ({
  fontSize: 14,
  fontWeight: 500,
  color: color ?? theme.palette.text.secondary,
  margin: '0px',
  lineHeight: '20px',
  letterSpacing: '0.1px',
  padding: theme.spacing(1.2, 0),
}));

/********************  Main Component  ********************/
const InputRow = ({
  title,
  inputData,
  amount,
  usdAmount,
  handleChange,
  handleMaxAmount,
  error,
}) => {
  const theme = useTheme();

  return (
    <MiddleRow>
      <Text main="true" fs={14} fw={500} mb={20}>
        {title}
      </Text>

      <PriceInput
        fullWidth
        autoComplete="off"
        placeholder="0"
        fontSize="24px"
        value={amount}
        onChange={handleChange}
        onKeyDown={isDigitsOnly}
        inputProps={{ tabIndex: 1 }}
        sx={{
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: error ? theme.palette.text.error : '',
          },
        }}
        startAdornment={
          <InputAdornment position="start">
            {inputData.icon}
            <Typography variant="span" marginLeft="8px" color={theme.palette.text.main}>
              {inputData.token}
            </Typography>
          </InputAdornment>
        }
        endAdornment={<InputAdornment position="end">{`~$${usdAmount}`}</InputAdornment>}
      />

      {error && (
        <Text error="true" fs={12} fw={400} mt={8}>
          {error}
        </Text>
      )}

      <Content>
        <ContentHeading>
          <SubHeading>
            {inputData.token} Balance: {inputData.balance ?? 0}
          </SubHeading>

          {handleMaxAmount && (
            <Button
              sx={{ fontSize: 14, fontWeight: 500, color: theme.palette.button.active }}
              onClick={handleMaxAmount}
              disableRipple
            >
              Max
            </Button>
          )}
        </ContentHeading>
      </Content>
    </MiddleRow>
  );
};

export default InputRow;
