import React, { useEffect } from 'react';
import { useStateIfMounted } from 'use-state-if-mounted';

import { Switch, useLocation } from 'react-router-dom';
import TopBarProgress from 'react-topbar-progress-indicator';

TopBarProgress.config({
  barColors: {
    0: '#00AB58',
    '1.0': '#00AB58',
  },
  shadowBlur: 5,
});

const CustomSwitch = ({ children }) => {
  const [progress, setProgress] = useStateIfMounted(false);
  const [prevLoc, setProvLoc] = useStateIfMounted('');
  const location = useLocation();

  useEffect(() => {
    setProvLoc(location.pathname);
    setProgress(true);
    if (location.pathname === prevLoc) {
      setProvLoc('');
    }
  }, [location]);

  useEffect(() => {
    setProgress(false);
  }, [prevLoc]);

  return (
    <>
      {progress && <TopBarProgress></TopBarProgress>}
      <Switch>{children}</Switch>
    </>
  );
};

export default CustomSwitch;
