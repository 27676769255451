import React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import { TextField, Typography } from '@mui/material';

const getInputBackgroundColor = (theme) => {
  return theme.palette.mode === 'dark'
    ? theme.palette.background.tertiary
    : theme.palette.background.default;
};

/************************* Styled Components  **************************/
const Container = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  marginBottom: theme.spacing(3),
}));

const Label = styled('label')(({ theme }) => ({
  fontSize: 16,
  fontWeight: 600,
  color: theme.palette.text.main,
  marginBottom: theme.spacing(1),
}));

const RequiredText = styled('span')(({ theme }) => ({
  color: theme.palette.text.error,
}));

const CustomizedTextField = styled(TextField)(
  ({ theme, height, disabled, disabled2, partialdisabled, success }) => ({
    '& input': {
      borderRadius: 8,
      padding: 0,
      paddingLeft: 16,
      paddingRight: 16,
      height: height ?? '',
      fontSize: 14,
      fontWeight: 500,
      lineHeight: '20px',
      color: partialdisabled ? theme.palette.text.secondary : theme.palette.text.main,

      backgroundColor: disabled
        ? disabled2
          ? theme.palette.background.secondary
          : 'rgba(138, 147, 155, 0.06)'
        : getInputBackgroundColor(theme),

      '&::placeholder': {
        color: theme.palette.text.secondary,
        opacity: 1,
      },
    },

    '& fieldset': {
      borderRadius: 8,
      borderColor: theme.palette.border.decorative,
    },

    '& input:-webkit-autofill': {
      WebkitBoxShadow: `0 0 0 30px ${getInputBackgroundColor(theme)} inset !important`,
      borderRadius: 8,
      WebkitTextFillColor: success
        ? theme.palette.support.success
        : partialdisabled
        ? theme.palette.text.secondary
        : theme.palette.text.main,
    },

    '& .Mui-disabled': {
      WebkitTextFillColor: success ? theme.palette.support.success : '',

      '& input': {
        backgroundColor: success ? getInputBackgroundColor(theme) : '',
      },

      '& fieldset.MuiOutlinedInput-notchedOutline': {
        borderColor: success ? theme.palette.support.success : '',
      },
    },

    '& .Mui-error': {
      '& fieldset.MuiOutlinedInput-notchedOutline': {
        borderColor: theme.palette.danger.color,
      },
    },

    '& .MuiFormHelperText-root.Mui-error': {
      color: theme.palette.text.error,
    },
  }),
);

/************************* Main Component  **************************/
const TextInput = ({
  title,
  placeholder,
  name,
  children,
  disabled2,
  error = false,
  helperText = '',
  disabled = false,
  required = false,
  rows = 0,
  // defaultValue = '',
  value = '',
  onChange,
  width,
  height,
  success,
  partialDisabled,
}) => {
  const theme = useTheme();

  return (
    <Container sx={{ width }}>
      <Label htmlFor={`field-${name}`}>
        {title}
        {required && <RequiredText> *</RequiredText>}
      </Label>
      {children ? (
        <Typography
          fontSize={12}
          color={theme.palette.text.medium}
          paddingBottom="0.5rem"
          display="inline"
        >
          {children}
        </Typography>
      ) : null}
      <CustomizedTextField
        name={name}
        error={error}
        helperText={helperText}
        placeholder={placeholder}
        id={`field-${name}`}
        multiline={!!rows}
        rows={rows}
        disabled={disabled || disabled2}
        disabled2={disabled2 ? 'true' : ''}
        value={value}
        onChange={(ev) => onChange(ev) || {}}
        height={height}
        success={success}
        partialdisabled={partialDisabled}
      />
    </Container>
  );
};

export default TextInput;
