import { ReferralApi } from 'clientSDK';
import dotenv from 'dotenv';
dotenv.config();

const referralApi = new ReferralApi();

export default {
  getAirdropBonuses: async () => {
    try {
      const responseData = await referralApi.getAirdropBonuses();
      return responseData;
    } catch (err) {
      console.error(err);
    }
  },
};
