import { Link } from 'react-router-dom';
import TimePicker from 'react-time-picker';
import {
  Menu,
  Select as MuiSelect,
  MenuItem as MuiMenuItem,
  Typography,
  ListItemButton,
  Switch,
  Tooltip,
  tooltipClasses,
  IconButton,
  CircularProgress,
  Backdrop,
  FormControl,
  FormLabel,
  Button,
  FormControlLabel,
  List,
  Table as MuiTable,
  TableRow,
  TableCell,
  Stack,
  Box,
  OutlinedInput,
  Link as MuiLink,
  styled,
} from '@mui/material';
import { withStyles } from '@mui/styles';
import { ExpandMore as ExpandMoreIcon, InfoOutlined } from '@mui/icons-material';
import clsx from 'clsx';
import PropTypes from 'prop-types';

import { RARITIES_PERCENTILE } from 'constants';
import { formatBigDecimalNumbers, removeCommas } from 'utils/numbers';

import {
  GMI,
  GMIIcon,
  EthBlueIcon,
  GMITokenLogo,
  EthNewIcon,
  EthRedIcon,
  EthIcon,
  EnergiIconCustom,
  NRGLogoInvertedIcon,
} from 'components/ImageComponent';

const LogoLink = styled(Link)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  textDecoration: 'none',
  color: theme.palette.text.main,
  '& img': {
    height: 34,
    marginRight: 6,
  },
  '& h1': {
    whiteSpace: 'nowrap',
    fontWeight: 400,
    fontSize: '26px',
    '& strong': {
      fontWeight: 600,
    },
  },

  '@media (min-width: 905px) and (max-width: 1279px)': {
    paddingRight: '0 !important',
  },
}));

export const GMILogo = ({ logoColor }) => {
  return (
    <LogoLink to="/" style={{ marginRight: 'auto', paddingLeft: 0 }}>
      <GMI color={logoColor} />
    </LogoLink>
  );
};

export const GMILogoOnly = ({ logoColor }) => {
  return (
    <Link to="/" style={{ width: 65, marginRight: 'auto' }}>
      <GMIIcon color={logoColor} />
    </Link>
  );
};

export const FlexBox = styled('div')(({ justify, padding, margin }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: justify ?? 'flex-start',
  padding: padding ?? 0,
  margin: margin ?? 0,
}));

export const GeneralFlexBox = styled('div')(
  ({ width, height, direction, align, justify, padding, margin, path, gap }) => ({
    display: 'flex',
    width: width ?? null,
    height: height ?? null,
    flexDirection: direction ?? 'row',
    alignItems: align ?? 'center',
    justifyContent: justify ?? 'flex-start',
    padding: padding ?? 0,
    margin: margin ?? 0,
    gap: gap ?? 0,

    '@media(max-width: 500px)': {
      justifyContent: path !== 'buy' || justify ? justify : 'flex-start',
    },
  }),
);

export const FlexCenter = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

export const VerticalFlexBox = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
}));

export const Divider = styled('div')(({ color, theme }) => ({
  height: '1px',
  width: '100%',
  backgroundColor:
    color ?? theme.palette.mode === 'light' ? 'rgba(30, 68, 94, 1)' : theme.palette.border.high,
  margin: '0px',
  '@media (max-width: 1440px)': {
    margin: '40px 0px',
  },
}));

const PriceText = styled('p')(({ theme, size, weight, color, pr }) => ({
  fontWeight: weight || 600,
  fontSize: size,
  color: color,
  margin: '0px',
  paddingRight: pr ?? theme.spacing(0.4),
}));

const PriceDiv = styled('div')(({ logo, justify, pr }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: justify ?? 'flex-start',
  gap: logo > 21 ? '4px' : '2px',
  marginRight: pr ?? 4,
}));

export const Price = ({
  price,
  currency = 'NRG',
  size = '16px',
  logo = 24,
  weight = 600,
  color,
  valid = true,
  show = false,
  justify = 'flex-start',
  logocolor,
  pr,
}) => {
  return (
    <PriceDiv logo={logo} justify={justify} pr={pr}>
      {currency === 'NRG' && show ? (
        <ToolTip title="NRG" arrow placement="top">
          <Box style={{ marginTop: 6 }}>
            {logocolor === 'black' ? (
              <NRGLogoInvertedIcon width={logo} height={logo} />
            ) : (
              <EnergiIconCustom width={logo} height={logo} />
            )}
          </Box>
        </ToolTip>
      ) : currency === 'ETH' && show ? (
        <ToolTip title="ETH" arrow placement="top">
          <Box style={{ marginTop: 4 }}>
            {logocolor === 'white' ? (
              <EthIcon width={logo} height={logo} />
            ) : logocolor === 'blue' ? (
              <EthBlueIcon width={logo} height={logo} />
            ) : logocolor === 'gray' ? (
              <EthNewIcon width={logo} height={logo} />
            ) : (
              <EthRedIcon width={logo} height={logo} />
            )}
          </Box>
        </ToolTip>
      ) : currency === 'GMI' ? (
        <ToolTip title="GMI" arrow placement="top">
          <Box style={{ marginTop: 4 }}>
            <GMITokenLogo width={logo} height={logo} />
          </Box>
        </ToolTip>
      ) : (
        ''
      )}
      <PriceText size={size} weight={weight} color={color} pr={pr}>
        {formatBigDecimalNumbers(Number(removeCommas(price)))}
      </PriceText>
      {valid && (
        <PriceText size={size} weight={weight} color={color}>
          {currency}
        </PriceText>
      )}
    </PriceDiv>
  );
};

export const StyledButton = styled('div')(({ theme, radius = '7px' }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '44px',
  height: '44px',
  border: `1px solid ${theme.palette.border.decorative}`,
  background: theme.palette.background.secondary,
  color: theme.palette.text.main,
  borderRadius: radius,
  transition: '0.3s',

  '&:hover': {
    cursor: 'pointer',
    boxShadow: `0px 0px 5px 0px ${theme.palette.shadow}`,
  },
}));

export const LinkText = styled('p')(({ theme, weight = 600 }) => ({
  color: theme.palette.primary.main,
  fontWeight: weight,
  fontSize: 16,
  letterSpacing: '0.15px',
  lineHeight: theme.spacing(3),

  '&:hover, &:active': {
    cursor: 'pointer',
    textDecoration: 'underline',
    opacity: 1,
  },
}));

export const ToolTip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  zIndex: 9999,
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.border.high,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    borderRadius: 4,
    backgroundColor: theme.palette.border.high,
    fontSize: '14px',
    fontWeight: 500,
    padding: '6px 8px',
    letterSpacing: '0.25px',
    marginTop: '0px !important',
    marginBottom: '10px !important',
    marginRight: '0px !important',
  },
}));

export const PositionToolTip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.border.high,
    transform: `translate(12px, 0px) !important`,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    borderRadius: 4,
    backgroundColor: theme.palette.border.high,
    fontSize: '14px',
    fontWeight: 500,
    padding: '6px 8px',
    letterSpacing: '0.25px',
    marginTop: '0px !important',
  },
}));

export const DisplayToolTip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.border.high,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    borderRadius: 4,
    backgroundColor: theme.palette.border.high,
    fontSize: '14px',
    fontWeight: 500,
    padding: '6px 8px',
    letterSpacing: '0.25px',
    marginBottom: '20px !important',
  },
}));

// For list items in Filter side bar - collections
export const ListItemFilter = styled(ListItemButton)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  color: theme.palette.text.main,

  '& svg': {
    width: '14px',
    height: '14px',
  },

  '&:hover': {
    backgroundColor: 'unset',
    '& svg': {
      width: '16px',
      height: '16px',
    },
    '& .MuiAvatar-root': {
      width: '32px',
      height: '32px',
    },
  },
}));

export const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(16px)',
      color: theme.palette.text.white,
      '& + .MuiSwitch-track': {
        backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#65C466',
        opacity: 1,
        border: 0,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#33cf4d',
      border: '6px solid #fff',
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color: theme.palette.mode === 'light' ? theme.palette.grey[100] : theme.palette.grey[600],
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 22,
    height: 22,
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
  },
}));

export const ModalContainer = styled('div')(({ theme, width }) => ({
  width: width ?? 550,
  display: 'flex',
  flexDirection: 'column',
  position: 'absolute',
  background: theme.palette.background.secondary,
  top: '50%',
  left: '50%',
  marginRight: '-50%',
  transform: 'translate(-50%, -50%)',
  borderRadius: 10,

  '&:focus': {
    outline: 'none',
  },
  '@media (max-width: 800px)': {
    width: 550,
  },
  '@media (max-width: 600px)': {
    overflow: 'scroll',
    borderRadius: 0,
  },

  '@media (max-width: 600px)': {
    width: 300,
  },
}));

export const ModalRow = styled('div')(({ theme, noborder = '', centered = '', padding }) => ({
  padding: padding ? theme.spacing(padding) : theme.spacing(2.2, 3.5),
  display: centered ? 'flex' : 'block',
  justifyContent: centered ? 'center' : 'flex-start',
  borderBottom: noborder ? 'none' : `1px solid ${theme.palette.border.separator}`,
}));

export const ModalCloseButton = styled(IconButton)(({ theme }) => ({
  position: 'absolute',
  right: 15,
  transform: 'translateY(-5px)',
  color: theme.palette.text.tertiary,
}));

export const InfoTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.background.default,
    color: 'white',
    maxWidth: 305,
    padding: '1rem',
    marginRight: '2.5rem',
    borderRadius: 10,
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.background.default,
  },
}));

export const InfoLink = styled(Link)(({ theme }) => ({
  textDecoration: 'none',
  fontWeight: 600,
  color: theme.palette.primary.main,
  display: 'inline-flex',
  '& svg': {
    marginLeft: 3,
    width: 20,
    color: theme.palette.primary.main,
  },
  '&:hover, &:hover svg': {
    color: theme.palette.primary.lighter,
  },
}));

export const MenuButton = styled('div')(({ active, theme }) => ({
  height: 46,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  cursor: 'pointer',
  fontWeight: '600',
  fontSize: '16px',
  lineHeight: '24px',
  letterSpacing: '0.15px',
  color: active && theme.palette.link.primary, // #525F6B

  '@media (max-width: 1023px)': {
    fontSize: '14px',
  },
}));

export const MenuNavigation = styled(Menu)(({ theme, shift }) => ({
  '& .MuiList-root': {
    paddingTop: 0,
    paddingBottom: 0,
    minWidth: 220,
    maxWidth: 350,
  },
  '& .MuiPaper-root': {
    marginTop: 10,
    transform: `translateX(${theme.spacing(shift ?? 0)})`,
  },
}));

export const MenuText = styled(Typography)(() => ({
  marginLeft: 16,
  marginRight: 16,
  fontSize: 16,
  fontWeight: 600,
}));

export const HeaderText = styled(Typography)(({ textAlign, fontWeight, theme }) => ({
  fontSize: 32,
  lineHeight: '44px',
  fontWeight: fontWeight ?? 600,
  textAlign: textAlign ?? 'center',
  color: theme.palette.text.main,
  '@media (max-width: 590px)': {
    fontSize: 24,
    padding: theme.spacing(0, 1),
  },
}));

export const SubTitleText = styled(Typography)(({ theme }) => ({
  fontSize: 16,
  fontWeight: 500,
  color: theme.palette.text.secondary,
  '@media (max-width: 590px)': {
    padding: theme.spacing(0, 1),
  },
}));

export const LoaderContainer = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  height: '100%',
}));

export const StyledCircularProgress = styled(CircularProgress)(({ theme }) => ({
  border: `1px solid ${theme.palette.border.medium}`,
  padding: theme.spacing(1.2),
  borderRadius: '50%',
}));

export const StyledBackdrop = styled(Backdrop)(({ theme }) => ({
  background: theme.palette.background.overlay,
  zIndex: 1200,
}));

export const StyledForm = styled(FormControl)(({ theme }) => ({
  padding: '16px 16px 0px 16px',
  background: theme.palette.background.secondary,
  position: 'fixed',
  bottom: 0,
  width: '100%',
  borderRadius: '8px 8px 0px 0px',
}));

export const StyledLabel = styled(FormLabel)(({ theme }) => ({
  color: `${theme.palette.text.main} !important`,
  fontWeight: 600,
  fontSize: 20,
}));

export const ScrollBox = styled(Box)(() => ({
  width: '50%',
  display: 'inline-block',
  overflowY: 'scroll',
  whiteSpace: 'nowrap',

  '&::-webkit-scrollbar': {
    display: 'none',
  },

  '@media(min-width: 620px)': {
    '&:not(:hover)': {
      textOverflow: 'ellipsis',
    },
  },
}));

export const StyledCloseButton = styled(Button)(({ theme }) => ({
  position: 'absolute',
  right: 0,
  color: theme.palette.text.main,
}));

export const StyledControlLabel = styled(FormControlLabel)(({ theme }) => ({
  borderBottom: `1px solid ${theme.palette.border.separator}`,
  padding: '4px 0',
  marginRight: -16,

  '& .MuiFormControlLabel-label': {
    fontWeight: 600,
    fontSize: 16,
  },
  '&:last-child': {
    borderBottom: 'none',
  },
}));

export const StyledList = styled(List)(({ theme }) => ({
  borderBottom: `1px solid ${theme.palette.border.separator}`,
  textAlign: 'center',
  padding: '18px 0',
  fontWeight: 600,
  fontSize: 16,
  letterSpacing: '0.15px',
  '&:last-child': {
    borderBottom: 'none',
  },
}));

export const Container = styled(Stack)(() => ({
  position: 'relative',
  width: '100%',
  overflow: 'auto',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '5px 25px 0px',
  '@media (max-width: 1280px)': {
    padding: '0px 32px',
  },
  '@media (max-width: 905px)': {
    padding: '0px 25px',
  },
  '@media (max-width: 600px)': {
    padding: '0px',
  },
}));

export const Table = styled(MuiTable)(({ minWidth = 650, theme }) => ({
  backgroundColor: theme.palette.background.secondary,

  boxSizing: 'border-box',
  overflow: 'scroll',
  minWidth: minWidth,

  '&::-webkit-scrollbar': {
    position: 'fixed',
    bottom: 78,
  },

  '& thead tr:first-of-type': {
    th: {
      borderTopWidth: 0,
    },
  },

  zIndex: -1,
}));

export const FirstTableHeaderCell = styled(TableCell)(
  ({ theme, minwidth, maxwidth, top = 0, spacing = '0px 16px' }) => ({
    minWidth: minwidth || 150,
    maxWidth: maxwidth || 250,
    height: 57,
    background: theme.palette.background.secondary,
    position: 'sticky',
    left: 0,
    zIndex: 4,
    top,
    padding: spacing,
  }),
);

export const NormalTableHeaderCell = styled(TableCell)(({ top = 0, spacing = '0px 16px' }) => ({
  zIndex: 3,
  margin: 0,
  height: 57,
  top,
  padding: spacing,
}));

export const FirstTableCell = styled(TableCell)(({ minwidth = 300, position }) => ({
  minWidth: minwidth,
  position: position ?? 'sticky',
  left: 0,
  zIndex: 2,
}));

export const NormalTableCell = styled(TableCell)(({ minwidth = 120 }) => ({
  zIndex: -1,
  minWidth: minwidth,
}));

export const TableHeaderCellText = styled('div')(({ theme }) => ({
  fontFamily: 'Inter',
  fontWeight: 600,
  fontSize: 16,
  color: theme.palette.text.main,
  display: 'flex',
  alignItems: 'center',
}));

export const TableCellText = styled(Typography)(({ fontWeight, theme, margin, fontSize }) => ({
  fontFamily: 'Inter',
  fontWeight: fontWeight ?? 600,
  fontSize: fontSize ?? 16,
  margin: margin ?? '0px 5px 5px',
  color: theme.palette.text.main,
}));

export const TableCellSubText = styled(Typography)(({ theme }) => ({
  fontFamily: 'Inter',
  fontWeight: 500,
  fontSize: 14,
  marginRight: 5,
  color: theme.palette.text.secondary,
}));

export const PositionFormControl = styled(FormControl)(({ position }) => ({
  position: position ?? 'relative',
}));

export const Select = styled(MuiSelect)(({ theme, width, height }) => ({
  width: width ?? '100%',
  height: height ?? 48,
  position: 'relative',
  borderRadius: '8px',
  fontWeight: 500,
  background:
    theme.palette.mode === 'dark'
      ? theme.palette.background.tertiary
      : theme.palette.background.light,
  color: theme.palette.text.main,
  lineHeight: '20px',
  letterSpacing: '0.1px',

  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
    border: `1px solid ${theme.palette.border.decorative}`,
  },
  '&.Mui-focused': {
    background: theme.palette.background.secondary,
  },
  '&.MuiSelect-icon': {
    color: theme.palette.text.main,
  },
}));

export const MenuItem = styled(MuiMenuItem)(({ theme, height }) => ({
  height: height ?? 56,
  padding: 16,
  color: theme.palette.text.main,
  background: theme.palette.background.secondary,
  borderBottom: `1px solid ${theme.palette.border.separator}`,
  lineHeight: '24px',
  letterSpacing: '0.15px',
  fontWeight: 600,
  '&.Mui-selected': {
    background: theme.palette.background.secondary,
    '&:hover': {
      backgroundColor: theme.palette.background.dark,
      boxShadow: `0px 0px 5px 0px ${theme.palette.shadow}`,
      transition: 'box-shadow 0.3s ease-in-out',
    },
  },
  '&:hover': {
    backgroundColor: theme.palette.background.dark,
    boxShadow: `0px -5px 4px -4px ${theme.palette.shadow}, 0px 5px 4px -4px ${theme.palette.shadow};`,
    transition: 'box-shadow 0.3s ease-in-out',
    zIndex: 10,
  },
  '&:last-child': {
    borderBottom: 'none',
  },
}));

export const HeaderTableRow = styled(TableRow)(({ theme }) => ({
  height: '57px',
  color: theme.palette.text.main,

  th: {
    backgroundColor: theme.palette.background.secondary,
  },
}));

export const CustomTableRow = styled(TableRow)(({ theme, height = '80px' }) => ({
  height: height,
  color: theme.palette.text.main,
  borderTop: `1px solid ${theme.palette.border.decorative}`,
  cursor: 'default',

  '&:hover td, &:hover th': {
    background: theme.palette.background.hover,
  },
}));

const iconStyles = {
  selectIcon: {
    color: 'grey',
  },
};

export const CustomExpandMore = withStyles(iconStyles)(({ className, classes, ...rest }) => {
  return <ExpandMoreIcon {...rest} className={clsx(className, classes.selectIcon)} />;
});

// Tabs for switching -
export const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
};
TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

export const SupplyText = styled(Typography)(({ fontSize }) => ({
  fontSize: fontSize ?? 12,
  lineHeight: '16px',
  marginLeft: 15,
  marginRight: 5,
  fontWeight: 500,
}));

export const SmallSecondaryText = styled(Typography)(({ theme }) => ({
  fontSize: 10,
  fontWeight: 500,
  lineHeigth: 15,
  color: theme.palette.text.secondary,
}));

export const HelpIconWrapper = styled('div')(({ width, height }) => ({
  width: width ?? 18,
  height: height ?? 18,
}));

export const PriceInput = styled(OutlinedInput)(({ theme }) => ({
  fontSize: '14px',
  borderRadius: 4,
  background: theme.palette.background.default,
  height: 48,
  fontWeight: 500,
  '& .MuiOutlinedInput-root': {
    borderRadius: 8,
    padding: 2,
    height: 48,
  },
  '& .MuiOutlinedInput-input': {
    color: theme.palette.text.main,
  },
  '& input::placeholder': {
    fontWeight: 500,
    color: theme.palette.text.secondary,
    opacity: 1,
  },
  '& fieldset': {
    borderColor: theme.palette.border.decorative,
  },
  '&:hover': {
    background: theme.palette.background.apply.hovered,
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.palette.border.decorative,
    },
  },
  '&:active': {
    background: theme.palette.background.apply.pressed,
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.palette.border.decorative,
    },
  },
  '& .MuiInputAdornment-root.MuiInputAdornment-positionEnd': {
    '& p': {
      fontSize: 12,
    },
  },
}));

export const ConnectMetamaskButton = styled(Button)(
  ({ theme, width, height, margintopspacing, changewidthonmobile = 'true' }) => {
    let onSmallerScreen =
      changewidthonmobile === 'true'
        ? {
            width: '94.5%',
            margin: '16px',
          }
        : {};

    return {
      fontSize: 16,
      fontWeight: 600,
      borderRadius: 8,
      boxShadow: 'none',
      backgroundColor: theme.palette.primary.main,
      width: width ?? 207,
      height: height ?? 48,
      marginTop: theme.spacing(margintopspacing) ?? 0,

      '&:hover': {
        boxShadow: 'none',
      },

      '@media (max-width: 695px)': onSmallerScreen,
    };
  },
);

export const HeadContainerMobile = styled('div')(({ theme, offers, top }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  height: 44,
  zIndex: 1000,
  height: 54,
  boxSizing: 'border-box',
  marginLeft: offers ? 0 : -24,
  marginRight: offers ? 0 : -24,
  paddingLeft: offers ? 0 : 24,
  paddingRight: offers ? 0 : 24,
  marginBottom: top ? 0 : theme.spacing(2.5),
  borderTop: top && `1px solid ${theme.palette.border.separator}`,
  borderBottom: !top && `1px solid ${theme.palette.border.separator}`,
}));

export const StyledTimepicker = styled(TimePicker)(({ theme }) => ({
  width: '100%',
  justifyContent: 'space-between',
  fontWeight: 500,
  fontSize: '14px !important',
  '& .react-time-picker__wrapper': {
    display: 'flex',
    border: 'none',
  },

  '& input': {
    color: theme.palette.text.main,
    fontWeight: 500,
    fontSize: '14px !important',
    '&:focus-visible': {
      border: 'none',
      outline: 'none',
    },
  },

  '& .react-time-picker__inputGroup__amPm': {
    color: theme.palette.text.secondary,
    appearance: 'none',

    '&:focus-visible': {
      border: 'none',
      outline: 'none',
    },

    '& option': {
      color: theme.palette.text.main,
      background: theme.palette.background.secondary,
      borderBottom: `1px solid ${theme.palette.border.separator}`,
      lineHeight: '24px',
      letterSpacing: '0.25px',
      fontWeight: 400,
      '&:last-child': {
        borderBottom: 'none',
      },
      '&:hover': {
        backgroundColor: theme.palette.background.tertiary,
        boxShadow: `0px 0px 5px 0px ${theme.palette.shadow}`,
        transition: 'box-shadow 0.3s ease-in-out',
      },
    },
  },
}));

export const StyledInfoOutlinedIcon = styled(InfoOutlined)(() => ({
  width: 13,
  height: 13,
  marginLeft: 4,
}));

export const CustomLink = styled(Link)(({ theme }) => ({
  color: theme.palette.text.main,
  textDecoration: 'none',
}));

export const NavigationBody = styled(Box)(({ theme, top }) => ({
  width: 450,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  backgroundColor: theme.palette.background.filter,
  marginTop: top,

  '@media (max-width: 600px)': {
    width: '100vw',
  },
}));

export const FilterMenuItem = styled(ListItemButton)(({ theme, heading }) => ({
  padding: theme.spacing(2),
  borderBottom: `0.5px solid ${theme.palette.border.separator}`,
  backgroundColor: heading ? theme.palette.background.secondary : theme.palette.background.select,
  color: theme.palette.text.main,
  lineHeight: '24px',
  letterSpacing: '0.15px',
  fontWeight: 600,
  height: heading ? 72 : 'auto',
  '&:hover': {
    backgroundColor: theme.palette.background.secondary,
    opacity: 0.8,
  },
}));

export const MenuItemArrowIcon = styled('div')(({ theme }) => ({
  flex: 1,
  justifyContent: 'flex-end',
  display: 'flex',
  color: theme.palette.icon.tertiary,
}));

export const PrimaryLink = styled(MuiLink)(({ theme }) => ({
  textDecoration: 'none',
  color: theme.palette.primary.main,

  '&:hover': {
    opacity: 0.8,
  },
}));

export const ContentWrapper = ({ children }) => {
  return (
    <FlexBox justify="center" sx={{ width: '100%' }}>
      <Box width="100%" maxWidth={1920}>
        {children}
      </Box>
    </FlexBox>
  );
};

export const RarityBadge = styled('div')(({ theme, rarity, overlay }) => {
  const BADGE_COLOR_PALETTE = Object.keys(RARITIES_PERCENTILE).reduce((acc, key) => {
    acc[key] = { color: theme.palette.rarity[key], shadow: theme.palette.rarity[key] };
    return acc;
  }, {});

  const overlayed = {
    position: 'absolute',
    right: theme.spacing(1),
    bottom: theme.spacing(1),
    width: 'auto',
    fontSize: 10,
    fontWeight: 500,
    backgroundColor: theme.palette.background.default,
  };

  return {
    border: `1px solid ${BADGE_COLOR_PALETTE[rarity]['color'] ?? theme.palette.text.secondary}`,
    boxShadow: `0px 0px 2px 1px ${BADGE_COLOR_PALETTE[rarity]['color'] ?? 'transparent'}`,
    color: BADGE_COLOR_PALETTE[rarity]['color'] ?? theme.palette.text.secondary,
    margin: 0,
    borderRadius: theme.spacing(0.5),
    width: 48,
    textAlign: 'center',
    padding: theme.spacing(0, 0.4),
    ...(overlay && overlayed),
  };
});

export const RarityBadgeWithTooltip = ({ score, rank, overlay }) => (
  <ToolTip
    title={<Typography fontWeight={500}>{RARITIES_PERCENTILE[rank ?? 'common']}</Typography>}
    placement="top"
  >
    <RarityBadge rarity={rank ?? 'common'} overlay={overlay}>
      {score}
    </RarityBadge>
  </ToolTip>
);
