import { useEffect } from 'react';
import { useStateIfMounted } from 'use-state-if-mounted';
import { readContract, writeContract } from '@wagmi/core';

import contracts from 'constants/contracts';
import useNotification from './useNotification';
import { TOTAL_ROYALTY_PERCENTAGE } from 'constants';
import useWalletContext from './useWalletContext';

const useRoyalties = ({ address }) => {
  const [currentEarnings, setCurrentEarnings] = useStateIfMounted([]);
  const [isLoading, setIsLoading] = useStateIfMounted(true);
  const notification = useNotification();
  const { chainId } = useWalletContext();

  const { RoyaltyRegistryStorage, RoyaltyRegistry } = contracts[chainId];

  const fetchRoyalties = async () => {
    try {
      const data = await readContract({
        address: RoyaltyRegistryStorage.address,
        abi: RoyaltyRegistryStorage.ABI,
        functionName: 'getRoyaltiesByToken',
        args: [address],
      });

      if (data.royalties) {
        const earnings = data.royalties.map((item) => ({
          earnerAddress: item.account,
          percentage: item.value / TOTAL_ROYALTY_PERCENTAGE.MULTIPLIER,
        }));

        setCurrentEarnings(earnings);
      }
    } catch (error) {
      notification({
        message: error.shortMessage,
        type: 'error',
      });
    }

    setIsLoading(false);
  };

  const save = async (earnings) => {
    try {
      const royalties = earnings.map((item) => [
        item.earnerAddress,
        item.percentage * TOTAL_ROYALTY_PERCENTAGE.MULTIPLIER,
      ]);
      const data = await writeContract({
        address: RoyaltyRegistry.address,
        abi: RoyaltyRegistry.ABI,
        functionName: 'setRoyaltiesByToken',
        args: [address, [...royalties]],
      });

      if (data.hash) {
        return data;
      }
    } catch (error) {
      throw error;
    }
  };

  useEffect(() => {
    fetchRoyalties();
  }, []);

  return { currentEarnings, setCurrentEarnings, fetchRoyalties, save, isLoading };
};

export default useRoyalties;
