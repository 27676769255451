import React from 'react';
import { styled, Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import { KeyboardArrowDown as KeyboardArrowDownIcon } from '@mui/icons-material';
import { LinkText } from 'components/StyledComponents';
import CircularLoadingNumber from './CircularLoadingNumber';
import { Text } from 'components/Text';

/********************  Styled Components  ********************/
export const StyledAccordion = styled((props) => (
  <Accordion disableGutters elevation={0} {...props} />
))(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  border: `1px solid ${theme.palette.divider}`,

  '&:first-of-type': {
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
  },

  '&:not(:last-child)': {
    borderBottom: 0,
  },

  '&:last-child': {
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
    borderBottomLeftRadius: 8,
    borderBottomRightRadius: 8,
  },

  '&:before': {
    display: 'none',
  },
}));

export const StyledAccordionSummary = styled((props) => (
  <AccordionSummary
    expandIcon={props.expandicon ? <KeyboardArrowDownIcon sx={{ fontSize: '24px' }} /> : ''}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  borderRadius: 8,
  height: '62px',
  padding: theme.spacing(2),
  flexDirection: 'row',

  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(180deg)',
  },

  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}));

export const StyledAccordionDetails = styled(AccordionDetails)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  padding: theme.spacing(2),
  borderTop: '1px solid rgba(0, 0, 0, .125)',
  backgroundColor: theme.palette.background.filter,
  gap: '10px',
  borderRadius: '0 0 8px 8px',
}));

export const AccordianLinkText = styled(LinkText)(({ theme }) => ({
  opacity: 0.95,
  color: theme.palette.text.error,
  fontSize: 16,
  margin: 0,
  fontWeight: 400,

  '&:hover': {
    color: theme.palette.text.error,
    opacity: 1,
    textDecoration: 'none',
  },
  '&:foucs': {
    color: theme.palette.text.error,
    textDecoration: 'none',
  },
}));

/********************  Main Component  ********************/
const AccordionPanel = ({
  value,
  panel,
  handleReInitialization,
  children,
  expandicon = 'true',
}) => (
  <StyledAccordion expanded={panel.expanded}>
    <StyledAccordionSummary expandicon={expandicon}>
      {value && <CircularLoadingNumber value={value} status={panel.status} />}
      <Text main="true" fs={16} fw={600} lh={24}>
        {panel.title}
      </Text>
    </StyledAccordionSummary>

    <StyledAccordionDetails>
      <div style={{ maxWidth: 550 }}>
        <Text secondary="true" fs={16} fw={400} lh={24} align="left">
          {panel.subTitle}
        </Text>
      </div>

      {children || (
        <>
          {panel.error ? (
            <AccordianLinkText onClick={handleReInitialization}>{panel.message}</AccordianLinkText>
          ) : (
            <Text primary="true" fs={16} fw={400} lh={24} align="left">
              {panel.message}
            </Text>
          )}
        </>
      )}
    </StyledAccordionDetails>
  </StyledAccordion>
);

export default AccordionPanel;
