import React from 'react';
import { Typography, InputAdornment } from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
import { styled, useTheme } from '@mui/material/styles';
import { GeneralFlexBox } from 'components/StyledComponents';
import {
  ModalIconButton,
  CheckoutModalRow,
  ModalHeaderContainer,
  PriceInput,
} from '../components/styledComponents';
import { StandardButton } from 'components/Button';
import { EthBlueIcon } from 'components/ImageComponent';
import { useDispatch } from 'react-redux';
import { useStateIfMounted } from 'use-state-if-mounted';
import { toFixedDecimals } from 'utils';
import useWalletContext from 'hooks/useWalletContext';
import { useMetamaskConnect } from 'hooks/useMetamaskConnect';

const MainContainer = styled(GeneralFlexBox)(() => ({
  width: '100%',
  flexDirection: 'column',
  // Move footer row to bottom screen when in vertical small screen
  '@media(max-width: 600px)': {
    height: '100%',
    justifyContent: 'space-between',
  },
}));

const MiddleRow = styled(CheckoutModalRow)(({ path }) => ({
  flexGrow: 1,
  alignItems: 'flex-start',
  flexDirection: 'column',

  '@media (max-width: 500px)': {
    justifyContent: path !== 'buy' ? 'center' : 'flex-start',
  },
}));

const CheckoutFooterRow = styled(CheckoutModalRow)(({ theme }) => ({
  borderTop: `1px solid ${theme.palette.border.separator}`,
}));

/********************  Main Component  ********************/
const PlaceBid = ({ data, closeModal, placeBid, lastPrice, path }) => {
  const { isConnected } = useWalletContext();
  const theme = useTheme();
  const [bidAmount, setBidAmount] = useStateIfMounted('');
  const [usdAmount, setUsdAmount] = useStateIfMounted(0);
  const [error, setError] = useStateIfMounted('');
  const dispatch = useDispatch();
  const { connectMetamask } = useMetamaskConnect();
  const { metadata } = data;

  const handleAmountChange = (value) => {
    const usd = value !== '' ? toFixedDecimals(parseFloat(value) * parseFloat(lastPrice)) : '0.00';
    setUsdAmount(usd);
    setBidAmount(value);
  };

  const handlePlaceBid = () => {
    if (bidAmount < 0.0001) {
      setError('You must offer a minimum amount of 0.0001 ETH');
    } else {
      setError('');
      placeBid(bidAmount);
    }
  };

  const toConnect = () => {
    dispatch({
      type: 'NOTIFICATION',
      payload: { status: true, message: `Default Error!`, type: 'error', link: null },
    });

    return (
      <>
        <MiddleRow sx={{ padding: theme.spacing(4, 3, 3, 3) }} path={path}>
          <Typography fontSize={32} fontWeight={600} width="100%" marginBottom="20px">
            Connect a Wallet
          </Typography>
          <Typography fontSize={14} fontWeight={500} width="100%">
            Please connect your metamask wallet to continue.
          </Typography>
        </MiddleRow>
        <CheckoutFooterRow>
          <StandardButton
            variant="contained"
            width={199}
            height={48}
            onClick={connectMetamask}
            disableRipple
          >
            Connect Wallet
          </StandardButton>
        </CheckoutFooterRow>
      </>
    );
  };

  const getDetails = () => {
    return (
      <>
        <MiddleRow path={path}>
          <Typography fontSize={14} fontWeight={500} width="100%" marginBottom="20px">
            Place your bid for{' '}
            <Typography
              variant="span"
              fontWeight={500}
              fontSize={14}
              color={theme.palette.link.primary}
            >
              {metadata?.name || `Item name #${data.tokenId}`}
            </Typography>{' '}
            from{' '}
            <Typography
              variant="span"
              fontWeight={500}
              fontSize={14}
              color={theme.palette.link.primary}
            >
              Energi Studios{' '}
            </Typography>
            collection.
          </Typography>
          <PriceInput
            id="outlined-adornment-amount"
            fullWidth
            autoComplete="off"
            type={'number'}
            value={bidAmount}
            onChange={(event) => {
              handleAmountChange(event?.currentTarget?.value ?? '0');
            }}
            placeholder="0"
            fontSize="24px"
            startAdornment={
              <InputAdornment position="start">
                <EthBlueIcon width={20} height={20} />
                <Typography variant="span" marginLeft="8px" color={theme.palette.text.main}>
                  ETH
                </Typography>
              </InputAdornment>
            }
            sx={{
              '& .MuiOutlinedInput-notchedOutline': {
                borderColor: error ? theme.palette.text.error : '',
              },
            }}
            endAdornment={<InputAdornment position="end">{`~$${usdAmount}`}</InputAdornment>}
          />
          {error && (
            <Typography
              color={theme.palette.text.error}
              fontSize={12}
              fontWeight={400}
              marginTop="8px"
            >
              {error}
            </Typography>
          )}
        </MiddleRow>
        <CheckoutFooterRow>
          <StandardButton
            variant="contained"
            width={199}
            height={48}
            onClick={handlePlaceBid}
            disableRipple
          >
            Place Bid
          </StandardButton>
        </CheckoutFooterRow>
      </>
    );
  };

  return (
    <MainContainer direction="column" width="100%">
      <ModalHeaderContainer>
        <Typography fontSize={24} fontWeight={500}>
          Place a bid
        </Typography>
        <ModalIconButton onClick={closeModal}>
          <CloseIcon style={{ fill: theme.palette.icon.tertiary }} />
        </ModalIconButton>
      </ModalHeaderContainer>
      {!isConnected ? toConnect() : getDetails()}
    </MainContainer>
  );
};

export default PlaceBid;
