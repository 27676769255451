import { NftsApi, CollectionsApi, UserApi } from 'clientSDK';

const api = new NftsApi();
const collectionsApi = new CollectionsApi();
const userApi = new UserApi();

export default {
  getNfts: async ({ buyNow = 0, owner, skip = 0, limit = 50 }) =>
    await api.list({ skip, limit, buyNow, owner }),

  getUserOwnedNfts: async (address) => await api.getUserOwnedNfts(address, 0, 50),

  getNftTokenFromId: async ({ contractAddress, tokenId }) =>
    await api.getNftTokenFromId({ contractAddress, tokenId }),

  getNftsFromAddress: async ({
    address,
    buyNow = 0,
    skip = 0,
    limit = 50,
    tokenIds = null,
    traits = {},
    min,
    max,
  }) =>
    await api.getNftsFromAddress({
      skip,
      limit,
      address,
      buyNow,
      tokenIds,
      traits: Object.keys(traits).length > 0 ? traits : undefined,
      min,
      max,
    }),

  getCollectionFromAddress: async ({ contractAddress }) =>
    await collectionsApi.getCollectionFromAddress({ contractAddress }),

  getAssetTradingHistory: async ({
    contractAddress,
    tokenId,
    listing = true,
    mint = true,
    sale = true,
    // offer = true,
    burn = true,
    transfer = true,
    skip = 0,
    limit = 100,
  }) =>
    await api.getTradingHistory({
      contractAddress,
      tokenId,
      listing,
      mint,
      sale,
      // offer,
      burn,
      transfer,
      skip,
      limit,
    }),

  getAssetOffers: async ({ contractAddress, tokenId, skip = 0, limit = 100, direction = 'desc' }) =>
    await api.getAssetOffers({ contractAddress, tokenId, skip, limit, direction }),

  getAssetPriceHistory: async ({
    contractAddress,
    tokenId,
    period = Date.now() / 1000, // 'all time' timestamp
    direction = 'desc',
    includeDaysWithoutTrade = true,
    includeAvgPrice = true,
  }) =>
    await api.getAssetPriceHistory({
      contractAddress,
      tokenId,
      period,
      direction,
      includeDaysWithoutTrade,
      includeAvgPrice,
    }),

  getCollectionTradingHistory: async ({
    contractAddress,
    listing = true,
    mint = true,
    sale = true,
    offer = true,
    skip = 0,
    limit = 100,
  }) =>
    await api.getCollectionTradingHistory({
      contractAddress,
      listing,
      mint,
      sale,
      offer,
      skip,
      limit,
    }),

  getCollectionPriceHistory: async ({
    contractAddress,
    period = Date.now() / 1000, // 'all time' timestamp
    direction = 'desc',
    includeDaysWithoutTrade = true,
    includeAvgPrice = true,
  }) =>
    await api.getCollectionPriceHistory({
      contractAddress,
      period,
      direction,
      includeDaysWithoutTrade,
      includeAvgPrice,
    }),

  updateCollectionImage: async ({ data }) => await userApi.updateCollectionImage({ data }),

  refreshMetadata: async ({ contractAddress, tokenId }) =>
    await api.refreshMetadata({ contractAddress, tokenId }),

  getPortfolioCollections: async (ownerAddress) => await collectionsApi.getPortfolio(ownerAddress),

  getPortfolioStats: async ({ collection, userAddress }) =>
    await userApi.getPortfolioStats({ collection, userAddress }),

  getNftsByOwnerInCollection: async ({ owner, collection, skip = 0, limit = 50 }) =>
    await api.getNftsByOwnerInCollection({ owner, contractAddress: collection, skip, limit }),

  getCollectionBidsFromAddress: async (address) =>
    await collectionsApi.getCollectionBidsFromAddress(address),

  getCreatorCollection: async (collectionUrl) =>
    await collectionsApi.getCreatorCollection(collectionUrl),
};
