// create this to simulate the listing of nfts on claim airdrop page
import { useEffect } from 'react';

export const useListedNfts = () => {
  const listedNFTs = localStorage.getItem('listedNFTs');

  useEffect(() => {
    if (!listedNFTs) {
      localStorage.setItem('listedNFTs', 0);
    }
  }, [listedNFTs]);

  const listNFT = () => {
    localStorage.setItem('listedNFTs', JSON.parse(localStorage.getItem('listedNFTs')) + 1);
  };

  return { listedNFTs, listNFT };
};
