import * as React from 'react';
import { styled } from '@mui/material/styles';
import { List as MuiList } from '@mui/material';
import AirdropRewards from './Rewards/AirdropRewards';
import ReferralFeesRewards from './Rewards/ReferralFeesRewards';
import ListingRewards from './Rewards/ListingRewards';
import FarmingRewards from './Rewards/FarmingRewards';

/********************  Styled Components  ********************/
const List = styled(MuiList)(({ theme }) => ({
  paddingTop: theme.spacing(0),
  height: '64vh',
  overflow: 'scroll',
  '& .MuiListItemButton-root': {
    borderRight: 0,
  },
}));

/************************* Main Component  **************************/
const Rewards = ({ closeSideBar }) => {
  return (
    <List>
      <AirdropRewards closeSideBar={closeSideBar} />
      <ReferralFeesRewards />
      <ListingRewards />
      <FarmingRewards />
    </List>
  );
};

export default Rewards;
