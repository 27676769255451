import React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import { Stack, Box, Typography, Button } from '@mui/material';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

const DollarText = styled(Typography)(({ fontSize, theme }) => ({
  fontSize: fontSize ?? 14,
  color: theme.palette.text.secondary,
  marginTop: '2px',
}));

const ClaimButton = styled(Button)(({ theme }) => ({
  fontWeight: 600,
  letterSpacing: '0.15px',
  lineHeight: '24px',
  fontSize: 16,
  color: theme.palette.button.active,
}));

/************************* Main Component  **************************/
export default function RewardsSection({
  link,
  price,
  dollar,
  button,
  name,
  logoComponent,
  closeSideBar,
}) {
  const theme = useTheme();
  const history = useHistory();
  const Logo = logoComponent;

  const handleClick = () => {
    if (!link) {
      return;
    }
    history.push(link);
    closeSideBar();
  };

  return (
    <Box
      sx={{
        height: 112,
        borderBottom: `1px solid ${theme.palette.border.separator}`,
        '&:last-child': { borderBottom: 'none' },
      }}
    >
      <Typography
        color={theme.palette.text.secondary}
        fontSize={16}
        fontWeight={600}
        marginBottom={2}
        marginTop={1}
      >
        {name}
      </Typography>
      <Stack
        direction={'row'}
        justifyContent={'space-between'}
        width={'100%'}
        alignItems={'center'}
      >
        <Stack direction={'row'} gap={'16px'} sx={{ alignItems: 'center' }}>
          <Logo width={32} height={32} />
          <Box justifyContent={'space-between'}>
            <Typography>{price}</Typography>
            <DollarText>{dollar}</DollarText>
          </Box>
        </Stack>
        <ClaimButton disabled={false} disableRipple onClick={handleClick}>
          {button}
        </ClaimButton>
      </Stack>
    </Box>
  );
}
