import { ORDER_TYPES } from 'constants';

export function getListingPrice(nft) {
  if (!nft?.ActiveOrders || !Array.isArray(nft.ActiveOrders)) {
    return null;
  }

  const listingOrder = nft.ActiveOrders.find((order) => order.Type === ORDER_TYPES.LISTING);
  if (!listingOrder) {
    return null;
  }

  return listingOrder.OrderAmountEth;
}

export function getListingEndDate(nft) {
  if (!nft?.ActiveOrders || !Array.isArray(nft.ActiveOrders)) {
    return null;
  }

  const listingOrder = nft.ActiveOrders.find((order) => order.Type === ORDER_TYPES.LISTING);
  if (!listingOrder) {
    return null;
  }

  return listingOrder.MatchBeforeTimestamp;
}

export function getListingOrderKey(nft) {
  if (!nft?.ActiveOrders || !Array.isArray(nft.ActiveOrders)) {
    return null;
  }

  const listingOrder = nft.ActiveOrders.find((order) => order.Type === ORDER_TYPES.LISTING);
  if (!listingOrder) {
    return null;
  }

  return listingOrder.Key;
}

export function getAllListingOrderKeys(nfts) {
  return nfts.map((nft) => getListingOrderKey(nft)).filter((key) => key);
}
