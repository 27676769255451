import React, { useEffect } from 'react';
import { useStateIfMounted } from 'use-state-if-mounted';
import marketplaceService from 'services/marketplace';
import { styled, useTheme } from '@mui/material/styles';
import { Fade, MenuItem as MuiMenuItem, FormControl, Box, useMediaQuery } from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
import { useDispatch } from 'react-redux';
import {
  MenuNavigation,
  ScrollBox,
  StyledBackdrop,
  StyledCloseButton,
  StyledForm,
  StyledLabel,
  StyledList,
} from 'components/StyledComponents';
import { MENU_CLOSING_TIME } from 'constants/index';
import ReportDialog from '../ArtCard/ReportDialog';

/********************  Styled Components  ********************/
const ItemsBox = styled(FormControl)(() => ({
  display: 'flex',
  flexDirection: 'row',
  gap: '10px',
  justifyContent: 'end',
  width: '100%',
  height: '100%',
}));

const MenuItem = styled(MuiMenuItem)(({ theme }) => ({
  padding: 16,
  width: '100%',
  color: theme.palette.text.main,
  background: theme.palette.background.secondary,
  borderBottom: `1px solid ${theme.palette.border.separator}`,
  lineHeight: '24px',
  letterSpacing: '0.15px',
  fontWeight: 600,
  '&:last-child': {
    borderBottom: 'none',
  },
  '&.Mui-focusVisible': {
    background: theme.palette.background.secondary,
    '&:hover': {
      backgroundColor: theme.palette.background.tertiary,
      boxShadow: `0px 0px 5px 0px ${theme.palette.shadow}`,
      transition: 'box-shadow 0.3s ease-in-out',
    },
  },
  '&:hover': {
    backgroundColor: theme.palette.background.tertiary,
  },
}));

const Heading = styled('div')(() => ({
  display: 'flex',
  width: '100%',
  height: '100%',
  alignItems: 'center',
  justifyContent: 'center',

  alignItems: 'center',
  '&:hover': {
    cursor: 'pointer',
  },
}));

/********************  Main Component  ********************/
const OptionsDropdown = ({
  menus,
  title,
  Icon,
  keyName,
  size = 'LARGE',
  preview = false,
  card,
}) => {
  const dispatch = useDispatch();
  const [disableRefreshMetadata, setDisableRefreshMetadata] = useStateIfMounted(false);
  const [anchorEl, setAnchorEl] = useStateIfMounted(null);
  const open = Boolean(anchorEl);
  const below700 = useMediaQuery('(max-width: 700px)');
  const [openBackdrop, setOpenBackdrop] = useStateIfMounted(false);
  const theme = useTheme();
  const iconStyle = {
    width: 25,
    height: 25,
    color: theme.palette.text.light,
  };

  const [openModal, setOpenModal] = useStateIfMounted(false);

  const handleClick = (event) => {
    if (anchorEl || preview) {
      setAnchorEl(null);
    } else {
      setAnchorEl(event.currentTarget);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const leaveMenu = () => {
    setTimeout(() => {
      setAnchorEl(null);
    }, MENU_CLOSING_TIME);
  };

  let metadataRefreshTimer;
  const handleMenuClick = async (option) => {
    setAnchorEl(null);
    switch (option.value) {
      case 'REFRESH_METADATA':
        setDisableRefreshMetadata(true);
        metadataRefreshTimer = setTimeout(() => setDisableRefreshMetadata(false), 10000); // 10s
        const metadataStatus = await marketplaceService.refreshMetadata({
          contractAddress: card.contractAddress,
          tokenId: card.tokenId,
        });
        dispatch({
          type: 'NOTIFICATION',
          payload: {
            status: true,
            type: metadataStatus.success ? 'success' : 'error',
            message: metadataStatus.message,
          },
        });
        break;
      case 'COPY_LINK':
        const link =
          option.item === 'nft'
            ? `${process.env.REACT_APP_PUBLIC_URL}/asset/${card.contractAddress}/${card.tokenId}`
            : window.location.href;
        navigator.clipboard.writeText(link);
        dispatch({
          type: 'NOTIFICATION',
          payload: { status: true, message: `Link copied!`, type: 'successNoIcon', link: null },
        });
        break;
      case 'WEBSITE':
        console.log('coming soon');
        break;
      case 'REPORT':
        setOpenModal(true);
        break;
      case 'SHARE_TWITTER':
        window.open(option.link, '_blank', 'noopener, noreferrer');
        break;
      default:
        return;
    }
  };

  useEffect(() => {
    return () => clearTimeout(metadataRefreshTimer);
  }, []);

  const itemsMobileView = () => {
    return (
      <StyledBackdrop open={openBackdrop}>
        <StyledForm>
          <ScrollBox>
            <StyledLabel>{title}</StyledLabel>
            <StyledCloseButton onClick={() => setOpenBackdrop(false)}>
              <CloseIcon />
            </StyledCloseButton>
          </ScrollBox>
          <Box>
            {menus.map((option) => (
              <StyledList
                key={`${option.value}_${option.name}`}
                onClick={() => {
                  setOpenBackdrop(false);
                  handleMenuClick(option);
                }}
              >
                {option.name}
              </StyledList>
            ))}
          </Box>
        </StyledForm>
      </StyledBackdrop>
    );
  };

  return (
    <ItemsBox onMouseLeave={leaveMenu} key={keyName}>
      {!below700 ? (
        <>
          <Heading
            id="card-button"
            aria-controls={open ? 'card-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            onClick={handleClick}
          >
            <Icon style={iconStyle} />
          </Heading>
          <MenuNavigation
            id="card-menu"
            shift={size === 'LARGE' ? 2 : 1}
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              'aria-labelledby': 'card-button',
              onMouseLeave: leaveMenu,
            }}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            TransitionComponent={Fade}
            disableScrollLock={true}
          >
            {menus.map((option) => (
              <MenuItem
                key={`${option.value}_${option.name}`}
                value={option.value}
                onClick={() => handleMenuClick(option)}
                disabled={option.value === 'REFRESH_METADATA' ? disableRefreshMetadata : false}
              >
                {option.name}
              </MenuItem>
            ))}
          </MenuNavigation>
        </>
      ) : (
        <>
          <Heading onClick={() => setOpenBackdrop(true)}>
            <Icon style={iconStyle} />
          </Heading>
          {itemsMobileView()}
        </>
      )}
      <ReportDialog open={openModal} onClose={() => setOpenModal(false)} card={card} />
    </ItemsBox>
  );
};

export default OptionsDropdown;
