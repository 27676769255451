import React from 'react';
import { useStateIfMounted } from 'use-state-if-mounted';

import { Box, IconButton, useMediaQuery } from '@mui/material';
import { styled } from '@mui/material/styles';
import {
  ContentCopy as ContentCopyIcon,
  AccountBalanceWallet as AccountBalanceWalletIcon,
} from '@mui/icons-material';
import { copyToClipboard, shorten } from 'utils/helper';
import { ToolTip } from 'components/StyledComponents';

/********************  Styled Components  ********************/

const Container = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
}));

const Address = styled(Box)(({ theme }) => ({
  height: 48,
  border: `1px solid ${theme.palette.border.separator}`,
  padding: theme.spacing(1, 2),
  borderRadius: 8,
  fontSize: 14,
  fontWeight: 500,
  color: theme.palette.text.main,
  margin: theme.spacing(2, 0),
  display: 'flex',
  alignItems: 'center',
  flex: 1,
  '& svg': {
    width: 24,
    height: 24,
    color: theme.palette.text.secondary,
  },
  '& span': {
    fontSize: 14,
    fontWeight: 500,
    color: theme.palette.text.main,
    marginLeft: theme.spacing(2),
  },
  '@media (max-width: 480px)': {
    fontSize: 11,
  },
}));

const Label = styled('div')(() => ({
  fontSize: 16,
  fontWeight: 600,
}));

const CopyIconButton = styled(IconButton)(({ theme }) => ({
  backgroundColor: theme.palette.background.light,
  height: 48,
  width: 48,
  padding: 0,
  margin: 0,
  marginLeft: theme.spacing(1),
  border: `1px solid ${theme.palette.border.decorative}`,
  borderRadius: 8,
  '& svg': {
    width: 24,
    height: 24,
  },
}));

/********************  Main Component  ********************/
const AddressBox = ({ address, label = '' }) => {
  const [copied, setCopied] = useStateIfMounted(null);
  const [copyTimeout, setCopyTimeout] = useStateIfMounted(null);

  const copy = async (address, type) => {
    await copyToClipboard(address);
    setCopied(type);
    if (copyTimeout) {
      clearTimeout(copyTimeout);
      setCopyTimeout(null);
    }
    const timeout = setTimeout(() => {
      setCopied(null);
      setCopyTimeout(null);
    }, 1000);
    setCopyTimeout(timeout);
  };

  const mobileView = useMediaQuery('(max-width: 768px)');

  return (
    <>
      {label && <Label>{label}</Label>}
      <Container>
        <Address>
          <AccountBalanceWalletIcon />
          <span>{mobileView ? shorten(address, 8) : address}</span>
        </Address>
        <ToolTip
          onClick={() => copy(address, 'address')}
          title={copied === 'address' ? 'Copied!' : 'Copy address'}
          placement="top"
          arrow
        >
          <CopyIconButton>
            <ContentCopyIcon />
          </CopyIconButton>
        </ToolTip>
      </Container>
    </>
  );
};

export default AddressBox;
