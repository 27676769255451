import React from 'react';
import { RadioGroup, Radio } from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
import { useTheme } from '@mui/styles';
import {
  StyledBackdrop,
  StyledForm,
  StyledLabel,
  StyledControlLabel,
  GeneralFlexBox,
} from 'components/StyledComponents';
import { ModalIconButton } from '../../components/styledComponents';

/********************  Main Component  ********************/
const DayMobileView = ({ expiredDate, setExpiredDate, open, setOpen }) => {
  const theme = useTheme();

  const isDefaultValue = [1, 3, 7, 30].includes(expiredDate);

  const handleExpiredDateChange = (event) => {
    setExpiredDate(Number(event.target.value) * 24);
    setOpen(false);
  };

  return (
    <StyledBackdrop open={open}>
      <StyledForm>
        <GeneralFlexBox width="100%" justify="space-between" sx={{ mb: '14px' }}>
          <StyledLabel id="demo-radio-buttons-group-label">Offer expiration</StyledLabel>
          <ModalIconButton onClick={() => setOpen(false)}>
            <CloseIcon style={{ fill: theme.palette.icon.tertiary }} />
          </ModalIconButton>
        </GeneralFlexBox>

        <RadioGroup value={expiredDate || ''} onChange={handleExpiredDateChange} sx={{ mb: 1 }}>
          <StyledControlLabel value="1" control={<Radio />} label="1 day" />
          <StyledControlLabel value="3" control={<Radio />} label="3 days" />
          <StyledControlLabel value="7" control={<Radio />} label="7 days" />
          <StyledControlLabel value="30" control={<Radio />} label="1 month" />
          <StyledControlLabel
            value={isDefaultValue ? '0' : expiredDate}
            control={<Radio />}
            label={isDefaultValue ? 'Custom date' : `${expiredDate} days`}
          />
        </RadioGroup>
      </StyledForm>
    </StyledBackdrop>
  );
};

export default DayMobileView;
