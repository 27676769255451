import React from 'react';
import { styled, useMediaQuery } from '@mui/material';
import AirdropReward from './AirdropReward';
import AirdropCriteria from './AirdropCriteria';
import BigButton from 'components/General/Section/BigButton';
import { useHistory } from 'react-router-dom';
import { ImportIcon, PlusIcon } from 'components/ImageComponent';

/********************  Styled Components  ********************/
const Container = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  width: '100%',
  gap: 24,
  maxWidth: 1408,
  paddingLeft: 32,
  paddingRight: 32,

  '@media(max-width: 1280px)': {
    gap: 26,
  },
  '@media(max-width: 905px)': {
    gap: 16,
    paddingLeft: 24,
    paddingRight: 24,
  },
  '@media(max-width: 500px)': {
    flexDirection: 'column',
    paddingLeft: 16,
    paddingRight: 16,
  },
}));

const LeftPanel = styled('div')(({ theme }) => ({
  border: `1px solid ${theme.palette.border.decorative}`,
  backgroundColor: theme.palette.background.filter,
  borderRadius: 8,
  width: 432,
  maxHeight: 398,

  '@media(max-width: 1280px)': {
    width: 388,
  },
  '@media(max-width: 905px)': {
    maxWidth: 420,
    width: '50%',
  },
  '@media(max-width: 600px)': {
    maxWidth: 268,
  },
  '@media(max-width: 500px)': {
    width: '100%',
    maxWidth: 480,
    maxHeight: 388,
  },
}));

const RightPanels = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: 24,
  flex: 1,
  maxHeight: 398,

  '@media(max-width: 905px)': {
    width: '50%',
  },
  '@media(max-width: 500px)': {
    width: '100%',
  },
}));

const ButtonStack = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'row',
  gap: 24,
  width: '100%',
  borderRadius: '8px',
  justifyContent: 'space-between',

  '@media(max-width: 905px)': {
    gap: 15,
    marginTop: 16,
  },
  '@media(max-width: 600px)': {
    flexDirection: 'column',
    gap: 16,
  },
}));

/********************  Sub Component  ********************/
const RenderBigButtons = () => {
  const history = useHistory();

  const handleCreate = () => {
    history.push({
      pathname: '/nfts-to-list',
      state: 'AirdropListing',
    });
  };

  return (
    <ButtonStack>
      <BigButton onClick={handleCreate} icon={<PlusIcon />}>
        Create a new listing
      </BigButton>
      <BigButton icon={<ImportIcon />}>Import your listings from OpenSea</BigButton>
    </ButtonStack>
  );
};

/********************  Main Component  ********************/
const AirdropContent = () => {
  const below905 = useMediaQuery('(max-width: 905px)');

  return (
    <>
      <Container>
        <LeftPanel>
          <AirdropReward />
        </LeftPanel>
        <RightPanels>
          <AirdropCriteria />
          {!below905 && <RenderBigButtons />}
        </RightPanels>
      </Container>
      <Container>{below905 && <RenderBigButtons />}</Container>
    </>
  );
};

export default AirdropContent;
