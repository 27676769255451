import { Drawer, ListItemText } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { ChevronLeft as ChevronLeftIcon } from '@mui/icons-material';
import { FilterMenuItem, MenuItemArrowIcon, NavigationBody } from 'components/StyledComponents';
import { AccountSupport, Notifications, Offers, Profile } from 'pages/Settings/components';
import { SettingsProvider } from 'context/SettingsContext';
import MyCollections from 'pages/Settings/components/MyCollections';

const SettingsDrawer = ({ open, page, overlay, onBack, params = {} }) => {
  const theme = useTheme();

  const renderPage = () => {
    switch (page) {
      case 'Profile':
        return <Profile />;
        break;
      case 'Notifications':
        return <Notifications />;
        break;
      case 'Offers':
        return <Offers />;
        break;
      case 'Account Support':
        return <AccountSupport />;
        break;
      case 'My Collections':
        return <MyCollections {...params} />;
        break;
      default:
        return null;
    }
  };

  return (
    <SettingsProvider>
      <Drawer
        anchor="right"
        open={open}
        variant={overlay ? 'temporary' : 'persistent'}
        sx={{
          zIndex: 2000,
          '.MuiPaper-root': {
            backgroundColor: theme.palette.background.secondary,
            backgroundImage: 'none',
          },
        }}
      >
        <NavigationBody>
          <FilterMenuItem heading={1} onClick={onBack}>
            <MenuItemArrowIcon style={{ flex: 'initial' }}>
              <ChevronLeftIcon style={{ color: theme.palette.text.main }} />
            </MenuItemArrowIcon>
            <ListItemText
              sx={{
                marginLeft: 2.5,
              }}
              primaryTypographyProps={{ fontWeight: 500, fontSize: 24 }}
              primary={page}
            />
          </FilterMenuItem>
          {renderPage()}
        </NavigationBody>
      </Drawer>
    </SettingsProvider>
  );
};

export default SettingsDrawer;
