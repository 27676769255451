import React from 'react';

import {
  PanelExpand,
  PanelExpandHead,
  PanelExpandContent,
  PanelTitle,
} from 'components/StyledPanelExpand';
import ProgressBar from 'components/General/ProgressBar';
import { DescriptionContent, DescriptionText, LinkText } from './common';

/********************  Main Component  ********************/
const ConfirmOffer = ({ failed, processing, onTryAgain }) => {
  return (
    <>
      <PanelExpand radius="8px 8px 0px 0px">
        <PanelExpandHead show={1} style={{ borderBottom: 0, borderRadius: '8px 8px 0px 0px' }}>
          <ProgressBar value={1} loading="false" completed="true" />
          <PanelTitle>Enable WNRG spending</PanelTitle>
        </PanelExpandHead>
      </PanelExpand>
      <PanelExpand radius="0px 0px 8px 8px">
        <PanelExpandHead show={1} style={{ borderRadius: '0px' }}>
          <ProgressBar value={2} loading={processing?.toString()} />
          <PanelTitle>Confirm offer</PanelTitle>
        </PanelExpandHead>
        <PanelExpandContent
          in={true}
          timeout="auto"
          unmountOnExit
          style={{ borderRadius: '0px 0px 8px 8px' }}
        >
          <DescriptionContent>
            <DescriptionText>Confirm the transaction in your wallet.</DescriptionText>
            {failed ? (
              <LinkText onClick={onTryAgain} failed>
                Initialize wallet again
              </LinkText>
            ) : (
              <LinkText>Waiting for confirmation</LinkText>
            )}
          </DescriptionContent>
        </PanelExpandContent>
      </PanelExpand>
    </>
  );
};

export default ConfirmOffer;
