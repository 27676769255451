import React, { useEffect } from 'react';
import { useStateIfMounted } from 'use-state-if-mounted';
import Countdown from 'react-countdown';
import { styled } from '@mui/material/';
import { RESEND_EMAIL_VERIFICATION_DELAY, LOCAL_STORAGE_TIMER_KEY } from 'constants/index';

/********************  Styled Components  ********************/
const Text = styled('span')(({ theme }) => ({
  fontSize: 16,
  fontWeight: 600,
  lineHeight: '24px',
  color: theme.palette.danger.variant,
  width: '100%',
  textAlign: 'center',
}));

/********************  Sub Component  ********************/
const renderer = ({ minutes, seconds, completed }) => {
  if (completed) {
    return null;
  }
  return (
    <Text>
      {minutes}:{String(seconds).padStart(2, '0')}
    </Text>
  );
};

/********************  Main Component  ********************/
const EmailCountdown = ({ onComplete }) => {
  const [data, setData] = useStateIfMounted({
    date: Date.now(),
    delay: RESEND_EMAIL_VERIFICATION_DELAY,
  });

  useEffect(() => {
    const savedDate = JSON.parse(localStorage.getItem(LOCAL_STORAGE_TIMER_KEY))?.endTime;
    if (savedDate) {
      const currentTime = Date.now();
      const delta = savedDate - currentTime;
      setData({ date: currentTime, delay: delta });
    }
  }, []);

  return <Countdown date={data.date + data.delay} onComplete={onComplete} renderer={renderer} />;
};

export default EmailCountdown;
