import React from 'react';
import { styled, useTheme, Button } from '@mui/material';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { GeneralFlexBox } from 'components/StyledComponents';
import { Text } from 'components/Text';

/********************  Styled Components  ********************/
const WrappedButton = styled(Button)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '24px',
  backgroundColor: theme.palette.background.filter,
  border: `1px solid ${theme.palette.border.decorative}`,
  borderRadius: 8,
  height: 80,
  width: '50%',

  '&:hover': {
    background: theme.palette.background.offer.hovered,
    boxShadow: 'none',
  },
  '&:active': {
    background: theme.palette.background.offer.pressed,
    boxShadow: 'none',
  },
  '&:disabled': {
    opacity: 0.54,
  },

  '@media (max-width: 600px)': {
    width: '100%',
  },
}));

const BigButton = ({ children, disabled, onClick, icon }) => {
  const theme = useTheme();

  return (
    <WrappedButton disabled={disabled} disableRipple onClick={onClick}>
      <GeneralFlexBox gap={13} justify="center">
        <GeneralFlexBox>{icon && icon}</GeneralFlexBox>
        <Text main="true" fs={16} fw={600} lh={24} align="left" xs={{ fs: 12, fw: 500, lh: 16 }}>
          {children}
        </Text>
      </GeneralFlexBox>
      <ArrowForwardIosIcon htmlColor={theme.palette.text.main} />
    </WrappedButton>
  );
};

export default BigButton;
