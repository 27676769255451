import { StatsApi } from 'clientSDK';
import dotenv from 'dotenv';
dotenv.config();

const statsApi = new StatsApi();

export default {
  getCollections: async () => {
    try {
      const responseData = await statsApi.getCollectionPriceStats();
      return responseData;
    } catch (err) {
      console.error(err);
    }
  },

  getActivities: async ({
    listing = true,
    mint = true,
    sale = true,
    transfer = true,
    burn = true,
    skip = 0,
    limit = 50,
  }) => {
    try {
      const responseData = await statsApi.getActivityStats({
        listing,
        mint,
        sale,
        // offer,
        transfer,
        burn,
        skip,
        limit,
      });
      return responseData;
    } catch (err) {
      console.error(err);
    }
  },

  getTopCollections: async ({
    market = 'all',
    period = 0,
    direction = 'desc',
    skip = 0,
    limit = 50,
  }) => {
    try {
      const responseData = await statsApi.getTopCollectionsStats({
        market,
        period,
        direction,
        skip,
        limit,
      });
      return responseData;
    } catch (err) {
      console.error(err);
    }
  },

  getUserActivities: async ({
    address,
    listing = true,
    sale = true,
    // offer = true,
    transfer = true,
    mint = true,
    burn = true,
    skip = 0,
    limit = 50,
  }) => {
    try {
      const responseData = await statsApi.getUserActivityStats({
        address,
        listing,
        mint,
        sale,
        // offer,
        transfer,
        burn,
        skip,
        limit,
      });
      return responseData;
    } catch (err) {
      console.error(err);
    }
  },
};
