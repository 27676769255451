import React, { createContext, useCallback } from 'react';
import {
  useAccount,
  useConnect,
  usePublicClient,
  useWalletClient,
  useDisconnect,
  useNetwork,
  useSwitchNetwork,
} from 'wagmi';
import { CHAIN_ID, CONFIG } from 'constants/index';
import { useStateIfMounted } from 'use-state-if-mounted';

export const WalletContext = createContext(null);

export const WalletProvider = ({ children }) => {
  const { address: rawAddress, isConnected } = useAccount();
  const address = rawAddress?.toLowerCase();
  const [walletModalOpen, setWalletModalOpen] = useStateIfMounted(false);
  const { connect, connectors, connectAsync } = useConnect();
  const { disconnect } = useDisconnect();
  const { chain } = useNetwork();
  const chainId = chain?.id ?? CHAIN_ID; // fallback = energi
  const { data: signer } = useWalletClient({ chainId: chainId });
  const provider = usePublicClient();
  const { switchNetworkAsync } = useSwitchNetwork();

  const isInstalled = typeof window.ethereum !== 'undefined';
  const wrongNetwork = isConnected && chainId !== CHAIN_ID;
  const isOnNRG = chainId === CONFIG.ENERGI_CHAIN_ID;
  const isOnETH = chainId === CONFIG.ETHEREUM_CHAIN_ID;

  const addNewToken = useCallback(async (options) => {
    await window.ethereum.request({
      method: 'wallet_watchAsset',
      params: {
        type: 'ERC20', // Initially only supports ERC20, but eventually more!
        options,
      },
    });
  }, []);

  return (
    <WalletContext.Provider
      value={{
        isInstalled,
        isConnected,
        connect: () => connectors?.length > 0 && connect({ connector: connectors[0] }),
        connectAsync: () => connectors?.length > 0 && connectAsync({ connector: connectors[0] }),
        provider,
        signer,
        chainId,
        addNewToken,
        address,
        disconnect,
        switchToNetwork: switchNetworkAsync,
        walletModalOpen,
        setWalletModalOpen,
        wrongNetwork,
        isOnNRG,
        isOnETH,
      }}
    >
      {children}
    </WalletContext.Provider>
  );
};
