import { useCallback, useEffect } from 'react';
import { useStateIfMounted } from 'use-state-if-mounted';
import marketplaceService from 'services/marketplace';
import notificationService from 'services/notification';
import { getAssetImageUrl } from 'utils/helper';
import useWalletContext from './useWalletContext';

const useOfferSettings = () => {
  const [ownedCollections, setOwnedCollections] = useStateIfMounted([]);
  const [offersLoading, setIsLoading] = useStateIfMounted(true);

  const { address } = useWalletContext();

  useEffect(() => {
    getUserOwnedCollections(); // TODO: now we have a proper endpoint, this should be refactored to call collection for a specific user
  }, [address]);

  const getUserOwnedCollections = async () => {
    const response = await marketplaceService.getNfts({ owner: address });
    const collections = extractCollectionsFromResponse(response.data);
    getCollectionDetails(collections);
  };

  const getCollectionDetails = useCallback(async (collections) => {
    try {
      const promise = collections?.map(async ({ contractAddress }) => {
        const collectionDetails = await marketplaceService.getCollectionFromAddress({
          contractAddress,
        });
        const nfts = await marketplaceService.getNftsFromAddress({
          limit: 1,
          address: contractAddress,
        });

        const userSettings = await notificationService.getUserSettings();
        return {
          address: collectionDetails?.address,
          name: collectionDetails?.name,
          numberOfItems: collectionDetails?.numberOfItems,
          offerPrice: userSettings?.collections_min_offer?.[contractAddress] || '',
          collectionImage: getAssetImageUrl(nfts[0]),
          floorPrice: nfts[0]?.collectionFloorPrice || 0,
        };
      });
      const collectionDetails = await Promise.all(promise);
      setOwnedCollections(collectionDetails);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  }, []);

  const extractCollectionsFromResponse = useCallback((cards) => {
    let collectionArray = [];
    if (cards) {
      cards.forEach((card) => {
        collectionArray.push({
          name: card.Erc721Token?.name || card.Erc1155Token?.name,
          contractAddress: card.Erc721Token?.tokenAddress || card.Erc1155Token?.tokenAddress,
        });
      });
    }
    return [...new Set(collectionArray.map(JSON.stringify))].map(JSON.parse);
  }, []);

  return { offersLoading, ownedCollections };
};

export default useOfferSettings;
