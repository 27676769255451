import React, { useEffect } from 'react';
import { useStateIfMounted } from 'use-state-if-mounted';

import { styled } from '@mui/material/styles';

/************************* Styled Components  **************************/
const CheckboxInput = styled('input')(({ theme }) => ({
  position: 'relative',
  width: 24,
  height: 24,
  border: `2px solid ${theme.palette.border.checkbox}`,
  borderRadius: 8,
  appearance: 'none',
  outline: 0,
  cursor: 'pointer',
  transition: 'background 175ms cubic-bezier(0.1, 0.1, 0.25, 1)',
  '&:before': {
    position: 'absolute',
    content: '""',
    display: 'block',
    top: 3,
    left: 2,
    width: 16,
    height: 9,
    borderStyle: 'solid',
    borderColor: theme.palette.border.checkbox,
    borderWidth: '0 2px 2px 0',
    transform: 'rotate(-45deg) scaleX(-1)',
    opacity: 0,
  },
  '&:checked:before': {
    opacity: 1,
  },
}));

/************************* Main Component  **************************/
const Checkbox = ({ name, onChange, checked = false }) => {
  const [isChecked, setIsChecked] = useStateIfMounted(checked);

  useEffect(() => {
    setIsChecked(checked);
  }, [checked]);

  const handleChange = (e) => {
    let newState = e.target.checked;
    setIsChecked(newState);
    if (!onChange) {
      return;
    }
    return onChange(newState);
  };

  return (
    <CheckboxInput
      type="checkbox"
      name={name}
      checked={isChecked}
      onChange={handleChange}
      value={isChecked}
      id={`field-${name}`}
    />
  );
};

export default Checkbox;
