import React from 'react';
import { styled, Typography, Box } from '@mui/material';
import { GeneralFlexBox } from 'components/StyledComponents';
import { GreenStarIcon } from 'components/ImageComponent';

const Title = styled(Typography)(({ fontSize, fontWeight, lineHeight, textAlign, theme }) => ({
  fontSize: fontSize ?? 32,
  fontWeight: fontWeight ?? 600,
  lineHeight: lineHeight ?? '40px',
  textAlign: textAlign ?? 'center',
  color: theme.palette.text.main,

  '@media (max-width: 600px)': {
    textAlign: textAlign ?? 'left',
    width: textAlign === 'center' ? '90%' : 'auto',
  },

  '@media (max-width: 360px)': {
    fontSize: 28,
    fontWeight: 500,
    lineHeight: '36px',
    textAlign: textAlign ?? 'left',
  },
}));

const Text = styled(Typography)(({ fontSize, fontWeight, lineHeight, textAlign, theme }) => ({
  fontSize: fontSize ?? 16,
  fontWeight: fontWeight ?? 400,
  lineHeight: lineHeight ?? '24px',
  textAlign: textAlign ?? 'left',
  color: theme.palette.text.tertiary,

  '@media (max-width: 600px)': {
    fontSize: 14,
    lineHeight: '20px',
  },

  '@media (max-width: 360px)': {
    width: textAlign === 'center' ? '87%' : 'auto',
  },
}));

/********************  Main Components  ********************/
const InviteDescription = ({ position, children }) => {
  return (
    <>
      <GeneralFlexBox gap={16} direction="column">
        {children || <Title textAlign={position}>Invite frens that are Gonna Make It!</Title>}
        <GeneralFlexBox gap={10} align="start" justify={position}>
          <Box>
            <GreenStarIcon />
          </Box>
          <Text textAlign={position}>
            Give your frens 10% REBATE on Service fees and EARN 10% of service fees on direct
            referrals
          </Text>
        </GeneralFlexBox>
        <GeneralFlexBox gap={10} align="start" justify={position}>
          <Box>
            <GreenStarIcon />
          </Box>
          <Text textAlign={position}>
            When your frens claims GMI Airdrop, you both get 10% bonus on the Airdrop amount in GMI
          </Text>
        </GeneralFlexBox>
      </GeneralFlexBox>
    </>
  );
};

export default InviteDescription;
