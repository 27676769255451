import { CLOSE_TOAST_MESSAGE, OPEN_TOAST_MESSAGE } from 'utils/types';

const initialState = {
  toast: {
    content: '',
    severity: 'info',
    open: false,
  },
};

export default function ui(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case OPEN_TOAST_MESSAGE:
      return {
        ...state,
        toast: {
          content: payload.content,
          severity: payload.severity,
          open: true,
        },
      };
    case CLOSE_TOAST_MESSAGE:
      return {
        ...state,
        toast: {
          content: '',
          severity: 'info',
          open: false,
        },
      };
    default:
      return state;
  }
}
