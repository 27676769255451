import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { styled } from '@mui/material';
import { Footer } from 'components/General';
import Airdrop_bg from 'assets/images/airdrop/airdrop_bg.png';
import AirdropContent from './components/AirdropContent';
import InviteDescription from 'components/Invite/InviteDescription';
import AirdropSteps from './components/AirdropSteps';
import Divider from 'components/General/Section/Divider';
import { Text } from 'components/Text';
import BlurGreenBackground from './components/BlurGreenBackground';
import NotQualifiedPage from './NotQualifiedPage';
import { StandardButton } from 'components/Button';
import { useAirdropBonus } from 'hooks/useAirdropBonus';

/********************  Styled Components  ********************/
const Content = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  paddingBottom: '48px',
  alignItems: 'center',
  translate: '0 -72px',
  width: '100%',

  '@media(max-width: 1280px)': {
    paddingBottom: '32px',
    translate: '0 -87px',
  },

  '@media(max-width: 905px)': {
    translate: '0 -48px',
  },

  '@media(max-width: 600px)': {
    paddingBottom: '24px',
    translate: '0 -24px',
  },

  '@media(max-width: 480px)': {
    translate: '0 -10px',
  },
}));

const InfoContainer = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'space-between',
  width: '100%',
  maxWidth: '1299px',
}));

const AirdropHeaderImg = styled('img')(() => ({
  minWidth: 600,
  width: '100%',
  objectFit: 'fit',

  '@media(max-width: 480px)': {
    minWidth: '100%',
  },
}));

const HeaderTextWrapper = styled('div')(() => ({
  display: 'flex',
  textAlign: 'center',
  padding: 48,

  '@media(max-width: 1280px)': {
    padding: '46px 0 40px 0',
  },
  '@media(max-width: 905px)': {
    padding: 44,
    width: '90%',
  },
  '@media(max-width: 600px)': {
    padding: '56px 0 55px 0',
    width: '100%',
  },
  '@media(max-width: 480px)': {
    padding: '28px 0 52px 0',
    width: '80%',
  },
}));

const InviteContainer = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
  width: '100%',
  padding: '0 162px 0 162px',

  '@media(max-width: 1280px)': {
    padding: '0 32px 0 32px',
  },
  '@media(max-width: 905px)': {
    padding: '0 24px 0 24px',
  },
  '@media(max-width: 500px)': {
    padding: '0 16px 0 16px',
  },
}));

const InviteFrensWrapper = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
  width: '100%',

  '@media(max-width: 600px)': {
    padding: '0 40px',
  },
  '@media(max-width: 480px)': {
    padding: 0,
  },
}));

/********************  Main Component  ********************/
const AirdropDashboard = () => {
  const history = useHistory();
  const { qualifiedForAirdrop } = useAirdropBonus();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  if (!qualifiedForAirdrop) {
    return <NotQualifiedPage />;
  }

  return (
    <>
      <Content>
        <BlurGreenBackground />
        <InfoContainer>
          <AirdropHeaderImg src={Airdrop_bg} alt="airdrop_bg" />
          <HeaderTextWrapper>
            <Text main="true" fs={42} fw={500} lh={50} xs={{ fs: 28, lh: 36 }}>
              Congratulations, you’re qualified for GMI Airdrop!
            </Text>
          </HeaderTextWrapper>
        </InfoContainer>

        <AirdropContent />

        <Divider />

        <InviteContainer>
          <InviteFrensWrapper>
            <InviteDescription position="center" />
            <StandardButton
              width={121}
              height={40}
              sx={{ mt: 2 }}
              onClick={() => history.push('rewards/referral_program')}
            >
              Invite frens
            </StandardButton>
          </InviteFrensWrapper>

          <Divider />

          <AirdropSteps />
        </InviteContainer>
      </Content>
      <Footer />
    </>
  );
};

export default AirdropDashboard;
