import { default as imageUnavailableCardImage } from 'assets/images/image-unavailable.svg';
import moment from 'moment';
import { formatNumber } from './numbers';

export const shorten = (str, amountFromSides) =>
  str && str.length > 20
    ? `${str.substring(0, amountFromSides)}...${str.substring(str.length - amountFromSides)}`
    : '';

export const copyToClipboard = (text) => {
  return navigator.clipboard.writeText(text);
};

export const getAssetImageUrl = (asset) => {
  if (!asset) {
    return null;
  }

  const { imageHttpUrl, imageIpfsCid } = asset;
  if (imageHttpUrl) {
    return imageHttpUrl;
  } else if (!imageIpfsCid) {
    return null;
  } else {
    const httpURL = 'https://ipfs.energi.software/ipfs/' + imageIpfsCid;
    return httpURL;
  }
};

export const defaultImageLoadErrorHandler = ({ currentTarget }) => {
  currentTarget.onerror = null; // prevents looping
  currentTarget.src = imageUnavailableCardImage;
};

/**
 * If address is long, we only display first and last part of the address
 * Eg: 0xda95...e832
 *
 * @param {string} address
 * @returns {string}
 */
export const ellipsisAddress = (address) => {
  if (address.length > 10) {
    return address.substring(0, 6) + '...' + address.substring(address.length - 4, address.length);
  }

  return address;
};

/**
 * Check all object properties, if prop value is null then convert to empty string
 *
 * @param {Object} object
 */
export const correctNullToEmptyString = (object) => {
  Object.keys(object).forEach((prop) => {
    if (object[prop] == null) {
      object[prop] = '';
    }
  });
  return object;
};

export const calculateTimeFromExp = (expiration) => {
  const now = moment();
  return moment.duration(now.diff(moment(expiration * 1000))).humanize();
};

export const calculateTimeFromCreated = (created) => {
  const now = moment();
  return moment.duration(moment(created * 1000).diff(now)).humanize();
};

export const formatStringToTimestamp = (stringDate) => {
  return moment(stringDate).valueOf() / 1000;
};

export const formatHumanReadableTime = (timestamp) => {
  return moment(timestamp).format('MMMM D, YYYY, h:mm a');
};

export const isExternalLink = (path) => {
  if (!path) {
    return false;
  }
  return path.startsWith('https://') || path.startsWith('http://');
};

export const addTimestampToImageUrl = (url) => {
  const timestampRegex = /\?timestamp=\d+/;
  if (timestampRegex.test(url)) {
    return url.replace(timestampRegex, `?timestamp=${Date.now()}`);
  }
  return `${url}?timestamp=${Date.now()}`;
};

export const calculateFloorPriceDifference = (price, floorPrice) => {
  const difference = price - floorPrice;
  const percentage = Math.abs((difference / price) * 100);
  const direction = difference > 0 ? 'above' : 'below';
  return `${formatNumber(percentage)}% ${direction}`;
};

export const openInNewTab = (url) => {
  const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
  if (newWindow) {
    newWindow.opener = null;
  }
};
