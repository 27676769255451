import React, { useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { useStateIfMounted } from 'use-state-if-mounted';
import {
  Drawer,
  Box,
  useMediaQuery,
  Tabs as MuiTabs,
  Tab as MuiTab,
  // hide for MVP
  // Badge,
  Grid,
  styled,
  useTheme,
} from '@mui/material';
import {
  Logout as LogoutIcon,
  SettingsOutlined as SettingsIcon,
  Close as CloseIcon,
} from '@mui/icons-material';

import { useUserContext } from 'context';
import useWalletContext from 'hooks/useWalletContext';
import useTokenContext from 'hooks/useTokenContext';
import { useMetamaskConnect } from 'hooks/useMetamaskConnect';
import { shorten } from 'utils/helper';

import MyWallet from './MyWallet';
import Rewards from './Rewards';
import { TabPanel } from 'components/StyledComponents';
import Blockie from 'components/Blockie';

/********************  Styled Components  ********************/
const Heading = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: theme.spacing(2.5, 3),
  '& svg': {
    fontSize: 32,
    color: theme.palette.text.secondary,
  },
}));

const Profile = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  '& svg:last-child': {
    fontSize: 25,
  },

  '&:hover': {
    cursor: 'pointer',
  },
}));

const StyledText = styled('p')(({ theme }) => ({
  color: theme.palette.icon.tertiary,
  fontSize: 14,
  lineHeight: '20px',
  letterSpacing: '0.25px',
  fontWeight: 400,
  margin: 0,
}));

const ListBox = styled(Box)(({ disabled }) => ({
  display: 'flex',
  paddingBottom: 14,
  paddingTop: 14,
  fontSize: 16,
  lineHeight: '24px',
  letterSpacing: '0.15px',
  fontWeight: 600,

  '&:hover': {
    cursor: disabled ? 'default' : 'pointer',
  },
}));

const GridBox = styled(Grid)(() => ({
  display: 'flex',
  paddingBottom: 14,
  fontSize: 16,
  lineHeight: '24px',
  letterSpacing: '0.15px',
  fontWeight: 600,
  height: 56,

  '&:hover': {
    cursor: 'pointer',
  },
}));

const Tabs = styled(MuiTabs)(({ theme }) => ({
  '& .MuiTabs-flexContainer': {
    justifyContent: 'center',
  },

  '& .Mui-selected': {
    color: `${theme.palette.button.active} !important`,
  },
}));

const Tab = styled(MuiTab)(() => ({
  fontSize: 16,
  fontWeight: 600,
  letterSpacing: 0.4,
  padding: '12px 48px',
}));

const FlexBox = styled('div')(() => ({
  alignItems: 'center',
}));

const a11yProps = (index) => {
  return {
    id: `tab-${index}`,
    'aria-controls': `tabpanel-${index}`,
  };
};

/*
 * hide for MVP
 *
const StyledBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    right: -16,
    top: 12,
    color: theme.palette.text.tertiary,
    fontWeight: 500,
    fontSize: 12,
    lineHeight: '16px',
    letterSpacing: '0.4px',
    padding: 0,
    background: theme.palette.border.separator,
    borderRadius: 4,
    minWidth: 16,
    minHeight: 24,
  },
}));
*/
/********************  Main Component  ********************/
const WalletSidebar = ({ open, handleSettings, setWalletSidebarOpen }) => {
  const theme = useTheme();
  const { isConnected, address } = useWalletContext();
  const { displayName } = useUserContext();
  const { verifyWalletOwnership } = useMetamaskConnect();
  const { signOut, loading } = useTokenContext();
  const overlay = useMediaQuery('(max-width: 1200px)');
  const history = useHistory();
  const [value, setValue] = useStateIfMounted(0);

  const exploreRef = useRef();

  const handleChange = (_, newValue) => {
    setValue(newValue);
  };

  const handleDisconnect = async () => {
    try {
      await signOut();
      setWalletSidebarOpen(false);
    } catch (err) {
      console.error(err);
    }
  };

  const handleProfile = () => {
    history.push(`/portfolio`);
    setWalletSidebarOpen(false);
  };

  const handleClick = (route) => {
    setWalletSidebarOpen(false);
    verifyWalletOwnership(() => {
      if (overlay) {
        handleSettings();
      } else {
        history.push(route);
        setWalletSidebarOpen(false);
      }
    });
  };

  return (
    <Drawer
      sx={{
        zIndex: 2100,
        '& .MuiDrawer-paper': {
          background: theme.palette.background.secondary,
          borderLeft: 'none',
          boxShadow: theme.palette.boxShadow,
          position: 'fixed',
          width: 400,

          '@media(max-width: 500px)': {
            width: '100%',
          },
          '&::-webkit-scrollbar': {
            display: 'none',
          },
        },
      }}
      anchor="right"
      open={open}
      ref={exploreRef}
      ModalProps={{
        keepMounted: true,
      }}
    >
      <Heading>
        <Profile onClick={handleProfile}>
          <Box style={{ display: 'flex', fontSize: 20, fontWeight: 600 }}>
            {isConnected && <Blockie size={56} />}
            <Box>
              {displayName || shorten(address, 5)}
              <StyledText>See your profile</StyledText>
            </Box>
          </Box>
        </Profile>
        <CloseIcon
          sx={{
            fill: theme.palette.text.main,
            '&:hover': {
              cursor: 'pointer',
            },
          }}
          onClick={() => setWalletSidebarOpen(false)}
        />
      </Heading>
      <Box style={{ padding: '12px 24px 0' }}>
        <GridBox xs={6} item onClick={() => handleClick('/account/settings/profile')}>
          <SettingsIcon
            fontSize="medium"
            style={{ color: theme.palette.text.main, marginRight: 8 }}
          />
          Account Settings
        </GridBox>
      </Box>
      <Box>
        <Box sx={{ borderBottom: 1, borderColor: 'divider', marginBottom: '-2px' }}>
          <Tabs
            variant={'standard'}
            scrollButtons="auto"
            value={value}
            key={value}
            onChange={handleChange}
            aria-label="my-rewards tab list"
          >
            <Tab
              key={0}
              disableRipple={true}
              label={<FlexBox>My Wallet</FlexBox>}
              {...a11yProps(0)}
            />
            {/*
            Hide for MVP 
            <Tab
              key={1}
              disableRipple={true}
              label={
                <FlexBox>
                  <StyledBadge badgeContent={0} showZero>
                    Rewards
                  </StyledBadge>
                </FlexBox>
              }
              {...a11yProps(1)}
            />
            */}
          </Tabs>
        </Box>

        <TabPanel value={value} index={0}>
          <MyWallet setWalletSidebarOpen={setWalletSidebarOpen} />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <Rewards closeSideBar={() => setWalletSidebarOpen(false)} />
        </TabPanel>
      </Box>
      {isConnected && (
        <Box style={{ paddingLeft: 24, margin: 'auto 0', marginBottom: 12 }}>
          <ListBox onClick={loading ? null : handleDisconnect} disabled={loading}>
            <LogoutIcon
              fontSize="medium"
              style={{ color: theme.palette.text.main, marginRight: 8 }}
            />
            {loading ? <div className="loading">Disconnecting...</div> : 'Disconnect'}
          </ListBox>
        </Box>
      )}
    </Drawer>
  );
};

export default WalletSidebar;
