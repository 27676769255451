import React, { useEffect } from 'react';
import { useStateIfMounted } from 'use-state-if-mounted';

import { Button, ButtonGroup, Modal, TextField, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';
import { ModalCloseButton, ModalContainer, ModalRow } from 'components/StyledComponents';

/********************  Styled Components  ********************/
const AddPropertyButton = styled(Button)(({ theme }) => ({
  border: `1px solid ${theme.palette.primary.main}`,
  minWidth: 'auto',
  width: 48,
  height: 48,
  fontSize: 20,
}));

const SaveButton = styled(Button)(({ theme }) => ({
  color: theme.palette.text.main,
  backgroundColor: theme.palette.primary.main,
  fontSize: 16,
  fontWeight: 600,
  padding: theme.spacing(1, 3.5),
}));

const GroupButton = styled(Button)(({ theme }) => ({
  borderTopRightRadius: 0,
  borderBottomRightRadius: 0,
  backgroundColor: theme.palette.background.secondary,
  color: theme.palette.text.light,
  borderColor: theme.palette.border.separator,
  '&:hover': {
    borderColor: theme.palette.border.separator,
    backgroundColor: theme.palette.background.secondary,
  },
}));

const GroupTextField = styled(TextField)(({ theme, last = '', larger = '', disabled }) => ({
  borderRadius: 0,
  borderLeftWidth: 0,
  borderTopRightRadius: last ? 4 : 0,
  borderBottomRightRadius: last ? 4 : 0,
  border: `1px solid ${theme.palette.border.separator}`,
  '& .MuiInputBase-root': {
    borderRadius: 0,
  },
  '& fieldset': {
    border: 'none',
  },
  '& input::placeholder': {
    fontWeight: 500,
    color: theme.palette.text.light,
    opacity: 1,
  },
  flex: !disabled ? (larger ? 8 : 3) : '0 1 auto',
}));

const AddMoreButton = styled(Button)(({ theme }) => ({
  marginTop: '1.5rem',
  marginBottom: '0.5rem',
  color: theme.palette.primary.main,
  borderColor: theme.palette.primary.main,
  fontWeight: 600,
}));

const CustomizedButtonGroup = styled(ButtonGroup)(({ theme }) => ({
  margin: theme.spacing(0.5, 0),
}));

const HeadingRow = styled('div')(({ theme }) => ({
  paddingLeft: theme.spacing(7),
  paddingBottom: theme.spacing(1),
  display: 'flex',
  fontSize: 15,
  fontWeight: 600,
  '& span': {
    flex: 1,
  },
}));

/************************* Main Component  **************************/
const PropertiesModal = ({ model, fieldname }) => {
  const [open, setOpen] = useStateIfMounted(false);
  const [ref, setRef] = useStateIfMounted(null);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const defaultRow = {};
  model
    .map((item) => item.name)
    .forEach((item) => {
      if (item) {
        defaultRow[item] = '';
      }
    });
  const [rows, setRows] = useStateIfMounted([defaultRow]);
  const [saved, setSaved] = useStateIfMounted([]);

  useEffect(() => {
    if (ref?.value && saved.length === 0) {
      const data = JSON.parse(ref.value);
      setRows(data);
      setSaved(data);
    }
  }, [ref]); // eslint-disable-line react-hooks/exhaustive-deps

  const updateRows = () => {
    setRows((rows) => [...rows, defaultRow]);
  };

  const removeRow = (index) => {
    const updatedArray = [...rows];
    updatedArray.splice(index, 1);
    setRows(updatedArray);
  };

  const updateRowValue = (index, value, field) => {
    const updatedRows = [...rows];
    updatedRows[index][field] = value;
    setRows(updatedRows);
  };

  const saveChanges = () => {
    const savedData = rows.filter((row) =>
      Object.values(row).every((value) => value.trim() !== ''),
    );
    setSaved(savedData);
    ref.value = JSON.stringify(savedData);
    setOpen(false);
  };

  useEffect(() => {
    setRows(saved.length ? saved : [defaultRow]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const renderRow = (rowIndex) => {
    return (
      <CustomizedButtonGroup>
        <GroupButton variant="outlined" onClick={() => removeRow(rowIndex)} disableRipple>
          <CloseIcon />
        </GroupButton>
        {model.map(({ type, ...props }, index) => {
          if (type === 'textfield') {
            return (
              <GroupTextField
                {...props}
                last={index + 1 === model.length ? 'true' : ''}
                key={index}
                onChange={(e) => {
                  updateRowValue(rowIndex, e.target.value, props.name);
                }}
                value={rows[rowIndex][props.name]}
              />
            );
          }
          return null;
        })}
      </CustomizedButtonGroup>
    );
  };

  return (
    <>
      <AddPropertyButton variant="outlined" onClick={handleOpen} disableRipple>
        +
      </AddPropertyButton>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{
          zIndex: 1500,
          borderRadius: 10,
        }}
      >
        <ModalContainer>
          <ModalRow centered="true">
            <Typography fontSize={20} fontWeight={600}>
              Add Properties
            </Typography>
            <ModalCloseButton onClick={() => setOpen(false)} disableRipple>
              <CloseIcon />
            </ModalCloseButton>
          </ModalRow>
          <ModalRow>
            <Typography fontSize={14} fontWeight={500} color="text.medium" paddingBottom="1.5em">
              Properties show up underneath your item, are clickable, and can be filtered in your
              collection&apos;s sidebar.
            </Typography>
            <HeadingRow>
              <span>Name</span>
              <span>Value</span>
            </HeadingRow>
            {rows.map((item, index) => (
              <div key={index}>{renderRow(index)}</div>
            ))}
            <AddMoreButton variant="outlined" size="large" onClick={updateRows} disableRipple>
              Add More
            </AddMoreButton>
          </ModalRow>
          <ModalRow noborder="true" centered="true">
            <SaveButton variant="contained" size="large" onClick={saveChanges} disableRipple>
              Save
            </SaveButton>
          </ModalRow>
        </ModalContainer>
      </Modal>
      <input type="hidden" name={fieldname} id={`field-${fieldname}`} ref={setRef} />
    </>
  );
};

export default PropertiesModal;
