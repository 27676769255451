import React from 'react';
import AirdropDashboard from './Airdrop';

const HomePage = React.lazy(() => import('./HomePage'));
const AssetPage = React.lazy(() => import('./AssetPage'));
const Notfound = React.lazy(() => import('./Notfound'));
const StatsPage = React.lazy(() => import('./Stats'));
const Portfolio = React.lazy(() => import('./Portfolio'));
const Settings = React.lazy(() => import('./Settings'));
const CreatorConsole = React.lazy(() => import('./CreatorConsole'));
const TermsOfService = React.lazy(() => import('./TermsOfService'));
const SingleCollection = React.lazy(() => import('./SingleCollection'));
const FarmingDashboard = React.lazy(() => import('./FarmingDashboard'));
const ListItemForSale = React.lazy(() => import('./ListItemForSale'));
const ReferralProgram = React.lazy(() => import('./ReferralProgram'));
const ApplyPromo = React.lazy(() => import('./ApplyPromo'));
const ListingRewards = React.lazy(() => import('./ListingRewards'));
const ListingRewardsHistory = React.lazy(() => import('./ListingRewards/History'));
const EmailPage = React.lazy(() => import('./Email'));
const Activity = React.lazy(() => import('./Activity'));
const Launchpad = React.lazy(() => import('./Launchpad'));

export const routes = [
  {
    path: '/',
    component: HomePage,
    exact: true,
    meta: {
      needAuth: false,
      needConnectWallet: false,
    },
  },
  {
    path: '/asset/:tokenAddress/:tokenId/sell',
    component: ListItemForSale,
    exact: true,
    meta: {
      needAuth: true,
      needConnectWallet: true,
    },
  },
  {
    path: '/asset/:tokenAddress/:tokenId',
    component: AssetPage,
    exact: true,
    meta: {
      needAuth: false,
      needConnectWallet: false,
    },
  },
  {
    path: '/collections',
    component: StatsPage,
    exact: true,
    meta: {
      needAuth: false,
      needConnectWallet: false,
    },
    fullScreen: true,
  },
  {
    path: '/portfolio',
    component: Portfolio,
    exact: true,
    meta: {
      needAuth: true,
      needConnectWallet: true,
    },
  },
  {
    path: '/portfolio/:address',
    component: Portfolio,
    exact: true,
    meta: {
      needAuth: false,
      needConnectWallet: false,
    },
  },
  {
    path: '/creator/:collectionURL',
    component: CreatorConsole,
    exact: true,
    meta: {
      needAuth: false,
      needConnectWallet: false,
    },
  },
  {
    path: '/account/settings',
    component: Settings,
    meta: {
      needAuth: true,
      needConnectWallet: true,
    },
  },
  {
    path: '/collections/:contract_address',
    component: SingleCollection,
    meta: {
      needAuth: false,
      needConnectWallet: false,
    },
    fullScreen: true,
  },
  {
    path: '/tos',
    component: TermsOfService,
    meta: {
      needAuth: false,
      needConnectWallet: false,
    },
  },
  {
    path: '/rewards/farming',
    component: FarmingDashboard,
  },
  {
    path: '/rewards/referral_program',
    component: ReferralProgram,
  },
  {
    path: '/ref/:id',
    component: ApplyPromo,
  },
  {
    path: '/rewards/listing-rewards/history',
    component: ListingRewardsHistory,
    meta: {
      needAuth: false,
      needConnectWallet: false,
    },
  },
  {
    path: '/rewards/listing-rewards',
    component: ListingRewards,
    meta: {
      needAuth: false,
      needConnectWallet: false,
    },
  },
  {
    path: '/airdrop',
    component: AirdropDashboard,
    meta: {
      needAuth: true,
      needConnectWallet: true,
    },
  },
  {
    path: '/confirm-email',
    component: EmailPage,
    meta: {
      needAuth: false,
      needConnectWallet: false,
    },
  },
  {
    path: '/subscription-email-verified',
    component: EmailPage,
    meta: {
      needAuth: false,
      needConnectWallet: false,
    },
  },
  {
    path: '/subscription-email-expired',
    component: EmailPage,
    meta: {
      needAuth: false,
      needConnectWallet: false,
    },
  },
  {
    path: '/profile-email-verified',
    component: EmailPage,
    meta: {
      needAuth: false,
      needConnectWallet: false,
    },
  },
  {
    path: '/activity',
    component: Activity,
    meta: {
      needAuth: false,
      needConnectWallet: false,
    },
  },
  {
    path: '/launchpad',
    component: Launchpad,
    meta: {
      needAuth: false,
      needConnectWallet: false,
    },
  },
  {
    path: '*',
    component: Notfound,
  },
];
