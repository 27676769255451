import { NotificationsApi } from 'clientSDK';

const notificationsApi = new NotificationsApi();

export default {
  getUserSettings: async () => await notificationsApi.getUserNotificationSettings(),

  updateUserSettings: async (settings) =>
    await notificationsApi.updateUserNotificationSettings(settings),

  updateOfferSettings: async (settings) => await notificationsApi.updateOfferSettings(settings),
};
