import React from 'react';
import { GMITokenLogo } from 'components/ImageComponent';
import RewardsContainer from './RewardsContainer';

const list = [
  {
    name: 'Rewards',
    price: '0 GMI',
    dollar: '$0.00',
    link: null,
    button: 'Earn rewards',
  },
];

/************************* Main Component  **************************/
export default function FarmingRewards() {
  return <RewardsContainer title="Listing rewards" list={list} logoComponent={GMITokenLogo} />;
}
