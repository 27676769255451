import React from 'react';
import { Select, MenuItem as MuiMenuItem, styled, useTheme } from '@mui/material';

import { LISTING_DURATIONS } from 'constants';

import { CustomExpandMore } from 'components/StyledComponents';

const DateSelect = styled(Select)(({ theme }) => ({
  width: '180px',
  marginRight: theme.spacing(1),
  height: 48,
  borderRadius: '8px',
  fontSize: 14,
  fontWeight: 500,
  background: theme.palette.background.filter,
  color: theme.palette.text.main,
  lineHeight: '20px',
  letterSpacing: '0.1px',

  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
    border: `1px solid ${theme.palette.border.decorative}`,
  },
  '&.MuiSelect-icon': {
    color: theme.palette.text.main,
  },
  '& .MuiOutlinedInput-input': {
    padding: theme.spacing(1.6, 2),
    marginTop: 1,
  },
  '&.MuiInputBase-formControl': {
    color: theme.palette.text.secondary,
    '& div[aria-expanded="true"]': {
      color: theme.palette.text.main,
      background: theme.palette.background.default,
    },
  },
  '&.MuiSelect-icon': {
    color: theme.palette.text.main,
  },

  '& fieldset': {
    borderColor: `${theme.palette.border.decorative} !important`,
  },

  '&:hover': {
    background: theme.palette.background.dropdown.hovered,
    '&.MuiInputBase-formControl': {
      color: theme.palette.text.main,
    },
  },
}));

const MenuItem = styled(MuiMenuItem)(({ theme }) => ({
  padding: 16,
  width: '100%',
  color: theme.palette.text.main,
  background: theme.palette.background.secondary,
  borderBottom: `1px solid ${theme.palette.border.separator}`,
  lineHeight: '24px',
  fontSize: '14px',
  letterSpacing: '0.15px',
  fontWeight: 600,

  '&:last-child': {
    borderBottom: 'none',
  },
  '&.Mui-selected': {
    background: theme.palette.background.secondary,
    '&:hover': {
      backgroundColor: theme.palette.background.tertiary,
      boxShadow: `0px 0px 5px 0px ${theme.palette.shadow}`,
      transition: 'box-shadow 0.3s ease-in-out',
    },
  },
  '&:hover': {
    backgroundColor: theme.palette.background.tertiary,
    boxShadow: `0px 0px 5px 0px ${theme.palette.shadow}`,
    transition: 'box-shadow 0.3s ease-in-out',
  },
}));

/********************  Main Component  ********************/
const DayDesktopView = ({ expiredDate, setExpiredDate }) => {
  const theme = useTheme();

  const handleExpiredDateChange = (event) => {
    const expiredDate = Number(event.target.value);

    setExpiredDate(expiredDate);
  };

  const renderValue = (selected) => {
    const duration = LISTING_DURATIONS.find((item) => item.value === selected);
    return duration ? duration.key : 'Custom date';
  };

  return (
    <DateSelect
      notched={false}
      small={1}
      label="items"
      IconComponent={CustomExpandMore}
      MenuProps={{
        sx: {
          zIndex: 3000,

          '&& .MuiList-root': {
            background: theme.palette.background.default,
            padding: 0,
          },
        },
      }}
      value={expiredDate}
      renderValue={renderValue}
      onChange={handleExpiredDateChange}
    >
      {LISTING_DURATIONS.map((duration) => (
        <MenuItem key={duration.value} value={duration.value}>
          {duration.key}
        </MenuItem>
      ))}
      <MenuItem value={expiredDate}>Custom date</MenuItem>
    </DateSelect>
  );
};

export default DayDesktopView;
