import { useContext } from 'react';
import { WindowContext } from 'context';

const useWindowContext = () => {
  const context = useContext(WindowContext);

  if (!context) {
    throw new Error('Window context must be use inside provider');
  }

  return context;
};

export default useWindowContext;
