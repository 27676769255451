import { createTheme } from '@mui/material/styles';

const typography = {
  fontFamily: [
    'Inter',
    'Open Sans',
    'Roboto',
    'system-ui',
    '-apple-system',
    'BlinkMacSystemFont',
    'Segoe UI',
    'Helvetica Neue',
    'Arial',
    'Noto Sans',
    'sans-serif',
    'Apple Color Emoji',
    'Segoe UI Emoji',
    'Segoe UI Symbol',
    'Noto Color Emoji',
  ].join(','),
  button: {
    textTransform: 'none',
  },
};

const breakpoints = {
  // TODO : Refactor all breakpoints and their corresponding components
  values: {
    xs: 360,
    sm: 500,
    md: 700,
    lg: 994,
    xl: 1300,
    desktop: 1600,
  },
};

export const lightTheme = createTheme({
  typography,
  breakpoints,
  spacing: 8,
  palette: {
    mode: 'light',
    white: '#FFFFFF',
    timer: '#FFFFFF',
    text: {
      main: '#04111D', // primary, body, header, hover
      secondary: '#81909E', // input labels
      tertiary: '#525F6B', // help text
      placeholder: '#C4CBD1',
      interactive: '#FFFFFF',
      error: '#E0002E',
      light: '#8B939B',
      white: '#FFFFFF',
      medium: '#707083',
      subtitle: '#707A83',
      menu: '#434D55',
      red: '#EB5757',
      crimson: '#FF4258',
      dark: '#212121',
      grey: '#707A83',
      title: '#04111D',
    },
    primary: {
      main: '#00AB58',
      support: '#00E676',
      darker: '#00AB58',
      lighter: '#45FF9A',
    },
    secondary: {
      main: '#2781E2',
    },
    button: {
      // bg color
      primary: '#00AB58',
      secondary: '#0F2C40',
      tertiary: '#00703A',
      active: '#00AB58',
      rgba: 'rgba(0, 171, 88, 0.3)',
    },
    danger: {
      color: '#FF4258', // button bg
      variant: '#E0002E', // text, icon, border
    },
    background: {
      default: '#FFFFFF',
      secondary: '#FFFFFF',
      light: '#FBFDFF',
      tertiary: '#E5E8EB',
      overlay: 'rgba(4, 17, 29, 0.8)',
      lighter: '#F3FBFE',
      linear: 'linear-gradient(0deg, rgba(4, 17, 29, 0.12), rgba(4, 17, 29, 0.12)), #FFFFFF',
      hover: 'linear-gradient(0deg, rgba(4, 17, 29, 0.08), rgba(4, 17, 29, 0.08)), #FFFFFF',
      filterchip: {
        pressed: 'linear-gradient(0deg, rgba(4, 17, 29, 0.12), rgba(4, 17, 29, 0.12)), #FAFFFC',
        hovered: 'linear-gradient(0deg, rgba(4, 17, 29, 0.08), rgba(4, 17, 29, 0.08)), #FAFFFC',
      },
      dropdown: {
        hovered: 'linear-gradient(0deg, rgba(4, 17, 29, 0.08), rgba(4, 17, 29, 0.08)), #FBFDFF',
      },
      filter: '#FFFFFF',
      offer: {
        hovered: 'linear-gradient(0deg, rgba(4, 17, 29, 0.08), rgba(4, 17, 29, 0.08)), #FBFDFF',
        pressed: 'linear-gradient(0deg, rgba(4, 17, 29, 0.12), rgba(4, 17, 29, 0.12)), #FBFDFF',
      },
      button: {
        hovered:
          'linear-gradient(0deg, rgba(255, 255, 255, 0.08), rgba(255, 255, 255, 0.08)), #00AB58',
        pressed:
          'linear-gradient(0deg, rgba(255, 255, 255, 0.12), rgba(255, 255, 255, 0.12)), #00AB58',
      },
      apply: {
        hovered: 'linear-gradient(0deg, rgba(4, 17, 29, 0.08), rgba(4, 17, 29, 0.08)), #FFFFFF',
        pressed: 'linear-gradient(0deg, rgba(4, 17, 29, 0.12), rgba(4, 17, 29, 0.12)), #FFFFFF',
      },
      clear: {
        hovered: 'linear-gradient(0deg, rgba(4, 17, 29, 0.08), rgba(4, 17, 29, 0.08)), #FAFFFC',
        pressed: 'linear-gradient(0deg, rgba(4, 17, 29, 0.12), rgba(4, 17, 29, 0.12)), #FAFFFC',
      },
      menuItem: '#04111D',
      select: '#FBFDFF',
    },
    border: {
      main: '#212121',
      primary: '#FAFFFC',
      medium: '#81909E',
      high: '#525F6B',
      separator: '#E5E8EB',
      decorative: '#E5E8EB',
      light: '#CCCCCC',
      shadow: '#AAA',
      checkbox: '#00AB58',
      divider: '#E5E8EB',
      outline: '#212121',
    },
    link: {
      primary: '#00AB58',
      secondary: '#00703A',
      inverse: '#A2ADB8',
    },
    icon: {
      primary: '#04111D',
      secondary: '#525F6B',
      tertiary: '#81909E',
      interactive: '#FFFFFF',
      special: '#FF4258',
      clock: '#DADADA',
    },
    disabled: {
      color: '#FBFCFC', // fields, bg, border
    },
    support: {
      error: '#FF4258',
      success: '#00AB58',
      warning: '#F6C000',
      warning2: '#F2994A',
      information: '#2081E2',
    },
    footer: {
      background: '#0F2C40',
      text: '#C4CBD1',
      input: '#E5E8EB',
    },
    shadow: '#888888',
    medium: {
      main: '#707083',
    },
    rarity: {
      mythic: '#FF4258',
      legendary: '#BD9E24',
      epic: '#9B51E0',
      rare: '#2D9CDB',
      uncommon: '#27AE60',
      common: '#E0E0E0',
    },
    tab: '#353840',
    loader: 'rgba(4, 17, 29, 0.8)',
    darkBg: '#2E333A',
    active: '#00703A',
    boxShadow:
      '0px 0px 2px rgba(0, 0, 0, 0.14), 0px 2px 2px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2)',
    boxShadowLarge:
      '0px 9px 12px rgba(0, 0, 0, 0.14), 0px 3px 16px rgba(0, 0, 0, 0.12), 0px 5px 6px rgba(0, 0, 0, 0.2);',
    ui2: '#FBFDFF',
    header: '#E0002E',
    filter:
      'drop-shadow(0px 0px 2px rgba(0, 0, 0, 0.14)) drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.12)) drop-shadow(0px 1px 3px rgba(0, 0, 0, 0.2))',
  },
  components: {
    MuiTableCell: {
      styleOverrides: {
        root: {
          borderTop: '1px solid #E5E8EB',
          borderBottomStyle: 'none',
        },
      },
    },
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          scrollbarColor: '#81909E #FFFFFF',
          '&::-webkit-scrollbar, & *::-webkit-scrollbar': {
            backgroundColor: '#FFFFFF',
            width: 12,
            height: 12,
            position: 'fixed',
            bottom: 40,
          },
          '&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb': {
            borderRadius: 10,
            backgroundColor: '#81909E',
            border: '2px solid #81909E',
          },
          '&::-webkit-scrollbar-thumb:focus, & *::-webkit-scrollbar-thumb:focus': {
            backgroundColor: '#81909E',
          },
          '&::-webkit-scrollbar-thumb:active, & *::-webkit-scrollbar-thumb:active': {
            backgroundColor: '#81909E',
          },
          '&::-webkit-scrollbar-thumb:hover, & *::-webkit-scrollbar-thumb:hover': {
            backgroundColor: '#81909E',
          },
          '&::-webkit-scrollbar-corner, & *::-webkit-scrollbar-corner': {
            backgroundColor: '#FFFFFF',
          },
        },
      },
    },
  },
});

export const darkTheme = createTheme({
  typography,
  breakpoints,
  spacing: 8,
  palette: {
    mode: 'dark',
    white: '#FFFFFF',
    timer: '#141619',
    text: {
      main: '#FFFFFF', // primary, body, header, hover
      secondary: '#81909E', // input labels
      tertiary: '#C4CBD1', // help text
      placeholder: '#6C7C8C',
      interactive: '#FFFFFF',
      error: '#F86D7A',
      light: '#8B939B',
      white: '#FFFFFF',
      medium: '#707083',
      subtitle: '#707A83',
      menu: '#434D55',
      red: '#EB5757',
      crimson: '#FF4258',
      dark: '#212121',
      grey: '#707A83',
      title: '#04111D',
      highlight: '#00D181',
      formPlaceholder: '#4F5050',
    },
    button: {
      // bg color
      primary: '#00AB58',
      secondary: '#FFFFFF',
      tertiary: '#00E676',
      active: '#00E676',
      rgba: 'rgba(0, 230, 118, 0.3)',
    },
    danger: {
      color: '#E0002E', // button bg
      variant: '#F86D7A', // text, icon, border
    },
    background: {
      default: '#141619',
      secondary: '#141619',
      light: '#141619',
      tertiary: '#2E333A',
      overlay: 'rgba(0, 0, 0, 0.8)',
      lighter: '#F3FBFE',
      select: '#1A2024',
      linear:
        'linear-gradient(0deg, rgba(255, 255, 255, 0.12), rgba(255, 255, 255, 0.12)), #141619',
      hover: 'linear-gradient(0deg, rgba(255, 255, 255, 0.08), rgba(255, 255, 255, 0.08)), #141619',
      offer: {
        hovered:
          'linear-gradient(0deg, rgba(255, 255, 255, 0.08), rgba(255, 255, 255, 0.08)), #2E333A',
        pressed:
          'linear-gradient(0deg, rgba(255, 255, 255, 0.12), rgba(255, 255, 255, 0.12)), #2E333A',
      },
      filterchip: {
        pressed:
          'linear-gradient(0deg, rgba(255, 255, 255, 0.12), rgba(255, 255, 255, 0.12)), #002211',
        hovered:
          'linear-gradient(0deg, rgba(255, 255, 255, 0.08), rgba(255, 255, 255, 0.08)), #002211',
      },
      dropdown: {
        hovered:
          'linear-gradient(0deg, rgba(255, 255, 255, 0.08), rgba(255, 255, 255, 0.08)), #2E333A',
      },
      filter: '#141619',
      button: {
        hovered:
          'linear-gradient(0deg, rgba(255, 255, 255, 0.08), rgba(255, 255, 255, 0.08)), #00AB58',
        pressed:
          'linear-gradient(0deg, rgba(255, 255, 255, 0.12), rgba(255, 255, 255, 0.12)), #00AB58',
      },
      apply: {
        hovered:
          'linear-gradient(0deg, rgba(255, 255, 255, 0.08), rgba(255, 255, 255, 0.08)), #141619',
        pressed:
          'linear-gradient(0deg, rgba(255, 255, 255, 0.12), rgba(255, 255, 255, 0.12)), #141619',
      },
      clear: {
        hovered:
          'linear-gradient(0deg, rgba(255, 255, 255, 0.08), rgba(255, 255, 255, 0.08)), #002211',
        pressed:
          'linear-gradient(0deg, rgba(255, 255, 255, 0.12), rgba(255, 255, 255, 0.12)), #002211',
      },
      highlight: '#00D181',
      grayGreen: '#1E1F1C',
    },
    border: {
      main: '#212121',
      primary: '#002211',
      medium: '#6C7C8C',
      high: '#81909E',
      separator: '#2E333A',
      decorative: '#525F6B',
      light: '#CCCCCC',
      shadow: '#444',
      checkbox: '#00E676',
      divider: '#2E333A',
      outline: '#FFFFFF',
      highlight: '#00D181',
    },
    link: {
      primary: '#00E676',
      secondary: '#0EFF8A',
      inverse: '#A2ADB8',
    },
    icon: {
      primary: '#FFFFFF',
      secondary: '#A2ADB8',
      tertiary: '#81909E',
      interactive: '#FFFFFF',
      special: '#FF4258',
      clock: '#DADADA',
    },
    disabled: {
      color: '#141619', // fields, bg, border
    },
    support: {
      error: '#FF4258',
      success: '#00E676',
      warning: '#F6C000',
      warning2: '#F2994A',
      information: '#65A8EB',
    },
    footer: {
      background: '#141619',
      text: '#A2ADB8',
      input: '#E5E8EB',
    },
    shadow: '#6C7C8C',
    primary: {
      main: '#00AB58',
      support: '#00E676',
      darker: '#00AB58',
      lighter: '#45FF9A',
    },
    medium: {
      main: '#707083',
    },
    rarity: {
      mythic: '#FF4258',
      legendary: '#F2C94C',
      epic: '#BB6BD9',
      rare: '#56CCF2',
      uncommon: '#6FCF97',
      common: '#BDBDBD',
    },
    tab: '#353840',
    loader: 'rgba(0, 0, 0, 0.8)',
    darkBg: '#2E333A',
    active: '#00E676',
    boxShadow:
      '0px 0px 2px rgba(0, 0, 0, 0.14), 0px 2px 2px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2)',
    boxShadowLarge:
      '0px 9px 12px rgba(0, 0, 0, 0.14), 0px 3px 16px rgba(0, 0, 0, 0.12), 0px 5px 6px rgba(0, 0, 0, 0.2);',
    ui2: '#2E333A',
    header: '#F86D7A',
    filter:
      'drop-shadow(0px 0px 2px rgba(0, 0, 0, 0.14)) drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.12)) drop-shadow(0px 1px 3px rgba(0, 0, 0, 0.2))',
  },
  components: {
    MuiTableCell: {
      styleOverrides: {
        root: {
          borderTop: '1px solid #2E333A',
          borderBottomStyle: 'none',
        },
      },
    },
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          scrollbarColor: '#81909E #141619',
          '&::-webkit-scrollbar, & *::-webkit-scrollbar': {
            backgroundColor: '#141619',
            width: 12,
            height: 12,
            position: 'fixed',
            bottom: 40,
          },
          '&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb': {
            borderRadius: 10,
            backgroundColor: '#81909E',
            border: '2px solid #81909E',
          },
          '&::-webkit-scrollbar-thumb:focus, & *::-webkit-scrollbar-thumb:focus': {
            backgroundColor: '#81909E',
          },
          '&::-webkit-scrollbar-thumb:active, & *::-webkit-scrollbar-thumb:active': {
            backgroundColor: '#81909E',
          },
          '&::-webkit-scrollbar-thumb:hover, & *::-webkit-scrollbar-thumb:hover': {
            backgroundColor: '#81909E',
          },
          '&::-webkit-scrollbar-corner, & *::-webkit-scrollbar-corner': {
            backgroundColor: '#141619',
          },
        },
      },
    },
  },
});
