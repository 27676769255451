import { useMemo } from 'react';
import { useStateIfMounted } from 'use-state-if-mounted';

import { Link as ReactRouterLink } from 'react-router-dom';
import { styled, useTheme } from '@mui/material/styles';
import {
  AppBar,
  Avatar as MuiAvatar,
  Box,
  Button as MuiButton,
  Dialog as MuiDialog,
  DialogActions as MuiDialogActions,
  DialogContent as MuiDialogContent,
  DialogTitle as MuiDialogTitle,
  FormControl as MuiFormControl,
  FormControlLabel,
  IconButton,
  InputLabel,
  MenuItem as MuiMenuItem,
  Radio,
  RadioGroup,
  Select as MuiSelect,
  Stack,
  Toolbar,
  Typography,
  useMediaQuery,
  Link as MuiLink,
} from '@mui/material';
import { Close as CloseIcon, ExpandMore } from '@mui/icons-material';
import {
  StyledBackdrop,
  StyledCloseButton,
  StyledForm,
  StyledLabel,
} from 'components/StyledComponents';
import { RAW_COLLECTION, REPORT_OPTIONS, SUPPORT_EMAIL } from 'constants/index';

const DialogTitle = styled(MuiDialogTitle)(() => ({
  fontWeight: 600,
  fontFamily: 'Inter',
  fontSize: 24,
  width: '100%',
  padding: '24px 24px 16px 24px',
  '@media(max-width: 360px)': {
    padding: '24px 16px 16px 16px',
  },
}));

const DialogContent = styled(MuiDialogContent)(({ theme }) => ({
  '&.MuiDialogContent-root': {
    padding: '40px 24px 32px',
    borderColor: theme.palette.border.separator,
    borderStyle: 'solid',
    borderWidth: '1px 0 1px 0',
  },
}));

const DialogActions = styled(MuiDialogActions)(() => ({
  padding: '24px',
  justifyContent: 'center',
}));

const Dialog = styled(MuiDialog)(({ theme }) => ({
  '.MuiDialog-paper': {
    maxWidth: '530px',
    backgroundColor: theme.palette.background.secondary,
    backgroundImage: 'none',
    margin: 0,
    '@media(max-width: 360px)': {
      borderRadius: 0,
    },
    '@media(min-width: 361px)': {
      borderRadius: '10px',
      position: 'absolute',
      top: '155px',
    },
  },
}));

const Link = styled(ReactRouterLink)(({ theme }) => ({
  color: theme.palette.button.active,
  '&:hover': {
    filter: 'brightness(112%)',
  },
  '&:active': {
    filter: 'brightness(108%)',
  },
}));

const FormControl = styled(MuiFormControl)(({ theme }) => ({
  '&:hover .MuiInputLabel-root': {
    color: theme.palette.text.main,
  },
  '.MuiInputLabel-root.Mui-focused': {
    color: `${theme.palette.text.main}`,
  },
  '&:hover .MuiOutlinedInput-notchedOutline': {
    border: `none`,
  },
}));

const Select = styled(MuiSelect)(({ theme }) => ({
  borderRadius: '10px',
  fontSize: '14px',
  fontWeight: 500,
  height: '48px',
  backgroundColor:
    theme.palette.mode === 'dark'
      ? theme.palette.background.filter
      : theme.palette.background.light,
  color: theme.palette.text.main,
  border: 'none',
  '.MuiSelect-icon': {
    color: theme.palette.icon.tertiary,
  },
  '&.Mui-focused': {
    backgroundColor: theme.palette.background.default,
  },
  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
    border: `1px solid ${theme.palette.border.separator}`,
  },
  '&:hover': {
    border: `1px solid ${theme.palette.border.seperator}`,
    backgroundColor:
      theme.palette.mode === 'dark'
        ? theme.palette.background.tertiary
        : theme.palette.background.light,
    filter: theme.palette.mode === 'dark' ? 'brightness(108%)' : 'brightness(97%)',
  },
}));

const Label = styled('label')(({ theme }) => ({
  fontSize: 16,
  fontWeight: 600,
  fontFamily: 'Inter',
  lineHeight: '24px',
  marginTop: '8px',
  marginBottom: '8px',
  color: theme.palette.text.main,
}));

const Button = styled(MuiButton)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  width: '200px',
  borderRadius: 8,
  color: theme.palette.text.white,
  fontSize: 16,
  fontWeight: 600,
  padding: '12px',
  lineHeight: '24px',
  letterSpacing: '0.15px',
  '&:hover': {
    backgroundColor: theme.palette.primary.main,
    filter: 'brightness(108%)',
    boxShadow: 'none',
  },
}));

const MenuItem = styled(MuiMenuItem)(({ theme }) => ({
  backgroundColor: theme.palette.background.secondary,
  padding: '16px',
}));

const Avatar = styled(MuiAvatar)(({ theme }) => ({
  width: '24px',
  height: '24px',
  borderWidth: '2px',
  borderColor: theme.palette.border.decorative,
  borderStyle: 'solid',
}));

export const StyledControlLabel = styled(FormControlLabel)(({ theme }) => ({
  borderBottom: `1px solid ${theme.palette.border.separator}`,
  padding: '8px 0',
  '.MuiFormControlLabel-label': {
    fontWeight: 600,
    fontSize: '16px',
  },
}));

const FakeDetails = ({ collection, setCollection }) => {
  const below360 = useMediaQuery('(max-width: 360px)');
  const [mobileOptionsOpen, setMobileOptionsOpen] = useStateIfMounted(false);
  const [selectOptionsOpen, setSelecteOptionsOpen] = useStateIfMounted(false);

  return (
    <Box sx={{ mt: 4 }}>
      <Label htmlFor="category-field">Original Collection</Label>
      <FormControl fullWidth sx={{ pt: '4px' }}>
        {collection === '' && (
          <SortLabel id="select-reason-label" shrink={false}>
            Select orignial collection
          </SortLabel>
        )}
        <Select
          id="category-field"
          IconComponent={ExpandMore}
          MenuProps={{
            PaperProps: {
              sx: {
                boxShadow: 2,
                '& .MuiList-root': {
                  padding: 0,
                  borderRadius: '4px',
                },
              },
            },
          }}
          inputProps={{
            sx: {
              '*': {
                fontSize: '14px !important',
              },
            },
          }}
          labelId="select-reason-label"
          value={collection}
          defaultValue={''}
          open={selectOptionsOpen}
          onOpen={() => {
            if (below360) {
              setMobileOptionsOpen(true);
            } else {
              setSelecteOptionsOpen(true);
            }
          }}
          onClose={() => {
            setMobileOptionsOpen(false);
            setSelecteOptionsOpen(false);
          }}
          onChange={(e) => {
            setCollection(e.target.value);
          }}
        >
          {RAW_COLLECTION.map(({ src, alt, name }, index) => (
            <MenuItem
              key={name}
              value={name}
              sx={{
                width: '100%',
              }}
              divider={index < RAW_COLLECTION.length - 1}
            >
              <Stack direction="row" alignItems="center" spacing="8px">
                <Avatar src={src} alt={alt} />
                <Typography fontWeight={600} fontSize={{ xs: '14px', xl: '16px' }}>
                  {name}
                </Typography>
              </Stack>
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      {below360 && (
        <CollectionsMobileView
          open={mobileOptionsOpen}
          setOpen={setMobileOptionsOpen}
          setValue={setCollection}
          value={collection}
        />
      )}
    </Box>
  );
};

const StolenDetails = () => (
  <Typography
    fontSize={14}
    fontWeight={400}
    fontFamily="Inter"
    letterSpacing=".25px"
    sx={{ pt: '32px' }}
  >
    If you believe your account has been compromised, go to account support in
    <Link sx={{ mx: '4px' }} underline="none" to="/account/settings/profile">
      account settings
    </Link>
    to freeze your account.
  </Typography>
);

const OtherDetails = () => (
  <Typography
    fontSize={14}
    fontWeight={400}
    fontFamily="Inter"
    letterSpacing=".25px"
    sx={{ pt: '24px' }}
  >
    For other trust and safety reporting,
    <MuiLink
      sx={{ mx: '4px' }}
      underline="none"
      rel="noopener noreferrer"
      href="https://wiki.energi.world/en/support/help-me" // TODO: change later
      target="_blank"
    >
      submit a ticket
    </MuiLink>
    and we&apos;ll help you.
  </Typography>
);

const ReasonsMobileView = ({ open, setOpen, setValue }) => {
  return (
    <StyledBackdrop open={open}>
      <StyledForm>
        <Box sx={{ mb: '12px', mt: '4px' }}>
          <StyledLabel id="demo-radio-buttons-group-label">Select a reason</StyledLabel>
          <StyledCloseButton onClick={() => setOpen(false)} disableRipple>
            <CloseIcon />
          </StyledCloseButton>
        </Box>
        <RadioGroup
          aria-labelledby="demo-radio-buttons-group-label"
          defaultValue=""
          name="radio-buttons-group"
        >
          {REPORT_OPTIONS.map((option, index) => (
            <StyledControlLabel
              key={option.value}
              value={option.value}
              onClick={() => {
                setValue(option.value);
                setOpen(false);
              }}
              control={<Radio sx={{ fontSize: '16px', fontWeight: 600 }} />}
              label={option.label}
              divider={index < REPORT_OPTIONS.length - 1}
            />
          ))}
        </RadioGroup>
      </StyledForm>
    </StyledBackdrop>
  );
};

const CollectionsMobileView = ({ open, setOpen, value, setValue }) => {
  return (
    <StyledBackdrop open={open}>
      <StyledForm>
        <Box sx={{ mb: '12px', mt: '4px' }}>
          <StyledLabel id="demo-radio-buttons-group-label">Original collection</StyledLabel>
          <StyledCloseButton onClick={() => setOpen(false)} disableRipple>
            <CloseIcon />
          </StyledCloseButton>
        </Box>
        <RadioGroup
          aria-labelledby="demo-radio-buttons-group-label"
          defaultValue=""
          name="radio-buttons-group"
        >
          {RAW_COLLECTION.map(({ src, alt, name }, index) => (
            <MenuItem
              key={name}
              value={name}
              onClick={() => {
                setValue(name);
                setOpen(false);
              }}
              sx={{ p: 0 }}
              divider={index < RAW_COLLECTION.length - 1}
            >
              <Stack
                direction="row"
                alignItems="center"
                spacing="8px"
                sx={{ my: '16px', mx: '8px' }}
              >
                <Radio size="small" sx={{ fontSize: '20px', p: 0 }} checked={value === name} />
                <Avatar src={src} alt={alt} />
                <Typography fontWeight={600} fontSize="14px">
                  {name}
                </Typography>
              </Stack>
            </MenuItem>
          ))}
        </RadioGroup>
      </StyledForm>
    </StyledBackdrop>
  );
};

const SortLabel = styled(InputLabel)(({ theme }) => ({
  margin: '4px 0',
  color: theme.palette.text.secondary,
  fontWeight: 500,
  fontSize: '14px',
  lineHeight: '20px',
  letterSpacing: '0.1px',
  '&.Mui-focused': {
    color: theme.palette.text.secondary,
  },
}));

/********************  Main Component  ********************/
const ReportDialog = ({ open, onClose, card }) => {
  const theme = useTheme();
  const isExtraSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [reason, setReason] = useStateIfMounted('');
  const [collection, setCollection] = useStateIfMounted('');
  const [mobileOptionsOpen, setMobileOptionsOpen] = useStateIfMounted(false);
  const [selectOptionsOpen, setSelectOptionsOpen] = useStateIfMounted(false);
  const notReadyForSumbit = useMemo(
    () => reason === '' || (reason === 0 && collection === ''),
    [reason, collection],
  );

  const assetLink = card
    ? `${process.env.REACT_APP_PUBLIC_URL}/asset/${card.contractAddress}/${card.tokenId}`
    : '';

  function handleClose() {
    setReason('');
    setCollection('');
    onClose();
  }

  function submit() {
    const reportOption = REPORT_OPTIONS.find((option) => option.value === reason);
    const endLines = encodeURIComponent('\n\n\n');
    const originalCollection = collection ? `Original Collection: ${collection}${endLines}` : '';
    const mail = `mailto:${SUPPORT_EMAIL}?subject=${reportOption.label}&body=Reason: ${reportOption.label}${endLines}${originalCollection}Reported Asset: ${assetLink}`;
    window.open(mail, '_blank');
    handleClose();
  }

  return (
    <Dialog open={open} onClose={handleClose} fullWidth fullScreen={isExtraSmallScreen}>
      {isExtraSmallScreen ? (
        <AppBar
          sx={{
            position: 'relative',
            borderRadius: 0,
            backgroundColor: theme.palette.background.secondary,
            backgroundImage: 'none',
            zIndex: 100,
            boxShadow: 'none',
          }}
        >
          <Toolbar sx={{ padding: 0 }}>
            <DialogTitle>
              <Stack direction="row" alignItems="center" justifyContent="space-between">
                <Typography fontSize={24} fontWeight={500} color="text.main">
                  Report this listing
                </Typography>
                <IconButton
                  onClick={handleClose}
                  sx={{ borderRadius: '8px', fontSize: '24px', p: 0 }}
                  size="small"
                >
                  <CloseIcon
                    fontSize="small"
                    sx={{
                      color: theme.palette.icon.tertiary,
                    }}
                  />
                </IconButton>
              </Stack>
            </DialogTitle>
          </Toolbar>
        </AppBar>
      ) : (
        <DialogTitle>
          <Stack direction="row" alignItems="center" justifyContent="space-between">
            <Typography fontSize={24} fontWeight={500} lineHeight="32px" color="text.main">
              Report this listing
            </Typography>
            <IconButton
              onClick={handleClose}
              size="small"
              sx={{ borderRadius: '8px', p: 0 }}
              disableRipple
            >
              <CloseIcon
                fontSize="small"
                sx={{
                  color: theme.palette.icon.tertiary,
                }}
              />
            </IconButton>
          </Stack>
        </DialogTitle>
      )}
      <DialogContent>
        <Label id="category-field" sx={{ mb: -2 }}>
          I think this item is...
        </Label>

        <FormControl fullWidth id="category-field" sx={{ pt: '4px' }}>
          {reason === '' && (
            <SortLabel id="select-reason-label" shrink={false}>
              Select a reason
            </SortLabel>
          )}
          <Select
            IconComponent={ExpandMore}
            value={reason}
            labelId="select-reason-label"
            notched={false}
            MenuProps={{
              PaperProps: {
                sx: {
                  boxShadow: 2,
                  '& .MuiList-root': {
                    padding: 0,
                    borderRadius: '4px',
                  },
                },
              },
            }}
            open={selectOptionsOpen}
            onOpen={() => {
              if (isExtraSmallScreen) {
                setMobileOptionsOpen(true);
              } else {
                setSelectOptionsOpen(true);
              }
            }}
            onClose={() => {
              setMobileOptionsOpen(false);
              setSelectOptionsOpen(false);
            }}
            onChange={(e) => {
              setReason(e.target.value);
            }}
          >
            {REPORT_OPTIONS.map(({ value, label }, index) => (
              <MenuItem
                key={value}
                value={value}
                sx={{ fontWeight: 600, fontSize: '16px', lineHeight: '24px' }}
                divider={index < REPORT_OPTIONS.length - 1}
              >
                {label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        {isExtraSmallScreen && (
          <ReasonsMobileView
            open={mobileOptionsOpen}
            setOpen={setMobileOptionsOpen}
            setValue={setReason}
          />
        )}
        {reason === 0 ? (
          <FakeDetails collection={collection} setCollection={setCollection} />
        ) : reason === 3 ? (
          <StolenDetails />
        ) : (
          reason === 4 && <OtherDetails />
        )}
      </DialogContent>
      <DialogActions disableSpacing>
        <Button
          variant="contained"
          onClick={submit}
          disabled={notReadyForSumbit}
          sx={{ boxShadow: 'none' }}
          disableRipple
        >
          Report
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ReportDialog;
