import React from 'react';
import { useStateIfMounted } from 'use-state-if-mounted';
import { Typography, InputAdornment, Button, Stack, styled, useTheme } from '@mui/material';

import { formatNumber } from 'utils/numbers';

import { CurrencyIcon } from 'components/Currency';
import { PriceInput } from '../../components/styledComponents';

const Content = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: 'inherit',
  padding: theme.spacing(1.2, 0),
}));

const ContentHeading = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
}));

const SubHeading = styled(Typography)(({ theme, color }) => ({
  fontSize: 14,
  fontWeight: 500,
  color: color ?? theme.palette.text.tertiary,
  margin: '0px',
  lineHeight: '20px',
  letterSpacing: '0.1px',
  padding: theme.spacing(1.2, 0),
}));

const StyledButton = styled(Button)(({ theme }) => ({
  color: theme.palette.link.primary,
  fontSize: 14,
  fontWeight: 500,
  lineHeight: '20px',
  letterSpacing: '0.25px',
}));

/********************  Main Component  ********************/
const WnrgDetails = ({
  data,
  ethBalance,
  wethBalance,
  lastPrice,
  setModal,
  bidAmount,
  onBidAmountChanged,
  setError,
  error,
}) => {
  const theme = useTheme();

  const [usdAmount, setUsdAmount] = useStateIfMounted('0.00');

  const { metadata } = data;

  const handleAmountChange = (value) => {
    const usd = value !== '' ? formatNumber(parseFloat(value) * parseFloat(lastPrice)) : '0.00';
    setError(Number(value) > Number(wethBalance) ? 'Insufficient WNRG balance' : '');
    setUsdAmount(usd);
    onBidAmountChanged(value);
  };

  return (
    <>
      <Typography fontSize={14} width="100%" marginBottom="20px" fontWeight={500}>
        Make your offer for{' '}
        <Typography
          variant="span"
          fontSize={14}
          color={theme.palette.link.primary}
          fontWeight={500}
        >
          {metadata?.name || `Item name #${data.tokenId}`}
        </Typography>{' '}
        from{' '}
        <Typography
          variant="span"
          fontSize={14}
          color={theme.palette.link.primary}
          fontWeight={500}
        >
          Energi Studios{' '}
        </Typography>
        collection.
      </Typography>
      <PriceInput
        id="outlined-adornment-amount"
        fullWidth
        autoComplete="off"
        type={'number'}
        onKeyDown={(e) => {
          if (e.key === 'e' || e.key === 'E') {
            e.preventDefault();
          }
        }}
        onPaste={(e) => {
          const paste = (e.clipboardData || window.clipboardData).getData('text');
          if (paste.includes('e') || paste.includes('E')) {
            e.preventDefault();
          }
        }}
        value={bidAmount}
        onChange={(event) => {
          handleAmountChange(event?.currentTarget?.value ?? '0');
        }}
        placeholder="0"
        fontSize="24px"
        startAdornment={
          <InputAdornment position="start">
            <CurrencyIcon currency="NRG" type="default" />
            <Typography variant="span" marginLeft="8px" color={theme.palette.text.main}>
              WNRG
            </Typography>
          </InputAdornment>
        }
        sx={{
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: error ? theme.palette.text.error : '',
          },
        }}
        endAdornment={<InputAdornment position="end">{`~$${usdAmount}`}</InputAdornment>}
      />
      {error && (
        <Typography color={theme.palette.text.error} fontSize={12} fontWeight={400} marginTop="8px">
          {error}
        </Typography>
      )}
      <Content>
        <ContentHeading>
          <SubHeading>WNRG Balance</SubHeading>
          <SubHeading>{wethBalance ?? 0} WNRG</SubHeading>
        </ContentHeading>
        <ContentHeading>
          <SubHeading>NRG balance</SubHeading>
          <SubHeading>{ethBalance ?? 0} NRG</SubHeading>
        </ContentHeading>
        <ContentHeading>
          <Stack direction="row" spacing={2}>
            <StyledButton onClick={() => setModal('convert_currency')} disableRipple>
              Convert NRG/WNRG
            </StyledButton>
            <StyledButton onClick={() => setModal('add_funds')} disableRipple>
              Add funds
            </StyledButton>
          </Stack>
        </ContentHeading>
      </Content>
    </>
  );
};

export default WnrgDetails;
