import { styled } from '@mui/material';

const Divider = styled('div')(({ theme }) => ({
  height: '1px',
  width: '100%',
  backgroundColor: theme.palette.border.separator,
  margin: '0px',
  marginBottom: 64,
  marginTop: 64,

  '@media (max-width: 600px)': {
    marginBottom: 48,
    marginTop: 48,
  },

  '@media (max-width: 420px)': {
    marginBottom: 32,
    marginTop: 32,
  },
}));

export default Divider;
