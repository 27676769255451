/* eslint-disable react/no-unescaped-entities */
import * as React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import {
  Dialog,
  DialogTitle,
  TextField,
  DialogContent,
  DialogActions,
  IconButton,
  Typography,
} from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
import { useStateIfMounted } from 'use-state-if-mounted';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  textAlign: 'center',

  '& .MuiBackdrop-root': {
    backgroundColor: 'rgba(4, 17, 29, 0.8)',
    opacity: 0.8,
  },
  '& .MuiDialog-paper': {
    maxWidth: '730px',
    height: 'auto',
    width: '100%',
    margin: '24px',
    '@media (max-width: 550px)': {
      maxHeight: '90vh',
      margin: '0',
      bottom: '0',
      position: 'absolute',
    },
  },
  '& .MuiDialogContent-root': {
    padding: theme.spacing(0, 8),
    backgroundColor: theme.palette.background.secondary,
    '@media (max-width: 550px)': {
      padding: theme.spacing(0, 4),
    },
  },
  '& .MuiDialogActions-root': {
    backgroundColor: theme.palette.background.secondary,
    justifyContent: 'center',
    padding: theme.spacing(3, 2),
  },
}));

const ConfirmInput = styled(TextField)(({ theme }) => ({
  width: 285,
  marginBottom: theme.spacing(3),
  borderRadius: 8,

  background:
    theme.palette.mode === 'dark' ? theme.palette.darkBg : theme.palette.background.default,
  '& p': {
    marginLeft: 0,
  },

  '& div': {
    borderRadius: 8,
  },

  '& .MuiOutlinedInput-input': {
    boxSizing: 'border-box',
    padding: theme.spacing(1.5),
    height: 48,
    fontSize: 14,
    fontWeight: 500,
  },

  '@media(max-width: 600px)': {
    marginTop: 10,
  },
}));

const TypographyContent = styled(Typography)(({ theme }) => ({
  fontSize: 16,
  fontWeight: 400,
  marginBottom: theme.spacing(3.5),
  '& b': {
    fontWeight: 600,
  },
}));

const ModalDialogTitle = styled(DialogTitle)(({ theme }) => ({
  margin: 0,
  padding: theme.spacing(2),
  color: theme.palette.text.main,
  fontWeight: 600,
  fontSize: 20,
  backgroundColor: theme.palette.background.secondary,
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <ModalDialogTitle {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            padding: '14px',
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </ModalDialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function LockConfirmationModal({
  isOpen,
  onDismiss,
  title,
  icon,
  content,
  confirmButton,
}) {
  const [text, setText] = useStateIfMounted('');

  const handleChange = (event) => {
    setText(event.target.value);
  };

  return (
    <BootstrapDialog onClose={onDismiss} aria-labelledby="customized-dialog-title" open={isOpen}>
      <BootstrapDialogTitle id="customized-dialog-title" onClose={onDismiss}>
        {title}
      </BootstrapDialogTitle>
      <DialogContent dividers>
        {icon ? icon() : ''}
        <TypographyContent gutterBottom>{content}</TypographyContent>
        <TypographyContent gutterBottom>
          Type <b>"lock my account”</b> to confirm
        </TypographyContent>
        <ConfirmInput
          variant="outlined"
          placeholder={`Type "lock my account"`}
          value={text}
          onChange={handleChange}
        />
      </DialogContent>
      <DialogActions
        sx={{
          padding: '20px 16px',
        }}
      >
        {confirmButton(text)}
      </DialogActions>
    </BootstrapDialog>
  );
}
