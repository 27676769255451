import { createContext, useContext, useEffect } from 'react';
import useOfferSettings from 'hooks/useOfferSettings';
import useNotificationSettings from 'hooks/useNotificationSettings';

const SettingsContext = createContext();
export const useSettingsContext = () => useContext(SettingsContext);

export const SettingsProvider = ({ children }) => {
  const { formData, isLoading, updateUserSettings } = useNotificationSettings();
  const { offersLoading, ownedCollections } = useOfferSettings();

  useEffect(() => {}, []);

  return (
    <SettingsContext.Provider
      value={{ formData, isLoading, updateUserSettings, offersLoading, ownedCollections }}
    >
      {children}
    </SettingsContext.Provider>
  );
};
