import React, { useEffect } from 'react';
import { useStateIfMounted } from 'use-state-if-mounted';
import { styled, useTheme } from '@mui/material/styles';
import { TextField, Typography, Stack, CircularProgress, useMediaQuery } from '@mui/material';
import { Box } from '@mui/system';
import { shorten } from 'utils/helper';
import { Form, Checkbox } from 'components/FormElements';
import { FlexBox } from 'components/StyledComponents';
import useCompareValues from 'hooks/useCompareValues';
import { EthBlueIcon } from 'components/ImageComponent';
import useWalletContext from 'hooks/useWalletContext';
import { useSettingsContext } from 'context/SettingsContext';

/********************  Styled Components  ********************/
const StackContainer = styled(Stack)(({ theme }) => ({
  background: theme.palette.background.default,
  padding: theme.spacing(0, 4),
  paddingBottom: theme.spacing(10),

  '@media (max-width: 600px)': {
    padding: theme.spacing(0, 2, 8),
    paddingTop: theme.spacing(3),
  },
}));

const Panel = styled('div')(({ theme }) => ({
  border: `1px solid ${theme.palette.border.decorative}`,
  marginTop: theme.spacing(2),
  borderRadius: 8,
  overflow: 'hidden',
  maxWidth: 850,
  padding: 0,
}));

const Row = styled('div')(({ theme }) => ({
  borderBottom: `1px solid ${theme.palette.border.light}`,
  padding: theme.spacing(2.5, 2),
  display: 'flex',
  backgroundColor: theme.palette.background.secondary,
  '&:last-child': {
    borderBottom: 'none',
  },
}));

const Heading = styled('p')(({ theme }) => ({
  fontSize: 42,
  color: theme.palette.text.main,
  fontWeight: 500,
  padding: 0,
  margin: 0,
  paddingBottom: theme.spacing(3),
  '@media (max-width: 500px)': {
    fontSize: 24,
  },
}));

const MinThresholdInput = styled('div')(({ theme }) => ({
  display: 'flex',
  border: `1px solid ${theme.palette.border.decorative}`,
  borderRadius: 6,
  overflow: 'hidden',
  width: 617,
  marginBottom: theme.spacing(4),
  '& > div:first-of-type': {
    width: 97,
    backgroundColor: theme.palette.background.light,
    display: 'flex',
    justifyContent: 'center',
  },
  '& > div:last-of-type': {
    flex: 1,
    borderLeft: `1px solid ${theme.palette.border.decorative}`,
  },

  '@media (max-width: 600px)': {
    width: '100%',
  },
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
  border: 'none',
  height: 48,
  input: {
    color: theme.palette.text.secondary,
    width: 490,
    border: 'none',
    fontSize: 16,
    paddingTop: theme.spacing(1.5),
  },
  fieldset: {
    border: 'none',
  },
}));

/********************  Main Component  ********************/
const Notifications = () => {
  const theme = useTheme();
  const { address } = useWalletContext();
  const { formData, isLoading, updateUserSettings } = useSettingsContext();
  const below600 = useMediaQuery('(max-width: 600px)');
  const [currentData, setCurrentData] = useStateIfMounted({ ...formData });

  useEffect(() => {
    setCurrentData({ ...formData });
  }, [formData]);

  const onValueChange = (field, value, deep) => {
    setCurrentData((prev) => {
      return deep === 0
        ? { ...prev, [field]: value }
        : {
            ...prev,
            inputs: prev.inputs.map((item) => {
              if (item.name === field) {
                return {
                  ...item,
                  checked: value,
                };
              } else {
                return { ...item };
              }
            }),
          };
    });
  };

  return (
    <StackContainer>
      {!below600 && <Heading>Notification Settings</Heading>}
      <Typography
        fontSize={16}
        fontWeight={600}
        color={theme.palette.text.secondary}
        paddingBottom={theme.spacing(4)}
      >
        Select which notifications you would like to receive for {shorten(address ?? '', 6)}
      </Typography>
      <Form
        submitButtonText={
          isLoading ? (
            <Box sx={{ lineHeight: 0 }}>
              <CircularProgress sx={{ p: 1, color: 'white' }} />
            </Box>
          ) : (
            'Save'
          )
        }
        disabled={useCompareValues(currentData, formData)}
        onSubmit={(e) => updateUserSettings(e)}
      >
        <Panel>
          {currentData.inputs.map((checkbox, index) => (
            <Row key={index}>
              <Box paddingTop={0.5}>
                <Checkbox
                  name={checkbox.name}
                  checked={checkbox.checked}
                  value={currentData[checkbox.name]}
                  onChange={(value) => onValueChange(checkbox.name, value, 1)}
                />
              </Box>
              <Box marginLeft={2}>
                <Typography
                  fontSize={16}
                  fontWeight={600}
                  color={theme.palette.text.main}
                  paddingBottom={theme.spacing(1)}
                >
                  {checkbox.title}
                </Typography>
                <Typography fontSize={16} fontWeight={400} color={theme.palette.text.secondary}>
                  {checkbox.description}
                </Typography>
              </Box>
            </Row>
          ))}
        </Panel>
        <Typography
          fontSize={16}
          fontWeight={600}
          color={theme.palette.text.main}
          marginBottom={1}
          marginTop={3.125}
        >
          Minimum Bid Threshold
        </Typography>
        <Typography
          fontSize={12}
          fontWeight={500}
          color={theme.palette.text.secondary}
          marginBottom={2}
        >
          Receive notifications only when you receive offers with a value greater than or equal to
          this amount of ETH.
        </Typography>
        <MinThresholdInput>
          <Box sx={{ padding: '8px' }}>
            <FlexBox>
              <EthBlueIcon width={24} height={24} />
              <Typography
                fontSize={14}
                fontWeight={500}
                color={theme.palette.text.main}
                marginLeft={1.5}
              >
                ETH
              </Typography>
            </FlexBox>
          </Box>
          <Box>
            <StyledTextField
              name="minimum_bid_threshold"
              value={currentData.minimum_bid_threshold}
              onChange={(e) => onValueChange('minimum_bid_threshold', e.target.value, 0)}
            />
          </Box>
        </MinThresholdInput>
      </Form>
    </StackContainer>
  );
};

export default Notifications;
