import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useStateIfMounted } from 'use-state-if-mounted';
import { Typography, useTheme, Stack, useMediaQuery } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Footer, LoadingBar } from 'components/General';
import { GeneralFlexBox } from 'components/StyledComponents';
import { GrayButtonOutlined, StandardButton } from 'components/Button';
import { DEFAULT_TIMEFRAME, RESOURCE_MENU } from 'constants/index';
import { TimeFrameDropdown, TitleWithDropDownButton } from 'components/TimeFrameDropdown';
import CollectionCard from './CollectionCard';
import { NotFound404Icon } from 'components/ImageComponent';
import collectionsService from 'services/stat';

/********************  Styled Components  ********************/
const LayoutContainer = styled('div')(() => ({
  width: '100%',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  maxWidth: '1440px',
  margin: '0 auto',
}));

const Title = styled(Typography)(({ theme }) => ({
  fontSize: 20,
  fontWeight: 600,
  color: theme.palette.text.main,
  margin: '32px 0',
  padding: '0 55px',
  textAlign: 'center',

  '@media(max-width: 480px)': {
    fontWeight: 500,
    fontSize: 16,
    lineHeight: '24px',
  },
}));

const CardWrapper = styled(GeneralFlexBox)(() => ({
  justifyContent: 'space-between',
  width: '100%',
  gap: 26,
  padding: '0 49px',
  marginTop: '-24px',
  marginBottom: '50px',

  '@media(max-width: 1280px)': {
    padding: '0 33px',
  },
  '@media(max-width: 905px)': {
    padding: '0 25px',
    gap: 18,
  },
  '@media(max-width: 600px)': {
    marginTop: '16px',
  },
  '@media(max-width: 595px)': {
    flexDirection: 'column',
  },
  '@media(max-width: 480px)': {
    padding: '0 17px',
  },
}));

/********************  Main Component  ********************/
const Notfound = () => {
  const theme = useTheme();
  const below885 = useMediaQuery('(max-width: 885px)');
  const below595 = useMediaQuery('(max-width: 595px)');

  const [topCollections, setTopCollections] = useStateIfMounted();
  const [selectedTimeFrame, setSelectedTimeFrame] = useStateIfMounted(DEFAULT_TIMEFRAME);
  const [timeFrameModalOpen, setTimeFrameModalOpen] = useStateIfMounted(false);
  const open = Boolean(timeFrameModalOpen);

  useEffect(() => {
    (async () => {
      const response = await collectionsService.getTopCollections({
        limit: 3,
      });
      setTopCollections(response);
    })();
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <LayoutContainer>
        <GeneralFlexBox direction="column" padding="208px 0 209px 0" align="center">
          <NotFound404Icon fill={theme.palette.text.main} />
          <Title>We can’t find the page that you’re looking for</Title>

          <Stack direction="row" spacing={2}>
            <Link to="/" style={{ textDecoration: 'none' }}>
              <StandardButton width={147} height={48}>
                Take me home
              </StandardButton>
            </Link>

            <a
              href={RESOURCE_MENU.find((link) => link.text === 'Help Center')?.path}
              target="_blank"
              rel="noopener noreferrer"
              style={{ textDecoration: 'none' }}
            >
              <GrayButtonOutlined width={119} height={48}>
                I need help
              </GrayButtonOutlined>
            </a>
          </Stack>
        </GeneralFlexBox>

        <TitleWithDropDownButton
          isOpen={timeFrameModalOpen}
          openDropDown={(e) => setTimeFrameModalOpen(e.currentTarget)}
          selectedTimeFrame={selectedTimeFrame}
          height={50}
          fontSize={24}
          fontWeight={500}
        >
          Top Collections in
        </TitleWithDropDownButton>

        <TimeFrameDropdown
          isOpen={open}
          anchorEl={timeFrameModalOpen}
          openDropDown={(e) => setTimeFrameModalOpen(e.currentTarget)}
          closeDropDown={() => setTimeFrameModalOpen(false)}
          selectTimeFrame={setSelectedTimeFrame}
        />

        <CardWrapper>
          {topCollections ? (
            <>
              {topCollections.data.slice(0, below595 ? 3 : below885 ? 2 : 3).map((item, index) => (
                <CollectionCard
                  key={index}
                  name={item.name}
                  creator={item.creator}
                  description={item.description}
                />
              ))}
            </>
          ) : (
            <LoadingBar />
          )}
        </CardWrapper>
      </LayoutContainer>
      <Footer />
    </>
  );
};

export default Notfound;
