import React from 'react';
import { useStateIfMounted } from 'use-state-if-mounted';

import { Button, Typography, Modal, IconButton } from '@mui/material';
import {
  InfoOutlined as InfoOutlinedIcon,
  Close as CloseIcon,
  InsertChart as InsertChartIcon,
  ArrowBack as ArrowBackIcon,
} from '@mui/icons-material';
import { styled, useTheme } from '@mui/material/styles';
import {
  InfoLink,
  InfoTooltip,
  ModalCloseButton,
  ModalContainer,
  ModalRow,
  VerticalFlexBox,
} from 'components/StyledComponents';
import AddressBox from '../AddressBox';

/********************  Styled Components  ********************/
const DepositButton = styled(Button)(({ theme }) => ({
  border: `1px solid ${theme.palette.border.separator}`,
  padding: theme.spacing(1.2, 2.5),
  marginTop: theme.spacing(2),
  width: 220,
  color: theme.palette.text.subtitle,
  fontWeight: 600,
  lineHeight: 1.25,
}));

const ModalBackButton = styled(IconButton)(() => ({
  position: 'absolute',
  left: 20,
  transform: 'translateY(-5px)',
}));

const DepositedButton = styled(Button)(({ theme }) => ({
  background: theme.palette.primary.main,
  color: theme.palette.text.white,
  fontSize: 16,
  fontWeight: 600,
  padding: theme.spacing(1.5, 4),
  borderRadius: 10,
}));

const ModalHeading = styled(Typography)(({ theme }) => ({
  '@media (max-width: 480px)': {
    fontSize: 14,
    paddingTop: theme.spacing(0.5),
  },
}));

/********************  Main Component  ********************/
const AddFundModal = ({ open, closeModal, address }) => {
  const theme = useTheme();
  const [depositModalLevel, setDepositModalLevel] = useStateIfMounted(1);

  return (
    <Modal open={open} onClose={closeModal} sx={{ zIndex: 1500 }}>
      <ModalContainer width={700}>
        {depositModalLevel === 1 ? (
          <>
            <ModalRow centered="true">
              <ModalHeading fontSize={20} fontWeight={600}>
                Add NRG to your wallet
              </ModalHeading>
              <ModalCloseButton onClick={closeModal}>
                <CloseIcon />
              </ModalCloseButton>
            </ModalRow>
            <ModalRow padding={5} noborder="true">
              <VerticalFlexBox>
                <Typography fontSize={14} fontWeight={600}>
                  Select one of the options to deposit NRG to your wallet
                </Typography>
                <DepositButton
                  color="secondary"
                  startIcon={<InsertChartIcon />}
                  onClick={() => setDepositModalLevel(2)}
                  disableRipple
                >
                  Deposit from an exchange
                </DepositButton>
              </VerticalFlexBox>
            </ModalRow>
          </>
        ) : (
          <>
            <ModalRow centered="true">
              <ModalHeading fontSize={20} fontWeight={600}>
                Deposit NRG from your exchange
              </ModalHeading>
              <ModalCloseButton onClick={closeModal}>
                <CloseIcon />
              </ModalCloseButton>
              <ModalBackButton onClick={() => setDepositModalLevel(1)}>
                <ArrowBackIcon />
              </ModalBackButton>
            </ModalRow>
            <ModalRow padding={5}>
              <VerticalFlexBox>
                <Typography
                  fontSize={14}
                  fontWeight={600}
                  textAlign="justify"
                  color={theme.palette.text.medium}
                >
                  Deposit NRG from an {` `}
                  <InfoTooltip
                    arrow
                    title={
                      <React.Fragment>
                        <Typography
                          fontSize={15}
                          fontWeight={600}
                          textAlign="center"
                          lineHeight={1.3}
                        >
                          An exchange allows individuals to trade cryptocurrencies. Compatible
                          crypto exchanges include{' '}
                          <InfoLink to={{ pathname: 'https://www.coinex.com/' }} target="_blank">
                            Coinex
                          </InfoLink>
                          ,{` `}
                          <InfoLink to={{ pathname: 'https://www.kucoin.com/' }} target="_blank">
                            KuCoin
                          </InfoLink>{' '}
                          and many other exchanges.
                          {` `}
                        </Typography>
                      </React.Fragment>
                    }
                  >
                    <InfoLink to="/">
                      exchange <InfoOutlinedIcon></InfoOutlinedIcon>
                    </InfoLink>
                  </InfoTooltip>
                  {` `}
                  or another wallet to the following address:
                </Typography>
                <AddressBox address={address} />
                <Typography fontSize={14} fontWeight={600} color={theme.palette.text.medium}>
                  Only send NRG to this address.
                </Typography>
              </VerticalFlexBox>
            </ModalRow>
            <ModalRow centered="true" noborder="true">
              <DepositedButton variant="contained" onClick={closeModal} disableRipple>
                I’ve made my deposit
              </DepositedButton>
            </ModalRow>
          </>
        )}
      </ModalContainer>
    </Modal>
  );
};

export default AddFundModal;
