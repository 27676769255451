import { WagmiConfig, configureChains, createConfig } from 'wagmi';
import { InjectedConnector } from 'wagmi/connectors/injected';
import { goerli, mainnet } from '@wagmi/core/chains';
import { jsonRpcProvider } from 'wagmi/providers/jsonRpc';
import { ReactHooksWrapper, setHook } from 'react-hooks-outside';
import {
  BalanceProvider,
  BookmarkWarningProvider,
  TokenProvider,
  WalletProvider,
  UserProvider,
  WindowProvider,
} from 'context';
import Root from './Root';
import useTokenContext from 'hooks/useTokenContext';
import useWalletContext from 'hooks/useWalletContext';
import { PriceProvider } from 'context/PriceContext';
import { ENERGI_MAINNET, ENERGI_TESTNET, NETWORK_RPC_URLS } from 'constants/chains';
import { APP_VERSION, CHAIN_ID } from 'constants';
import { UserCollectionsProvider } from 'context/UserCollectionsContext';

setHook('tokenContext', useTokenContext);
setHook('walletContext', useWalletContext);

const getNetwork = (id) => {
  switch (id) {
    case 1:
      return mainnet;
    case 5:
      return goerli;
    case 39797:
      return ENERGI_MAINNET;
    case 49797:
      return ENERGI_TESTNET;
    default:
      return;
  }
};

const { publicClient, webSocketPublicClient, chains } = configureChains(
  [getNetwork(CHAIN_ID)],
  [
    jsonRpcProvider({
      rpc: (chain) => ({
        http: NETWORK_RPC_URLS[chain.id],
      }),
      stallTimeout: 1_000,
      priority: 0,
    }),
  ],
);

const wagmiClient = createConfig({
  autoConnect: true,
  webSocketPublicClient,
  publicClient,
  connectors: [
    new InjectedConnector({
      chains,
      options: {
        name: 'Injected',
        shimDisconnect: true,
      },
    }),
  ],
});

console.log(
  `%cGMI ${APP_VERSION}`,
  'color:white; font-size: 30px; border: 1rem ridge #00AB58 ; border-radius: 7px; padding: 10px; margin: 20px; -webkit-text-stroke: 1px #00AB58;',
);

function App() {
  return (
    <WagmiConfig config={wagmiClient}>
      <WalletProvider>
        <PriceProvider>
          <TokenProvider>
            <WindowProvider>
              <BalanceProvider>
                <UserProvider>
                  <UserCollectionsProvider>
                    <BookmarkWarningProvider>
                      <Root />
                      <ReactHooksWrapper />
                    </BookmarkWarningProvider>
                  </UserCollectionsProvider>
                </UserProvider>
              </BalanceProvider>
            </WindowProvider>
          </TokenProvider>
        </PriceProvider>
      </WalletProvider>
    </WagmiConfig>
  );
}

export default App;
