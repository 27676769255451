import { useContext } from 'react';
import { TokenContext } from 'context';

const useTokenContext = () => {
  const context = useContext(TokenContext);

  if (!context) {
    throw new Error('Token context must be use inside provider');
  }

  return context;
};

export default useTokenContext;
