import { createContext, useCallback, useContext, useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useStateIfMounted } from 'use-state-if-mounted';

import orderbookService from 'services/orderbook';
import userService from 'services/user';

import useFormatOfferResponse from 'hooks/useFormatOfferResponse';
import useWalletContext from 'hooks/useWalletContext';

const filterStatusOpen = [
  { name: 'Price', active: false },
  { name: 'Collections', active: false },
];

const initialPriceStatus = {
  min: '',
  max: '',
  apply: false,
};

const OffersFilterContext = createContext();
export const useOffersFilterContext = () => useContext(OffersFilterContext);

/********************  Context Provider ********************/
export const OffersFilterProvider = ({ children }) => {
  const { address } = useWalletContext();
  const { id: addressFromUrl } = useParams();

  const [offersReceived, setOffersReceived] = useStateIfMounted([]);
  const [offersMade, setOffersMade] = useStateIfMounted({ collection: [], nft: [] });
  const [loading, setLoading] = useStateIfMounted(true);
  const [filterOpen, setFilterOpen] = useStateIfMounted(filterStatusOpen);
  const [applyPrice, setApplyPrice] = useStateIfMounted(initialPriceStatus);
  const [collections, setCollections] = useStateIfMounted([]);
  const formatOfferResponse = useFormatOfferResponse();

  const filteredOffers = useMemo(() => {
    const filteredPriceMade = filterByPrice(offersMade.nft, applyPrice);
    const filteredPriceReceived = filterByPrice(offersReceived, applyPrice);

    return {
      received: filteredPriceReceived,
      made: {
        collection: [],
        nft: filteredPriceMade,
      },
    };
  }, [offersReceived, offersMade, applyPrice]);

  useEffect(() => {
    if (addressFromUrl || address) {
      getUserOffers().finally(() => setLoading(false));
    }
  }, [addressFromUrl, address]);

  const getUserOffers = async () => {
    await getOffersMade();
    await getOffersReceived();
  };

  const getOffersMade = useCallback(async () => {
    const response = await orderbookService.getOffersMade({
      makerAddress: addressFromUrl || address,
    });
    if (Boolean(response?.length)) {
      const formattedOffers = await formatOfferResponse(response);
      setOffersMade({ ...offersMade, nft: formattedOffers.filter((offer) => !offer.isListing) });
    }
  }, [addressFromUrl, formatOfferResponse, setOffersMade]);

  const getOffersReceived = useCallback(async () => {
    const response = await userService.getReceivedOffers({ address: addressFromUrl || address });
    const formattedOffers = await formatOfferResponse(response?.openOffers);
    setOffersReceived(formattedOffers);
  }, [addressFromUrl, formatOfferResponse, setOffersReceived]);

  const clearFilters = () => {
    setOffersMade(offersMade);
    setOffersReceived(offersReceived);
    setApplyPrice(initialPriceStatus);
    setCollections(collections.map((x) => ({ ...x, active: false, show: true })));
  };

  const handleOpenFilter = (filterName) => {
    const newStatus = filterOpen.map((x) => {
      return x.name === filterName ? { ...x, active: !x.active } : x;
    });
    setFilterOpen(newStatus);
  };

  const removeByTokenId = (id, contract, offers) =>
    offers.filter(({ NftToken, NftContract }) => !(NftToken === id && NftContract === contract));

  const removeOffer = (id, contract) => {
    // TODO: Implement offersReceived
    // setOffersReceived(removeByTokenId(id, offersReceived));
    setOffersMade({ ...offersMade, nft: removeByTokenId(id, contract, offersMade.nft) });
  };

  return (
    <OffersFilterContext.Provider
      value={{
        loading,
        filteredOffers,
        filterOpen,
        handleOpenFilter,
        clearFilters,
        collections,
        addCollections: setCollections,
        applyPrice,
        addApplyPrice: setApplyPrice,
        statusFilterList: [],
        addStatus: () => {},
        filteredOnSaleList: {},
        addFilter: () => {},
        removeOffer,
      }}
    >
      {children}
    </OffersFilterContext.Provider>
  );
};

/********************  Helper function  ********************/
const filterByPrice = (offers, applyPrice) =>
  (offers || []).filter((offer) => {
    if (applyPrice.min && !applyPrice.max) {
      return offer.price >= Number(applyPrice.min);
    }
    if (applyPrice.max && !applyPrice.min) {
      return offer.price <= Number(applyPrice.max);
    }
    if (applyPrice.max && applyPrice.min) {
      return offer.price >= Number(applyPrice.min) && offer.price <= Number(applyPrice.max);
    }
    return offer;
  });
