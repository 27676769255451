import React from 'react';
import { useStateIfMounted } from 'use-state-if-mounted';
import {
  Stack,
  Accordion as MuiAccordion,
  AccordionSummary as MuiAccordionSummary,
  AccordionDetails as MuiAccordionDetails,
  Button as MuiButton,
  Typography,
  Checkbox,
  GlobalStyles,
  FormControlLabel,
  useMediaQuery,
  styled,
  useTheme,
} from '@mui/material';
import { ExpandMore as ExpandMoreIcon } from '@mui/icons-material';

import userService from 'services/user';

import {
  ACCOUNT_SUPPORT_ACCORDIONS,
  COMPROMISED_OPTIONS,
  SOMETHING_WENT_WRONG,
} from 'constants/index';
import useNotification from 'hooks/useNotification';

import { FlexBox, HeaderText, SubTitleText } from 'components/StyledComponents';
import LockConfirmationModal from 'components/LockConfirmationModal';
import { ButtonOutlined } from 'components/Button';
import { LockLightIcon, LockDarkIcon } from 'components/ImageComponent';
import ResponsiveSelect from 'components/FormElements/ResponsiveSelect';

/********************  Styled Components  ********************/
const StackContainer = styled(Stack)(({ theme }) => ({
  background: theme.palette.background.default,
  padding: theme.spacing(0, 15),
  '@media (max-width: 1024px)': {
    padding: theme.spacing(0, 3),
  },
  '@media (max-width: 480px)': {
    padding: theme.spacing(0, 2),
  },
}));

const AccordionWrapper = styled('div')(({ theme }) => ({
  width: 600,
  marginTop: theme.spacing(4),
  borderTop: `1px solid ${theme.palette.border.decorative}`,
  borderBottom: `1px solid ${theme.palette.border.decorative}`,
  '@media (max-width: 768px)': {
    width: '100%',
  },
}));

const Accordion = styled(MuiAccordion)(({ theme }) => ({
  '&:before': {
    backgroundColor: theme.palette.border.decorative,
  },
  '&.Mui-expanded': {
    borderTop: `1px solid ${theme.palette.border.decorative}`,
    '&:before': {
      opacity: 1,
    },
  },
}));

const AccordionSummary = styled(MuiAccordionSummary)(({ theme }) => ({
  padding: theme.spacing(0.5, 1),
  backgroundColor: theme.palette.background.default,
}));

const AccordionSummaryText = styled(Typography)(({ theme }) => ({
  fontSize: 16,
  fontWeight: 600,
  color: theme.palette.text.main,
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2, 1),
  paddingBottom: theme.spacing(5),
  backgroundColor: theme.palette.background.default,
}));

const AccordionDetailText = styled(Typography)(({ theme }) => ({
  fontSize: 14,
  fontWeight: 400,
  lineHeight: '18px',
  color: theme.palette.text.secondary,
  '& a': {
    color:
      theme.palette.mode === 'dark' ? theme.palette.button.tertiary : theme.palette.button.primary,
    fontSize: 14,
    lineHeight: '26px',
    textDecoration: 'none',
  },
}));

const Button = styled(MuiButton)(({ theme }) => ({
  height: 44,
  width: 150,
  fontSize: 16,
  lineHeight: '21px',
  fontWeight: 600,
  marginTop: theme.spacing(2),
  color: theme.palette.text.error,
  border: `1px solid ${theme.palette.text.error}`,
  '&:disabled': {
    opacity: 0.24,
    color: theme.palette.text.error,
    border: `1px solid ${theme.palette.text.error}`,
  },

  '&:hover': {
    borderColor: theme.palette.text.error,
    background: 'linear-gradient(0deg, rgba(4, 17, 29, 0.08), rgba(4, 17, 29, 0.08))',
    boxShadow:
      '0px 0px 2px rgba(0, 0, 0, 0.14), 0px 2px 2px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2)',
  },
  '&:active': {
    background: 'linear-gradient(0deg, rgba(4, 17, 29, 0.12), rgba(4, 17, 29, 0.12))',
    boxShadow: 'none',
  },
  '.MuiTouchRipple-child': {
    background:
      'linear-gradient(0deg, rgba(255, 255, 255, 0.12), rgba(255, 255, 255, 0.12)), #141619',
  },
}));

export const StyledControlLabel = styled(FormControlLabel)(({ theme }) => ({
  borderBottom: `1px solid ${theme.palette.border.separator}`,
  padding: '8px 0',
  '.MuiFormControlLabel-label': {
    fontWeight: 600,
    fontSize: 16,
  },
}));

const CheckBoxLabel = styled(Typography)(({ theme }) => ({
  fontSize: 12,
  fontWeight: 500,
  lineHeight: '18px',
  color: theme.palette.text.subtitle,
  '@media(min-width: 500px)': {
    width: 369,
  },

  '& a': {
    color:
      theme.palette.mode === 'dark' ? theme.palette.button.tertiary : theme.palette.button.primary,
    textDecoration: 'none',
  },
}));

const WrapperIcon = styled('div')(({ theme }) => ({
  '& svg': {
    width: '16vh',
  },
  margin: theme.spacing(4, 0),
  marginBottom: theme.spacing(6),
  '@media(max-width: 550px)': {
    margin: theme.spacing(1, 0),
  },
}));

const CustomizedButtonOutlined = styled(ButtonOutlined)(({ theme }) => ({
  fontSize: 16,
  lineHeight: '24px',
  fontWeight: 600,
  borderRadius: '8px',
  '&:disabled': {
    color: theme.palette.text.error,
  },
}));

/********************  Main Component  ********************/
const AccountSupport = () => {
  const theme = useTheme();
  const below600 = useMediaQuery('(max-width: 600px)');
  const [compromisedValue, setCompromisedValue] = useStateIfMounted(1);
  const [agreed, setAgreed] = useStateIfMounted(false);
  const [openConfirmModal, setOpenConfirmModal] = useStateIfMounted(false);
  const showNotification = useNotification();

  const wrappedOnDismiss = () => {
    setOpenConfirmModal(false);
  };

  const lockAccount = async (value) => {
    try {
      if (value === 'lock my account') {
        await userService.lockAccount();
        setOpenConfirmModal(false);
        showNotification({ type: 'success', message: `Your account has been locked!` });
      }
    } catch (error) {
      showNotification({ type: 'error', message: SOMETHING_WENT_WRONG });
    }
  };

  return (
    <StackContainer>
      <Stack
        direction="row"
        alignItems="center"
        sx={{ borderBottom: { xs: `1px solid ${theme.palette.border.separator}`, md: 'none' } }}
      >
        {!below600 && (
          <HeaderText marginBottom={3} textAlign="left" sx={{ m: 0 }}>
            Account Support
          </HeaderText>
        )}
      </Stack>
      <SubTitleText textAlign="left" sx={{ p: { xs: '24px 16px', md: '24px 0' }, fontWeight: 600 }}>
        If you need help related to your account, we can help you.
      </SubTitleText>

      <AccordionWrapper>
        {ACCOUNT_SUPPORT_ACCORDIONS.map((item, index) => (
          <Accordion key={index} disableGutters elevation={0} square>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon sx={{ color: 'icon.secondary' }} />}
              aria-controls={`panel${index}-content`}
              id={`panel${index}-header`}
            >
              <AccordionSummaryText>{item.summary}</AccordionSummaryText>
            </AccordionSummary>
            <AccordionDetails>
              {index === 2 ? (
                <Stack flexDirection="column">
                  <AccordionDetailText>{item.detail}</AccordionDetailText>
                  <GlobalStyles
                    styles={(theme) => ({
                      'div.MuiPaper-root > ul.MuiList-root': {
                        backgroundColor: theme.palette.background.secondary,
                      },
                    })}
                  />
                  <ResponsiveSelect
                    options={COMPROMISED_OPTIONS}
                    setValue={setCompromisedValue}
                    value={compromisedValue}
                  />
                  <FlexBox>
                    <Checkbox
                      style={{
                        color: agreed ? theme.palette.button.primary : theme.palette.button.high,
                      }}
                      checked={agreed}
                      onChange={() => setAgreed(!agreed)}
                    />
                    <CheckBoxLabel>
                      I understand I must provide a sworn statement certified by a
                      <a
                        href="https://en.wikipedia.org/wiki/Notary_public"
                        target="_blank"
                        rel="noreferrer"
                      >
                        {' '}
                        notary public
                      </a>{' '}
                      to unlock my account.
                    </CheckBoxLabel>
                  </FlexBox>

                  <Button
                    variant="outlined"
                    disabled={!agreed}
                    onClick={() => setOpenConfirmModal(true)}
                    disableRipple
                  >
                    Lock Account
                  </Button>
                </Stack>
              ) : (
                <AccordionDetailText>{item.detail}</AccordionDetailText>
              )}
            </AccordionDetails>
          </Accordion>
        ))}
      </AccordionWrapper>
      <LockConfirmationModal
        isOpen={openConfirmModal}
        onDismiss={wrappedOnDismiss}
        title=" Lock your account?"
        icon={() => (
          <WrapperIcon>
            {theme.palette.mode === 'dark' ? <LockDarkIcon /> : <LockLightIcon />}
          </WrapperIcon>
        )}
        content={[
          'Proceeding will ',
          <b key="boldText">prohibit your account from buying and selling </b>,
          'on GonnaMakeIt.',
        ]}
        confirmButton={(value) => (
          <div>
            <CustomizedButtonOutlined
              textcolor={theme.palette.text.secondary}
              onClick={wrappedOnDismiss}
              disableRipple
              sx={{
                width: 88,
              }}
            >
              Cancel
            </CustomizedButtonOutlined>
            <CustomizedButtonOutlined
              textcolor={theme.palette.text.error}
              disabled={value !== 'lock my account'}
              onClick={() => lockAccount(value)}
              disableRipple
              sx={{
                width: 140,
                whiteSpace: 'nowrap',
              }}
            >
              Lock Account
            </CustomizedButtonOutlined>
          </div>
        )}
      />
    </StackContainer>
  );
};

export default AccountSupport;
