import { createContext, useCallback, useEffect } from 'react';
import { useStateIfMounted } from 'use-state-if-mounted';
import { fetchBalance } from '@wagmi/core';
import useWalletContext from 'hooks/useWalletContext';
import { formatNumber, setBalanceWithDecimals } from 'utils/numbers';
import { BALANCE_TOKEN_LIST } from 'constants/index';
import { usePriceContext } from './PriceContext';
import useWindowContext from 'hooks/useWindowContext';

export const BalanceContext = createContext(null);

export const BalanceProvider = ({ children }) => {
  const [balances, setBalances] = useStateIfMounted([]);
  const { address, chainId, provider } = useWalletContext();
  const { coinPrice } = usePriceContext();
  const { active } = useWindowContext();

  const totalBalance = balances?.reduce((a, b) => a + b.value, 0);

  const getTokenBalances = useCallback(async () => {
    try {
      const promise = BALANCE_TOKEN_LIST.map(async (token) => {
        let balance = 0;
        let result;
        if (token.isNative && token.chains.includes(chainId)) {
          result = await fetchBalance({ address });
        } else {
          let tokenAddress = token.addresses[chainId];
          result = await fetchBalance({
            address,
            chainId: chainId,
            token: tokenAddress,
          });
        }
        if (result?.value) {
          balance = setBalanceWithDecimals(result.value, result.decimals);
        }
        return {
          ...token,
          balance: formatNumber(Number(balance)),
          last_price: coinPrice, // TODO: get tokenPrice other than wrapped coin for ERC20
          value: Number(balance) * coinPrice,
        };
      });
      const balances = await Promise.all(promise);
      const assetsWithBalance = balances.filter(({ value }) => value > 0);
      setBalances(assetsWithBalance);
    } catch (error) {
      console.error(error);
    }
  }, [address, chainId, coinPrice, provider]);

  useEffect(() => {
    if (!active || !address) {
      return;
    }

    getTokenBalances();
  }, [active, address, getTokenBalances]);

  useEffect(() => {
    if (address && chainId) {
      getTokenBalances();
    }
  }, [address, chainId, coinPrice, getTokenBalances]);

  return (
    <BalanceContext.Provider
      value={{
        totalBalance,
        balances,
        getTokenBalances,
        wethBalance: balances[1]?.balance,
        ethBalance: balances[0]?.balance,
        ethLastPrice: balances[0]?.last_price,
      }}
    >
      {children}
    </BalanceContext.Provider>
  );
};
