import React from 'react';
import { styled } from '@mui/material/styles';
import CountdownTimer from 'react-countdown';
import { useMediaQuery } from '@mui/material';

/********************  Styled Components  ********************/
const Container = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'start',
  padding: '5px 20px',
  borderBottom: `1px solid ${theme.palette.border.decorative}`,
  width: '100%',

  '@media(max-width: 400px)': {
    padding: '5px 10px',
  },
}));

const Timer = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  justifyContent: 'start',
  alignItems: 'center',
  gap: '50px',

  '@media(max-width: 480px)': {
    gap: '20px',
  },
}));

const TimerNumber = styled('p')(({ theme }) => ({
  fontSize: '20px',
  fontWeight: 600,
  color: theme.palette.text.main,
  margin: '0px',
}));

const Text = styled('p')(({ theme }) => ({
  fontSize: 16,
  color: theme.palette.text.secondary,
  fontWeight: 600,
  marginTop: '10px',
  letterSpacing: '0.15px',
  lineHeight: '24px',
}));

const dateFormatter = new Intl.DateTimeFormat('en-us', {
  year: 'numeric',
  month: 'long',
  day: 'numeric',
  timeZone: 'UTC',
});
const timeFormatter = new Intl.DateTimeFormat('en-us', {
  hour: 'numeric',
  minute: 'numeric',
  hour12: true,
  timeZone: 'UTC',
});

const TimerContainer = styled('span')(() => ({
  letterSpacing: 0.15,
  fontWeight: 600,
}));

/************************* Main Component  **************************/
const Countdown = ({ expiry, type, size = 'LARGE' }) => {
  const below700 = useMediaQuery('(max-width: 700px)');

  const expiryString = `Sale ends ${dateFormatter.format(expiry)} at ${timeFormatter.format(
    expiry,
  )} UTC`;

  const renderer = (value) => {
    if (type === 'timer') {
      return (
        <TimerContainer>
          {value.days || 0}d&nbsp;
          {value.hours || 0}h&nbsp;
          {(size === 'LARGE' || below700) && `${value.minutes || 0}m`}
        </TimerContainer>
      );
    }

    return Object.entries(value.formatted).map(([key, value]) => {
      return (
        <div key={key}>
          <TimerNumber>{value}</TimerNumber>
          <Text
            style={{
              textTransform: 'capitalize',
            }}
          >
            {key}
          </Text>
        </div>
      );
    });
  };

  if (type === 'timer') {
    return expiry ? <CountdownTimer date={expiry || 0} renderer={renderer} /> : renderer(0);
  }

  return (
    <Container>
      <Text dark={0}>{expiryString.replace(' AM', 'am').replace(' PM', 'pm')}</Text>
      <Timer>
        <CountdownTimer date={expiry} renderer={renderer} />
      </Timer>
    </Container>
  );
};

export default Countdown;
