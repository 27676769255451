import React from 'react';
import { styled } from '@mui/material';
import {
  Circle_1_Icon,
  Circle_2_Icon,
  Circle_3_Icon,
  Circle_4_Icon,
} from 'components/ImageComponent';
import { GeneralFlexBox } from 'components/StyledComponents';
import NumSection from 'components/General/Section/NumSection';
import { Text } from 'components/Text';

/********************  Styled Components  ********************/
const NumSectionWrapper = styled('div')(() => ({
  display: 'flex',
  alignItems: 'flex-start',
  justifyContent: 'space-between',
  width: '100%',
  margin: '40px 0 0 0',

  '@media (max-width: 1280px)': {
    maxWidth: 1063,
  },

  '@media (max-width: 600px)': {
    flexDirection: 'column',
    gap: 24,
  },

  '& div:nth-of-type(1)': {
    '& div:nth-of-type(1)': {
      '& p:nth-of-type(2)': {
        maxWidth: 210,

        '@media (max-width: 1000px)': {
          maxWidth: 163,
        },
        '@media (max-width: 600px)': {
          maxWidth: 210,
        },
        '@media (max-width: 480px)': {
          maxWidth: 260,
        },
      },
    },

    '& div:nth-of-type(2)': {
      '& p:nth-of-type(2)': {
        maxWidth: 160,

        '@media (max-width: 480px)': {
          maxWidth: 210,
        },
      },
    },
  },

  '& div:nth-of-type(2)': {
    '& div:nth-of-type(1)': {
      '& p:nth-of-type(2)': {
        maxWidth: 230,

        '@media (max-width: 1280px)': {
          maxWidth: 215,
        },
        '@media (max-width: 1000px)': {
          maxWidth: 163,
        },
        '@media (max-width: 600px)': {
          maxWidth: 220,
        },
        '@media (max-width: 480px)': {
          maxWidth: 260,
        },
      },
    },

    '& div:nth-of-type(2)': {
      '& p:nth-of-type(2)': {
        maxWidth: 230,

        '@media (max-width: 480px)': {
          maxWidth: 260,
        },
      },
    },
  },
}));

const NumWrapper = styled('div')(() => ({
  display: 'flex',
  alignItems: 'flex-start',
  justifyContent: 'flex-start',
  width: '100%',

  '@media (max-width: 480px)': {
    flexDirection: 'column',
    gap: 24,
    alignItems: 'center',
  },
}));

/********************  Sub Component  ********************/
const Steps = ({ steps }) => {
  return (
    <NumWrapper>
      {steps.map((step, index) => (
        <NumSection
          key={index}
          icon={step.icon}
          title={step.title}
          text={step.text}
          position="center"
        />
      ))}
    </NumWrapper>
  );
};

/********************  Main Components  ********************/
const AIRDROP_STEPS = [
  {
    icon: <Circle_1_Icon />,
    title: 'Connect your wallet',
    text: 'Connect your Metamask and check if you are legible for the GMI Airdrop',
  },
  {
    icon: <Circle_2_Icon />,
    title: 'List Two NFT',
    text: 'List or import at least two NFTs to unlock your rewards',
  },
  {
    icon: <Circle_3_Icon />,
    title: 'Refer frens',
    text: 'When your fren claims GMI Airdrop, you both get 10% bonus on the Airdrop Amount in GMI',
  },
  {
    icon: <Circle_4_Icon />,
    title: 'Claim Rewards',
    text: `Claim your rewards, \n lock them up to 5 years to earn even more GMI`,
  },
];

const AirdropSteps = () => {
  return (
    <GeneralFlexBox align="center" width="100%" justify="center" direction="column">
      <Text main="true" fs={42} fw={500} lh={50} xs={{ fs: 28, lh: 36 }}>
        How GMI Airdrop works?
      </Text>
      <NumSectionWrapper>
        <Steps steps={AIRDROP_STEPS.slice(0, 2)} />
        <Steps steps={AIRDROP_STEPS.slice(2)} />
      </NumSectionWrapper>
    </GeneralFlexBox>
  );
};

export default AirdropSteps;
