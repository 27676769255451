import React from 'react';
import { EthBlueIcon } from 'components/ImageComponent';
import RewardsContainer from './RewardsContainer';

const list = [
  {
    name: 'Rewards',
    price: '0 ETH',
    dollar: '$0.00',
    link: null,
    button: 'Invite frens',
  },
  {
    name: 'Service fee rebate',
    price: '0 ETH',
    dollar: '$0.00',
    link: null,
    button: 'Apply promo',
  },
];

/************************* Main Component  **************************/
export default function FarmingRewards() {
  return <RewardsContainer title="Referral fees rewards" list={list} logoComponent={EthBlueIcon} />;
}
