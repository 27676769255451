import { ApiClient } from '../ApiClient';

export class ReferralApi {
  /**
   * Constructs a new ReferralApi.
   * @alias module:api/ReferralApi
   * @class
   * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
   * default to {@link module:ApiClient#instance} if unspecified.
   */
  constructor(apiClient) {
    this.apiClient = apiClient || ApiClient.referralApiInstance;
  }

  /**
   *  Get Airdrop Bonuses
   *
   * @param {string} token
   */
  async getAirdropBonuses() {
    return await this.apiClient.callApi('/user/{address}/airdrop', 'get', {}, {}, null);
  }
}
