import { useEffect } from 'react';
import { useStateIfMounted } from 'use-state-if-mounted';
import { useHistory } from 'react-router-dom';

import emailService from 'services/email';
import useNotification from './useNotification';
import { startEmailTimer } from 'utils/emailTimer';
import { EMAIL_ADDRESS_VALIDATOR } from 'utils/validators';
import { LOCAL_STORAGE_TIMER_KEY } from 'constants/index';

const useSubscribeEmail = () => {
  const history = useHistory();
  const showNotification = useNotification();

  const [isLoading, setIsLoading] = useStateIfMounted(false);
  const [availableToResend, setAvailableToResend] = useStateIfMounted(false);

  useEffect(() => {
    if (!localStorage.getItem(LOCAL_STORAGE_TIMER_KEY)) {
      setAvailableToResend(true);
    }
  }, []);

  const onCountDownComplete = () => {
    localStorage.removeItem(LOCAL_STORAGE_TIMER_KEY);
    setAvailableToResend(true);
  };

  const subscribeEmail = async (signupEmail) => {
    setIsLoading(true);
    try {
      if (!EMAIL_ADDRESS_VALIDATOR.test(signupEmail)) {
        throw Error;
      }
      const reponse = await emailService.subscribeToMailingList(signupEmail);
      if (reponse.success) {
        showNotification({
          type: 'success',
          message: 'Confirmation email sent.',
        });
        history.push({
          pathname: '/confirm-email',
          state: { email: signupEmail },
        });
        startEmailTimer();
        setAvailableToResend(false);
      } else {
        showNotification({
          type: 'error',
          message: reponse.message,
        });
      }
    } catch (error) {
      showNotification({
        type: 'error',
        message: 'Invalid email address.',
      });
    } finally {
      setIsLoading(false);
    }
  };

  return { subscribeEmail, isLoading, availableToResend, onCountDownComplete };
};

export default useSubscribeEmail;
