import { ApiClient } from '../ApiClient';

export class EmailApi {
  /**
   * Constructs a new EmailApi.
   * @alias module:api/EmailApi
   * @class
   * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
   * default to {@link module:ApiClient#instance} if unspecified.
   */
  constructor(apiClient) {
    this.apiClient = apiClient || ApiClient.userApiInstance;
  }

  /**
   * Join newsletter
   * @param {{email}} body
   */
  async subscribeToMailingList(body) {
    return await this.apiClient.callApi(
      '/email/subscribeToMailingList',
      'post',
      {},
      {},
      null,
      {},
      body,
    );
  }

  /**
   * Verify profile
   * @param {{email, address}} body
   */
  async verifyEmail(body) {
    return await this.apiClient.callApi(
      '/email/profileEmailVerification',
      'post',
      {},
      {},
      null,
      {},
      body,
    );
  }
}
