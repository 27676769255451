import { useReserveValues } from 'hooks/farmingFunction';
import useWalletContext from 'hooks/useWalletContext';
import React, { createContext, useContext, useEffect, useState } from 'react';
import { COIN } from 'constants/index';
import general from '../services/general';

const PriceContext = createContext();
export const usePriceContext = () => useContext(PriceContext);

export const PriceProvider = ({ children }) => {
  const { chainId } = useWalletContext();
  const [ethPrice, setEthPrice] = useState();
  const [nrgPrice, setNrgPrice] = useState();
  const [coinPrice, setCoinPrice] = useState(0);
  const [ethPerGmi] = useReserveValues();

  useEffect(async () => {
    const prices = await general.prices();
    if (prices) {
      if (COIN.Symbol === 'ETH') {
        setEthPrice(prices.ethereum);
        setCoinPrice(prices.ethereum);
      } else if (COIN.Symbol === 'NRG') {
        setNrgPrice(prices.energi);
        setCoinPrice(prices.energi);
      }
    }
  }, [chainId]);

  return (
    <PriceContext.Provider
      value={{ ethPrice, gmiPrice: ethPrice * ethPerGmi, nrgPrice, coinPrice }}
    >
      {children}
    </PriceContext.Provider>
  );
};
