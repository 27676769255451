import React, { useCallback } from 'react';
import { useStateIfMounted } from 'use-state-if-mounted';
import { styled } from '@mui/material';
import Modal from 'components/Modal';
import { Text } from 'components/Text';
import { GeneralFlexBox } from 'components/StyledComponents';
import AccordionPanel from 'components/General/AccordionPanel';
import useNotification from 'hooks/useNotification';

/********************  Styled Components  ********************/
const ContentWrapper = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  padding: '20px 24px 24px 24px',

  '@media (max-width: 480px)': {
    padding: '20px 16px 24px 16px',
  },
}));

/********************  Main Component  ********************/
const ClaimGmiModal = ({ isOpen, onDismiss, handleSuccess, width, claimData }) => {
  const showNotification = useNotification();

  const [approvePanel, setApprovePanel] = useStateIfMounted({
    expanded: false,
    status: 'Closed',
    title: 'Initialize your wallet',
    subTitle: `To claim your rewards for the first time, you must sign in. This request will not trigger a blockchain transaction or cost any gas fees.`,
    message: 'Waiting for signature...',
    error: false,
  });

  const [signaturePanel, setSignaturePanel] = useStateIfMounted({
    expanded: false,
    status: 'Closed',
    title: 'Confirm transaction',
    subTitle: 'Confirm the transaction in your wallet.',
    message: 'Waiting for confirmation...',
    error: false,
  });

  const testInitializeWallet = useCallback(() => {
    // TODO: implement logic from backend
    setApprovePanel({
      ...approvePanel,
      expanded: true,
      status: 'Loading',
      message: 'Waiting for confirmation',
    });
    setTimeout(() => {
      setApprovePanel({
        ...approvePanel,
        expanded: false,
        status: 'Complete',
      });
      testConfirmTransaction();
    }, 4000);
  }, []);

  const testConfirmTransaction = useCallback(() => {
    // TODO: implement logic from backend
    setSignaturePanel({
      ...signaturePanel,
      expanded: true,
      status: 'Loading',
      message: 'Waiting for signature',
    });
    setTimeout(() => {
      setSignaturePanel({
        ...signaturePanel,
        expanded: false,
        status: 'Complete',
      });
      showNotification({
        message: 'GMI claim succesful!',
        type: 'success',
        link: 'https://etherscan.io/',
      });

      handleSuccess();
    }, 4000);
  }, []);

  const handleOpenAccordion = () => {
    testInitializeWallet();
  };

  const handleReInitialization = () => {
    if (approvePanel.status === 'Complete') {
      testConfirmTransaction();
    } else {
      testInitializeWallet();
    }
  };

  return (
    <Modal title="Claim GMI" isOpen={isOpen} onDismiss={onDismiss} width={width} borderradius={10}>
      <ContentWrapper>
        <GeneralFlexBox width="100%" justify="space-between" sx={{ mb: '20px' }}>
          <Text main="true" fs={20} fw={600} lh={28}>
            {claimData.gmiAmount} GMI
          </Text>
          <Text secondary="true" fs={12} fw={400} lh={16}>
            {claimData.gmiValue}
          </Text>
        </GeneralFlexBox>

        <AccordionPanel
          value={1}
          panel={approvePanel}
          handleOpenAccordion={handleOpenAccordion}
          handleReInitialization={handleReInitialization}
        />
        <AccordionPanel
          value={2}
          panel={signaturePanel}
          handleOpenAccordion={handleOpenAccordion}
          handleReInitialization={handleReInitialization}
        />
      </ContentWrapper>
    </Modal>
  );
};

export default ClaimGmiModal;
