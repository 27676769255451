import React from 'react';
import { styled } from '@mui/material/styles';
import { useMediaQuery, useTheme } from '@mui/material';
import { Chart } from 'pages/Stats/Rankings/components';
import { Text, GreenLinkText } from 'components/Text';
import CheckIcon from '@mui/icons-material/Check';
import { GeneralFlexBox } from 'components/StyledComponents';
import { useListedNfts } from 'hooks/useListedNfts';

/********************  Styled Components  ********************/
const Container = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  border: `1px solid ${theme.palette.border.decorative}`,
  backgroundColor: theme.palette.background.filter,
  borderRadius: 8,
  padding: '32px 32px 24px 32px',
  width: '100%',
  height: 294,
  gap: 24,
  justifyContent: 'space-between',

  '@media(max-width: 905px)': {
    flexDirection: 'column',
    alignItems: 'start',
    padding: 24,
    gap: 16,
    height: 398,
  },
  '@media(max-width: 500px)': {
    padding: 16,
    height: 344,
  },
}));

const ChartContainer = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'center',
}));

const ChartInnerText = styled('div')(() => ({
  position: 'absolute',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',

  '@media(max-width: 600px)': {
    translate: '0 -5px',
  },
}));

const CriteriaContent = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'start',
  alignItems: 'start',
  gap: 8,
  width: '100%',
  maxWidth: 596,

  '@media(max-width: 905px)': {
    maxWidth: '100%',
    gap: 16,
  },

  '@media(max-width: 600px)': {
    marginTop: 0,
    width: 230,
  },
}));

/********************  Sub Component  ********************/
const ListText = ({ text }) => {
  const { listedNFTs } = useListedNfts();
  const theme = useTheme();

  return (
    <GeneralFlexBox>
      {listedNFTs < 2 ? '•' : <CheckIcon style={{ fill: theme.palette.button.active }} />}
      <Text
        tertiary="true"
        fs={16}
        fw={400}
        lh={24}
        align="left"
        sx={{ ml: 1 }}
        sm={{ fs: 14, lh: 20 }}
      >
        {text}
      </Text>
    </GeneralFlexBox>
  );
};

/********************  Main Component  ********************/
const NUM_NFTS_TO_LIST = 2;

const AirdropCriteria = () => {
  const below905 = useMediaQuery('(max-width: 905px)');
  const { listedNFTs } = useListedNfts();

  return (
    <Container>
      <ChartContainer>
        <Chart
          resource={[listedNFTs, NUM_NFTS_TO_LIST - listedNFTs]}
          type="doughnut"
          label="listings"
        />
        <ChartInnerText>
          <Text
            main="true"
            fs={42}
            fw={500}
            lh={50}
            md={{ fs: 28, lh: 36 }}
            sm={{ fs: 20, fw: 600 }}
          >
            {listedNFTs}/{NUM_NFTS_TO_LIST}
          </Text>
          <Text tertiary="true" fs={14} fw={400} lh={20} md={{ fs: 10, lh: 8 }}>
            Listed
          </Text>
        </ChartInnerText>
      </ChartContainer>

      <CriteriaContent>
        <Text main="true" fs={20} fw={600} lh={28}>
          Unlock criteria:
        </Text>
        <ListText text="List two or more NFTs on the GMI Marketplace." />
        <ListText text="Listing price is within 1.2x the global collection floor price" />

        <GreenLinkText
          href={'https://docs.gonnamakeit.com/docs/about/guide-airdrop-claim'}
          sx={{ m: below905 ? '8px 0 0 16px' : '16px 0 0 16px' }}
        >
          Learn more
        </GreenLinkText>
      </CriteriaContent>
    </Container>
  );
};

export default AirdropCriteria;
