import React from 'react';
import { Breadcrumbs, Link } from '@mui/material';
import { styled } from '@mui/material/styles';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

/************************* Styled Components  **************************/
const Container = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.background.secondary,
  padding: theme.spacing(2.6),
  borderBottom: `1px solid ${theme.palette.border.separator}`,
}));

const CustomLink = styled(Link)(({ theme }) => ({
  color: theme.palette.text.secondary,
  transitionDuration: '100ms',
  textDecoration: 'none',
  '&:hover': {
    color: theme.palette.text.main,
  },
}));

/************************* Main Component  **************************/
const Breadcrumb = ({ links }) => {
  return (
    <Container>
      <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
        {links.map(({ href, title }, index) => (
          <CustomLink key={index} href={href}>
            {title}
          </CustomLink>
        ))}
      </Breadcrumbs>
    </Container>
  );
};

export default Breadcrumb;
