import React from 'react';
import { IconButton, styled, Typography } from '@mui/material';
import { WarningIcon } from 'components/ImageComponent';
import { FlexBox } from 'components/StyledComponents';
import CloseIcon from '@mui/icons-material/Close';
import useWalletContext from 'hooks/useWalletContext';
import { useBookmarkWarning } from 'context';

/********************  Styled Components  ********************/
const WarningContainer = styled('div')(({ top }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  backgroundColor: '#F6C000',
  padding: '10px 16px 8px 16px',
  color: 'black',
  letterSpacing: '0.4px',
  width: '100%',
  height: 42,
  width: '100%',
  zIndex: 1300,
  position: 'fixed',
  top: top,
}));

const WarningText = styled(Typography)(() => ({
  fontSize: 12,
  fontWeight: 400,
  lineHeight: '18px',
  marginLeft: 9,
}));

const BoldSpan = styled('span')(() => ({
  fontSize: 12,
  fontWeight: 500,
  lineHeight: '16px',
}));

/********************  Main Component  ********************/
const BookmarkWarning = () => {
  const { wrongNetwork } = useWalletContext();
  const { isOpen, handleClose } = useBookmarkWarning();

  if (!isOpen) {
    return null;
  }

  return (
    <WarningContainer top={wrongNetwork ? 60 : 0}>
      <FlexBox>
        <div>
          <WarningIcon black="true" width={18} height={18} />
        </div>
        <WarningText>
          Always make sure the URL is <BoldSpan>{window.location.host}</BoldSpan> - bookmark it to
          be safe.
        </WarningText>
      </FlexBox>
      <IconButton disableRipple onClick={handleClose} sx={{ p: 0, width: 18, height: 18 }}>
        <CloseIcon sx={{ color: 'black', width: 18, height: 18 }} />
      </IconButton>
    </WarningContainer>
  );
};

export default BookmarkWarning;
