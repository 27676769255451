import React from 'react';
import {
  EthDarkCircle,
  EthLightCircle,
  EthIcon,
  EthDarkIcon,
  EthBlueIcon,
  EthRedIcon,
  EthereumGreyIcon,
  NRGLogoInvertedIcon,
  NRGLogoDarkIcon,
  NRGLogoLightIcon,
  NRGLogoInvertedDarkIcon,
  NRGLogoInvertedLightIcon,
  NRGLogoIcon,
  EnergiIconCustom,
} from 'components/ImageComponent';

const currencyChainId = {
  1: 'ETH',
  5: 'ETH',
  39797: 'NRG',
  49797: 'NRG',
};

export const CurrencyIcon = ({ props = {}, currency, type, chainId }) => {
  if (chainId && !Object.keys(currencyChainId).includes(chainId)) {
    return null;
  }
  const targetCurrency = currency ?? currencyChainId[chainId];
  return {
    ETH: {
      circleDark: <EthDarkCircle {...props} />,
      circleLight: <EthLightCircle {...props} />,
      light: <EthIcon {...props} />,
      dark: <EthDarkIcon {...props} />,
      default: <EthBlueIcon {...props} />,
      current: <EthRedIcon {...props} />,
      symbol: <EthereumGreyIcon {...props} />,
    },
    NRG: {
      circleDark: <NRGLogoInvertedDarkIcon {...props} />,
      circleLight: <NRGLogoInvertedLightIcon {...props} />,
      light: <NRGLogoDarkIcon {...props} />,
      dark: <NRGLogoLightIcon {...props} />,
      default: <NRGLogoInvertedIcon {...props} />,
      current: <NRGLogoIcon {...props} />,
      symbol: <EnergiIconCustom {...props} />,
    },
  }[targetCurrency][type];
};
