import {
  Select as MuiSelect,
  Box,
  FormControl,
  MenuItem,
  InputLabel,
  useMediaQuery,
  SwipeableDrawer,
  Stack,
  Typography,
  IconButton,
  FormControlLabel as MuiFormControlLabel,
  RadioGroup,
  Radio,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { styled, useTheme } from '@mui/material/styles';
import { useStateIfMounted } from 'use-state-if-mounted';

const Select = styled(MuiSelect)(({ theme }) => ({
  backgroundColor: theme.palette.background.select,
  border: `1px solid ${theme.palette.border.decorative}`,
  color: theme.palette.text.secondary,
  borderRadius: 8,
  height: 48,
  fontSize: 14,
  fontWeight: 500,
}));

export const FormControlLabel = styled(MuiFormControlLabel)(({ theme }) => ({
  borderBottom: `1px solid ${theme.palette.border.separator}`,
  padding: '8px 0',
  backgroundColor: theme.palette.background.light,
  '.MuiFormControlLabel-label': {
    fontWeight: 600,
    fontSize: '16px',
  },
}));

export default function ResponsiveSelect({ breakpoint = 'lg', setValue, value, label, options }) {
  const theme = useTheme();
  const screenBelowBreakpoint = useMediaQuery(theme.breakpoints.down(breakpoint));
  const [open, setOpen] = useStateIfMounted(0);
  function handleChange(e) {
    setValue(e.target.value);
    setOpen(0);
  }

  function handleOpen() {
    if (screenBelowBreakpoint) {
      setOpen(2);
    } else {
      setOpen(1);
    }
  }
  return (
    <>
      <Box>
        <FormControl
          fullWidth
          sx={{
            margin: '24px 0 30px',
          }}
        >
          {label && (
            <InputLabel id={`label fo ${label}`} sx={{ fontSize: 14 }}>
              {label}
            </InputLabel>
          )}
          <Select
            labelId={`label fo ${label}`}
            value={value}
            label={label}
            notched={false}
            placeholder="sssss"
            onChange={handleChange}
            onOpen={handleOpen}
            open={open === 1}
            onClose={() => setOpen(0)}
          >
            {options.map((option, index) => (
              <MenuItem
                key={index}
                value={option.value}
                divider={index < options.length - 1}
                sx={{
                  fontSize: 16,
                  fontWeight: 600,
                  '&.Mui-selected': {
                    backgroundColor: 'transparent',
                  },
                }}
              >
                {option.text}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
      <SwipeableDrawer
        anchor={'bottom'}
        open={open === 2}
        onClose={() => setOpen(0)}
        sx={{ '.MuiDrawer-paper': { borderTopLeftRadius: '8px', borderTopRightRadius: '8px' } }}
        onOpen={() => {}}
      >
        <Stack
          direction="row"
          alignItems="center"
          sx={{ p: '16px', backgroundColor: theme.palette.background.light }}
        >
          <Typography flexGrow={1} fontSize="20px" fontWeight={600} lineHeight="28px">
            Select a reason
          </Typography>
          <IconButton size="small">
            <CloseIcon sx={{ fontSize: '14px', color: 'icon.primary' }} />
          </IconButton>
        </Stack>
        <RadioGroup defaultValue="" value={value}>
          {options.map((option) => (
            <FormControlLabel
              key={option.value}
              value={option.value}
              onClick={() => {
                setValue(option.value);
                setOpen(0);
              }}
              control={<Radio sx={{ fontSize: '16px', fontWeight: 600, px: '20px' }} />}
              label={option.text}
            />
          ))}
        </RadioGroup>
      </SwipeableDrawer>
    </>
  );
}
