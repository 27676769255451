import React from 'react';
import { Link } from 'react-router-dom';
import { Typography, Stack, useTheme, useMediaQuery } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Footer } from 'components/General';
import { GeneralFlexBox } from 'components/StyledComponents';
import { GrayButtonOutlined, StandardButton } from 'components/Button';
import useWalletContext from 'hooks/useWalletContext';
import { useMetamaskConnect } from 'hooks/useMetamaskConnect';

/********************  Styled Components  ********************/
const Container = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
}));

const Title = styled(Typography)(() => ({
  fontSize: 42,
  fontFamily: 'Inter',
  fontWeight: 500,
  '@media(max-width: 600px)': {
    fontSize: 32,
  },
  '@media(max-width: 360px)': {
    fontSize: 24,
  },
}));

const Text = styled(Typography)(({ theme }) => ({
  fontSize: 20,
  fontWeight: 600,
  color: theme.palette.text.secondary,
  margin: theme.spacing(3, 0),
  textAlign: 'center',
  '@media(max-width: 600px)': {
    fontSize: 16,
  },
  '@media(max-width: 360px)': {
    fontWeight: 500,
  },
}));

/********************  Main Component  ********************/
const Disconnected = () => {
  const theme = useTheme();
  const below360 = useMediaQuery('(max-width:360px)');
  const { isInstalled, setWalletModalOpen } = useWalletContext();
  const { connectMetamask } = useMetamaskConnect();

  const handleConnectWallet = async () => {
    if (isInstalled) {
      connectMetamask();
    } else {
      setWalletModalOpen(true);
    }
  };

  return (
    <>
      <Container>
        <GeneralFlexBox direction="column" padding={theme.spacing(20, 0)} align="center">
          <Title>Whoops!</Title>
          <Text>
            To view this page you need to connect your
            <br /> metamask wallet
          </Text>

          <Stack direction={below360 ? 'column' : 'row'} spacing={2}>
            <StandardButton width={187} height={48} onClick={handleConnectWallet}>
              Connect Metamask
            </StandardButton>

            <Link to={'/'} style={{ textDecoration: 'none' }}>
              <GrayButtonOutlined width={187} height={48}>
                Go back home
              </GrayButtonOutlined>
            </Link>
          </Stack>
        </GeneralFlexBox>
      </Container>
      <Footer />
    </>
  );
};

export default Disconnected;
