import React, { useEffect, useRef } from 'react';
import { Button } from '@mui/material';
import { styled } from '@mui/material/styles';

/********************  Styled Components  ********************/
const Buttons = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(1),
  '@media (max-width: 480px)': {
    display: 'flex',
    flexDirection: 'column',
    '& .MuiButton-root': {
      margin: theme.spacing(0.8, 0),
    },
  },
}));

const CustomizedButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  boxShadow: 'none',
  borderRadius: 8,
  color: theme.palette.text.interactive,
  fontSize: 16,
  fontWeight: 600,
  padding: theme.spacing(1, 4),
  height: 40,
  width: 72,
  '&:disabled': {
    background: theme.palette.button.primary,
    opacity: 0.24,
    color: theme.palette.white,
  },
}));

/************************* Main Component  **************************/
const Form = ({
  children,
  submitButtonText,
  disabled,
  values = {},
  extra = null,
  onSubmit,
  buttonProps = {},
}) => {
  const form = useRef(null);
  const handleFormSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    const data = Object.fromEntries(formData);
    for (let key in data) {
      try {
        const formattedData = JSON.parse(data[key]);
        data[key] = formattedData;
      } catch (err) {
        continue;
      }
    }
    onSubmit(data);

    e.target.reset();
  };

  useEffect(() => {
    for (const key in values) {
      const element = form.current.elements.namedItem(`field-${key}`);
      if (element) {
        let value = values[key];
        if (element.type === 'checkbox') {
          element.checked = value;
          continue;
        }
        if (typeof value === 'object') {
          value = JSON.stringify(value);
        }
        element.value = value;
      }
    }
  }, [values]);

  return (
    <form ref={form} onSubmit={handleFormSubmit}>
      {children}
      <Buttons {...buttonProps}>
        <CustomizedButton type="submit" variant="contained" disabled={disabled} disableRipple>
          {submitButtonText}
        </CustomizedButton>
        {extra}
      </Buttons>
    </form>
  );
};

export default Form;
