import React, { createContext, useContext } from 'react';
import { useStateIfMounted } from 'use-state-if-mounted';

export const BookmarkWarningContext = createContext();
export const useBookmarkWarning = () => useContext(BookmarkWarningContext);

export const BookmarkWarningProvider = ({ children }) => {
  const [isOpen, setIsOpen] = useStateIfMounted(!Boolean(localStorage['bookmarkSaved']));

  const handleClose = () => {
    setIsOpen(false);
    localStorage.setItem('bookmarkSaved', true);
  };

  return (
    <BookmarkWarningContext.Provider value={{ isOpen, handleClose }}>
      {children}
    </BookmarkWarningContext.Provider>
  );
};
