import React, { useMemo } from 'react';
import { useStateIfMounted } from 'use-state-if-mounted';

import { styled, useTheme } from '@mui/material/styles';
import { MenuItem, Select, Typography, InputLabel, FormControl } from '@mui/material';

/************************* Styled Components  **************************/
const Container = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  marginBottom: theme.spacing(3),
}));

const Label = styled('label')(({ theme }) => ({
  fontSize: 16,
  fontWeight: 600,
  color: theme.palette.text.main,
}));

const RequiredText = styled('span')(({ theme }) => ({
  color: theme.palette.text.error,
}));

const CustomSelect = styled(Select)(() => ({
  '& .MuiSelect-select': {
    display: 'flex',
    alignItems: 'center',
  },
}));

const SelectImage = styled('img')(() => ({
  marginRight: 10,
}));

/************************* Main Component  **************************/
const SelectBox = ({
  title,
  label,
  name,
  children,
  required = false,
  value,
  options = [],
  onChange,
}) => {
  const theme = useTheme();
  const [ref, setRef] = useStateIfMounted();
  const [selectedValue, setSelectedValue] = useStateIfMounted('');

  const handleOnChange = (e) => {
    setSelectedValue(e.target.value);
    onChange(e);
  };

  useMemo(() => {
    if (ref?.value && !selectedValue) {
      setSelectedValue(ref.value);
    }
  }, [ref?.value, selectedValue]);

  return (
    <Container>
      <Label htmlFor={`field-${name}`}>
        {title}
        {required && <RequiredText>*</RequiredText>}
      </Label>
      <Typography fontSize={12} color={theme.palette.text.medium} padding="0.5rem 0 0.8rem 0">
        {children}
      </Typography>
      <input
        type="hidden"
        id={`field-${name}`}
        name={name}
        ref={setRef}
        value={selectedValue || value}
      />
      <FormControl>
        <InputLabel id={`input-label-${name}`}>{label}</InputLabel>
        <CustomSelect
          label={label}
          labelId={`input-label-${name}`}
          onChange={handleOnChange}
          value={selectedValue || value}
        >
          {options.map(({ value, text, image }, index) => (
            <MenuItem key={index} value={value}>
              <SelectImage src={image} alt="" />
              {text}
            </MenuItem>
          ))}
        </CustomSelect>
      </FormControl>
    </Container>
  );
};

export default SelectBox;
