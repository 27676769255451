import { ApiClient } from '../ApiClient';

export class NotificationsApi {
  /**
   * Constructs a new NotificationsApi.
   * @alias module:api/NotificationsApi
   * @class
   * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
   * default to {@link module:ApiClient#instance} if unspecified.
   */
  constructor(apiClient) {
    this.apiClient = apiClient || ApiClient.userApiInstance;
  }

  /**
   * Get user notification settings
   */
  async getUserNotificationSettings() {
    return await this.apiClient.callApi('/notification/settings', 'get', {}, {}, null);
  }

  /**
   * Update user notification settings
   *
   * @param {{*}} settings The settings to update to server
   */
  async updateUserNotificationSettings(settings) {
    return await this.apiClient.callApi(
      '/notification/settings',
      'post',
      {},
      {},
      null,
      undefined,
      settings,
    );
  }

  /**
   * Update user offer settings
   *
   * @param {{*}} settings The settings to update to server
   */
  async updateOfferSettings(settings) {
    return await this.apiClient.callApi(
      '/notification/offer',
      'post',
      {},
      {},
      null,
      undefined,
      settings,
    );
  }
}
