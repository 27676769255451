import { useContext } from 'react';
import { BalanceContext } from 'context';

const useBalanceContext = () => {
  const context = useContext(BalanceContext);

  if (!context) {
    throw new Error('Balance context must be use inside provider');
  }

  return context;
};

export default useBalanceContext;
