import { useMemo } from 'react';

export default function useCompareValues(newValue, oldValue) {
  return useMemo(() => {
    if (!newValue || newValue === {}) {
      return true;
    } else if (JSON.stringify(oldValue) !== JSON.stringify(newValue)) {
      return false;
    } else {
      return true;
    }
  }, [newValue, oldValue]);
}
