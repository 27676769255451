import React from 'react';
import { GMITokenLogo } from 'components/ImageComponent';
import RewardsContainer from './RewardsContainer';

const list = [
  {
    name: 'GMI Airdrop',
    price: '0 GMI',
    dollar: '$0.00',
    link: '/airdrop',
    button: 'Check now',
  },
  {
    name: 'Airdrop bonus',
    price: '0 GMI',
    dollar: '$0.00',
    link: null,
    button: 'Enter referral link',
  },
  {
    name: 'Airdrop Referral reward',
    price: '0 GMI',
    dollar: '$0.00',
    link: null,
    button: 'Invite frens',
  },
];

/************************* Main Component  **************************/
const AirdropRewards = ({ closeSideBar }) => (
  <RewardsContainer
    title="Airdrop rewards"
    list={list}
    logoComponent={GMITokenLogo}
    closeSideBar={closeSideBar}
  />
);

export default AirdropRewards;
