import { styled, Typography } from '@mui/material';

export const LinkText = styled('p')(({ theme, failed, onClick }) => ({
  color: failed ? theme.palette.text.error : theme.palette.text.main,
  fontWeight: 400,
  fontSize: 16,
  letterSpacing: '0.15px',
  lineHeight: '24px',
  marginBottom: 0,
  cursor: onClick ? 'pointer' : 'input',
}));

export const DescriptionContent = styled('div')(() => ({
  alignItems: 'center',
  gap: '6px',
  justifyContent: 'start',
  width: '100%',
  overflowY: 'auto',
  padding: '20px',
}));

export const DescriptionText = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.secondary,
  fontWeight: 400,
  letterSpacing: '0.15px',
  lineHeight: '24px',
  fontSize: 16,
}));
