import React from 'react';
import { Link } from 'react-router-dom';
import { styled, Typography } from '@mui/material';

export const Text = styled(Typography)(
  ({ theme, fs, fw, lh, align, xs, sm, md, lg, main, secondary, tertiary, error, mt, mb }) => ({
    fontSize: fs ?? 16,
    fontWeight: fw ?? 400,
    lineHeight: lh ? `${lh}px` : '24px',
    textAlign: align ?? 'center',
    marginTop: mt ?? 0,
    marginBottom: mb ?? 0,
    color: secondary
      ? theme.palette.text.secondary
      : tertiary
      ? theme.palette.text.tertiary
      : main
      ? theme.palette.text.main
      : error
      ? theme.palette.text.error
      : null,

    '@media (max-width: 1280px)': {
      fontSize: lg?.fs ?? fs ?? 16,
      fontWeight: lg?.fw ?? fw ?? 400,
      lineHeight: lg?.lh ? `${lg?.lh}px` : lh ? `${lh}px` : '24px',
      textAlign: lg?.align ?? align ?? 'center',
      marginTop: lg?.mt ?? mt ?? 0,
      marginBottom: lg?.mb ?? mb ?? 0,
    },
    '@media (max-width: 905px)': {
      fontSize: md?.fs ?? lg?.fs ?? fs ?? 16,
      fontWeight: md?.fw ?? lg?.fw ?? fw ?? 400,
      lineHeight: md?.lh ? `${md?.lh}px` : lg?.lh ? `${lg?.lh}px` : lh ? `${lh}px` : '24px',
      textAlign: md?.align ?? lg?.align ?? align ?? 'center',
      marginTop: md?.mt ?? lg?.mt ?? mt ?? 0,
      marginBottom: md?.mb ?? lg?.mb ?? mb ?? 0,
    },
    '@media (max-width: 600px)': {
      fontSize: sm?.fs ?? md?.fs ?? lg?.fs ?? fs ?? 16,
      fontWeight: sm?.fw ?? md?.fw ?? lg?.fw ?? fw ?? 400,
      lineHeight: sm?.lh
        ? `${sm?.lh}px`
        : md?.lh
        ? `${md?.lh}px`
        : lg?.lh
        ? `${lg?.lh}px`
        : lh
        ? `${lh}px`
        : '24px',
      textAlign: sm?.align ?? md?.align ?? lg?.align ?? align ?? 'center',
      marginTop: sm?.mt ?? md?.mt ?? lg?.mt ?? mt ?? 0,
      marginBottom: sm?.mb ?? md?.mb ?? lg?.mb ?? mb ?? 0,
    },
    '@media (max-width: 480px)': {
      fontSize: xs?.fs ?? sm?.fs ?? md?.fs ?? lg?.fs ?? fs ?? 16,
      fontWeight: xs?.fw ?? sm?.fw ?? md?.fw ?? lg?.fw ?? fw ?? 400,
      lineHeight: xs?.lh
        ? `${xs?.lh}px`
        : sm?.lh
        ? `${sm?.lh}px`
        : md?.lh
        ? `${md?.lh}px`
        : lg?.lh
        ? `${lg?.lh}px`
        : lh
        ? `${lh}px`
        : '24px',
      textAlign: xs?.align ?? sm?.align ?? md?.align ?? lg?.align ?? align ?? 'center',
      marginTop: xs?.mt ?? sm?.mt ?? md?.mt ?? lg?.mt ?? mt ?? 0,
      marginBottom: xs?.mb ?? sm?.mb ?? md?.mb ?? lg?.mb ?? mb ?? 0,
    },
  }),
);

export const GreenText = styled(Typography)(({ theme, fs, fw, lh, align }) => ({
  fontSize: fs ?? 12,
  fontWeight: fw ?? 400,
  lineHeight: `${lh}px` ?? '16px',
  textAlign: align ?? 'center',
  color: theme.palette.link.primary,
}));

export const GreenSpanText = styled('span')(({ theme, align }) => ({
  textAlign: align ?? 'center',
  color: theme.palette.link.primary,
}));

export const GreenReactLink = styled(Link)(({ theme, align }) => ({
  textAlign: align ?? 'center',
  color: theme.palette.link.primary,
  textDecoration: 'none',
}));

export const BlueText = styled(Typography)(({ theme, fs, fw, lh, align }) => ({
  fontSize: fs ?? 14,
  fontWeight: fw ?? 500,
  lineHeight: `${lh}px` ?? '20px',
  textAlign: align ?? 'center',
  color: theme.palette.support.information,
}));

export const GreenLinkText = styled((props) => <a target="_blank" {...props}></a>)(
  ({ fs, fw, lh, align, theme }) => ({
    fontSize: fs ?? 16,
    fontWeight: fw ?? 600,
    lineHeight: `${lh}px` ?? '24px',
    textAlign: align ?? 'center',
    color: theme.palette.link.primary,
    textDecoration: 'none',
    cursor: 'pointer',
  }),
);

export const BlueLinkText = styled((props) => <a {...props}></a>)(
  ({ fs, fw, lh, align, theme }) => ({
    fontSize: fs ?? 16,
    fontWeight: fw ?? 600,
    lineHeight: `${lh}px` ?? '24px',
    textAlign: align ?? 'center',
    color: theme.palette.support.information,
    textDecoration: 'none',
    cursor: 'pointer',
  }),
);
