import React from 'react';
import { styled } from '@mui/system';
import { Box } from '@mui/material';
import { ENERGI_TOKENS, TOKEN_LIST } from 'constants/index';
import { EthBlueIcon, EthRedIcon, NRGLogoDarkIcon } from 'components/ImageComponent';
import Dropdown from './Dropdown';
import { formatNumber } from 'utils/numbers';

/********************  Styled Components  ********************/
const BalanceBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  border: `1px solid ${theme.palette.border.lighter}`,
  borderRadius: 10,
  overflow: 'hidden',
  marginBottom: theme.spacing(1),
}));

const StyledText = styled('p')(({ theme }) => ({
  color: theme.palette.icon.tertiary,
  fontSize: 14,
  lineHeight: '20px',
  letterSpacing: '0.25px',
  fontWeight: 500,
  margin: 0,
}));

const LabelBox = styled(Box)(() => ({
  display: 'flex',
  fontSize: 16,
  fontWeight: 600,
  alignItems: 'center',
}));

/********************  Main Component  ********************/
const ContractBox = ({ balances, visibility, setWalletSidebarOpen }) => {
  const dropdownList = {
    ETH: [
      { text: 'Add ETH', path: '/#', key: 'ETH_add' },
      { text: 'Wrap ETH', path: '/#', key: 'ETH_wrap' },
    ],
    WETH: [
      { text: 'Add ETH', path: '/#', key: 'WETH_add' },
      { text: 'Unwrap ETH', path: '/#', key: 'WETH_wrap' },
    ],
    GMI: [
      { text: 'Add GMI', path: '/#', key: 'GMI_add' },
      { text: 'Buy GMI', path: '/#', key: 'GMI_wrap' },
    ],
    NRG: [
      { text: 'Add NRG', path: '/#', key: 'NRG_add' },
      {
        text: 'Buy NRG',
        path: 'https://energi.world/get-nrg/',
        key: 'NRG_wrap',
      },
    ],
    WNRG: [
      { text: 'Add WNRG', path: '/#', key: 'WNRG_add' },
      { text: 'Buy WNRG', path: '/#', key: 'WNRG_wrap' },
    ],
  };

  if (!balances?.length) {
    return null;
  }

  return (
    <div>
      {balances.map((token, index) =>
        TOKEN_LIST.includes(token.symbol) ? (
          <BalanceBox key={index}>
            <LabelBox>
              {token.symbol === 'WETH' ? (
                <EthRedIcon width={32} height={32} style={{ marginRight: 16 }} />
              ) : ENERGI_TOKENS.includes(token.symbol) ? (
                <NRGLogoDarkIcon width={32} height={32} style={{ marginRight: 16 }} />
              ) : (
                <EthBlueIcon width={32} height={32} style={{ marginRight: 16 }} />
              )}
              <Box>
                {token.symbol}
                <StyledText>{token.name}</StyledText>
              </Box>
            </LabelBox>
            <LabelBox>
              <Box style={{ marginRight: 8, textAlign: 'end' }}>
                {visibility ? token.balance : '*****'}
                <StyledText>{visibility ? `$ ${formatNumber(token.value)}` : '*****'}</StyledText>
              </Box>
              <Dropdown
                symbol={token.symbol}
                list={dropdownList[token.symbol]}
                setWalletSidebarOpen={setWalletSidebarOpen}
              />
            </LabelBox>
          </BalanceBox>
        ) : (
          ''
        ),
      )}
    </div>
  );
};

export default ContractBox;
