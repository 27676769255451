import { NOTIFICATION } from 'utils/types';
import dotenv from 'dotenv';
dotenv.config();

const initialState = {
  status: false,
  message: '',
  type: '',
  link: null,
  notificationId: 0,
  description: '',
  position: 'bottom-right',
};

export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case NOTIFICATION:
      return {
        ...state,
        status: payload.status,
        message: payload.message,
        type: payload.type,
        link: payload.link,
        notificationId: state.notificationId + 1,
        description: payload.description,
        position: payload.position,
      };
    default:
      return state;
  }
}
