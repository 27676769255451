import React from 'react';
import CircleOutlinedIcon from '@mui/icons-material/CircleOutlined';
import CloseIcon from '@mui/icons-material/Close';
import {
  Menu,
  MenuItem as MuiMenuItem,
  styled,
  SwipeableDrawer,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { StandardButtonNotoutlined } from 'components/Button';
import { ChevronIcon } from 'components/ImageComponent';
import { GeneralFlexBox } from 'components/StyledComponents';
import { COLLECTION_VOLUMES_TIMEFRAMES } from 'constants/index';

/********************  Main Components  ********************/
export const TitleWithDropDownButton = ({
  children,
  isOpen,
  openDropDown,
  selectedTimeFrame,
  height,
  width,
  fontSize,
  fontWeight,
}) => {
  const below600 = useMediaQuery('(max-width: 600px)');

  const currentTimeframe = Object.keys(COLLECTION_VOLUMES_TIMEFRAMES).find(
    (key) => COLLECTION_VOLUMES_TIMEFRAMES[key] === selectedTimeFrame,
  );

  return (
    <GeneralFlexBox
      style={{
        marginBottom: !below600 && '40px',
      }}
    >
      <Typography fontSize={fontSize ?? 32} marginRight={1} fontWeight={fontWeight ?? 600}>
        {children}
      </Typography>
      <DropDownButton
        onClick={openDropDown}
        height={height}
        width={width}
        fontSize={fontSize}
        fontWeight={fontWeight}
      >
        {currentTimeframe}
        <ChevronIcon style={isOpen ? { transform: 'rotate(180deg)' } : {}} />
      </DropDownButton>
    </GeneralFlexBox>
  );
};

export const TimeFrameDropdown = ({
  isOpen,
  anchorEl,
  openDropDown,
  closeDropDown,
  selectTimeFrame,
}) => {
  const theme = useTheme();
  const below600 = useMediaQuery('(max-width: 600px)');

  const onTimeFrameSelect = (option) => {
    selectTimeFrame(option);
    closeDropDown();
  };

  return (
    <>
      {below600 ? (
        <SwipeableDrawer
          anchor={'bottom'}
          open={isOpen}
          onClose={closeDropDown}
          onOpen={openDropDown}
          sx={{
            '& > div': {
              borderRadius: '8px 8px 0 0',
              backgroundColor:
                theme.palette.mode === 'dark' ? 'rgba(0, 0, 0, 0.8)' : 'rgba(4, 17, 29, 0.8)',
            },
          }}
        >
          <GeneralFlexBox
            padding="16px"
            justify="space-between"
            sx={{ background: theme.palette.background.secondary }}
          >
            <Typography fontSize={20} fontWeight={600}>
              Time period
            </Typography>
            <CloseIcon onClick={closeDropDown} sx={{ cursor: 'pointer' }} />
          </GeneralFlexBox>

          {Object.keys(COLLECTION_VOLUMES_TIMEFRAMES).map((timeframe) => (
            <MenuItem
              key={timeframe}
              value={COLLECTION_VOLUMES_TIMEFRAMES[timeframe]}
              width="100vw"
              onClick={() => onTimeFrameSelect(COLLECTION_VOLUMES_TIMEFRAMES[timeframe])}
            >
              <CircleOutlinedIcon
                sx={{ width: '16px', height: '16px', mr: '10px', fill: theme.palette.icon.clock }}
              />
              {timeframe}
            </MenuItem>
          ))}
        </SwipeableDrawer>
      ) : (
        <StyledMenu anchorEl={anchorEl} open={isOpen} onClose={closeDropDown}>
          {Object.keys(COLLECTION_VOLUMES_TIMEFRAMES).map((timeframe) => (
            <MenuItem
              key={timeframe}
              value={COLLECTION_VOLUMES_TIMEFRAMES[timeframe]}
              width="220px"
              onClick={() => onTimeFrameSelect(COLLECTION_VOLUMES_TIMEFRAMES[timeframe])}
            >
              {timeframe}
            </MenuItem>
          ))}
        </StyledMenu>
      )}
    </>
  );
};

/********************  Styled Components  ********************/
const DropDownButton = styled(StandardButtonNotoutlined)(
  ({ height, width, fontSize, fontWeight }) => ({
    width: width ?? 'max-content',
    height: height,
    fontSize: fontSize ?? 32,
    fontWeight: fontWeight ?? 600,
    margin: 0,
    gap: 10,
    padding: 10,

    '@media (max-width: 600px)': {
      padding: 8,
    },
  }),
);

const StyledMenu = styled((props) => <Menu elevation={8} {...props} />)(({ theme }) => ({
  background: theme.palette.background.main,
  borderRadius: 4,
  maxHeight: '70%',
  minWidth: theme.spacing(40),

  '&& .MuiList-root': {
    padding: 0,
    border: `1px solid ${theme.palette.border.separator}`,
    boxShadow:
      '0px 0px 2px rgba(0, 0, 0, 0.14), 0px 2px 2px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2)',
  },
}));

const MenuItem = styled(MuiMenuItem)(({ theme, width }) => ({
  padding: 16,
  width: width ?? '100%',
  color: theme.palette.text.main,
  background: theme.palette.background.secondary,
  borderBottom: `1px solid ${theme.palette.border.separator}`,
  lineHeight: '24px',
  letterSpacing: '0.15px',
  fontWeight: 600,
  '&:last-child': {
    borderBottom: 'none',
  },
  '&.Mui-selected': {
    background: theme.palette.background.secondary,
    '&:hover': {
      backgroundColor: theme.palette.background.tertiary,
      boxShadow: `0px 0px 5px 0px ${theme.palette.shadow}`,
      transition: 'box-shadow 0.3s ease-in-out',
    },
  },
  '&:hover': {
    backgroundColor: theme.palette.background.tertiary,
    boxShadow: `0px 0px 5px 0px ${theme.palette.shadow}`,
    transition: 'box-shadow 0.3s ease-in-out',
  },
}));
