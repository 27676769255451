import React from 'react';
import { styled } from '@mui/material';

/********************  Styled Components  ********************/
const LeftBlur = styled('div')(() => ({
  position: 'absolute',
  width: 146,
  height: 146,
  left: 82,
  top: 352,
  background: 'rgba(0, 230, 118, 0.6)',
  filter: 'blur(100px)',
  zIndex: -1,
}));

const MiddleBlur = styled('div')(() => ({
  position: 'absolute',
  width: 146,
  height: 146,
  left: '50%',
  translate: '-50% -50%',
  top: 0,
  background: 'rgba(0, 230, 118, 0.6)',
  filter: 'blur(100px)',
  zIndex: -1,
}));

const RightBlur = styled('div')(() => ({
  position: 'absolute',
  width: 242,
  height: 242,
  left: 1232,
  top: 265,
  background: 'rgba(0, 230, 118, 0.6)',
  filter: 'blur(200px)',
  zIndex: -1,
}));

/********************  Main Component  ********************/
const BlurGreenBackground = ({ onlyTwoDots }) => {
  return (
    <>
      {!onlyTwoDots && <LeftBlur />}
      <MiddleBlur />
      <RightBlur />
    </>
  );
};

export default BlurGreenBackground;
