export const ENERGI_TESTNET = {
  id: 49797,
  name: 'Energi',
  network: 'energi',
  nativeCurrency: {
    decimals: 18,
    name: 'Energi',
    symbol: 'NRG',
  },
  rpcUrls: {
    public: { http: ['https://nodeapi.test.energi.network'] },
    default: { http: ['https://nodeapi.test.energi.network'] },
  },
  blockExplorers: {
    etherscan: { name: 'Energi explorer', url: 'https://explorer.test.energi.network' },
    default: { name: 'Energi explorer', url: 'https://explorer.test.energi.network' },
  },
  contracts: {},
};

export const ENERGI_MAINNET = {
  id: 39797,
  name: 'Energi',
  network: 'energi',
  nativeCurrency: {
    decimals: 18,
    name: 'Energi',
    symbol: 'NRG',
  },
  rpcUrls: {
    public: { http: ['https://nodeapi.energi.network'] },
    default: { http: ['https://nodeapi.energi.network'] },
  },
  blockExplorers: {
    etherscan: { name: 'Energi explorer', url: 'https://explorer.energi.network' },
    default: { name: 'Energi explorer', url: 'https://explorer.energi.network' },
  },
  contracts: {},
};

export const NETWORK_RPC_URLS = {
  1: 'https://ethereum.energi.network',
  5: 'https://ethereum.test.energi.network',
  39797: 'https://nodeapi.energi.network',
  49797: 'https://nodeapi.test.energi.network',
};
