import { Route } from 'react-router-dom';
import Notfound from 'pages/Notfound';
import Disconnected from 'pages/Disconnected';
import { LoadingBar } from 'components/General';
import useTokenContext from 'hooks/useTokenContext';
import useWalletContext from 'hooks/useWalletContext';

const ProtectedRoute = ({ ...rest }) => {
  const { isConnected, isInstalled } = useWalletContext();
  const { isAuthenticated } = useTokenContext();

  return (
    <>
      {isInstalled === undefined ? (
        <LoadingBar />
      ) : isAuthenticated && isConnected ? (
        <Route {...rest} />
      ) : (
        <Route {...rest} component={Disconnected} />
      )}
    </>
  );
};

const SemiProtectedRoute = ({ ...rest }) => {
  const { isConnected } = useWalletContext();

  return <>{isConnected ? <Route {...rest} /> : <Route {...rest} component={Notfound} />}</>;
};

export default function CustomRoute({ meta, ...rest }) {
  return (
    <>
      {meta?.needAuth && meta?.needConnectWallet ? (
        <ProtectedRoute {...rest} />
      ) : meta?.needConnectWallet ? (
        <SemiProtectedRoute {...rest} />
      ) : (
        <Route {...rest} />
      )}
    </>
  );
}
