import React from 'react';
import { styled, useMediaQuery } from '@mui/material';
import { useHistory } from 'react-router-dom';
import Modal, { Divider } from 'components/Modal';
import { Text } from 'components/Text';
import { GeneralFlexBox } from 'components/StyledComponents';
import { StandardButton, GrayButtonOutlined } from 'components/Button';

/********************  Styled Components  ********************/
const Wrapper = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '20px 24px',

  '@media (max-width: 480px)': {
    padding: '20px 16px',
  },
}));

const TextContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  width: '100%',
  borderRadius: 6,
  backgroundColor: theme.palette.background.filter,
  border: `1px solid ${theme.palette.border.decorative}`,
  padding: '16px 24px 16px 24px',
  marginTop: 20,
}));

/********************  Main Component  ********************/
const ClaimSuccessModal = ({ isOpen, onDismiss, width, claimData }) => {
  const below480 = useMediaQuery('(max-width: 480px)');
  const history = useHistory();

  return (
    <Modal title="Claim GMI" isOpen={isOpen} onDismiss={onDismiss} width={width} borderradius={10}>
      <Wrapper>
        <Text main="true" fs={20} fw={600} lh={28} mb={16}>
          🎉 {claimData.gmiAmount} GMI Claim succesful! 🎉
        </Text>
        <Text secondary="true" fs={12} fw={400} lh={16}>
          {claimData.gmiValue}
        </Text>
        <TextContainer>
          <Text secondary="true" align="left">
            You have succesfully claimed 3000 GMI Tokens. You can earn more rewards with GMI Farming
            and joining our referral program
          </Text>
        </TextContainer>
      </Wrapper>

      <Divider />

      <GeneralFlexBox width="100%" gap={16} justify="center" sx={{ py: 3 }}>
        <GrayButtonOutlined
          width={below480 ? 199 : 162}
          height={48}
          onClick={() => history.push('/rewards/referral_program')}
          sx={{ display: below480 && 'none' }}
        >
          Share with frens
        </GrayButtonOutlined>
        <StandardButton
          width={232}
          height={48}
          onClick={() => history.push('/rewards/referral_program/redeem')}
        >
          Earn Rewards with GMI
        </StandardButton>
      </GeneralFlexBox>
    </Modal>
  );
};

export default ClaimSuccessModal;
