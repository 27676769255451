import React, { useRef, useEffect } from 'react';
import { styled, useTheme } from '@mui/material/styles';
import { useStateIfMounted } from 'use-state-if-mounted';

const ScrollingContainer = styled('div')(({ textWidth, width }) => {
  let scrollingProperties = {};
  let componentWidth = 'auto';
  if (textWidth >= width) {
    scrollingProperties = {
      '&:hover': {
        textOverflow: 'clip',
        '& > *:only-child': {
          marginLeft: width - textWidth,
        },
      },
    };
    componentWidth = width;
  }

  return {
    margin: 0,
    width: componentWidth,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    '& > *:only-child': {
      transition: '3s',
      transitionTimingFunction: 'linear',
    },
    ...scrollingProperties,
  };
});

const ScrollingText = ({ width, text, styles = '', containerStyles = {} }) => {
  const ref = useRef();
  const theme = useTheme();
  const [textWidth, setTextWidth] = useStateIfMounted(0);

  useEffect(() => {
    if (ref.current) {
      setTextWidth(ref.current.offsetWidth);
    }
  }, []);

  return (
    <ScrollingContainer textWidth={textWidth} width={width} style={containerStyles}>
      <span style={typeof styles === 'function' ? styles(theme) : {}} ref={ref}>
        {text}
      </span>
    </ScrollingContainer>
  );
};

export default ScrollingText;
