import React from 'react';
import { styled, Typography, CircularProgress } from '@mui/material';
import { renderToStaticMarkup } from 'react-dom/server';
import {
  ConfirmationNumberOpen,
  ConfirmationNumberComplete,
  ConfirmationNumberClosed,
} from 'components/ImageComponent';

/********************  Styled Components  ********************/
const ConfirmationNumberCircleDiv = styled('div')(({ url }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '30px',
  height: '30px',
  fontSize: '12px',
  fontWeight: 500,
  marginRight: 16,
  backgroundImage: `url("data:image/svg+xml,${url}")`,
  backgroundRepeat: 'no-repeat',
}));

const CircleDiv = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'row',
  border: `1px solid white`,
  borderRadius: '30px',
  width: '30.5px',
  height: '30.5px',
  marginLeft: '-0.5px',
  marginRight: '16px',
}));

const CircleText = styled(Typography)(({ theme }) => ({
  position: 'absolute',
  top: '21px',
  left: '36px',
  color: theme.palette.text.default,
  fontSize: '12px',
  fontWeight: 500,
}));

/********************  Main Component  ********************/
const ConfirmationIconString = {
  Loading: encodeURIComponent(renderToStaticMarkup(<ConfirmationNumberOpen />)),
  Closed: encodeURIComponent(renderToStaticMarkup(<ConfirmationNumberClosed />)),
  Complete: encodeURIComponent(renderToStaticMarkup(<ConfirmationNumberComplete />)),
};

const CircularLoadingNumber = ({ value, status }) => {
  if (status === 'Loading') {
    return (
      <CircleDiv>
        <CircleText>{value}</CircleText>
        <CircularProgress variant="indeterminate" disableShrink size={28.5} thickness={3.5} />
      </CircleDiv>
    );
  }

  return (
    <ConfirmationNumberCircleDiv url={ConfirmationIconString[status]}>
      {value}
    </ConfirmationNumberCircleDiv>
  );
};

export default CircularLoadingNumber;
