import React, { useContext } from 'react';
import { useStateIfMounted } from 'use-state-if-mounted';
import { styled } from '@mui/system';
import { MoreVert as MoreIcon } from '@mui/icons-material';
import { Grow, Paper, MenuList, Popper } from '@mui/material';
import { MenuItem, MenuText, ToolTip } from 'components/StyledComponents';
import { MENU_CLOSING_TIME } from 'constants/index';
import { CheckoutModalContext } from 'pages/context';

/********************  Styled Components  ********************/
const SubMenuComponent = styled(Popper)(() => ({
  width: '220px',
  margin: '0px',
  zIndex: 1500,
  position: 'absolute',
  borderRadius: 14,
}));

const SubMenuList = styled(MenuList)(() => ({
  padding: '0px',
  borderRadius: 14,
}));

/*const Link = styled('a')(({ theme }) => ({
  color: theme.palette.text.main,
  lineHeight: '24px',
  letterSpacing: '0.15px',
  fontWeight: 600,
  textDecoration: 'none',
}));*/

/********************  Main Component  ********************/
const Dropdown = ({ symbol, list, setWalletSidebarOpen }) => {
  const [anchorEl, setAnchorEl] = useStateIfMounted(null);
  const open = Boolean(anchorEl);
  const { openModal } = useContext(CheckoutModalContext);

  const anchorRef = React.useRef(null);
  const prevOpen = React.useRef(open);

  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }
    prevOpen.current = open;
  }, [open]);

  const handleClick = (event) => {
    if (anchorEl) {
      setAnchorEl(null);
    } else {
      setAnchorEl(event.currentTarget);
    }
  };

  const leaveMenu = () => {
    setTimeout(() => {
      setAnchorEl(null);
    }, MENU_CLOSING_TIME);
  };

  return (
    <div onMouseLeave={leaveMenu}>
      <ToolTip title="More" placement="left" id={'more'}>
        <MoreIcon
          id={`submenu-button-${symbol}`}
          ref={anchorRef}
          aria-controls={open ? `sub-menu-${symbol}` : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          onClick={handleClick}
          sx={{ '&:hover': { cursor: 'pointer' } }}
        />
      </ToolTip>
      <SubMenuComponent
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps }) => (
          <Grow
            {...TransitionProps}
            sx={{
              transformOrigin: 'left bottom',
            }}
          >
            <Paper elevation={2}>
              <SubMenuList
                autoFocusItem={open}
                id="composition-menu"
                aria-labelledby="composition-button"
                sx={{ padding: 0 }}
              >
                <MenuItem
                  key={list[0].key}
                  sx={{
                    '&:last-child': {
                      borderBottom: 'none',
                    },
                  }}
                  onClick={() => {
                    setWalletSidebarOpen(false);
                    openModal([], 'add_funds', 'buy');
                  }}
                >
                  <MenuText>{list[0].text}</MenuText>
                </MenuItem>
                <MenuItem
                  key={list[1].key}
                  sx={{
                    '&:last-child': {
                      borderBottom: 'none',
                    },
                  }}
                  onClick={() => {
                    const newWindow = window.open(list[1].path, '_blank');
                    if (newWindow) {
                      newWindow.opener = null; // Set the opener to null for security (noopener)
                      newWindow.referrerPolicy = 'no-referrer'; // Set the referrer policy (noreferrer)
                    }
                  }}
                >
                  <MenuText>{list[1].text}</MenuText>
                </MenuItem>
              </SubMenuList>
            </Paper>
          </Grow>
        )}
      </SubMenuComponent>
    </div>
  );
};

export default Dropdown;
