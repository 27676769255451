import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useStateIfMounted } from 'use-state-if-mounted';
import { styled } from '@mui/material/styles';
import {
  Button,
  Box,
  TextField,
  Stack,
  InputAdornment,
  useMediaQuery,
  CircularProgress,
  ButtonBase,
} from '@mui/material';
import {
  Instagram as InstagramIcon,
  Visibility as VisibilityIcon,
  Public as PublicIcon,
} from '@mui/icons-material';
import { VerificationWarningIcon, TwitterXIcon } from 'components/ImageComponent';
import { Form, TextInput, UploadInput } from 'components/FormElements';
import { AddressBox } from 'components/General';
import useCompareValues from 'hooks/useCompareValues';
import VerifyEmailModal from 'components/LockEmailConfirmation/VerifyEmailModal';
import useWalletContext from 'hooks/useWalletContext';
import { useUserContext } from 'context';
import defaultProfileBanner from 'assets/images/profile_banner.png';
import useNotification from 'hooks/useNotification';

/********************  Styled Components  ********************/
const StackContainer = styled(Stack)(({ theme }) => ({
  background: theme.palette.background.default,
  padding: theme.spacing(0, 15, 4),
  '@media (max-width: 1240px)': {
    padding: theme.spacing(0, 8, 4),
  },
  '@media (max-width: 905px)': {
    padding: theme.spacing(0, 12, 4),
  },
  '@media (max-width: 896px)': {
    padding: theme.spacing(0, 4, 8),
  },
  '@media (max-width: 500px)': {
    padding: theme.spacing(0, 2, 8),
  },
}));

const Header = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  flexWrap: 'wrap',
}));

const Heading = styled('p')(() => ({
  fontSize: 42,
  fontWeight: 500,
  padding: 0,
  margin: 0,
  width: 510,
  '@media (max-width: 1280px)': {
    width: 480,
  },
  '@media (max-width: 500px)': {
    fontSize: 24,
  },
}));

const StyledPreviewButton = styled(Button)(({ theme }) => ({
  width: 129,
  height: 40,
  color: theme.palette.text.secondary,
  border: `1px solid ${theme.palette.border.decorative}`,
  borderRadius: theme.spacing(1.25),
  fontSize: 16,
  fontWeight: 600,
  backgroundColor: theme.palette.background.secondary,
  '&:hover': {
    color: theme.palette.text.main,
  },
}));

const FormContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  marginTop: theme.spacing(3),
  alignItems: 'flex-start',
  justifyContent: 'space-center',
  '@media (max-width: 904px)': {
    flexDirection: 'column',
  },
  '@media (max-width: 500px)': {
    width: '100%',
  },
}));

const LeftSection = styled(Box)(() => ({
  width: 510,
  '@media (max-width: 1280px)': {
    width: 480,
  },
  '@media (max-width: 904px)': {
    width: '100%',
  },
}));

const RightSection = styled(Stack)(({ theme }) => ({
  marginTop: theme.spacing(5),
  width: '100%',
  '@media (max-width: 600px)': {
    flexDirection: 'row',
    width: '100%',
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'flex-start',
  },
}));

const SocialMedia = styled('div')(({ theme }) => ({
  marginBottom: theme.spacing(2),
}));

const SocialMediaInput = styled(TextField)(({ theme }) => ({
  display: 'block',
  margin: theme.spacing(1, 0),
  '& .MuiInputBase-root': {
    width: '100%',
  },
  '& input': {
    fontSize: 14,
    fontWeight: 500,
    padding: theme.spacing(1.7, 2),
    paddingLeft: theme.spacing(0.5),
  },
  '& fieldset': {
    border: `1px solid ${theme.palette.border.separator}`,
  },
  '& .Mui-focused fieldset.MuiOutlinedInput-notchedOutline': {
    borderColor: theme.palette.border.high,
  },
  '&::placeholder': {
    color: theme.palette.background.secondary,
  },
  '& svg': {
    paddingRight: 0,
    marginRight: 0,
    color: theme.palette.text.secondary,
  },

  '& input:-webkit-autofill': {
    WebkitBoxShadow: `0 0 0 30px ${theme.palette.background.default} inset !important`,
    transition: 'background-color 5000s ease-in-out 0s',
  },
}));

const LinksHeading = styled('p')(({ theme }) => ({
  fontSize: 16,
  fontWeight: 600,
  margin: 0,
  marginBottom: theme.spacing(1),
}));

const StyledVerificationWarningIcon = styled(VerificationWarningIcon)(({ theme }) => ({
  verticalAlign: -5,
  marginRight: theme.spacing(1),
}));

const PendingText = styled('span')(({ theme }) => ({
  fontSize: 14,
  fontWeight: 500,
  color: theme.palette.text.tertiary,
}));

const ResendEmailButton = styled(ButtonBase)(({ theme }) => ({
  color: theme.palette.support.warning2,
  fontSize: 14,
  fontWeight: 500,
}));

/********************  Sub Component  ********************/
const PreviewButton = ({ address }) => {
  return (
    <StyledPreviewButton
      LinkComponent={Link}
      target="_blank"
      to={`/portfolio/${address}`}
      startIcon={<VisibilityIcon />}
      variant="text"
      rel="noopener noreferrer"
      disableRipple
    >
      Preview
    </StyledPreviewButton>
  );
};

/********************  Main Component  ********************/
const Profile = () => {
  const below904 = useMediaQuery('(max-width:904px)');
  const below600 = useMediaQuery('(max-width: 600px)');

  const { address } = useWalletContext();
  const showNotification = useNotification();

  const {
    userData,
    formData,
    updateUserData,
    showEmailModal,
    setShowEmailModal,
    closeEmailModal,
    isLoading,
  } = useUserContext();

  const [currentData, setCurrentData] = useStateIfMounted({ ...formData });
  const [banner, setBanner] = useStateIfMounted('');
  const [avatar, setAvatar] = useStateIfMounted('');
  const [showVerificationWarning, setShowVerificationWarning] = useStateIfMounted(false);

  const isDataUnchanged = useCompareValues(currentData, formData);

  const isNotAvailableToSave =
    !address || isDataUnchanged || Object.keys(currentData).some((key) => currentData[key].error);

  useEffect(() => {
    setCurrentData({ ...formData });
  }, [formData]);

  useEffect(() => {
    const { banner, avatar } = userData;
    avatar && setAvatar(avatar);
    banner && setBanner(banner);
  }, [userData]);

  const handleTextChange = (field, value) => {
    setCurrentData((prev) => ({
      ...prev,
      [field]: {
        ...prev[field],
        error: value !== '' && !prev[field]?.validator?.test(value),
        value: value,
      },
    }));
  };

  const handleImageChange = async (e, type, setState) => {
    const file = e.target.files[0];
    // file size should not exceed 100 MB
    if (file.size > 100e6) {
      return showNotification({ type: 'error', message: 'File size exceeds 100 MB' });
    }
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.addEventListener('load', (e) => {
      setState(e.target.result);
      setCurrentData({ ...currentData, type: e.target.result });
    });
  };

  return (
    <>
      <VerifyEmailModal
        isOpen={showEmailModal}
        onDismiss={() => {
          closeEmailModal();
          setShowVerificationWarning(true);
        }}
        email={currentData.email.value}
      />
      <StackContainer sx={{ paddingBottom: '80px' }}>
        {!below600 && (
          <Header>
            <Heading>{below600 ? 'Profile' : 'Profile Settings'}</Heading>
            <Box flex={1} display="flex" justifyContent="center">
              {!below904 && <PreviewButton address={address} />}
            </Box>
          </Header>
        )}
        <Form
          submitButtonText={
            isLoading ? (
              <Box sx={{ lineHeight: 0 }}>
                <CircularProgress sx={{ p: 1, color: 'white' }} />
              </Box>
            ) : (
              'Save'
            )
          }
          disabled={isNotAvailableToSave}
          onSubmit={(event) => updateUserData({ data: event, avatar, banner })}
          extra={below904 ? <PreviewButton address={address} /> : null}
          buttonProps={{
            sx: {
              display: 'flex',
              flexDirection: below904 ? 'row !important' : 'column !important',
              gap: '16px',
            },
          }}
        >
          <FormContainer>
            <LeftSection>
              <TextInput
                error={currentData.username.error}
                helperText={
                  currentData.username.error
                    ? 'Invalid username. Can be 50 characters long and can contain only letters, numbers, hyphens(-), and underscores(_).'
                    : ''
                }
                placeholder="Enter username"
                title="Username"
                name="username"
                value={currentData.username.value || ''}
                height={46}
                onChange={(e) => handleTextChange('username', e.target.value)}
                sx={{
                  '& input, & textarea': {
                    color: 'red',
                  },
                }}
              />
              <TextInput
                error={currentData.bio.error}
                helperText={
                  currentData.bio.error
                    ? 'Invalid Bio. Can be 160 characters long and can contain only letters, numbers, (-), (_), (@), and (.).'
                    : ''
                }
                placeholder="Tell the world your story!"
                title="Bio"
                name="bio"
                value={currentData.bio.value || ''}
                onChange={(e) => handleTextChange('bio', e.target.value)}
                rows={3}
                sx={{
                  '& input, & textarea': {
                    color: 'red',
                  },
                }}
              />
              <TextInput
                error={currentData.email.error}
                helperText={
                  currentData.email.error
                    ? 'Invalid Email address. Please enter correct email address'
                    : ''
                }
                placeholder="Enter email"
                title="Email Address"
                name="email"
                value={currentData.email.value || ''}
                required
                height={46}
                onChange={(e) => handleTextChange('email', e.target.value)}
              />
              {!userData.verifiedEmail && showVerificationWarning && (
                <Box marginTop={-2} marginBottom={2} display="flex" justifyContent="space-between">
                  <Box>
                    <StyledVerificationWarningIcon />
                    <PendingText>Verification Pending</PendingText>
                  </Box>
                  <Box marginRight={2}>
                    <ResendEmailButton disableRipple onClick={() => setShowEmailModal(true)}>
                      Resend Email
                    </ResendEmailButton>
                  </Box>
                </Box>
              )}
              <SocialMedia>
                <LinksHeading>Links</LinksHeading>
                <SocialMediaInput
                  error={currentData.twitter.error}
                  helperText={currentData.twitter.error ? 'Invalid profile address' : ''}
                  name="twitter"
                  placeholder="Your X profile address"
                  value={currentData.twitter.value || ''}
                  onChange={(e) => handleTextChange('twitter', e.target.value)}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <TwitterXIcon />
                      </InputAdornment>
                    ),
                  }}
                />
                <SocialMediaInput
                  error={currentData.instagram.error}
                  helperText={
                    currentData.instagram.error ? 'Invalid Instagram profile address' : ''
                  }
                  name="instagram"
                  placeholder="Your Instagram profile address"
                  value={currentData.instagram.value || ''}
                  onChange={(e) => handleTextChange('instagram', e.target.value)}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <InstagramIcon />
                      </InputAdornment>
                    ),
                  }}
                />
                <SocialMediaInput
                  error={currentData.website.error}
                  helperText={currentData.website.error ? 'Invalid webiste address' : ''}
                  name="website"
                  placeholder="Your website address"
                  value={currentData.website.value || ''}
                  onChange={(e) => handleTextChange('website', e.target.value)}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <PublicIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </SocialMedia>
              <AddressBox address={address} label="Wallet Address" />
            </LeftSection>
            <RightSection>
              <UploadInput
                type="avatar"
                image={avatar}
                onFileChange={(e) => handleImageChange(e, 'avatar', setAvatar)}
              />
              <UploadInput
                type="banner"
                image={banner || defaultProfileBanner}
                onFileChange={(e) => handleImageChange(e, 'banner', setBanner)}
              />
            </RightSection>
          </FormContainer>
        </Form>
      </StackContainer>
    </>
  );
};

export default Profile;
