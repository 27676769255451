import React, { createContext, useCallback, useContext, useEffect } from 'react';
import { useStateIfMounted } from 'use-state-if-mounted';
import useWalletContext from 'hooks/useWalletContext';
import userService from 'services/user';
import { isAddress } from 'utils';
import { useLocation } from 'react-router-dom';
import useTokenContext from 'hooks/useTokenContext';

const FavoriteNftsContext = createContext();
export const useFavoriteNftsContext = () => useContext(FavoriteNftsContext);

export const FavoriteNftsProvider = ({ children }) => {
  const [favoriteNfts, setFavoriteNfts] = useStateIfMounted([]);
  const [userFavoriteNfts, setUserFavoriteNfts] = useStateIfMounted([]);

  const { isAuthenticated } = useTokenContext();
  const { address } = useWalletContext();
  const location = useLocation();

  const addressFromUrl = location.pathname.split('/')[2];

  const getMyFavoriteNfts = useCallback(async () => {
    const data = await userService.getFavoriteNfts(address);
    setFavoriteNfts(data?.rows);
  }, [address, setFavoriteNfts]);

  const getProfileFavoriteNfts = useCallback(async () => {
    if (isAddress(addressFromUrl)) {
      const data = await userService.getFavoriteNfts(addressFromUrl);
      setUserFavoriteNfts(data?.rows);
    }
  }, [addressFromUrl, setUserFavoriteNfts]);

  const refetch = () => {
    getMyFavoriteNfts();
    getProfileFavoriteNfts();
  };

  useEffect(() => {
    if (!isAuthenticated) {
      setFavoriteNfts([]);
      return;
    }

    getMyFavoriteNfts();
  }, [getMyFavoriteNfts, isAuthenticated, setFavoriteNfts]);

  useEffect(() => {
    getProfileFavoriteNfts();
  }, [getProfileFavoriteNfts]);

  return (
    <FavoriteNftsContext.Provider value={{ favoriteNfts, userFavoriteNfts, refetch }}>
      {children}
    </FavoriteNftsContext.Provider>
  );
};
