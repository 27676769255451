import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { useStateIfMounted } from 'use-state-if-mounted';
import { Avatar, Button, Typography, Box, CircularProgress, styled, useTheme } from '@mui/material';

import { CARD_OPTIONS, COIN } from 'constants/index';
import useWalletContext from 'hooks/useWalletContext';
import useIsMyAddress from 'hooks/useIsMyAddress';
import { CheckoutModalContext } from 'pages/context';
import { defaultImageLoadErrorHandler } from 'utils/helper';
import { getFullMediaUrl } from 'utils/image';
import { formatNumber } from 'utils/numbers';
import { getListingPrice } from 'utils/order';

import { ReactComponent as DotLightIcon } from 'assets/images/dotLight.svg';
import { ReactComponent as DotDarkIcon } from 'assets/images/dotDark.svg';
import { PositionToolTip, Price } from 'components/StyledComponents';
/* 
*  Hide for soft lunch: 
*  https://git.energi.software/energi/tech/dweb/nft/marketplace/webapp/-/issues/532'
import { FavoriteIconButton } from 'components/Icons';
*/
//import { ApprovedIcon, ApprovedDarkIcon } from 'components/ImageComponent';
import OptionsDropdown from '../OptionsDropdown';
import { ScrollingText } from '../';

/********************  Styled Components  ********************/
const ArtCardContainer = styled('div')(({ theme, preview }) => ({
  width: '100%',
  height: '100%',
  border: `1px solid ${theme.palette.border.separator}`,
  borderRadius: 6,
  pointerEvents: preview ? 'none' : 'all',
}));

const Card = styled('div')(({ theme }) => ({
  transition: 'top ease 0.6s',
  width: '100%',
  overflow: 'hidden',
  background: theme.palette.background.secondary,
  borderRadius: 6,

  '&:hover': {
    background: theme.palette.background.hover,
  },

  '&:active': {
    background: theme.palette.background.linear,
  },
}));

const ImageContainer = styled('div')(() => ({
  width: '100%',
  height: 0,
  paddingTop: '100%',
  position: 'relative',
}));

const CardImage = styled('img')(() => ({
  width: '100%',
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  objectFit: 'cover',
}));

const LoadingImage = styled('div')(() => ({
  width: '100%',
  height: '100%',
  justifyContent: 'center',
  alignItems: 'center',
  display: 'flex',
  position: 'absolute',
  top: 0,
  left: 0,
}));

const CardContent = styled('div')(({ theme, layout }) => ({
  display: 'flex',
  flexDirection: 'column',
  padding: layout === 'SMALL' ? theme.spacing(1, 1, 0, 0.7) : theme.spacing(1, 1, 0, 2.2),

  '@media(max-width: 700px)': {
    padding: layout === 'SMALL' ? theme.spacing(0, 1, 0, 0.5) : theme.spacing(0, 1, 0, 2.2),
  },

  '@media(max-width: 500px)': {
    padding: layout === 'LARGE' ? theme.spacing(0, 1, 0, 2.2) : theme.spacing(0.5),
  },
}));

const AssetNameStyles = (theme) => ({
  letterSpacing: 0.25,
  lineHeight: '24px',
  fontWeight: 500,
  color: theme.palette.text.main,
  whiteSpace: 'nowrap',
});

const Heading = styled('div')(({ theme, layout }) => ({
  fontSize: 14,
  fontWeight: 500,
  color: theme.palette.text.main,
  margin: '0px',
  letterSpacing: 0.25,
  lineHeight: '24px',
  display: 'flex',
  alignItems: 'center',
  width: '100%',

  '@media(max-width: 500px)': {
    fontSize: layout === 'LARGE' ? 14 : 10,
    lineHeight: layout === 'LARGE' ? '24px' : '15px',
  },
}));

const SubHeading = styled(Typography)(({ theme, layout }) => ({
  fontSize: layout === 'LARGE' ? 12 : 10,
  fontWeight: layout === 'LARGE' ? 400 : 500,
  color: theme.palette.text.secondary,
  margin: '0px',
  lineHeight: layout === 'LARGE' ? '16px' : '15px',
  letterSpacing: '0.4px',
}));

const ContentHeading = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
}));

const SmallContentHeading = styled('div')(() => ({
  display: '-webkit-box',
}));

const UnlitedText = styled(Typography)(({ theme, marginTop }) => ({
  display: 'flex',
  alignItems: 'end',
  color: theme.palette.text.secondary,
  fontSize: '16px',
  fontWeight: 600,
  lineHeight: '24px',
  marginTop: marginTop ?? '18px',
  marginBottom: '16px',
  textAlign: 'left',
}));

const Footer = styled(Box)(() => ({
  display: 'flex',
  padding: '8px',
  justifyContent: 'space-between',
  alignItems: 'center',
  gap: 8,

  '@media(max-width: 500px)': {
    gap: 4,
  },
}));

/* 
*  Hide for soft lunch: 
*  https://git.energi.software/energi/tech/dweb/nft/marketplace/webapp/-/issues/532
const FavoriteDiv = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
}));
*/

const CustomizedButton = styled(Button)(({ theme, layout, width }) => ({
  backgroundColor: theme.palette.primary.main,
  borderRadius: 10,
  color: theme.palette.text.white,
  fontSize: layout === 'SMALL' ? 14 : 16,
  fontWeight: 600,
  padding: 0,
  letterSpacing: '0.15px',
  lineHeight: '24px',
  width: width ?? '50%',
  height: 40,
  boxShadow: 'none',
  borderRadius: 8,

  '&:hover': {
    background: theme.palette.background.button.hovered,
    boxShadow: 'none',
  },
  '&:active': {
    background: theme.palette.background.button.pressed,
    boxShadow: 'none',
  },

  '&:disabled': {
    background: theme.palette.button.active,
    color: theme.palette.text.white,
    opacity: 0.24,
  },

  '@media(max-width: 500px)': {
    fontSize: layout === 'SMALL' ? 12 : 16,
    fontWeight: layout === 'SMALL' ? 500 : 600,
    letterSpacing: layout === 'SMALL' ? '0.4px' : '0.15px',
    lineHeight: layout === 'SMALL' ? '16px' : '24px',
  },
}));

const StyledButton = styled(Button)(({ theme, layout }) => ({
  backgroundColor: 'transparent',
  color: theme.palette.button.tertiary,
  fontSize: layout === 'SMALL' ? 14 : 16,
  fontWeight: layout === 'SMALL' ? 500 : 600,
  letterSpacing: layout === 'SMALL' ? '0.25px' : '0.15px',
  lineHeight: layout === 'SMALL' ? '20px' : '24px',
  width: '50%',
  textAlign: 'center',
  whiteSpace: 'nowrap',
  height: 40,
  padding: 0,
  borderRadius: 8,

  '&:disabled': {
    color: theme.palette.button.tertiary,
    opacity: 0.24,
  },

  '&:hover': {
    backgroundColor: 'transparent',
    cursor: 'pointer',
  },

  '@media(max-width: 700px)': {
    fontSize: layout === 'SMALL' ? 14 : 16,
    fontWeight: 600,
    letterSpacing: '0.15px',
    lineHeight: '24px',
  },

  '@media(max-width: 500px)': {
    fontSize: layout === 'SMALL' ? 12 : 16,
    fontWeight: layout === 'SMALL' ? 500 : 600,
    letterSpacing: layout === 'SMALL' ? '0.4px' : '0.15px',
    lineHeight: layout === 'SMALL' ? '16px' : '24px',
  },
}));

const BoxContent = styled(Box)(() => ({
  width: '100%',
  '&:hover': {
    cursor: 'pointer',
  },
}));

const HeaderContainer = styled(Box)(({ size, theme }) => ({
  padding: size === 'LARGE' ? theme.spacing(0.82, 1) : theme.spacing(1),
  boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.05)',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',

  '@media(max-width: 500px)': {
    height: size === 'LARGE' ? 'auto' : 35,
    padding: size === 'LARGE' ? theme.spacing(0.82, 1) : theme.spacing(1, 1),
  },
}));

/************************* Main Component  **************************/
const ArtCard = ({ card, size = 'LARGE', type = null, preview = false, titleCardWidth }) => {
  const theme = useTheme();

  const history = useHistory();
  const { openModal } = useContext(CheckoutModalContext);
  const { wrongNetwork } = useWalletContext();

  const [loading, setLoading] = useStateIfMounted(true);

  const owned = useIsMyAddress(card.lastOwnerAddress);
  const listingPrice = getListingPrice(card);

  const handleSellClick = () => {
    return history.push({
      pathname: `/asset/${card.contractAddress}/${card.tokenId}/sell`,
      state: history.location.state,
    });
  };

  const handleClick = () => {
    history.push(`/asset/${card.contractAddress}/${card.tokenId}`);
  };

  return (
    <ArtCardContainer preview={preview}>
      <Card size={size} type={type}>
        <HeaderContainer size={size}>
          <Heading layout={size}>
            {size === 'LARGE' && (
              <Avatar
                sx={{
                  width: 36,
                  height: 36,
                  marginRight: 1,
                  border: `2px solid ${theme.palette.border.decorative}`,
                  borderRadius: '50%',
                }}
                alt="Avatar"
                src={getFullMediaUrl(card.NftContract.contractThumbnailPathName)}
              />
            )}
            <span
              style={{
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                maxWidth: titleCardWidth,
              }}
            >
              {card.NftContract.name}
            </span>
            {
              // TODO: Verified Collections
              /*card.approved || theme.palette.mode === 'dark' ? (
              <ApprovedIcon
                width={size === 'LARGE' ? 14 : 12}
                height={size === 'LARGE' ? 14 : 12}
              />
            ) : (
              <ApprovedDarkIcon
                width={size === 'LARGE' ? 14 : 12}
                height={size === 'LARGE' ? 14 : 12}
              />
            )*/
            }
          </Heading>
          <Box>
            <OptionsDropdown
              size={size}
              card={card}
              keyName={card.name}
              Icon={theme.palette.mode === 'light' ? DotDarkIcon : DotLightIcon}
              menus={CARD_OPTIONS}
              title={card.name}
            />
          </Box>
        </HeaderContainer>
        <BoxContent style={{ display: 'inline-grid' }} onClick={handleClick}>
          <ImageContainer>
            <CardImage
              src={getFullMediaUrl(card?.thumbnailPathName, card?.rawFileUrl)}
              alt=""
              loading="lazy"
              onLoad={() => setLoading(false)}
              onError={defaultImageLoadErrorHandler}
            />
            {loading && (
              <LoadingImage>
                <CircularProgress />
              </LoadingImage>
            )}
          </ImageContainer>
        </BoxContent>

        <CardContent style={{ width: '100%' }} layout={size}>
          <ContentHeading layout={size}>
            <PositionToolTip title={card.name} placement="bottom-start">
              <div>
                <ScrollingText
                  width={titleCardWidth}
                  fontSize={size === 'LARGE' ? 14 : 12}
                  text={card?.name || card?.tokenId}
                  styles={AssetNameStyles}
                />
              </div>
            </PositionToolTip>
            {
              // TODO: Favorites
              /*
            <FavoriteDiv>
              <FavoriteIconButton nft={card} placement="bottom" />
            </FavoriteDiv>
            */
            }
          </ContentHeading>
          {card.highestOffer && (
            <SmallContentHeading style={{ height: 16 }}>
              <Price
                price={card.highestOffer}
                currency={COIN.Symbol}
                logocolor="red"
                size={size === 'LARGE' ? '12px' : '10px'}
                logo={16}
                show={true}
                color={theme.palette.text.main}
                weight={500}
                valid={size === 'LARGE' ? true : false}
              />
              <SubHeading layout={size}>Highest Offer</SubHeading>
            </SmallContentHeading>
          )}
          {listingPrice ? (
            <SmallContentHeading
              style={{
                height: size === 'LARGE' ? 24 : 16,
                marginTop: card.highestOffer ? (size === 'LARGE' ? 0 : 2) : '18px',
              }}
            >
              <Price
                price={listingPrice}
                currency={COIN.Symbol}
                logocolor="blue"
                size={size === 'LARGE' ? '16px' : '12px'}
                logo={16}
                show={true}
                color={theme.palette.text.main}
                weight={size === 'LARGE' ? 600 : 500}
                valid={size === 'LARGE' ? true : false}
              />
              {card?.dollar > 0 && (
                <SubHeading layout={size} style={{ marginTop: size === 'LARGE' ? 3 : 0 }}>
                  (${formatNumber(card.dollar)})
                </SubHeading>
              )}
            </SmallContentHeading>
          ) : (
            <UnlitedText marginTop={'2px'}>Unlisted</UnlitedText>
          )}
        </CardContent>

        <Footer spacing={2}>
          {owned ? (
            <CustomizedButton
              variant="contained"
              onClick={handleSellClick}
              layout={size}
              disabled={wrongNetwork}
              disableRipple
              width={owned ? '100%' : '50%'}
            >
              {Boolean(listingPrice) ? 'Edit Listing' : 'Sell Item'}
            </CustomizedButton>
          ) : (
            <CustomizedButton
              variant="contained"
              onClick={() => openModal(card, 'checkout', 'buy')}
              layout={size}
              disabled={wrongNetwork || !listingPrice || card.userOwnedAsset}
              disableRipple
            >
              Buy Now
            </CustomizedButton>
          )}
          {!owned && (
            <StyledButton
              onClick={() => openModal(card, 'make_offer', 'offer')}
              layout={size}
              disabled={wrongNetwork || card.userOwnedAsset}
            >
              Make Offer
            </StyledButton>
          )}
        </Footer>
      </Card>
    </ArtCardContainer>
  );
};

export default React.memo(ArtCard);
